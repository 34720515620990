import { Key, ReactElement, useCallback, useMemo, useState } from 'react';
import type { Props, SingleValue } from 'react-select';

export type OptionType = { value: Key; label: Key | ReactElement; toString?(): string };

type SelectProps<Option, IsMultiple extends boolean> = Required<Props<Option, false>>;

export type UseSelectOptions<Option> = {
  defaultValue?: SingleValue<Option>;
  options?: { value: string | number; label: string }[];
  onChange?: (value: SingleValue<Option>) => void;
};

export const useSelect = <Option = OptionType>({ defaultValue, onChange: onChange }: UseSelectOptions<Option>) => {
  const [value, setValue] = useState<SingleValue<Option>>(defaultValue || null);
  const [focused, setFocused] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const parsedValue = useMemo<Key[] | Key | null>(() => {
    if (Array.isArray(value)) {
      return value.map<Key>((item) => item.value);
    } else {
      return (value as OptionType | null)?.value || null;
    }
  }, [value]);

  const handleChange = useCallback<SelectProps<any, false>['onChange']>(
    (value) => {
      setValue(value);
      onChange?.(value);
    },
    [onChange],
  );

  const onFocus = useCallback<SelectProps<any, false>['onFocus']>(() => {
    setFocused(true);
  }, []);

  const onBlur = useCallback<SelectProps<any, false>['onBlur']>(() => {
    setTouched(true);
    setFocused(false);
  }, []);

  return { value, parsedValue, focused, touched, onChange: handleChange, onFocus, onBlur };
};
