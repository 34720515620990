import { UpdateAccountDto } from '@root/modules/accounts/dtos/update-account.dto';
import { IAccount } from '@root/modules/accounts/types/account';
import { Id } from '@root/shared/utils/types';

export class UpdateAccountDtoMapper {
  public static toDomain(account: IAccount, userId: Id): UpdateAccountDto {
    return {
      userId: userId.toString(),
      accountId: account.id,
      username: account.user,
      password: '',
      brokerServerId: account.brokerServerId,
      serviceAccountId: account.serviceAccountId,
      proxyServerId: account.proxyServerId,
      maxDrawDownLimit: account.maxDrawDownLimit,
    };
  }

  public static toPersistence({ userId, accountId, ...values }: UpdateAccountDto) {
    return {
      user: Number(values.username) || undefined,
      password: values.password,
      brokerServerId: values.brokerServerId,
      serviceAccountId: values.serviceAccountId,
      isServiceAccountAssigned: !!values.serviceAccountId,
      proxyServerId: values.proxyServerId,
      maxDrawDownLimit: values.maxDrawDownLimit,
    };
  }
}
