const generatedColors = {};

export const generateColor = (id: string) => {
  if (generatedColors[id]) {
    return generatedColors[id];
  } else {
    const hexColor = Math.floor(Math.random() * 16777215).toString(16);
    generatedColors[id] = hexColor.padStart(6, '0');
    return generatedColors[id];
  }
};
