import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ProxyServers } from '@root/modules/accounts/types/proxy-servers';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetProxyServersResponse = IHttpResponse<200, ProxyServers[]> | IHttpResponse<400, string>;

export const getProxyServers = async (): Promise<GetProxyServersResponse> => {
  try {
    const response = await fetcher(`/proxy-servers`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
