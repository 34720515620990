import dayjs from 'dayjs';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useSwitchLocale = () => {
  const {
    i18n: { language, dir },
  } = useTranslation('common');

  useEffect(() => {
    dayjs.locale(language);

    document.documentElement.lang = language;
    document.documentElement.dir = dir(language);
  }, [dir, language]);
};
