import { Selector, createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';
import { ISubscriptionState } from '@root/shared-files/modules/subscriptions/store/subscriptions.slice';

const getSubscriptions = (state: IAppState) => state.subscriptions;
const getUserSubscriptionInfo = (state: ISubscriptionState) => state.userSubscriptionInfo;

export const subscriptionsSelector = {
  userSubscriptionInfo: createSelector<[Selector<IAppState, ISubscriptionState>], ISubscriptionState['userSubscriptionInfo']>([getSubscriptions], getUserSubscriptionInfo),
};
