import isObject from 'lodash/isObject';

import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppStore } from '@root/hooks';
import { SocketEventPayloads } from '@root/infra/socket/event-payloads';
import { SocketEventTypes } from '@root/infra/socket/event-types';
import { CreateExpertDtoMapper } from '@root/modules/experts/mappers/create-expert-dto.mapper';
import { IExpert } from '@root/modules/experts/types/expert';
import { createExpertValidation } from '@root/modules/experts/validations/common.validation';
import { useSignal } from '@root/modules/magic-terminal/contexts/signal.context';
import { ResultLeave, sendSignalService } from '@root/modules/magic-terminal/services/send-signal.service';
import { MagicTerminalOrderMarketType, MagicTerminalOrderPendingType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { IRetrySignalForm } from '@root/modules/magic-terminal/types/signal';
import { createMagicTerminalOrderValidation } from '@root/modules/magic-terminal/validations/create-magic-terminal-order.validation';
import { MainOrderType } from '@root/modules/orders/enums/orders';
import { IFormStatus } from '@root/shared/form';
import { isAxiosError } from '@root/shared/utils/axios-utils';
import { hasMessage } from '@root/shared/utils/error-utils';
import { isClientErrorResponseStatus, isServerErrorResponseStatus } from '@root/shared/utils/http-utils';
import { applyFiltersOnSource, makeLongResponseRequest } from '@root/shared/utils/message-source';
import { notify } from '@root/shared/utils/notification';

export enum RetryErrorType {
  connectionIssueDetected,
  insufficientFunds,
  tradeExecutionFailed,
}

export type RetryErrorFormikStatus = {
  error: RetryErrorType;
  message: string;
};

export type RetryFormikStatus = RetryErrorFormikStatus | IFormStatus;

export const useRetrySignalForm = () => {
  const { t } = useTranslation('forex-experts');
  const { t: yupT } = useTranslation('yup');
  const { signalData, expert } = useSignal();

  const initialValues = useMemo<IRetrySignalForm | null>(() => {
    if (expert && signalData?.signal) {
      const { price, symbol, type } = signalData.signal;
      const expertValues = CreateExpertDtoMapper.toDomain(expert);

      return {
        ...expertValues,
        symbol: symbol.toUpperCase(),
        price: price.toString(),
        marketType: [MainOrderType.BUY, MainOrderType.BUY_LIMIT, MainOrderType.BUY_STOP].includes(type) ? MagicTerminalOrderMarketType.BUY : MagicTerminalOrderMarketType.SELL,
        pendingType: [2, 3].includes(type) ? MagicTerminalOrderPendingType.LIMIT : MagicTerminalOrderPendingType.MARKET,
        useAdvancedSettings: !!expertValues.slippage,
      };
    }

    return null;
  }, [expert, signalData?.signal]);

  const store = useAppStore();

  const onSubmit = useCallback(
    async (values: IRetrySignalForm, helpers: FormikHelpers<IRetrySignalForm>) => {
      if (signalData?.id) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { symbol, price, marketType, pendingType, useAdvancedSettings, ...rest } = values;
        const expert = CreateExpertDtoMapper.toPersistence(rest);

        try {
          const result = await makeLongResponseRequest({
            subscribe: applyFiltersOnSource(
              (callback: (m: SocketEventPayloads.SignalRetryResultSocketMessage) => void) =>
                store.actionListener.subscribe(SocketEventTypes.signalRetryResult, (action) => callback(action.payload)),
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ({ expertId, signalId }) => signalData!.expertId === expertId && signalData!.signal.signalId === signalId,
            ),
            selectResultFromMessage: (message) => message.result,
            makeRequest: () => sendSignalService(signalData?.id, expert as Omit<IExpert, 'id' | 'isEnabled' | 'createdAt'>),
          });

          if (result.level === ResultLeave.success) {
            helpers.setStatus({ type: 'success', message: 'Order successfully placed' } as IFormStatus);

            notify({
              type: 'success',
              text: t('magicTerminal.notifications.orderCreated'),
            });

            helpers.resetForm();

            return;
          }

          // We are currently unable to recognize this error case.
          // eslint-disable-next-line no-constant-condition
          if (false) {
            helpers.setStatus({ error: RetryErrorType.insufficientFunds, message: result.message } as RetryErrorFormikStatus);

            return;
          }

          // We are currently unable to recognize this error case.
          // eslint-disable-next-line no-constant-condition
          if (false) {
            helpers.setStatus({ error: RetryErrorType.connectionIssueDetected, message: result.message });

            return;
          }

          helpers.setStatus({ error: RetryErrorType.tradeExecutionFailed, message: result.message });
        } catch (error: unknown) {
          if (isAxiosError(error) && error.response !== undefined) {
            if (isServerErrorResponseStatus(error.response.status) && hasMessage(error.response)) {
              helpers.setStatus({ type: 'error', message: error.response.message } as IFormStatus);
              notify({ type: 'danger', text: error.response.message }, { data: 'errorData' in error.response && (error.response.errorData as object) });
            }

            if (
              isClientErrorResponseStatus(error.response.status) &&
              'errors' in error.response &&
              isObject(error.response.errors) &&
              Object.keys(error.response.errors).length > 0
            ) {
              const message = Object.values(error.response.errors).join('\n');

              helpers.setStatus({ type: 'error', message } as IFormStatus);
              notify({ type: 'danger', text: message });

              return;
            }
          }

          const message = t('signalRetry.defaultErrorMessage');

          helpers.setStatus({ type: 'error', message } as IFormStatus);
          notify({ type: 'danger', text: message }, { data: error as object });
        }
      }
    },
    [t, signalData, store.actionListener],
  );

  const validationSchema = useMemo(() => createExpertValidation(t, yupT).concat(createMagicTerminalOrderValidation(t, yupT)).omit(['name', 'symbols']), [t, yupT]);

  return {
    initialValues,
    schema: validationSchema,
    onSubmit,
  };
};
