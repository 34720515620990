import { FC } from 'react';

import errorImg from '@root/assets/images/cases/error.webp';
import { Image } from '@root/shared/ui/image';

export const ErrorImg: FC = () => (
  <div className='w-[60px] h-[60px]'>
    <Image src={errorImg} wrapperClassName='-mt-[3px] -mr-[23px] -mb-[11px] -ml-[2px]' alt='warning' />
  </div>
);
