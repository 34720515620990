import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import { FC, ReactNode, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSm } from '@root/infra/layout/hooks/media';
import { ModifyPartialCloseDto, useModifyPartialClose } from '@root/modules/orders/hooks/use-modify-partial-close';
import { useOrderCurrentPrice } from '@root/modules/orders/hooks/use-order-current-price';
import { IOrder } from '@root/modules/orders/types/orders';
import { displayCurrentPrice } from '@root/modules/orders/utils/orders';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { CheckboxField, TextField } from '@root/shared/form';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { Button } from '@root/shared/ui/button';
import { IconLabelLayout } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Modal } from '@root/shared/ui/modal';
import { Text } from '@root/shared/ui/typography';
import { getOrderSideColor } from '@root/shared/utils/colors';
import { globalRound } from '@root/shared/utils/number/round';

export type ModifyModalProps = {
  isOpen: boolean;
  closeModal(): void;
  order: IOrder;
};

type Item = {
  title: string;
  value: ReactNode;
};

const FormComponent: FC<FormikProps<ModifyPartialCloseDto> & ModifyModalProps> = ({ isOpen, handleSubmit, closeModal, order, values, isValid, setFieldValue }) => {
  const { t } = useTranslation('forex-orders');
  const { hideActions } = useGhostLogin();

  const { currentPrice } = useOrderCurrentPrice(order);
  const totalValue = order.lots;

  const handleValueChange = useCallback(
    (value, usePercentage) => {
      if (usePercentage) {
        const absoluteValue = (totalValue * value) / 100;
        setFieldValue('closeAbsoluteValue', globalRound(absoluteValue, 2));
        setFieldValue('closePercentValue', value);
      } else {
        const percentValue = (value / totalValue) * 100;
        setFieldValue('closeAbsoluteValue', value);
        setFieldValue('closePercentValue', globalRound(percentValue, 2));
      }
    },
    [totalValue, setFieldValue],
  );

  const sm = useSm();

  const items: Item[] = [
    { title: t('partialClose.positionVolume'), value: order.lots },
    {
      title: t('partialClose.type'),
      // TODO: Implement OrderType component to reuse accros whole app
      value: <Text className={clsx(`inline-flex -my-0.5 bg-gray-700 py-0.5 px-3 rounded-full font-medium ${getOrderSideColor(order.type)}`)}>{order.type}</Text>,
    },
    { title: t('partialClose.pair'), value: order.symbol },
    { title: t('partialClose.openPrice'), value: globalRound(order.openPrice, 6) },
    { title: t('partialClose.currentPrice'), value: displayCurrentPrice(currentPrice) },
  ];

  return (
    <Modal isOpen={isOpen} onCancel={closeModal} footer={null} className={'max-w-[640px]!'} title={t('partialClose.closePartially')}>
      <form className='flex flex-col -mt-4' onSubmit={handleSubmit}>
        <div>
          <div className='-m-2'>
            <table className='w-full'>
              {sm ? (
                <>
                  <thead>
                    <tr>
                      {items.map(({ title }, index) => (
                        <th key={index} className='p-2 align-top text-left rtl:text-right text-sm font-normal text-grayscale-400'>
                          {title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {items.map(({ value }, index) => (
                        <td key={index} className='p-2'>
                          {value}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </>
              ) : (
                <tbody>
                  {items.map(({ title, value }, index) => (
                    <tr key={index}>
                      <th className='p-2 text-left text-sm font-normal text-grayscale-400 whitespace-nowrap'>{title}</th>
                      <td className='p-2 text-right'>{value}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>

        <div className='flex flex-col gap-4 w-full mt-6'>
          <Text>{t('partialClose.partialClose')}</Text>

          <div className='flex align-center gap-4'>
            <CheckboxField
              type='radio'
              name='options.moneyManagement.useGeneral'
              label={<Text className='font-bold'>{t('partialClose.absolute')}</Text>}
              checked={!values.usePercentage}
              onChange={() => setFieldValue('usePercentage', false)}
            />

            <CheckboxField
              type='radio'
              name='options.moneyManagement.useGeneral'
              label={<Text className='font-bold'>{t('partialClose.percentage')}</Text>}
              checked={values.usePercentage}
              onChange={() => setFieldValue('usePercentage', true)}
            />
          </div>

          {/* Absolute value */}
          {!values.usePercentage ? (
            <div className='flex align-center gap-4'>
              <div className='w-1/2'>
                <TextField
                  name='closeAbsoluteValue'
                  label={
                    <IconLabelLayout
                      icon={
                        <InfoTooltip content={<Text>{t('fields.lotSize.description')}</Text>}>
                          <InfoTooltipIcon />
                        </InfoTooltip>
                      }
                    >
                      {t('fields.lotSize.label')}
                    </IconLabelLayout>
                  }
                  value={values.closeAbsoluteValue}
                  placeholder={t('fields.lotSize.placeholder')}
                  onChange={(e) => handleValueChange(e.target.value, false)}
                />
              </div>
              {!Number.isNaN(values.closePercentValue) && <Text className='mt-9'>{`${values.closePercentValue}%`}</Text>}
            </div>
          ) : (
            <div className='flex align-center gap-4'>
              <div className='w-1/2'>
                <TextField
                  name='closePercentValue'
                  label={
                    <IconLabelLayout
                      icon={
                        <InfoTooltip content={<Text>{t('fields.lotSize.description')}</Text>}>
                          <InfoTooltipIcon />
                        </InfoTooltip>
                      }
                    >
                      {t('fields.lotSize.label')}
                    </IconLabelLayout>
                  }
                  suffix='%'
                  value={values.closePercentValue}
                  placeholder={t('fields.lotSize.placeholder')}
                  onChange={(e) => handleValueChange(e.target.value, true)}
                />
              </div>
              <Text className='mt-9'>{t('partialClose.lotSize', { count: parseFloat(values.closeAbsoluteValue) })}</Text>
            </div>
          )}
        </div>

        <div className='w-full flex justify-between mt-8'>
          <Button type='button' onClick={closeModal} outlined>
            {t('Close', { ns: 'forex-common' })}
          </Button>
          <Button type='submit' variant='primary' disabled={!isValid || hideActions}>
            {t('partialClose.closeByMarket')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const ModifyPartialCloseModal: FC<ModifyModalProps> = memo(
  (props) => {
    const { initialValues, onSubmit, validationSchema } = useModifyPartialClose(props);

    return (
      <Formik<ModifyPartialCloseDto>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}
        enableReinitialize
        component={(formProps) => <FormComponent {...formProps} {...props} />}
      />
    );
  },
  (prev, next) => prev.isOpen === next.isOpen && prev.order.ticket === next.order.ticket,
);

ModifyPartialCloseModal.displayName = 'ModifyPartialCloseModal';
