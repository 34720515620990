import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient } from '@root/infra/query';
import { UpdateAccountDto } from '@root/modules/accounts/dtos/update-account.dto';
import { resetDrawdownLimit as resetAccountDDLimit } from '@root/modules/accounts/services/reset-drawdown-limit.service';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { resetDrawdownLimit } from '@root/modules/experts/services/reset-drawdown-limit.service';
import { notify } from '@root/shared/utils/notification';

export const useDrawdownReset = (values: CreateExpertDto | UpdateAccountDto) => {
  const { t } = useTranslation('forex-experts');
  const [resetLoading, setResetLoading] = useState<boolean>(false);

  const handleResetDrawdown = useCallback(async () => {
    setResetLoading(true);
    const isExpertForm = !!(values as CreateExpertDto)?.unitType;
    if (isExpertForm) {
      const response = await resetDrawdownLimit((values as CreateExpertDto).id as string);
      if (response.status === 200) {
        queryClient.invalidateQueries({ queryKey: ['experts', (values as CreateExpertDto).id], exact: true });
        notify({
          type: 'success',
          title: t('fields.drawdown.resetDDLimitSuccess'),
        });
      } else {
        notify({
          type: 'danger',
          title: response.payload,
        });
      }
    } else {
      const response = await resetAccountDDLimit((values as UpdateAccountDto).accountId as string);
      if (response.status === 200) {
        queryClient.invalidateQueries({ queryKey: ['accounts'], exact: false });
        notify({
          type: 'success',
          title: t('fields.drawdown.resetDDLimitSuccess'),
        });
      } else {
        notify({
          type: 'danger',
          title: response.payload,
        });
      }
    }
    setResetLoading(false);
  }, [values, t]);

  return {
    resetLoading,
    handleResetDrawdown,
  };
};
