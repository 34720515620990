import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { SymbolType } from '@root/modules/accounts/enums';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSymbolsWithType } from '@root/modules/accounts/hooks/use-get-symbols-with-type';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { generateId } from '@root/shared/utils/generate-id';

export const useCreateAccountStep = (accountId?: string, allowedSymbolType?: SymbolType | null, usePips?: boolean) => {
  const { t } = useTranslation('forex-experts');

  const navigate = useNavigate();
  const { values, errors, setFieldValue } = useFormikContext<CreateExpertDto>();
  const accountsQuery = useGetAccounts();
  const symbolsWithTypeQuery = useGetSymbolsWithType();

  const accountConfig = useMemo(() => {
    if (accountsQuery.data === undefined) {
      return {
        options: [],
        activeOptions: [],
      };
    }

    const sortedAccounts = [...accountsQuery.data].sort((a, b) => {
      const connectedA = a.status === 'CONNECTED';
      const connectedB = b.status === 'CONNECTED';
      return Number(connectedB) - Number(connectedA);
    });

    const options = sortedAccounts.map((item) => ({
      value: item.id,
      label: `${item.username} ${item.company} - ${item.mtType}`,
    }));

    const activeOptions = sortedAccounts
      .filter((item) => item.isSignedIn)
      .map((item) => ({
        value: item.id,
        label: `${item.username} ${item.company} - ${item.mtType}`,
      }));

    return {
      options,
      activeOptions,
    };
  }, [accountsQuery.data]);

  const noAccounts = accountsQuery.data?.length === 0 && accountsQuery.isFetched;

  const selectedAccount = useMemo(() => accountsQuery.data?.find((item) => item.id === accountId), [accountsQuery.data, accountId]);

  const symbolsConfig = useMemo(() => {
    const allSymbolsOption = { value: 'all', label: t('allCurrencyPairsOption') };

    if (symbolsWithTypeQuery.data === undefined) {
      return { symbols: [], options: [], optionsWithAllOption: [allSymbolsOption] };
    }

    const symbols = symbolsWithTypeQuery.data
      .filter((item) => {
        const isPipsSymbol = !!(item.symbolType === SymbolType.CURRENCY || item.name.match('XAUUSD'));

        const selectedAccountSymbolsMapping = selectedAccount?.symbolsMapping ?? {};

        return (
          (usePips === undefined || (usePips && isPipsSymbol) || (!usePips && !isPipsSymbol)) &&
          (!allowedSymbolType || item.symbolType === allowedSymbolType || (allowedSymbolType === SymbolType.CURRENCY && item.name.match('XAUUSD'))) &&
          selectedAccountSymbolsMapping[item.name] !== null &&
          selectedAccountSymbolsMapping[item.name] !== undefined
        );
      })
      .map((item) => item.name);

    const options = symbols.map((item) => ({ value: item, label: item }));

    const optionsWithAllOption = [allSymbolsOption, ...options];

    return { symbols, options, optionsWithAllOption };
  }, [t, symbolsWithTypeQuery.data, selectedAccount, usePips, allowedSymbolType]);

  const onSymbolChange = useCallback(
    (options) => {
      if (options.some((item) => item.value === 'all')) {
        return setFieldValue('symbols', symbolsConfig.symbols);
      }

      const newValue = options.map((item) => item.value);
      setFieldValue('symbols', newValue);
    },
    [setFieldValue, symbolsConfig.symbols],
  );

  const onAccountCreate = useCallback(
    (nextPageUrl) => {
      const cacheId = generateId().toString();
      localStorage.setItem(cacheId, JSON.stringify(values));
      const nextUrl = nextPageUrl + `?cache=${cacheId}`;
      navigate(PageLinks.createAccount + `?next=${nextUrl}`);
    },
    [navigate, values],
  );

  const removeSymbolByIndex = useCallback(
    (index: number) => {
      setFieldValue(
        'symbols',
        values.symbols.filter((_, i) => i !== index),
      );
    },
    [setFieldValue, values.symbols],
  );

  const selectAllSymbols = useCallback(() => setFieldValue('symbols', symbolsConfig.symbols), [setFieldValue, symbolsConfig.symbols]);
  const clearAllSymbols = useCallback(() => setFieldValue('symbols', []), [setFieldValue]);

  useEffect(() => {
    if (accountConfig.options.length && !values.account) {
      setTimeout(() => {
        setFieldValue('account', accountConfig.options[0].value);
      });
    }
  }, [accountConfig.options, values.account, setFieldValue]);

  const fetched = accountId !== '' && accountId !== undefined && symbolsWithTypeQuery.isFetched && accountsQuery.isFetched;

  const state = {
    noAccounts,
    accounts: accountsQuery,
    accountOptions: accountConfig.options,
    symbols: values.symbols,
    selectAllSymbols,
    removeSymbolByIndex,
    clearAllSymbols,
    account: values.account,
    symbolOptions: symbolsConfig.optionsWithAllOption,
    symbolOptionsWithoutAllOption: symbolsConfig.options,
    errors,
    activeAccountOptions: accountConfig.activeOptions,
    fetched,
  };

  const handlers = {
    onAccountCreate,
    onSymbolChange,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
