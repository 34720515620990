import clsx from 'clsx';
import { FormikValues, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useChart } from '@root/modules/charting-library/contexts/chart-instance.context';
import { widget } from '@root/modules/charting-library/utils/chart';
import { useCreateAccountStep } from '@root/modules/experts/hooks/use-create-account-step';
import { magicTerminalDefaultSymbolLocalStorageKey } from '@root/modules/magic-terminal/constants';
import { ApplySharedMagicTerminal } from '@root/modules/magic-terminal/containers/share/apply-shared-magic-terminal';
import { useMagicTerminalOrderSettings } from '@root/modules/magic-terminal/hooks/use-magic-terminal-order-settings';
import { MagicTerminalOrderMarketType, MagicTerminalOrderPendingType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { SelectField, TextField } from '@root/shared/form/fields';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { Button } from '@root/shared/ui/button';
import { FieldHint } from '@root/shared/ui/form';
import { SelectButton } from '@root/shared/ui/select-button/select-button';
import { Text } from '@root/shared/ui/typography';
import { setToLocalStorage } from '@root/shared/utils/local-storage';

const loader = <LoadingIcon width={18} height={18} />;

export const MagicTerminalOrderSettings = <T extends FormikValues>() => {
  const { t } = useTranslation('forex-experts');
  const { chartInstance } = useChart();
  const isRetrySignalPage = !!useParams().id;
  const { values, setFieldValue, setFieldError, errors } = useFormikContext<T>();
  const [{ allowedSymbolType, marketPrice }] = useMagicTerminalOrderSettings();
  const [{ symbolOptionsWithoutAllOption, account, noAccounts, activeAccountOptions, fetched }, { onAccountCreate }] = useCreateAccountStep(values.account);

  const symbolOptions = values.symbols.length
    ? values.symbols.map((value) => ({
        value,
        label: value,
      }))
    : symbolOptionsWithoutAllOption;

  const priceLoading = values.symbol && !errors.symbol && values.pendingType === MagicTerminalOrderPendingType.MARKET && !marketPrice;

  const handleSymbolChange = (e: { value: string }) => {
    if (chartInstance) {
      widget.deleteLineShapes(chartInstance);
    }

    setToLocalStorage(magicTerminalDefaultSymbolLocalStorageKey, e.value);

    setFieldValue('symbol', e.value);
  };

  useEffect(() => {
    if (!errors.symbol && !symbolOptions.find((item) => item.value === values.symbol)) {
      setFieldError('symbol', t('noPairError', { pair: values.symbol }));
    }
  }, [errors.symbol, setFieldError, symbolOptions, t, values.symbol]);

  return (
    <div>
      <div className='flex items-center mb-2.5'>
        <Button
          variant='success'
          tint='500'
          disabled={isRetrySignalPage}
          className={clsx('w-full border-[1px]! border-grayscale-500 ltr:rounded-r-none rtl:rounded-l-none bg-grayscale-800!', {
            'bg-success-500! border-success-500! text-white!': values.marketType === MagicTerminalOrderMarketType.BUY,
          })}
          onClick={() => setFieldValue('marketType', MagicTerminalOrderMarketType.BUY)}
        >
          {t('magicTerminal.form.marketType.buy')}
        </Button>

        <Button
          variant='danger'
          tint='500'
          disabled={isRetrySignalPage}
          className={clsx('w-full border-[1px]! border-grayscale-500 ltr:rounded-l-none rtl:rounded-r-none', {
            'bg-danger-500! border-danger-500! text-white!': values.marketType === MagicTerminalOrderMarketType.SELL,
            'bg-grayscale-800!': values.marketType !== MagicTerminalOrderMarketType.SELL,
          })}
          onClick={() => setFieldValue('marketType', MagicTerminalOrderMarketType.SELL)}
        >
          {t('magicTerminal.form.marketType.sell')}
        </Button>
      </div>

      {!isRetrySignalPage && <ApplySharedMagicTerminal />}

      {!isRetrySignalPage && noAccounts && (
        <div className='mb-4'>
          <Text size='sm' className='mb-4'>
            {t('cases.noAccount')}
          </Text>
          <Button outlined className='w-full' onClick={() => onAccountCreate(PageLinks.magicTerminal)}>
            {t('actions.connectAccount')}
          </Button>
        </div>
      )}
      {!noAccounts && (
        <div className='flex items-start flex-col sm:flex-row md:flex-col xl:flex-row'>
          <div className='flex-1 w-full'>
            <SelectField
              options={activeAccountOptions}
              isDisabled={isRetrySignalPage || !activeAccountOptions?.length}
              name='account'
              wrapperClassName={'mb-2'}
              label={t('magicTerminal.form.account.label')}
              placeholder={t('magicTerminal.form.account.placeholder')}
            />
          </div>
        </div>
      )}

      {fetched && !noAccounts && symbolOptions?.length < 2 && (
        <FieldHint variant='danger'>
          {t('accountNotSupported', { symbolType: values.strategy.type === 'signal' ? t('symbolType.' + allowedSymbolType) : t(values.usePips ? 'pips' : 'points') })}
        </FieldHint>
      )}

      <div className='items-start gap-2 grid grid-cols-2'>
        <SelectField
          options={symbolOptions}
          name='symbol'
          wrapperClassName={'mb-2'}
          isDisabled={!account || isRetrySignalPage}
          placeholder={t('magicTerminal.form.pair.placeholder')}
          label={t('magicTerminal.form.pair.label')}
          onChange={handleSymbolChange}
        />

        <TextField
          disabled={!activeAccountOptions?.length || isRetrySignalPage}
          name='price'
          type='number'
          value={values.pendingType === MagicTerminalOrderPendingType.MARKET ? marketPrice : values.price}
          prefix={priceLoading && loader}
          wrapperClassName={'mb-2'}
          label={values.pendingType === MagicTerminalOrderPendingType.MARKET ? t('magicTerminal.form.price.currentPriceLabel') : t('magicTerminal.form.price.priceLabel')}
          placeholder={!priceLoading ? t('magicTerminal.form.price.placeholder') : ''}
          onChange={(event) => {
            setFieldValue('price', event.target.value);
            setFieldValue('pendingType', MagicTerminalOrderPendingType.LIMIT);
          }}
        />
      </div>

      <div className='flex justify-start items-center gap-2 mb-4 mt-2'>
        <SelectButton
          $labeled
          className='border-none! py-1! px-1! font-bold'
          type='button'
          disabled={isRetrySignalPage}
          selected={values.pendingType === MagicTerminalOrderPendingType.MARKET}
          onClick={() => setFieldValue('pendingType', MagicTerminalOrderPendingType.MARKET)}
        >
          <Text size='sm'>{t('magicTerminal.form.pendingType.market')}</Text>
        </SelectButton>
        <SelectButton
          $labeled
          className='border-none! py-1! px-1! font-bold'
          type='button'
          disabled={isRetrySignalPage}
          selected={values.pendingType === MagicTerminalOrderPendingType.LIMIT}
          onClick={() => {
            setFieldValue('pendingType', MagicTerminalOrderPendingType.LIMIT);
            setFieldValue('price', marketPrice);
          }}
        >
          <Text size='sm'>{t('magicTerminal.form.pendingType.limit')}</Text>
        </SelectButton>
      </div>
    </div>
  );
};
