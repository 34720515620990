import { FC } from 'react';

import { ConnectionIssueDetectedModal } from '@root/modules/magic-terminal/containers/retry-status-modals/connection-issue-detected-modal';
import { InsufficientFundsModal } from '@root/modules/magic-terminal/containers/retry-status-modals/insufficient-funds-modal';
import { TradeExecutionFailedModal } from '@root/modules/magic-terminal/containers/retry-status-modals/trade-execution-failed-modal';
import { RetryErrorFormikStatus, RetryErrorType, RetryFormikStatus } from '@root/modules/magic-terminal/hooks/use-retry-signal-form';

const isErrorRetryFormikStatus = (status: RetryFormikStatus | null): status is RetryErrorFormikStatus => status !== null && 'error' in status;

export const RetryStatusModals: FC<{ status: RetryFormikStatus | null; setStatus: (nextStatus: null) => void; submitForm: () => Promise<void> }> = ({
  status,
  setStatus,
  submitForm,
}) => {
  if (!isErrorRetryFormikStatus(status)) {
    return null;
  }

  const { error, message } = status;

  const handleCancel = () => {
    setStatus(null);
  };

  const handleTryAgain = async () => {
    setStatus(null);
    void submitForm();
  };

  return (
    <>
      <ConnectionIssueDetectedModal open={error === RetryErrorType.connectionIssueDetected} onCancel={handleCancel} onTryAgain={handleTryAgain} />
      <InsufficientFundsModal open={error === RetryErrorType.insufficientFunds} onCancel={handleCancel} onTryAgain={handleTryAgain} />
      <TradeExecutionFailedModal open={error === RetryErrorType.tradeExecutionFailed} onCancel={handleCancel} onTryAgain={handleTryAgain} error={message} />
    </>
  );
};
