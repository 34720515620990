import groupBy from 'lodash/groupBy';

import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { GET_SYMBOLS_WITH_TYPE } from '@root/modules/accounts/queries/get-symbols-with-type.query';
import { Market } from '@root/modules/marketplace/types/marketplace-trade-idea-provider';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useGetSymbolsWithType = () => {
  const userId = useSelector(authSelector.userId);

  return useQuery({
    queryKey: ['fx', userId, 'symbols-with-type'],
    queryFn: GET_SYMBOLS_WITH_TYPE,
    enabled: !!userId,
    staleTime: 1000 * 60 * 10,
  });
};

export enum SymbolType {
  currency = 'CURRENCY',
  index = 'INDEX',
  synthetic = 'SYNTHETIC',
}

export const marketBySymbolTypeMap = {
  [SymbolType.currency]: 'forex',
  [SymbolType.index]: 'indices',
  [SymbolType.synthetic]: 'synthetic',
};

export const useSymbolsByMarketMap = (): Record<Market, string[]> | undefined => {
  const { data } = useGetSymbolsWithType();

  const symbolsByMarketMap = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }

    return Object.fromEntries(
      Object.entries(groupBy(data, 'symbolType')).map(([key, value]) => [marketBySymbolTypeMap[key as SymbolType], value.map((item) => item.name)]),
    ) as Record<Market, string[]>;
  }, [data]);

  return symbolsByMarketMap;
};
