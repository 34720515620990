import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { MarketplaceTradeIdeaMapper } from '@root/modules/marketplace/mappers/marketplace-trade-idea.mapper';
import { MarketPlaceTradeIdeaItem } from '@root/modules/marketplace/types/marketplace-trade-idea';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetSubscribedProvidersResponseData = {
  data: MarketPlaceTradeIdeaItem[];
};

export type GetSubscribedProvidersResponse = IHttpResponse<200, GetSubscribedProvidersResponseData> | IHttpResponse<400, string>;

export const getSubscribedProviders = async (): Promise<GetSubscribedProvidersResponse> => {
  try {
    const response = await fetcher('/trade-ideas/providers/subscribed');

    return {
      status: 200,
      payload: {
        data: response.data.filter((item) => item.market !== 'crypto').map((item) => MarketplaceTradeIdeaMapper.toDomain(item)),
      },
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
