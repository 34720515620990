import clsx from 'clsx';
import { ChangeEventHandler, FC, FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { timeInForcePredefinedValueOptions } from '@root/modules/experts/components/time-in-force/time-in-force-predefined-values';
import { TimeInForcePredefinedValue } from '@root/modules/experts/dtos/create-expert.dto';
import { CheckboxField } from '@root/shared/form';
import { FieldHint } from '@root/shared/ui/form';
import { Text, Title } from '@root/shared/ui/typography';

type BaseInputProps = { value: string; onTouched: () => void; onChange: (nextValue: string) => void };

export type TimeInForceCutomOpton = FC<BaseInputProps>;

export const TimeInForcePredefinedValuesFieldset: FC<
  BaseInputProps & { densed?: boolean; extendWithCutomOpton?: TimeInForceCutomOpton; touched: boolean; error: string | undefined }
> = ({ densed, extendWithCutomOpton: CutomOpton, value, touched, error, onTouched, onChange }) => {
  const { t } = useTranslation();

  const handleRadioChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value as TimeInForcePredefinedValue);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    onTouched();
  };

  return (
    <div className={clsx('flex flex-col', densed ? 'gap-y-1' : 'gap-y-4')}>
      <Title level={densed ? 8 : 7}>{t('forex-experts:fields.timeInForce.label')}</Title>

      <div className='flex flex-col gap-y-1'>
        {timeInForcePredefinedValueOptions.map((option) => (
          <CheckboxField
            key={option.value}
            id={option.value}
            type='radio'
            name='timeInForce'
            value={option.value}
            onBlur={handleBlur}
            onChange={handleRadioChange}
            label={<Text className='text-sm'>{t(option.labelTranslationId)}</Text>}
            checked={value === option.value}
            hideError
          />
        ))}

        {CutomOpton !== undefined && <CutomOpton value={value} onTouched={onTouched} onChange={onChange} />}

        {touched && error !== undefined && <FieldHint variant='danger'>{error}</FieldHint>}
      </div>
    </div>
  );
};
