import i18n from 'i18next';
import { put, takeLatest } from 'redux-saga/effects';

import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { notify } from '@root/shared/utils/notification';

function* tgAccountLinked(action) {
  yield put(userSettingsSlice.actions.setTgAccounts(action.payload?.id ? [action.payload] : []));

  notify({
    title: i18n.t('common:telegramAccountConnected') as string,
    type: 'success',
  });
}

function* tgAccountUnlinked() {
  yield put(userSettingsSlice.actions.setTgAccounts([]));

  notify({
    title: i18n.t('common:telegramAccountUnlinked') as string,
    type: 'success',
  });
}

export function* userSettingsSaga() {
  yield takeLatest([userSettingsSlice.actions.tgAccountLinked], tgAccountLinked);
  yield takeLatest([userSettingsSlice.actions.tgAccountUnlinked], tgAccountUnlinked);
}
