import { keepPreviousData, useQuery } from 'react-query';

import { GET_EXPERT, GetExpertData, GetExpertQueryKey } from '@root/modules/experts/queries/get-expert.query';

export const useGetExpert = (id?: string, enabled = true) => {
  return useQuery<GetExpertData, Error, GetExpertData, GetExpertQueryKey>({
    queryKey: ['experts', id || ''],
    queryFn: GET_EXPERT,
    placeholderData: keepPreviousData,
    enabled: !!id && enabled,
  });
};
