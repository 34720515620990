import styled from 'styled-components';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RelativePortal from 'react-relative-portal';

import { helpLinks } from '@root/shared/constants/social-links';
import { DiscordWhiteIcon } from '@root/shared/icons/discord-icon';
import { KnowledgeBaseOutlinedIcon } from '@root/shared/icons/knowledge-base-outlined-icon';
import { TelegramWhiteIcon } from '@root/shared/icons/telegram-white-icon';
import { YoutubeWhiteIcon } from '@root/shared/icons/youtube-white-icon';
import { Text } from '@root/shared/ui/typography';

const Wrapper = styled.div`
  position: absolute;
  z-index: 20;
  left: calc(100% + 80px);
  top: -60px;
  min-width: 280px;
  box-shadow: 0 10px 24px 0 rgba(12, 12, 12, 0.6);
`;

export const FeedbackContent: FC = () => {
  const { t } = useTranslation('common');

  return (
    <RelativePortal>
      <Wrapper className='p-4 rounded-sm bg-gray-800 text-gray-100! !hover:text-grayscale-100'>
        <Text className='font-bold text-[14px]! mb-3'>{t('sideBar.help.title')}</Text>
        <div className='w-full'>
          <div className='mb-3'>
            <a href={helpLinks.knowbase} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>
              <KnowledgeBaseOutlinedIcon height={16} width={16} /> <Text size='sm'>{t('sideBar.help.knowledgeBase')}</Text>
            </a>
          </div>
          <div className='mb-3'>
            <a href={helpLinks.telegram} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>
              <TelegramWhiteIcon height={16} width={16} /> <Text size='sm'>{t('sideBar.help.telegram')}</Text>
            </a>
          </div>
          <div className='mb-3'>
            <a href={helpLinks.youtube} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>
              <YoutubeWhiteIcon height={16} width={16} /> <Text size='sm'>{t('sideBar.help.youtube')}</Text>
            </a>
          </div>
          <div>
            <a href={helpLinks.discord} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>
              <DiscordWhiteIcon height={16} width={16} /> <Text size='sm'>{t('sideBar.help.discord')}</Text>
            </a>
          </div>
          {/* <div className="w-full flex items-center gap-x-2 cursor-pointer" onClick={() => (window as any)?.LC_API?.open_chat_window()}>
            <ChatIcon height={16} width={16} /> <Text size='sm'>{t('sideBar.help.chat')}</Text>
          </div> */}
        </div>
      </Wrapper>
    </RelativePortal>
  );
};
