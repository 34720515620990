import { theme } from '@root/infra/theme';

export const colorPalette = {
  sellLine: 'rgba(129, 57, 65, 1)',
  buyLine: 'rgba(29, 75, 71, 1)',
  verticalLine: 'rgb(255, 235, 59)',
  breakEven: 'rgba(229, 197, 80, 1)',
  highLowLine: 'rgba(39, 119, 198, 1)',
  candleDown: 'rgba(255, 84, 102, 1)',
  candleUp: 'rgba(0, 194, 174, 1)',
  blue: 'rgba(39, 119, 198, 1)',
  green: 'rgba(35, 134, 124, 1)',
  red: 'rgba(163, 80, 88, 1)',
  buyDot: 'rgba(0, 194, 174, 1)',
  sellDot: 'rgba(255, 84, 102, 1)',
  bgColor: '#202020',
};

export const lineStyles = {
  default: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderColor: 'rgba(255, 255, 255, 0.8)',
    lineColor: colorPalette.blue,
    textColor: colorPalette.blue,
  },
  takeProfit: {
    backgroundColor: theme.gray[800],
    borderColor: theme.success[800],
    lineColor: theme.success[800],
    textColor: theme.success[700],
  },
  trailingStop: {
    backgroundColor: theme.gray[800],
    borderColor: theme.success[500],
    lineColor: theme.success[500],
    textColor: theme.success[500],
  },
  breakEven: {
    backgroundColor: theme.gray[800],
    borderColor: theme.secondary[300],
    lineColor: theme.secondary[300],
    textColor: theme.secondary[300],
  },
  stopLoss: {
    backgroundColor: theme.gray[800],
    borderColor: theme.danger[500],
    lineColor: theme.danger[500],
    textColor: theme.danger[500],
  },
};
