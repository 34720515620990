import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const YoutubeWhiteIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width={40} height={40} viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M9.28271 7.86623L7.48591 7.02782C7.32911 6.95503 7.20031 7.03662 7.20031 7.21022V8.78943C7.20031 8.96303 7.32911 9.04463 7.48591 8.97183L9.28191 8.13343C9.43951 8.05982 9.43951 7.93983 9.28271 7.86623ZM8.00031 0.319824C3.75871 0.319824 0.320312 3.75822 0.320312 7.99983C0.320312 12.2414 3.75871 15.6798 8.00031 15.6798C12.2419 15.6798 15.6803 12.2414 15.6803 7.99983C15.6803 3.75822 12.2419 0.319824 8.00031 0.319824ZM8.00031 11.1198C4.06911 11.1198 4.00031 10.7654 4.00031 7.99983C4.00031 5.23422 4.06911 4.87982 8.00031 4.87982C11.9315 4.87982 12.0003 5.23422 12.0003 7.99983C12.0003 10.7654 11.9315 11.1198 8.00031 11.1198Z' />
    </svg>
  );
};
