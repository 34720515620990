import styled from 'styled-components';

import clsx from 'clsx';
import { FC } from 'react';

const BulletItemWrapper = styled.li`
  padding-left: 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.gray[100]};
    border-radius: 50%;
  }
`;

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const BulletItem: FC<Props> = ({ children, className }) => {
  return <BulletItemWrapper className={clsx('rtl:before:right-0 rtl:before:left-auto rtl:pl-0 rtl:pr-4', className)}>{children}</BulletItemWrapper>;
};
