import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IAccountBalance } from '@root/modules/accounts/types/balance';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Id } from '@root/shared/utils/types';

export type GetAccountBalanceServiceResponse = IHttpResponse<200, IAccountBalance[]> | IHttpResponse<400, string>;
export type GetAccountBalanceServiceQuery = { userId: Id };

export const getAccountBalanceService = async (): Promise<GetAccountBalanceServiceResponse> => {
  try {
    const response = await fetcher(`/accounts/accounts-info`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
