import * as Sentry from '@sentry/browser';

const developmentEnvironment = import.meta.env.MODE === 'development';

if (!developmentEnvironment) {
  const SENTRY_DSN = import.meta.env.SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN;
  const SENTRY_ENVIRONMENT = import.meta.env.SENTRY_ENVIRONMENT || import.meta.env.VITE_SENTRY_ENVIRONMENT;

  Sentry.init({
    dsn: SENTRY_DSN,
    initialScope: {
      tags: {
        service: 'forex-fe',
        version: SENTRY_ENVIRONMENT,
      },
    },
    tracesSampleRate: 1.0,
  });
}
