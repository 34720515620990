import { Layout } from '@root/infra/layout/containers';
import { RetrySignalForm } from '@root/modules/magic-terminal/containers/retry-signal-form';
import { useSignal } from '@root/modules/magic-terminal/contexts/signal.context';
import { TradesTable } from '@root/modules/orders/containers/trades-table';
import { SelectedBrokerProvider } from '@root/modules/orders/contexts/selected-broker.context';
import { useQuoteSubscriber } from '@root/modules/orders/hooks/use-quote-subscriber';

export const RetrySignalPage = () => {
  useQuoteSubscriber('all', true);
  const { signalData, isLoading } = useSignal();

  if (isLoading) {
    return null;
  }

  return (
    <Layout>
      <SelectedBrokerProvider storagePrefix='retry_signal_form'>
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-12'>
            <RetrySignalForm />
          </div>
          <div className='col-span-12'>{!isLoading && signalData && <TradesTable doNotDisplayUnknownCurrency />}</div>
        </div>
      </SelectedBrokerProvider>
    </Layout>
  );
};
