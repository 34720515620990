import merge from 'lodash/merge';

import { useFormikContext } from 'formik';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useChart } from '@root/modules/charting-library/contexts/chart-instance.context';
import { useCreateAccountStep } from '@root/modules/experts/hooks/use-create-account-step';
import { useShareConfig } from '@root/modules/magic-terminal/contexts/share-config.context';
import { useMagicTerminalInitialValues } from '@root/modules/magic-terminal/hooks/use-magic-terminal-initial-values';
import { MagicTerminalForm } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { TextField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { DefaultImage } from '@root/shared/ui/image/image-with-fallback';
import { Modal } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';
import { notify } from '@root/shared/utils/notification';

export const ApplySharedMagicTerminal: FC = () => {
  const { t } = useTranslation('forex-experts');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { values, setValues } = useFormikContext<MagicTerminalForm>();
  const [{ activeAccountOptions }] = useCreateAccountStep(values.account);
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);
  const [link, setLink] = useState('');
  const { initialValuesData } = useMagicTerminalInitialValues();
  const [first, last] = values?.user?.split(' ') || [];
  const noRoles = !subscriptionInfo?.roles?.length;
  const { downloadConfigById } = useShareConfig();
  const { chartInstance, importChartSettings } = useChart();

  const onCancel = useCallback(() => {
    setIsOpen(false);
    setLink('');
  }, [setIsOpen]);

  const subscriptionAllowFxMagicBreakeven = useSelector(authSelector.subscriptionAllowFxMagicBreakeven);
  const subscriptionAllowFxMagicTrailingStop = useSelector(authSelector.subscriptionAllowFxMagicTrailingStop);
  const subscriptionAllowFxMagicPartialTakeProfit = useSelector(authSelector.subscriptionAllowFxMagicPartialTakeProfit);

  const applySharedConfig = useCallback(async () => {
    try {
      const urlObj = new URL(link);
      const sharedConfigId = urlObj.searchParams.get('sharedConfigId');

      if (sharedConfigId) {
        const sharedConfig = await downloadConfigById(sharedConfigId);

        if (!sharedConfig) {
          throw Error('Failed to download shared config');
        }

        const sharedConfigToMixin = sharedConfig?.orderData;

        if (subscriptionAllowFxMagicBreakeven) {
          delete sharedConfigToMixin.breakEven;
        }

        if (subscriptionAllowFxMagicTrailingStop) {
          delete sharedConfigToMixin.trailStop;
        }

        if (subscriptionAllowFxMagicPartialTakeProfit) {
          delete sharedConfigToMixin.partialClose;
        }

        const values = merge({ user: sharedConfig.orderData.user }, initialValuesData, sharedConfigToMixin);

        if (values.account && activeAccountOptions?.some((item) => item.value !== values.account)) {
          values.account = activeAccountOptions[0].value;
        }

        if (chartInstance && sharedConfig?.chartData) {
          importChartSettings(sharedConfig.chartData);
        }

        setValues(values);
        onCancel();
        setLink('');
      } else {
        throw Error('Unknown url');
      }
    } catch {
      notify({
        text: t('sharedTerminal.incorrectUrlFormat'),
        type: 'danger',
      });
    }
  }, [
    activeAccountOptions,
    chartInstance,
    downloadConfigById,
    importChartSettings,
    initialValuesData,
    link,
    onCancel,
    setValues,
    subscriptionAllowFxMagicBreakeven,
    subscriptionAllowFxMagicPartialTakeProfit,
    subscriptionAllowFxMagicTrailingStop,
    t,
  ]);

  if (noRoles) {
    return null;
  }

  return (
    <div className='flex flex-col gap-2.5'>
      {values.user && (
        <div className='flex items-center gap-2.5'>
          <DefaultImage label='' width={34} height={34} background='rgb(0, 194, 174)'>
            <Text className='font-semibold' size='sm'>
              {first?.substring(0, 1).toUpperCase()}
              {last?.substring(0, 1).toUpperCase()}
            </Text>
          </DefaultImage>
          <Text className='font-medium' size='sm'>
            {t('sharedTerminal.setupSharedBy', { name: values.user })}
          </Text>
        </div>
      )}

      <Text size='sm' className='text-primary-400 font-semibold cursor-pointer mb-1 hover:opacity-80' onClick={() => setIsOpen(!isOpen)}>
        {t('sharedTerminal.applySharedSetup')}
      </Text>

      <Modal title={<Title level={6}> {t('sharedTerminal.openShareSetup')} </Title>} onCancel={onCancel} footer={null} isOpen={isOpen}>
        <div className='flex flex-col items-center gap-6 -mt-7'>
          <TextField
            name='applySetup'
            type='text'
            value={link}
            wrapperClassName={'w-full mb-2 my-6'}
            label={t('sharedTerminal.enterSharedLink')}
            placeholder='URL'
            onChange={(e) => setLink(e.target.value)}
          />
          <div className='w-full flex items-center justify-between'>
            <Button onClick={onCancel} outlined>
              {t('common:cancel')}
            </Button>
            <Button onClick={applySharedConfig}>{t('sharedTerminal.apply')}</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
