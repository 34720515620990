import { Selector, createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';
import { IAccountsState } from '@root/modules/accounts/store/accounts.slice';

const getState = (state: IAppState) => state.accounts;
const getUpdateAccountId = (state: IAccountsState) => state.updateAccountId;
const getBalance = (state: IAccountsState) => state.balance;
const getSymbolMapperModalData = (state: IAccountsState) => state.symbolMapperModalData;
const getIsBalanceFetchedData = (state: IAccountsState) => state.balance.isBalanceFetchedData;
const getServiceAccountModalOpened = (state: IAccountsState) => state.serviceAccountModalOpened;
const getBalanceCurrency = (state: IAccountsState) => state.balance.balanceCurrency;
const getResetAccountId = (state: IAccountsState) => state.resetAccountId;

export const accountsSelector = {
  resetAccountId: createSelector<[Selector<IAppState, IAccountsState>], IAccountsState['resetAccountId']>([getState], getResetAccountId),
  updateAccountId: createSelector<[Selector<IAppState, IAccountsState>], IAccountsState['updateAccountId']>([getState], getUpdateAccountId),
  balance: createSelector<[Selector<IAppState, IAccountsState>], IAccountsState['balance']>([getState], getBalance),
  symbolMapperModalData: createSelector<[Selector<IAppState, IAccountsState>], IAccountsState['symbolMapperModalData']>([getState], getSymbolMapperModalData),
  isBalanceFetchedData: createSelector<[Selector<IAppState, IAccountsState>], IAccountsState['balance']['isBalanceFetchedData']>([getState], getIsBalanceFetchedData),
  serviceAccountModalOpened: createSelector<[Selector<IAppState, IAccountsState>], IAccountsState['serviceAccountModalOpened']>([getState], getServiceAccountModalOpened),
  balanceCurrency: createSelector<[Selector<IAppState, IAccountsState>], IAccountsState['balance']['balanceCurrency']>([getState], getBalanceCurrency),
};
