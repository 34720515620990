export type ParsedSymbol = { exchange: string; symbol: string } | null;

export const parseFullSymbol = (fullSymbol: string): ParsedSymbol => {
  try {
    const [exchange, symbol] = fullSymbol.split(':');

    return {
      exchange,
      symbol,
    };
  } catch (error) {
    return null;
  }
};
