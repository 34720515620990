import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { AuthUserMapper } from '@root/shared-files/modules/auth/mappers';
import { IAuthUser } from '@root/shared-files/modules/auth/types';
import { UserSubscriptionInfo } from '@root/shared-files/modules/subscriptions/types/user-subscription-info';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetProfileServiceResponse = IHttpResponse<200, { user: IAuthUser; userSubscriptionInfo: UserSubscriptionInfo }> | IHttpResponse<400, string>;

export const getProfileService = async (): Promise<GetProfileServiceResponse> => {
  try {
    const response = await fetcher.get('/auth/me');

    return {
      status: 200,
      payload: {
        userSubscriptionInfo: AuthUserMapper.collectSubscriptionInfo(response.data),
        user: AuthUserMapper.toDomain(response.data.user),
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
