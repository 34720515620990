import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const TelegramWhiteIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width={40} height={40} viewBox='0 0 40 40' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.0007 3.33301C10.8007 3.33301 3.33398 10.7997 3.33398 19.9997C3.33398 29.1997 10.8007 36.6663 20.0007 36.6663C29.2007 36.6663 36.6673 29.1997 36.6673 19.9997C36.6673 10.7997 29.2007 3.33301 20.0007 3.33301ZM27.734 14.6663C27.484 17.2997 26.4007 23.6997 25.8507 26.6497C25.6173 27.8997 25.1507 28.3163 24.7173 28.3663C23.7507 28.4497 23.0173 27.733 22.084 27.1163C20.6173 26.1497 19.784 25.5497 18.3673 24.6163C16.7173 23.533 17.784 22.933 18.734 21.9663C18.984 21.7163 23.2507 17.833 23.334 17.483C23.3456 17.43 23.344 17.375 23.3295 17.3227C23.315 17.2704 23.2879 17.2225 23.2507 17.183C23.1507 17.0997 23.0173 17.133 22.9007 17.1497C22.7507 17.183 20.4173 18.733 15.8673 21.7997C15.2007 22.2497 14.6006 22.483 14.0673 22.4663C13.4673 22.4497 12.334 22.133 11.484 21.8497C10.434 21.5163 9.61732 21.333 9.68398 20.7497C9.71732 20.4497 10.134 20.1497 10.9173 19.833C15.784 17.7163 19.0173 16.3163 20.634 15.6497C25.2673 13.7163 26.2173 13.383 26.8507 13.383C26.984 13.383 27.3006 13.4163 27.5007 13.583C27.6673 13.7163 27.7173 13.8997 27.734 14.033C27.7173 14.133 27.7506 14.433 27.734 14.6663Z'
        fill='currentColor'
      />
    </svg>
  );
};
