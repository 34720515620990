import styled from 'styled-components';

import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AdvancedSettings } from '@root/modules/experts/components/advanced-settings';
import { BreakEven } from '@root/modules/experts/components/break-even';
import { MoneyManagementFieldset } from '@root/modules/experts/components/money-management-fieldset';
import { PartialTakeProfitsFieldset } from '@root/modules/experts/components/partial-take-profits-fieldset/partial-take-profits-fieldset';
import { SessionManagement } from '@root/modules/experts/components/session-management';
import { StopLossTakeProfitFieldset } from '@root/modules/experts/components/stop-loss-take-profit-fieldset';
import { TrailingStop } from '@root/modules/experts/components/trailing-stop';
import { FailedOrderReason } from '@root/modules/magic-terminal/components/failed-order-reason';
import TradingView from '@root/modules/magic-terminal/containers/chart/trading-view';
import { MagicTerminalOrderSettings } from '@root/modules/magic-terminal/containers/form/magic-terminal-order-settings';
import { RetryStatusModals } from '@root/modules/magic-terminal/containers/retry-status-modals/retry-status-modals';
import { useRetrySignalForm } from '@root/modules/magic-terminal/hooks/use-retry-signal-form';
import { MagicTerminalOrderMarketType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { IRetrySignalForm } from '@root/modules/magic-terminal/types/signal';
import { useCheckUserRoles } from '@root/shared-files/modules/auth/hooks/use-check-roles';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { Button } from '@root/shared/ui/button';
import { Switch } from '@root/shared/ui/form/switch';
import { ButtonLink } from '@root/shared/ui/link';
import { Title } from '@root/shared/ui/typography';

export const FormWrapper = styled.form`
  h1 {
    font-size: 15px;
  }

  .block span.suffix {
    font-size: 13px;
  }
`;

const Footer = styled.div`
  box-shadow: 0px -4px 24px rgba(12, 12, 12, 0.6);
`;

const RetrySignalFormComponent: FC<FormikProps<IRetrySignalForm>> = ({ values, isValid, isSubmitting, handleSubmit, status = null, setStatus, submitForm }) => {
  const { t } = useTranslation('forex-experts');
  const [chartVisible, setChartVisible] = useState(true);
  const { hideActions } = useGhostLogin();

  const { isForexRoleValid } = useCheckUserRoles();

  return (
    <>
      <div className='flex mb-4 flex-wrap align-start md:flex-row md:justify-between md:w-auto gap-4'>
        <div className='flex items-center justify-between gap-4 w-full'>
          <Title level={5} bold>
            {t('common:sideBar.magicTerminal')}
          </Title>

          <span className='md:invisible'>
            <Switch name='no' checked={chartVisible} label={t('magicTerminal.showChart')} onChange={(e) => setChartVisible(e.target.checked)} />
          </span>
        </div>
      </div>

      <FormWrapper className='w-full' onSubmit={handleSubmit}>
        <div className='w-full flex justify-between gap-4 relative flex-col md:flex-row'>
          {chartVisible && (
            <div className=' grow sticky'>
              <TradingView<IRetrySignalForm> />
            </div>
          )}

          <div className=' bg-gray-800 rounded-sm w-full md:w-[340px] h-full relative overflow-hidden min-w-[360px]'>
            <div className='w-full p-[10px] overflow-y-auto' style={{ height: 492, scrollbarWidth: 'none' }}>
              <div className='p-[10px] bg-grayscale-900 rounded-sm'>
                <FailedOrderReason />
                <MagicTerminalOrderSettings<IRetrySignalForm> />
                <MoneyManagementFieldset fieldsSchema='signal' isPartialEdit />
              </div>
              <div className='p-[10px] bg-grayscale-900 rounded-sm mt-2.5'>
                <SessionManagement isPartialEdit />
                <StopLossTakeProfitFieldset fieldsSchema='signal' withDescription={false} />
                <div className='mb-2' />
                <BreakEven use={values.breakEven.use} levels={values.breakEven.levels} densed />
                <div className='mt-3' />
                <TrailingStop use={values.trailStop.use} unitType={values.unitType} isTradeUpdate />
                <PartialTakeProfitsFieldset isPartialEdit showDescription={false} />
              </div>

              <div className='p-[10px] bg-grayscale-900 rounded-sm mt-2.5'>
                <SwitchField label={<Title level={7}>{t('magicTerminal.form.advancedSettings.label')}</Title>} name='useAdvancedSettings' />
                {values.useAdvancedSettings && (
                  <div className='mt-4'>
                    <AdvancedSettings isPartialEdit />
                  </div>
                )}
              </div>
            </div>

            <Footer className='w-full sticky bottom-0 left-0 bg-gray-800 p-4 z-30'>
              <div className='w-full flex justify-center items-center'>
                <div className='w-full flex flex-col gap-2.5'>
                  <Button
                    className={clsx('w-full', {
                      'border-danger-500': values.marketType === MagicTerminalOrderMarketType.SELL,
                      'border-success-500': values.marketType === MagicTerminalOrderMarketType.BUY,
                    })}
                    type='submit'
                    variant={values.marketType === MagicTerminalOrderMarketType.SELL ? 'danger' : 'success'}
                    tint='500'
                    disabled={!isValid || hideActions || isSubmitting || !isForexRoleValid}
                    loading={isSubmitting}
                  >
                    {t('magicTerminal.form.retrySignal')}
                  </Button>

                  <ButtonLink outlined isLocalRoute href={PageLinks.magicTerminal} className='flex items-center'>
                    {t('magicTerminal.form.returnToTerminal')}
                  </ButtonLink>
                </div>
              </div>
            </Footer>
          </div>
        </div>
      </FormWrapper>

      <RetryStatusModals status={status} setStatus={setStatus} submitForm={submitForm} />
    </>
  );
};

export const RetrySignalForm: FC = () => {
  const { initialValues, schema, onSubmit } = useRetrySignalForm();

  if (!initialValues) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit} validateOnChange validateOnMount enableReinitialize>
      {(props) => <RetrySignalFormComponent {...props} />}
    </Formik>
  );
};
