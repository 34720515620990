import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getDefaultMethod } from '@root/shared-files/modules/auth/helpers/get-default-method';
import { handleTwoFaErrors } from '@root/shared-files/modules/auth/helpers/handle-two-fa-errors';
import { signInVerify } from '@root/shared-files/modules/auth/services/two-fa/sign-in-verify.service';
import { authSlice } from '@root/shared-files/modules/auth/store';
import { EnterCodeWithBackupForm, TwoFAAuthType, TwoFAResponse } from '@root/shared-files/modules/auth/types/two-fa';
import { twoFaCodeValidation } from '@root/shared-files/modules/auth/validations/two-fa.validation';
import { IFormStatus } from '@root/shared/form';
import { deleteCookie } from '@root/shared/utils/cookies';
import { notify } from '@root/shared/utils/notification';

export type SignInVerifyForm = EnterCodeWithBackupForm;

export const useSignInVerifyForm = (twoFaResponse: TwoFAResponse, closeModal?: () => void) => {
  const { t } = useTranslation('two-fa');
  const [searchParams] = useSearchParams();
  const schema = useMemo(() => twoFaCodeValidation(t), [t]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    const { method } = getDefaultMethod(twoFaResponse);

    return {
      code: '',
      useBackupCode: false,
      backupCode: '',
      type: method?.type || TwoFAAuthType.Authenticator,
    };
  }, [twoFaResponse]);

  const onSubmit = useCallback(
    async (values: SignInVerifyForm, helpers: FormikHelpers<SignInVerifyForm>) => {
      helpers.setSubmitting(true);
      const data = values.useBackupCode
        ? {
            type: TwoFAAuthType.Backup,
            code: values.backupCode,
          }
        : {
            type: values.type,
            code: values.code,
          };
      const response = await signInVerify(data);
      if (response.status === 200) {
        const session = response.payload;
        deleteCookie('twoFaAccessToken');
        dispatch(authSlice.actions.signedIn(response.payload));
        helpers.setStatus({ type: 'success', message: null } as IFormStatus);

        const next = decodeURIComponent(searchParams.get('next') || '');
        if (session.userSubscriptionInfo.roles?.length) {
          if (next && next !== '/') {
            navigate(next);
          } else {
            navigate('/');
          }
        } else {
          navigate(`/subscriptions`);
        }

        closeModal?.();
      } else {
        if (response.status === 418) {
          handleTwoFaErrors(response, values, helpers);
        } else {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }
      }
      helpers.setSubmitting(false);
    },
    [closeModal, dispatch, navigate, searchParams],
  );

  return {
    initialValues,
    schema,
    onSubmit,
  };
};
