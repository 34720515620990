import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const AppleIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 15 17' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M14.0276 12.9691C13.7857 13.5281 13.4993 14.0427 13.1674 14.5157C12.7151 15.1606 12.3447 15.6071 12.0593 15.8549C11.6169 16.2618 11.1428 16.4702 10.6352 16.4821C10.2707 16.4821 9.83124 16.3784 9.31965 16.168C8.80638 15.9586 8.33469 15.8549 7.9034 15.8549C7.45107 15.8549 6.96595 15.9586 6.44705 16.168C5.92736 16.3784 5.50871 16.488 5.18862 16.4989C4.70182 16.5196 4.2166 16.3053 3.73227 15.8549C3.42315 15.5853 3.03649 15.1231 2.5733 14.4683C2.07633 13.7691 1.66775 12.9582 1.34766 12.0338C1.00485 11.0353 0.833008 10.0685 0.833008 9.13238C0.833008 8.06012 1.0647 7.13531 1.52879 6.36032C1.89352 5.73782 2.37874 5.24677 2.98603 4.88629C3.59331 4.5258 4.24949 4.34211 4.95614 4.33035C5.34279 4.33035 5.84984 4.44995 6.47994 4.68501C7.10826 4.92085 7.51171 5.04046 7.68859 5.04046C7.82083 5.04046 8.26902 4.90061 9.0288 4.6218C9.74729 4.36324 10.3537 4.25618 10.8505 4.29835C12.1966 4.40699 13.2079 4.93764 13.8805 5.89366C12.6766 6.62312 12.081 7.64482 12.0929 8.9555C12.1038 9.97641 12.4741 10.826 13.202 11.5005C13.5319 11.8136 13.9002 12.0556 14.3101 12.2274C14.2212 12.4852 14.1274 12.7321 14.0276 12.9691ZM10.9403 0.820335C10.9403 1.62052 10.648 2.36765 10.0653 3.05918C9.36212 3.88128 8.51158 4.35633 7.58924 4.28137C7.57748 4.18537 7.57067 4.08434 7.57067 3.97817C7.57067 3.20999 7.90508 2.38789 8.49894 1.71572C8.79542 1.37538 9.17249 1.0924 9.62976 0.866654C10.086 0.644281 10.5176 0.521303 10.9235 0.500244C10.9354 0.607216 10.9403 0.714195 10.9403 0.820325V0.820335Z' />
    </svg>
  );
};
