export const stringifyParams = (params: Record<string, any>, includeAll?: boolean): string => {
  const searchParams = new URLSearchParams();

  if (includeAll) {
    for (const prop in params) {
      if (Object.prototype.hasOwnProperty.call(params, prop) && params[prop] !== undefined) {
        const value = params[prop];
        if (Array.isArray(value)) {
          value.forEach((v) => searchParams.append(prop, v));
        } else {
          searchParams.append(prop, value);
        }
      }
    }
  } else {
    for (const prop in params) {
      if (Object.prototype.hasOwnProperty.call(params, prop) && params[prop] !== undefined && (!params[prop] || params[prop] !== 'all')) {
        const value = params[prop];
        if (Array.isArray(value)) {
          value.forEach((v) => searchParams.append(prop, v));
        } else {
          searchParams.append(prop, value);
        }
      }
    }
  }

  return searchParams.toString();
};
