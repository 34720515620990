import { useMemo } from 'react';

import { createPartialTakeProfitEditingDto, makeExpertFormValues } from '@root/modules/experts/helpers/make-expert-form-values';
import { magicTerminalDefaultSymbolLocalStorageKey } from '@root/modules/magic-terminal/constants';
import { MagicTerminalForm, MagicTerminalOrderMarketType, MagicTerminalOrderPendingType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { getFromLocalStorage } from '@root/shared/utils/local-storage';

export const useMagicTerminalInitialValues = () => {
  const initialValues: MagicTerminalForm = useMemo(() => {
    const initialValuesData = makeExpertFormValues();

    const defaultSymbol = getFromLocalStorage<string>(magicTerminalDefaultSymbolLocalStorageKey);

    return {
      ...initialValuesData,
      name: 'magic',
      strategy: { ...initialValuesData.strategy, type: 'manual' },
      partialClose: {
        use: false,
        calculationType: '0',
        takeprofits: [createPartialTakeProfitEditingDto()],
      },
      manualSlTp: { ...initialValuesData.manualSlTp, tpRatio: '', type: '0', fixedSl: '', fixedTp: '' },
      price: '',
      symbol: defaultSymbol ?? '',
      marketType: MagicTerminalOrderMarketType.BUY,
      pendingType: MagicTerminalOrderPendingType.MARKET,
      manualMoneyManagement: { ...initialValuesData.manualMoneyManagement, lotsize: '', riskPercent: '' },
      trailStop: { ...initialValuesData.trailStop, afterX: '', followY: '' },
    };
  }, []);

  return { initialValuesData: initialValues };
};
