import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useChartVisualMode } from '@root/modules/charting-library/contexts/chart-visual-mode-context';
import { BreakEven } from '@root/modules/experts/components/break-even';
import { MoneyManagementFieldset } from '@root/modules/experts/components/money-management-fieldset';
import { PartialTakeProfitsFieldset } from '@root/modules/experts/components/partial-take-profits-fieldset/partial-take-profits-fieldset';
import { StopLossTakeProfitFieldset } from '@root/modules/experts/components/stop-loss-take-profit-fieldset';
import { TrailingStop } from '@root/modules/experts/components/trailing-stop';
import { Footer, FormWrapper, HeightWrapper } from '@root/modules/magic-terminal/components/magic-terminal.ui';
import TradingView from '@root/modules/magic-terminal/containers/chart/trading-view';
import { MagicTerminalOrderSettings } from '@root/modules/magic-terminal/containers/form/magic-terminal-order-settings';
import { ShareMagicTerminalButton } from '@root/modules/magic-terminal/containers/share/share-magic-terminal-button';
import { useMagicTerminalDrawings } from '@root/modules/magic-terminal/hooks/use-magic-terminal-drawings';
import { useMagicTerminalForm } from '@root/modules/magic-terminal/hooks/use-magic-terminal-form';
import { MagicTerminalForm as MagicTerminalFormDto, MagicTerminalOrderMarketType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { Button } from '@root/shared/ui/button';
import { Switch } from '@root/shared/ui/form/switch';
import { Title } from '@root/shared/ui/typography';

const MagicTerminalFormComponent: FC<FormikProps<MagicTerminalFormDto>> = ({ values, isValid, isSubmitting, handleSubmit }) => {
  const { t } = useTranslation('forex-experts');
  const [chartVisible, setChartVisible] = useState(true);
  const { hideActions } = useGhostLogin();
  const { isFullScreenMode, isOrderFormVisible, setIsOrderFormVisible } = useChartVisualMode();
  useMagicTerminalDrawings();

  const subscriptionAllowFxMagicBreakeven = useSelector(authSelector.subscriptionAllowFxMagicBreakeven);
  const subscriptionAllowFxMagicTrailingStop = useSelector(authSelector.subscriptionAllowFxMagicTrailingStop);
  const subscriptionAllowFxMagicPartialTakeProfit = useSelector(authSelector.subscriptionAllowFxMagicPartialTakeProfit);

  return (
    <HeightWrapper $isFullScreenMode={isFullScreenMode}>
      <div className='flex mb-4 flex-wrap align-start md:flex-row md:justify-between md:w-auto gap-4'>
        {isFullScreenMode ? (
          <div className='flex items-center justify-end gap-1.5 w-full'>
            <Switch
              wrapperClassName='flex-row-reverse'
              name='no'
              checked={isOrderFormVisible}
              label={t('magicTerminal.hideOrderCreation')}
              onChange={(e) => setIsOrderFormVisible(e.target.checked)}
            />
          </div>
        ) : (
          <div className='flex items-center justify-between gap-4 w-full'>
            <Title level={5} bold>
              {t('common:sideBar.magicTerminal')}
            </Title>

            <span className='md:invisible'>
              <Switch name='no' checked={chartVisible} label={t('magicTerminal.showChart')} onChange={(e) => setChartVisible(e.target.checked)} />
            </span>
          </div>
        )}
      </div>
      <FormWrapper className='w-full' onSubmit={handleSubmit}>
        <div className='w-full flex justify-between gap-4 relative flex-col md:flex-row'>
          {chartVisible && (
            <div className=' grow sticky'>
              <TradingView<MagicTerminalFormDto> />
            </div>
          )}

          {isOrderFormVisible && (
            <div className=' bg-gray-800 rounded-sm w-full md:w-[340px] h-full relative overflow-hidden min-w-[360px] '>
              <div className={clsx('w-full p-[10px] overflow-y-auto order-section-container')} style={{ scrollbarWidth: 'none' }}>
                <div className='p-[10px] bg-grayscale-900 rounded-sm'>
                  <MagicTerminalOrderSettings<MagicTerminalFormDto> />
                  <MoneyManagementFieldset fieldsSchema='manual' isPartialEdit isMagicTerminal />
                </div>
                <div className='p-[10px] bg-grayscale-900 rounded-sm mt-2.5'>
                  <StopLossTakeProfitFieldset fieldsSchema='manual' isPartialEdit withDescription={false} />
                </div>

                <div className='p-[10px] bg-grayscale-900 rounded-sm mt-2.5'>
                  <SwitchField label={<Title level={7}>{t('magicTerminal.form.advancedSettings.label')}</Title>} name='useAdvancedSettings' />
                  {values.useAdvancedSettings && (
                    <div className='mt-4'>
                      <div className='flex mb-2'>
                        <BreakEven use={values.breakEven.use} levels={values.breakEven.levels} densed disabled={!subscriptionAllowFxMagicBreakeven} />
                      </div>
                      <TrailingStop use={values.trailStop.use} unitType={values.unitType} isTradeUpdate disabled={!subscriptionAllowFxMagicTrailingStop} />
                      <div className='mt-2'>
                        <PartialTakeProfitsFieldset magicTerminal isPartialEdit showDescription={false} disabled={!subscriptionAllowFxMagicPartialTakeProfit} />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Footer className='w-full sticky bottom-0 left-0 bg-gray-800 p-4 z-30'>
                <div className='w-full flex justify-center items-center'>
                  <div className='w-full flex items-center justify-between gap-2'>
                    <ShareMagicTerminalButton disabled={!values.account} />

                    {values.marketType === MagicTerminalOrderMarketType.BUY ? (
                      <Button
                        className='w-full border-success-500'
                        type='submit'
                        variant='success'
                        tint='500'
                        disabled={!isValid || hideActions || isSubmitting}
                        loading={isSubmitting}
                      >
                        {t('magicTerminal.form.marketType.buy')}
                      </Button>
                    ) : values.marketType === MagicTerminalOrderMarketType.SELL ? (
                      <Button
                        className='w-full border-danger-500'
                        type='submit'
                        variant='danger'
                        tint='500'
                        disabled={!isValid || hideActions || isSubmitting}
                        loading={isSubmitting}
                      >
                        {t('magicTerminal.form.marketType.sell')}
                      </Button>
                    ) : null}
                  </div>
                </div>
              </Footer>
            </div>
          )}
        </div>
      </FormWrapper>
    </HeightWrapper>
  );
};

export const MagicTerminalForm: FC = () => {
  const { initialValues, schema, onSubmit } = useMagicTerminalForm();

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit} validateOnChange validateOnMount enableReinitialize>
      {(props) => <MagicTerminalFormComponent {...props} />}
    </Formik>
  );
};
