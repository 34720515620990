import { ISignalData } from '@root/modules/magic-terminal/types/signal';

export class SignalDtoMapper {
  public static toDomain(data): ISignalData {
    return {
      id: data.id,
      expertId: data.expertId,
      signal: data.signal.signal,
      reason: data.reason,
      status: data.status,
    };
  }
}
