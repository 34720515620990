import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';

export const useCheckUserRoles = () => {
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const isForexRoleValid = useMemo(() => subscriptionInfo?.roles?.some((role) => role.includes('forex')), [subscriptionInfo]);

  return { isForexRoleValid };
};
