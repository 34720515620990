import { createAction, createSlice } from '@reduxjs/toolkit';

import { IOrder } from '@root/modules/orders/types/orders';

export type IOrdersState = {
  orders: Record<string, IOrder>;
  shouldListenOrders: boolean;
  closedOrders: Array<number>;
};

const initialState: IOrdersState = {
  orders: {},
  shouldListenOrders: false,
  closedOrders: [],
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    subscribe: (state) => {
      state.shouldListenOrders = true;
    },
    unsubscribe: (state) => {
      state.shouldListenOrders = false;
    },
    setOrdersData: (state, action) => {
      state.orders = action.payload;
    },
    setClosedOrders: (state, action) => {
      state.closedOrders = [...state.closedOrders, action.payload];
    },
  },
});

export const deleteAccountActionCreator = createAction<string>('accounts/delete');
