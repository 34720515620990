import { FC, Fragment } from 'react';

import { MoneyValue } from '@root/modules/orders/components/money-value';
import { Profit } from '@root/modules/orders/types/profit';

export const Pnl: FC<{ profitByCurrencyMap: Record<string, Profit> }> = ({ profitByCurrencyMap }) => {
  if (Object.keys(profitByCurrencyMap).length === 0) {
    return <>-</>;
  }

  return (
    <span className='flex items-center'>
      {Object.entries(profitByCurrencyMap).map(([currency, profit], index, array) => (
        <Fragment key={currency}>
          <MoneyValue className='text-base' value={profit.profit} currency={currency} />
          {index !== array.length - 1 && <span className='block w-px h-3 bg-color-divider mx-1' />}
        </Fragment>
      ))}
    </span>
  );
};
