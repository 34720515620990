import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const ShieldIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' {...props}>
      <path
        d='M1.25099 1.66699L4.99996 0.416992L8.74901 1.66699C8.74901 1.66699 8.75 2.91699 8.75 5.41699C8.75 7.91699 4.99996 9.58366 4.99996 9.58366C4.99996 9.58366 1.25001 7.91699 1.25 5.41699C1.25 2.91699 1.25099 1.66699 1.25099 1.66699Z'
        fill='currentColor'
      />
    </svg>
  );
};
