import { Context } from '@opentelemetry/api';
import { ReadableSpan, Span, SpanProcessor } from '@opentelemetry/sdk-trace-web';

import { AttributeNames } from '@root/infra/integrations/telemetry/enums/AttributeNames';
import SessionGateway from '@root/infra/integrations/telemetry/gateways/Session.gateway';

const { userId } = SessionGateway.getSession();

export class SessionIdProcessor implements SpanProcessor {
  forceFlush(): Promise<void> {
    return Promise.resolve();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onStart(span: Span, parentContext: Context): void {
    span.setAttribute(AttributeNames.SESSION_ID, userId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEnd(span: ReadableSpan): void {}

  shutdown(): Promise<void> {
    return Promise.resolve();
  }
}
