import styled from 'styled-components';

import { ComponentProps } from 'react';

import checkboxOffSvg from '@root/assets/images/checkbox/off.svg';
import checkboxOnSvg from '@root/assets/images/checkbox/on.svg';
import { Toggle, TogglerWrapper } from '@root/shared/ui/toggler/toggler.styled';

export interface CheckboxExtraProps {
  error?: boolean;
  transparent?: boolean;
}

export type CheckboxProps = ComponentProps<'input'>;

const Container = styled.label`
  position: relative;
  display: inline-flex;
`;

const Label = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.gray[100]};
  cursor: pointer;
  z-index: 1;
`;

const Icon = styled.div<{ type: 'checkbox' | 'radio'; checked: boolean }>`
  display: inline-flex;
  width: 14px;
  height: 14px;
  position: relative;
  cursor: pointer;
  z-index: 0;

  ${({ type }) => {
    return type === 'checkbox'
      ? `
      &::before,
      &::after {
        display: none;
        content: '';
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("${checkboxOffSvg}");
        background-size: cover;
        position: absolute;
      }
    
      &::after {
        background-image: url("${checkboxOnSvg}");
        width: 26px;
        height: 22px;
        left: -5px;
        top: -2.6px;
        background-repeat: no-repeat;
      }
    `
      : '';
  }}

  ${({ theme, type, checked }) => {
    return type === 'radio'
      ? `
      &::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        top: 0;
        left: 0;
        border: 1px solid ${theme.grayscale[500]};
        border-radius: 50%;
      }

      &::after {
        content: '';
        position: absolute;
        display: ${checked ? 'block' : 'none'};
        width: 7px;
        height: 7px;
        top: 4px;
        left: 4px;
        border-radius: 50%;
        background: ${theme.primary[400]};
      }
    `
      : '';
  }}
`;

const Input = styled.input<CheckboxExtraProps>`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  top: 0;
  left: 0;

  &:checked ~ ${Icon}:after {
    display: block;
  }

  &:not(:checked) ~ ${Icon}:before {
    display: block;
  }

  &:disabled {
    cursor: not-allowed !important;

    &:checked ~ ${Icon}:after {
      background: ${({ theme }) => theme.grayscale[500]};
    }
  }
`;

Input.defaultProps = {
  type: 'checkbox',
};

const ToggleWrapper = TogglerWrapper;

export const CheckboxStyled = { Container, Label, Icon, Input, Toggle, ToggleWrapper };
