import { useFormikContext } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { UpdateAccountForm } from '@root/modules/accounts/hooks/use-update-account';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { useDrawdownReset } from '@root/modules/experts/hooks/use-drawdown-reset';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { SelectField, TextField } from '@root/shared/form';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { InfoIcon } from '@root/shared/icons/info-icon';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { RefreshIcon } from '@root/shared/icons/refresh-icon';
import { WarningIcon } from '@root/shared/icons/warning-icon';
import { Button } from '@root/shared/ui/button';
import { IconLabelLayout } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';

type Props = {
  isPartialEdit?: boolean;
};

export const Drawdown: FC<Props> = () => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.drawdown' });
  const { values, setFieldValue } = useFormikContext<CreateExpertDto | UpdateAccountForm>();
  const accounts = useGetAccounts();
  const { data: experts } = useGetExperts();

  const onUseChange = useCallback(
    (event) => {
      const checked = !!event.target.checked;
      setFieldValue('maxDrawDownLimit.use', checked);
    },
    [setFieldValue],
  );

  const { drawdownBalanceTypeOptions, drawdownLimitPeriodOptions } = useCreateExpertFieldOptions();

  const { resetLoading, handleResetDrawdown } = useDrawdownReset(values as CreateExpertDto);

  const isExpertForm = !!(values as CreateExpertDto)?.unitType;
  const isUpdateAccountForm = !!(values as UpdateAccountForm)?.accountId;

  const hasGreaterDDLimit = useMemo(() => {
    if (isExpertForm) {
      const expertValues = values as CreateExpertDto;
      const account = accounts.data?.find((item) => item.id === expertValues?.account);
      if (!!account?.maxDrawDownLimit && expertValues?.maxDrawDownLimit?.use && Number(account?.maxDrawDownLimit?.period) === Number(expertValues?.maxDrawDownLimit?.period)) {
        return Number(account?.maxDrawDownLimit?.percent) < Number(expertValues?.maxDrawDownLimit?.percent);
      }
    }

    if (isUpdateAccountForm) {
      const accountValues = values as UpdateAccountForm;
      if (accountValues?.maxDrawDownLimit?.use && accountValues?.maxDrawDownLimit?.percent) {
        const accountExperts = experts?.filter((expert) => expert.account?.id === accountValues.accountId);
        const greatestDD = accountExperts?.reduce((acc, expert) => {
          if (!expert.maxDrawDownLimit || Number(expert?.maxDrawDownLimit?.period) !== Number(accountValues?.maxDrawDownLimit?.period)) {
            return acc;
          }
          return Math.max(acc, Number(expert.maxDrawDownLimit.percent));
        }, 0);
        return Number(accountValues?.maxDrawDownLimit?.percent) < Number(greatestDD || 0);
      }
    }

    return false;
  }, [accounts.data, experts, isExpertForm, isUpdateAccountForm, values]);

  const shouldHideBalanceType = useMemo(() => {
    const expertValues = values as CreateExpertDto;
    const strategyType = expertValues?.strategy?.type;
    const type = strategyType === 'manual' ? expertValues.manualMoneyManagement?.type : expertValues.signalMoneyManagement?.type;
    return type !== '0' && isExpertForm;
  }, [values, isExpertForm]);

  return (
    <div className='flex flex-col gap-y-2'>
      <SwitchField
        name='maxDrawDownLimit.use'
        label={
          <InfoTooltip content={<Text size='sm'>{t('use.hint')}</Text>} positions={['top', 'left', 'right', 'bottom']}>
            <div className='flex justify-start items-center gap-x-2 cursor-pointer'>
              <Title level={7}>{t('use.label')}</Title>
              <InfoIcon className='shrink-0' width={16} height={16} />
            </div>
          </InfoTooltip>
        }
        onChange={onUseChange}
        disabled={!isExpertForm}
      />
      {values.maxDrawDownLimit?.use && (
        <>
          <div className='flex justify-between items-end gap-x-4'>
            {!shouldHideBalanceType && (
              <SelectField
                options={drawdownBalanceTypeOptions}
                name='maxDrawDownLimit.basedOn'
                label={t('balanceType.label')}
                placeholder={t('balanceType.placeholder')}
                wrapperClassName='grow'
              />
            )}
            {values.maxDrawDownLimit?.use && ((values as CreateExpertDto).id || (values as UpdateAccountForm).accountId) && (
              <InfoTooltip content={<Text size='sm'>{t('resetAccountHint')}</Text>} positions={['top', 'left', 'right', 'bottom']}>
                <Button loading={resetLoading} className='cursor-pointer flex justify-center gap-x-2 mb-0.5 px-3!' outlined onClick={handleResetDrawdown}>
                  <RefreshIcon width={16} height={16} className='mt-0.5' />
                  <Text size='sm'>{t('resetAccount')}</Text>
                </Button>
              </InfoTooltip>
            )}
          </div>
          <div className='grid xl:grid-cols-2 gap-x-4 gap-y-2'>
            <TextField
              name='maxDrawDownLimit.percent'
              label={
                <IconLabelLayout
                  icon={
                    <InfoTooltip content={t('percent.hint')}>
                      <InfoTooltipIcon />
                    </InfoTooltip>
                  }
                >
                  {t('percent.label')}
                </IconLabelLayout>
              }
              type='number'
              placeholder={t('percent.placeholder')}
              suffix='%'
            />
            {hasGreaterDDLimit && (
              <div className='text-secondary-200 xl:hidden flex items-center gap-x-2'>
                <WarningIcon className='shrink-0' />
                <Text size='sm'> {t('greaterThanAccountLimit')}</Text>
              </div>
            )}
            <SelectField
              options={drawdownLimitPeriodOptions}
              name='maxDrawDownLimit.period'
              label={
                <IconLabelLayout
                  icon={
                    <InfoTooltip content={t('drawdownLimitPeriod.hint')}>
                      <InfoTooltipIcon />
                    </InfoTooltip>
                  }
                >
                  {t('drawdownLimitPeriod.label')}
                </IconLabelLayout>
              }
              placeholder={t('drawdownLimitPeriod.placeholder')}
            />
          </div>
          {hasGreaterDDLimit && (
            <div className='text-secondary-200 hidden xl:flex items-center gap-x-2'>
              <WarningIcon className='shrink-0' />
              <Text size='sm'> {t('greaterThanAccountLimit')}</Text>
            </div>
          )}
        </>
      )}
    </div>
  );
};
