import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export type IMaintenanceState = {
  isMaintenanceMode: boolean;
};

export namespace MaintenanceActions {
  export type SetIsMaintenance = PayloadAction<boolean>;
}

export type MaintenanceSliceReducer = {
  setIsMaintenance: CaseReducer<IMaintenanceState, MaintenanceActions.SetIsMaintenance>;
};

export const marketplaceState: IMaintenanceState = {
  isMaintenanceMode: false,
};

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState: marketplaceState,
  reducers: {
    setIsMaintenance: (state, action) => {
      state.isMaintenanceMode = action.payload;
    },
  } as MaintenanceSliceReducer,
});
