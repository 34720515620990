import { IBroker, IDataByBrokerId } from '@root/modules/accounts/types/servers-with-company';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export abstract class BrokersDtoMapper {
  public static toDomain(list): { list: IBroker[]; dataByBrokerServerId: IDataByBrokerId } {
    const dataByBrokerServerId = list.reduce((acc, item) => {
      const { id, name, servers, serviceAccounts, alias } = item;
      servers.forEach((server) => (acc[server.id] = { alias, serverId: server.id, serverName: server.server, companyName: name, companyId: id, serviceAccounts }));
      return acc;
    }, {});

    return {
      list,
      dataByBrokerServerId,
    };
  }
}
