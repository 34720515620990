import styled from 'styled-components';

import { FC } from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { ChevronLeftIcon, ChevronRightIcon } from '@root/shared/icons/chevron-icon';

const PaginateWrapper = styled.div`
  & > ul {
    display: flex;

    & > li.previous,
    & > li.next {
      & > a {
        display: block;
        padding: ${({ theme }) => `${theme.spacing(2.3)}px ${theme.spacing(2)}px`};
        font-size: 12px;
        color: ${({ theme }) => theme.info[400]};
        border: ${({ theme }) => `1px solid ${theme.gray[700]}`};
      }
    }

    & > li.previous.disabled,
    & > li.next.disabled {
      & > a {
        pointer-events: none;
        color: ${({ theme }) => theme.gray[600]};
      }
    }

    & > li:not(.previous):not(.next) {
      & > a {
        display: block;
        position: relative;
        overflow: hidden;
        font-size: 15px;
        padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2.5)}px`};
        color: ${({ theme }) => theme.gray[600]};
        font-weight: 700;
        border: ${({ theme }) => `1px solid ${theme.gray[700]}`};
      }

      &.selected > a {
        color: ${({ theme }) => theme.gray[100]};
        background-color: ${({ theme }) => theme.info[400]};
        border: ${({ theme }) => `1px solid ${theme.info[400]}`};
      }
    }
  }

  :is([dir='ltr'] &) {
    li.previous {
      & > a {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    li.next {
      & > a {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  :is([dir='rtl'] &) {
    li.previous {
      & > a {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    li.next {
      & > a {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
`;

export const Paginate: FC<ReactPaginateProps> = (props) => {
  return (
    <PaginateWrapper>
      <ReactPaginate previousLabel={<ChevronLeftIcon />} nextLabel={<ChevronRightIcon />} {...props} />
    </PaginateWrapper>
  );
};
