import isNull from 'lodash/isNull';

import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalLoader } from '@root/infra/layout/components/global-loader';
import { RemapSymbolRow } from '@root/modules/accounts/components/remap-symbol-row';
import { useGetAccountsOptions } from '@root/modules/accounts/hooks/use-get-accounts-options';
import { useSymbolRemapTable } from '@root/modules/accounts/hooks/use-symbol-remap-table';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { usePageLeave } from '@root/shared/hooks/use-page-leave';
import { IOption } from '@root/shared/types/general';
import { Button } from '@root/shared/ui/button';
import { EmptyData } from '@root/shared/ui/empty-data';
import { Label, Select } from '@root/shared/ui/form';
import { LeaveModal } from '@root/shared/ui/leave-modal/leave-modal';
import { SkeletonTable } from '@root/shared/ui/skeleton-table/skeleton-table';
import { Table } from '@root/shared/ui/table';

const { Head, HeadSlimCellCenter, Row, HeadCellSlim, Body } = Table;

export const RemapSymbolTable: FC = () => {
  const { t } = useTranslation('forex-accounts', { keyPrefix: 'remapSymbol' });
  const [selectedBroker, setSelectedBroker] = useState<null | IOption>(null);
  const [{ isSubmitting, list, allOptions, dataChanged, isLoading }, { onSymbolChange, handleSaveSymbols, handleReset }] = useSymbolRemapTable(selectedBroker?.value);

  const { isModalOpen, closeModal, handleLeaveClick } = usePageLeave(dataChanged);
  const options = useGetAccountsOptions();

  const handleBrokerChange = useCallback(
    (value) => {
      setSelectedBroker(value);
    },
    [setSelectedBroker],
  );

  useEffect(() => {
    if (!selectedBroker && options) {
      setSelectedBroker(options[0]);
    }
  }, [selectedBroker, options]);

  const { hideActions } = useGhostLogin();

  if (isNull(selectedBroker)) {
    return <GlobalLoader />;
  }

  const noData = !selectedBroker;

  return (
    <div className='bg-gray-800 max-w-[800px] rounded-sm'>
      <div className='p-4 flex items-center flex-wrap justify-between gap-4 gap-y-0'>
        <div className='flex flex-col justify-between flex-1 w-full min-w-[240px]'>
          <Label>{t('label')}</Label>
          <Select onChange={handleBrokerChange} options={options || []} value={selectedBroker} className='w-full' alignMenuToRight />
        </div>

        <Button className='ml-auto mt-[26px]' disabled={!dataChanged || hideActions} loading={isSubmitting} onClick={handleSaveSymbols}>
          {t('actions.saveAllChanges')}
        </Button>
      </div>
      <div className='overflow-x-scroll'>
        <table className='w-full min-w-[460px] '>
          <colgroup>
            <col className='hidden md:block' />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <Head>
            <Row>
              <HeadCellSlim className='hidden md:block'>#</HeadCellSlim>
              <HeadSlimCellCenter>{t('columns.brokerSymbol')}</HeadSlimCellCenter>
              <HeadSlimCellCenter>{t('columns.sgmSymbol')}</HeadSlimCellCenter>
              <HeadSlimCellCenter>{t('columns.status')}</HeadSlimCellCenter>
            </Row>
          </Head>
          <Body>
            {!noData &&
              !isLoading &&
              list?.map((item, index) => <RemapSymbolRow key={item.sageSymbol} index={index} onSymbolChange={onSymbolChange} item={item} allOptions={allOptions} />)}
            {!noData && isLoading && <SkeletonTable rows={20} columns={6} />}
          </Body>
        </table>
      </div>

      {noData && <EmptyData />}

      {!!list.length && (
        <div className='p-4 flex items-center justify-between'>
          <Button outlined disabled={!dataChanged} onClick={handleReset}>
            {t('actions.reset')}
          </Button>

          <Button disabled={!dataChanged || hideActions} loading={isSubmitting} onClick={handleSaveSymbols}>
            {t('actions.saveAllChanges')}
          </Button>
        </div>
      )}

      {/*<div className='flex justify-center p-4'>*/}
      {/*  <Paginate pageCount={10} marginPagesDisplayed={3} />*/}
      {/*</div>*/}
      <LeaveModal isOpen={isModalOpen} onOk={handleLeaveClick} onCancel={closeModal} />
    </div>
  );
};
