import { Selector, createSelector } from '@reduxjs/toolkit';

import type { IAppState } from '@root/infra/store';
import { UserAgentState } from '@root/infra/user-agent/store/user-agent.slice';

const getState = (state: IAppState) => state.userAgent;
const getSelf = (state: UserAgentState) => state;
const getIsMobile = (state: UserAgentState) => state.isMobile;
const getIsDesktop = (state: UserAgentState) => !state.isMobile;
const getIsIos = (state: UserAgentState) => state.isIOS;
const getIsSafari = (state: UserAgentState) => state.isSafari;

export const userAgentSelector = {
  main: createSelector<[Selector<IAppState, UserAgentState>], UserAgentState>([getState], getSelf),
  isMobile: createSelector<[Selector<IAppState, UserAgentState>], UserAgentState['isMobile']>([getState], getIsMobile),
  isDesktop: createSelector<[Selector<IAppState, UserAgentState>], UserAgentState['isMobile']>([getState], getIsDesktop),
  isIOS: createSelector<[Selector<IAppState, UserAgentState>], UserAgentState['isIOS']>([getState], getIsIos),
  isSafari: createSelector<[Selector<IAppState, UserAgentState>], UserAgentState['isSafari']>([getState], getIsSafari),
};
