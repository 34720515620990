import { QueryFunction } from 'react-query';

import { getAccountService } from '@root/modules/accounts/services/get-account.service';
import { IAccount } from '@root/modules/accounts/types/account';
import { Id } from '@root/shared/utils/types';

export type GetAccountQueryKey = ['fx', Id | undefined, 'accounts', string | undefined];
export type GetAccountData = IAccount;

export const GET_ACCOUNT: QueryFunction<GetAccountData, GetAccountQueryKey> = async ({ queryKey }) => {
  const [, userId, , accountId] = queryKey;

  if (!userId || !accountId) {
    throw new Error('Invalid query');
  }

  const result = await getAccountService({ userId, accountId });

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
