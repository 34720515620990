import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { SubscriptionsModuleConfig } from '@root/shared-files/modules/subscriptions/module.config';
import { SubscriptionProduct } from '@root/shared-files/modules/subscriptions/types/subscription-products';
import { IHttpResponse } from '@root/shared/utils/http-service';

export type GetSubscriptionProducts = IHttpResponse<200, SubscriptionProduct[]> | IHttpResponse<400, string>;

export const getSubscriptionProducts = async (): Promise<GetSubscriptionProducts> => {
  try {
    const response = await fetcher.get(`/misc/subscriptions/platform/products?sourceApp=${SubscriptionsModuleConfig.sourceApp}`);

    return {
      status: 200,
      payload: response.data.map((item) => SubscriptionsModuleConfig.productsMapper.toDomain(item)),
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
