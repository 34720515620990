import { useFormikContext } from 'formik';
import { useCallback } from 'react';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { createPartialTakeProfitEditingDto } from '@root/modules/experts/helpers/make-expert-form-values';

export const useSignalTakeProfitFields = (isPartialEdit = false) => {
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();

  const fillRRDefaultProfits = useCallback(
    (rr?: number) => {
      const { signalSlTp } = values;
      const rrRatio = rr || signalSlTp.tpRatio;
      const takeprofits = Array.from({ length: rrRatio }).map((_, index) => createPartialTakeProfitEditingDto({ amount: `${index + 1}` }));
      setFieldValue('partialClose.use', true);
      setFieldValue('partialClose.calculationType', '1');
      setFieldValue('partialClose.takeprofits', takeprofits);
    },
    [values, setFieldValue],
  );

  const onSlTpTypeChange = useCallback(
    (option) => {
      setFieldValue('signalSlTp.type', option.value);

      if (option.value === '4') {
        setFieldValue('signalSlTp.profitCalculationType', '0');
        setFieldValue('signalSlTp.tpRatio', 2);
        setFieldValue('signalSlTp.riskRatio', 1);
      } else {
        setFieldValue('signalSlTp.profitCalculationType', '0');
        setFieldValue('signalSlTp.fixedSl', '');
        setFieldValue('signalSlTp.fixedTp', '');
        setFieldValue('signalSlTp.tpRatio', '');
      }

      if (!isPartialEdit) {
        if (option.value === '4') {
          fillRRDefaultProfits(2);
        } else {
          setFieldValue('partialClose.calculationType', '0');
          setFieldValue('partialClose.takeprofits', [createPartialTakeProfitEditingDto()]);
        }
      }
    },
    [isPartialEdit, setFieldValue, fillRRDefaultProfits],
  );

  const onCalculationTypeChange = useCallback(
    (option) => {
      setFieldValue('signalSlTp.profitCalculationType', option.value);
      if (option.value === '0') {
        setFieldValue('signalSlTp.tpRatio', 2);
        setFieldValue('signalSlTp.riskRatio', 1);
        if (!isPartialEdit) {
          fillRRDefaultProfits(2);
        }
      }
    },
    [isPartialEdit, fillRRDefaultProfits, setFieldValue],
  );

  const onTpRatioChange = useCallback(
    (option) => {
      setFieldValue('signalSlTp.tpRatio', option.value);
      if (option.value !== 1) setFieldValue('signalSlTp.riskRatio', 1);
      if (!isPartialEdit) {
        const rr = option.value;
        fillRRDefaultProfits(rr);
      }
    },
    [isPartialEdit, setFieldValue, fillRRDefaultProfits],
  );

  const onRiskRatioChange = useCallback(
    (option) => {
      setFieldValue('signalSlTp.riskRatio', option.value);
      if (option.value !== 1) setFieldValue('signalSlTp.tpRatio', 1);
    },
    [setFieldValue],
  );

  return {
    onSlTpTypeChange,
    onCalculationTypeChange,
    fillRRDefaultProfits,
    onRiskRatioChange,
    onTpRatioChange,
  };
};
