import { useLayoutEffect, useMemo, useState } from 'react';

import { TabType } from '@root/modules/orders/enums/orders';
import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { IOrder } from '@root/modules/orders/types/orders';
import { isOrderSlOrTpReached, isSfxOrder } from '@root/modules/orders/utils/orders';
import { useSelect } from '@root/shared/form';
import { usePaginationPreferences } from '@root/shared/hooks/use-pagination-preferences';
import { usePaginatedList } from '@root/shared/utils/hooks';

export type PageSizeOption = { value: number; label: string };

type Props = {
  tab: TabType;
  mtType?: string;
  expertId?: string;
  enabled?: boolean;
  selectedBroker?: string;
  noPending?: boolean;
  isMagicTerminal?: boolean;
  currencies?: string[];
};

export const useGetOrders = ({ tab, expertId, enabled, selectedBroker, mtType, isMagicTerminal, currencies }: Props) => {
  const [page, setPage] = useState<number>(0);
  const { handlePageChange } = usePaginationPreferences();

  const { openOrders, invalidateOpenOrderQueries } = useGetOpenOrders({ enabled: enabled || tab !== 'history', currencies, selectedBroker, mtType });

  const [orderSlOrTpWasReachedByIdMap, setOrdersSlOrTpWasReaachedByIdMap] = useState<{ [key: number]: boolean }>({});

  const sortedList = useMemo(
    () =>
      openOrders
        .filter((order) => {
          if (orderSlOrTpWasReachedByIdMap[order.ticket] === true) {
            return false;
          }

          const orderIsSfx = isSfxOrder(order);

          return (expertId === undefined || (orderIsSfx && order.expertId === expertId)) && (!isMagicTerminal || (orderIsSfx && order.magic));
        })
        .sort((a, b) => new Date(b.openTime).getTime() - new Date(a.openTime).getTime()),
    [expertId, isMagicTerminal, openOrders, orderSlOrTpWasReachedByIdMap],
  );

  useLayoutEffect(() => {
    const newOrderSlOrTpReaachedEntries = sortedList
      .filter((order) => orderSlOrTpWasReachedByIdMap[order.ticket] !== true && order.currentPrice !== null && isOrderSlOrTpReached(order, order.currentPrice))
      .map((order) => [order.ticket, true] as const);

    if (newOrderSlOrTpReaachedEntries.length > 0) {
      setOrdersSlOrTpWasReaachedByIdMap({
        ...orderSlOrTpWasReachedByIdMap,
        ...Object.fromEntries(newOrderSlOrTpReaachedEntries),
      });
    }
  }, [sortedList, orderSlOrTpWasReachedByIdMap]);

  const pageSizeInput = useSelect<PageSizeOption>({
    defaultValue: { value: 10, label: '10' },
    onChange: (page) => handlePageChange('dashboard.balances', page?.value || 10),
  });

  const paginatedData = usePaginatedList<IOrder>(sortedList, {
    page,
    pageSize: pageSizeInput.value?.value || 10,
  });

  const state = {
    list: sortedList,
    totalOpenOrders: sortedList.length,
    page,
    pageSizeInput,
    paginatedData,
  };

  const handlers = { setPage, invalidateOpenOrderQueries };

  return [state, handlers] as [typeof state, typeof handlers];
};
