import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const EditIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0996 0.697837L4.89532 6.90213C4.67569 7.12176 4.53349 7.40615 4.48911 7.71398L4.10984 10.3738C4.05261 10.7751 4.39226 11.1212 4.79459 11.0715L7.3796 10.7523C7.69586 10.7137 7.99131 10.5695 8.21806 10.3442L14.4817 4.08049C15.364 3.19827 15.4178 1.78834 14.6145 0.842223L14.4992 0.716272C13.548 -0.235662 12.0342 -0.235662 11.0996 0.697837ZM13.6114 1.56836L13.6928 1.65744C14.0655 2.09728 14.0394 2.78192 13.6114 3.20991L7.34912 9.47221L7.31385 9.50019C7.28835 9.51621 7.25935 9.52673 7.22962 9.53037L5.44141 9.75068L5.7077 7.88855C5.71405 7.84446 5.73434 7.80387 5.76583 7.77239L11.9699 1.56836C12.4235 1.11523 13.1583 1.11523 13.6114 1.56836ZM6.15479 3.07446C6.15479 2.73454 5.87923 2.45898 5.53931 2.45898H2.25676L2.11925 2.4631C0.9366 2.53413 0 3.51519 0 4.71574V13.7428L0.00411722 13.8803C0.0751418 15.0629 1.05621 15.9995 2.25676 15.9995H11.2838L11.4213 15.9954C12.6039 15.9244 13.5405 14.9433 13.5405 13.7428V8.81894L13.5349 8.73542C13.4942 8.435 13.2367 8.20346 12.9251 8.20346C12.5851 8.20346 12.3096 8.47902 12.3096 8.81894V13.7428L12.3043 13.8477C12.2518 14.3652 11.8152 14.7686 11.2838 14.7686H2.25676L2.15182 14.7633C1.6343 14.7108 1.23096 14.2742 1.23096 13.7428V4.71574L1.23625 4.6108C1.28874 4.09328 1.7253 3.68994 2.25676 3.68994H5.53931L5.62283 3.68432C5.92325 3.64357 6.15479 3.38606 6.15479 3.07446Z'
      />
    </svg>
  );
};
