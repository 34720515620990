import { QueryFunction } from 'react-query';

import { getExpertService } from '@root/modules/experts/services/get-expert.service';
import { IExpert } from '@root/modules/experts/types/expert';
import { Id } from '@root/shared/utils/types';

export type GetExpertQueryKey = ['experts', string];
export type GetExpertData = IExpert;

export const GET_EXPERT: QueryFunction<GetExpertData, GetExpertQueryKey> = async ({ queryKey }) => {
  const [, expertId] = queryKey;

  if (!expertId) {
    throw new Error('Invalid query');
  }

  const result = await getExpertService(expertId);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
