import { useMemo } from 'react';
import { keepPreviousData, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { TEST_PROVIDER_NAME } from '@root/modules/marketplace/constants/test-providers';
import {
  GET_MARKETPLACE_SUBSCRIBED,
  GetMarketplaceSubscribedProvidersQueryKey,
  GetMarketplaceTradeIdeaListData,
} from '@root/modules/marketplace/query/get-marketplace-subscribed-providers.query';
import { checkTestProvidersAvailable } from '@root/modules/marketplace/utils/check-test-providers-available';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { subscriptionsSelector } from '@root/shared-files/modules/subscriptions/store';

export const useGetSubscribedProviders = ({ enabled = true } = {}) => {
  const isAuthed = useSelector(authSelector.isAuthed);
  const subscriptionInfo = useSelector(subscriptionsSelector.userSubscriptionInfo);
  const isQAMember = useMemo(() => checkTestProvidersAvailable(subscriptionInfo?.roles), [subscriptionInfo]);

  const query = useQuery<GetMarketplaceTradeIdeaListData, Error, GetMarketplaceTradeIdeaListData, GetMarketplaceSubscribedProvidersQueryKey>({
    queryKey: ['marketplace-subscribed-providers'],
    queryFn: GET_MARKETPLACE_SUBSCRIBED,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 2,
    enabled: enabled && isAuthed,
  });

  const data = useMemo(() => {
    const list = query.data?.data?.filter((item) => (!isQAMember ? !item?.name?.includes(TEST_PROVIDER_NAME) : true)) || [];

    if (query?.data) {
      return {
        ...query.data,
        data: list,
      };
    }

    return query.data;
  }, [query, isQAMember]);

  return { ...query, data };
};
