import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient } from '@root/infra/query';
import { ExpertOrder, ModifyTradeForm } from '@root/modules/experts/components/details/trades-and-history/modify-trade-modal';
import { CreateExpertDtoMapper } from '@root/modules/experts/mappers/create-expert-dto.mapper';
import { updateExpertOrder } from '@root/modules/experts/services/update-experts-order.service';
import { createExpertValidation } from '@root/modules/experts/validations/common.validation';
import { notify } from '@root/shared/utils/notification';

type Props = {
  order: ExpertOrder;
  closeModal?: () => void;
};

export const useModifyTradeForm = ({ order, closeModal }: Props) => {
  const { t } = useTranslation('forex-experts');
  const { t: yupT } = useTranslation('yup');

  const initialValues = useMemo<ModifyTradeForm>(() => {
    let partialAllowed = true;

    if (order.additionalDetails?.options?.partialClose?.level > 0) {
      partialAllowed = false;
    }

    const expertConfiguration = CreateExpertDtoMapper.toDomain(order.expertConfiguration, order.magic);

    return {
      ...expertConfiguration,
      partialClose: {
        ...expertConfiguration.partialClose,
        calculationType: expertConfiguration.partialClose.use ? expertConfiguration.partialClose.calculationType : '0',
      },
      partialAllowed,
    };
  }, [order.additionalDetails?.options?.partialClose?.level, order.expertConfiguration, order.magic]);

  const onSubmit = useCallback(
    async (values: ModifyTradeForm, helpers: FormikHelpers<ModifyTradeForm>) => {
      helpers.setSubmitting(true);

      const expert = CreateExpertDtoMapper.toPersistence({
        ...values,
        partialClose: {
          ...values.partialClose,
          calculationType: values.partialClose.use ? values.partialClose.calculationType : '0',
        },
      });

      const data = {
        breakEven: expert.breakEven,
        partialClose: expert.partialClose,
        trailStop: expert.trailStop,
      };

      const response = await updateExpertOrder(order.expertConfiguration.id, order.ticket, order.accountId, order.ticket, order.magic, data);

      if (response.status === 200) {
        const expertId = order.expertConfiguration.id;
        queryClient.invalidateQueries({ queryKey: ['fx', expertId] });
        queryClient.invalidateQueries({ queryKey: ['fx', order.accountId, 'open-orders'] });
        notify({
          type: 'success',
          title: t('tradeUpdated'),
        });
      } else {
        notify({
          type: 'danger',
          title: response.payload,
        });
      }

      closeModal?.();

      helpers.setSubmitting(false);
    },
    [order.expertConfiguration.id, order.ticket, order.accountId, order.magic, closeModal, t],
  );

  return {
    initialValues,
    onSubmit,
    validationSchema: createExpertValidation(t, yupT, order.magic),
  };
};
