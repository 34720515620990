import { createGlobalStyle } from 'styled-components';

import { FC, ReactNode } from 'react';

import { GhostLoginBanner } from '@root/infra/layout/components/ghost-login-banner';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

const GlobalGhostLoginStyles = createGlobalStyle<{ $isGhostLogin: boolean }>`
  body {
    margin: 0;
    padding: ${({ $isGhostLogin }) => ($isGhostLogin ? '3px 4px' : '0')};
    ${({ $isGhostLogin, theme }) => ($isGhostLogin ? `background: ${theme.gradient[5]}` : '')};

    &::after {
      pointer-events: none;
      content: ${({ $isGhostLogin }) => ($isGhostLogin ? "''" : 'none')};
      position: fixed;
      inset: 0;
      border: 4px solid;
      border-image: ${({ $isGhostLogin, theme }) => ($isGhostLogin ? theme.gradient[5] : 'transparent')} 1;
      z-index:1000000;
    }
  }
`;

type Props = {
  children: ReactNode;
};

export const GhostLoginWrapper: FC<Props> = ({ children }) => {
  const { isGhostLogin } = useGhostLogin();

  return (
    <div>
      <GlobalGhostLoginStyles $isGhostLogin={isGhostLogin} />
      {isGhostLogin && <GhostLoginBanner />}
      {children}
    </div>
  );
};
