import styled from 'styled-components';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuizModal } from '@root/shared-files/modules/auth/hooks/use-quiz-modal';
import { Button } from '@root/shared/ui/button/button';
import { Modal } from '@root/shared/ui/modal';
import { Title } from '@root/shared/ui/typography';

const IFrame = styled.iframe`
  height: calc(80vh - 90px);
`;

export const QuizModal: FC = () => {
  const { t } = useTranslation('common');
  const { url, step, loading, confirmCompletion } = useQuizModal();

  return (
    <Modal
      isOpen={true}
      footer={null}
      hideCrossIcon
      // We still in need of `!w-11/12 !max-w-none` to support tailwind v3
      className='!w-11/12 !max-w-none w-11/12! max-w-none!'
    >
      {step === 1 && (
        <div className='-mt-8'>
          <Title level={7} className='font-bold text-center'>
            {t('quiz.completeText')}
          </Title>
          {url && <IFrame src={url} className='w-full' />}
          <div className='w-full flex justify-end gap-4 mt-4'>
            <Button loading={loading} onClick={confirmCompletion}>
              {t('quiz.confirmButton')}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
