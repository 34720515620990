import { LiveChatWidget, WidgetState } from '@livechat/widget-react';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router';

import { useGetCurrentSubscription } from '@root/infra/layout/hooks/use-get-current-subscription';
import { authSelector } from '@root/shared-files/modules/auth/store/auth.selector';

const LIVECHAT_LICENSE = import.meta.env.VITE_LIVE_CHAT_LICENSE;

export enum LiveChatWidgetVisibility {
  maximized = 'maximized',
  minimized = 'minimized',
  hidden = 'hidden',
}

export const liveChatWidgetVisibilityUrlSearchParameter = 'live-chat-widget-visibility';

export const SageLiveChat = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(authSelector.user);
  const authed = useSelector(authSelector.isAuthed);
  const widgetState = searchParams.get(liveChatWidgetVisibilityUrlSearchParameter) as WidgetState['visibility'];

  const { currentSubscription } = useGetCurrentSubscription();

  const onVisibilityChanged = ({ visibility }) => {
    setSearchParams((urlSearchParams) => {
      urlSearchParams.set(liveChatWidgetVisibilityUrlSearchParameter, visibility);

      return urlSearchParams;
    });
  };

  if (authed && user) {
    return (
      <div style={{ position: 'fixed', bottom: '50px', right: '50px', zIndex: 1000 }}>
        <LiveChatWidget
          customerName={user.fullName}
          customerEmail={user.email}
          license={LIVECHAT_LICENSE}
          visibility={widgetState || LiveChatWidgetVisibility.minimized}
          sessionVariables={{
            ['Login']: user.email,
            ['Email Address']: user.email,
            ['Subscription']: currentSubscription?.name || '-',
            ['Account_ID']: (user.id as string) || '-',
          }}
          onVisibilityChanged={onVisibilityChanged}
        />
      </div>
    );
  }

  return null;
};
