import { ReactNode, createContext, useContext, useState } from 'react';

interface ChartVisualModeContextType {
  isFullScreenMode: boolean;
  isOrderFormVisible: boolean;
  setIsFullScreenMode: (value: boolean) => void;
  setIsOrderFormVisible: (value: boolean) => void;
}

const ChartVisualModeContext = createContext<ChartVisualModeContextType | undefined>(undefined);

interface ChartVisualModeProviderProps {
  children: ReactNode;
}

export const ChartVisualModeProvider = ({ children }: ChartVisualModeProviderProps) => {
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [isOrderFormVisible, setIsOrderFormVisible] = useState(true);

  const value = {
    isFullScreenMode,
    isOrderFormVisible,
    setIsFullScreenMode: (value) => {
      setIsFullScreenMode(value);
      if (!value) {
        setIsOrderFormVisible(true);
      }
    },
    setIsOrderFormVisible,
  };

  return <ChartVisualModeContext.Provider value={value}>{children}</ChartVisualModeContext.Provider>;
};

export const useChartVisualMode = () => {
  const context = useContext(ChartVisualModeContext);
  if (context === undefined) {
    throw new Error('useChartVisualMode must be used within a ChartVisualModeProvider');
  }
  return context;
};
