import * as yup from 'yup';

import { MagicTerminalOrderPendingType } from '@root/modules/magic-terminal/types/magic-terminal-form';

export const createMagicTerminalOrderValidation = (t, yupT) =>
  yup.object().shape({
    price: yup.number().when('pendingType', {
      is: MagicTerminalOrderPendingType.LIMIT,
      then: (schema) => schema.required(yupT('mixed.requiredNoPath')).label(t('magicTerminal.form.price.priceLabel')),
      otherwise: (schema) => schema.label(t('magicTerminal.form.price.currentPriceLabel')),
    }),
    symbol: yup
      .string()
      // TODO - add proper XAU validation
      // .test('has-xau', t('fields.symbols.xauOnly'), (symbols, context) => {
      //   const allowedSymbolType = getAllowedSymbolType(context);
      //   const isManualStrategy = getStrategyType(context) === 'manual';
      //   const usePips = getUsePips(context);
      //   return isManualStrategy ? true : !((symbols || [])?.length > 1 && symbols?.includes('XAUUSD') && (allowedSymbolType ? allowedSymbolType !== SymbolType.CURRENCY : !usePips));
      // })
      .required(yupT('mixed.requiredNoPath'))
      .label(t('magicTerminal.form.pair.label')),
    manualSlTp: yup.object().shape({
      tpRatio: yup
        .number()
        .typeError(yupT('number.type'))
        .when('type', {
          is: 1,
          then: (schema) => schema.required(yupT('mixed.requiredNoPath')),
        })
        .label(t('fields.manualStopLossTakeProfit.tpRatio.label')),
    }),
  });
