import { milisecondsPerSecond } from '@root/shared/constants/date';
import { isAxiosError } from '@root/shared/utils/axios-utils';
import { isServerErrorResponseStatus } from '@root/shared/utils/http-utils';
import { createControllablePromise } from '@root/shared/utils/promise-utils';

export type MessageSource<T> = (callback: (message: T) => void) => () => void;

export const takeFirstMessageFromSource = <T>(subscribe: MessageSource<T>, { signal }: { signal: AbortSignal }) => {
  const [socketPromise, { resolve: socketPromiseResolve, reject: socketPromiseReject }] = createControllablePromise<T>();

  const unsubscribe = subscribe((message: T) => {
    unsubscribe();

    socketPromiseResolve(message);
  });

  signal.addEventListener('abort', () => {
    unsubscribe();
    socketPromiseReject(signal.reason);
  });

  return socketPromise;
};

type SourceMessage<S> = S extends MessageSource<infer T> ? T : never;

export const applyFiltersOnSource = <S extends MessageSource<SourceMessage<S>>>(subscribe: S, ...filters: ((message: SourceMessage<S>) => boolean)[]): S =>
  ((callback) =>
    subscribe((message) => {
      if (filters !== undefined && !filters.every((filter) => filter(message))) {
        return;
      }

      callback(message);
    })) as S;

export const timeoutMessage = 'timeout';

const createLongResponseRequestTimeoutError = () => new DOMException(timeoutMessage, 'AbortError');

export const isLongResponseRequestTimeoutError = (error: unknown): error is DOMException =>
  error instanceof DOMException && error.name === 'AbortError' && error.message === timeoutMessage;

export const makeLongResponseRequest = async <T, M>({
  subscribe,
  selectResultFromMessage,
  makeRequest,
}: {
  subscribe: MessageSource<M>;
  selectResultFromMessage: (message: M) => T;
  makeRequest: () => Promise<T>;
}) => {
  const [promise, { resolve, reject }] = createControllablePromise<T>();

  let resultHasBeenReceivedFromSocket = false;

  let unsubscribeTimeoutId: ReturnType<typeof setTimeout> | null = null;

  const socketAbortController = new AbortController();

  takeFirstMessageFromSource<M>(subscribe, { signal: socketAbortController.signal })
    .then(selectResultFromMessage)
    .then((result) => {
      resultHasBeenReceivedFromSocket = true;

      if (unsubscribeTimeoutId !== null) {
        clearTimeout(unsubscribeTimeoutId);
      }

      resolve(result);
    });

  makeRequest()
    .then((result) => {
      if (resultHasBeenReceivedFromSocket) {
        return;
      }

      socketAbortController.abort();

      resolve(result);
    })
    .catch((error: unknown) => {
      if (resultHasBeenReceivedFromSocket) {
        return;
      }

      if (isAxiosError(error)) {
        if (error.response !== undefined) {
          // One of the 500 cases is a long response from the trading terminal,
          // and this is exactly the case we are trying to cover here.
          if (isServerErrorResponseStatus(error.response.status)) {
            unsubscribeTimeoutId = setTimeout(() => {
              socketAbortController.abort();

              reject(createLongResponseRequestTimeoutError());
            }, 60 * milisecondsPerSecond);

            return;
          }
        }
      }

      socketAbortController.abort();

      reject(error);
    });

  return promise;
};
