import { useEffect, useState } from 'react';

export const useCheckIsIframe = () => {
  const [openedInIframe, setOpenedInIframe] = useState<boolean>(false);

  useEffect(() => {
    if (window !== undefined && window.top != window.self) {
      setOpenedInIframe(true);
    }
  }, [setOpenedInIframe]);

  return { openedInIframe };
};
