import { ChangeEventHandler, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LabelWithInfoTooltip } from '@root/infra/form/label-with-info-tooltip';
import { isTimeInForcePredefinedValue } from '@root/modules/experts/components/time-in-force/time-in-force-predefined-values';
import { TimeInForceCutomOpton } from '@root/modules/experts/components/time-in-force/time-in-force-predefined-values-fieldset';
import { CheckboxField } from '@root/shared/form';
import { DateInput, Label } from '@root/shared/ui/form';
import { TimeInput } from '@root/shared/ui/form/time-input';
import { Text } from '@root/shared/ui/typography';
import { getStartOfTheDay, toIsoStringWithoutTimezone, toLeadingZeroString } from '@root/shared/utils/date';

const timeFormatRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

const compileDateAndTime = (date: Date, time: string) => {
  const [hours, minutes] = time.split(':').map(Number);

  const dateTime = new Date(date);

  dateTime.setHours(hours, minutes);

  return dateTime;
};

export const TimeInForceCustomInputOption: TimeInForceCutomOpton = ({ value, onTouched, onChange }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.timeInForce' });

  const timeInForceIsPredefinedValue = isTimeInForcePredefinedValue(value);

  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState<string>('');

  const сhangeValue = (date: Date | null, time: string) => {
    if (date === null || !timeFormatRegex.test(time)) {
      onChange('');

      return;
    }

    const dateTime = compileDateAndTime(date, time);

    onChange(toIsoStringWithoutTimezone(dateTime));
  };

  const handleDateInputChange = (date: Date | null) => {
    setDate(date);

    сhangeValue(date, time);
  };

  const handleTimeInputChange = (nextTime: string) => {
    setTime(nextTime);

    сhangeValue(date, nextTime);
  };

  const handleCustomRadioChange: ChangeEventHandler<HTMLInputElement> = () => {
    сhangeValue(date, time);
  };

  const handleBlur = () => {
    onTouched();
  };

  useLayoutEffect(() => {
    if (timeInForceIsPredefinedValue) {
      return;
    }

    const date = new Date(value);

    setDate(getStartOfTheDay(date));

    setTime(`${toLeadingZeroString(date.getHours())}:${toLeadingZeroString(date.getMinutes())}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to run this effect only once
  }, []);

  return (
    <div className='flex flex-col gap-y-1'>
      <CheckboxField
        id='timeInForceCustom'
        type='radio'
        name='timeInForce'
        label={<Text className='text-sm'>{t('options.specifiedByUser.label')}</Text>}
        checked={!timeInForceIsPredefinedValue}
        onChange={handleCustomRadioChange}
        onBlur={handleBlur}
        hideError
      />
      {!timeInForceIsPredefinedValue && (
        <div className='flex flex-wrap gap-x-2'>
          <div className='flex flex-col w-[196px] max-w-full'>
            <Label>{t('options.specifiedByUser.date.label')}</Label>
            <DateInput minDate={new Date()} fullWidth className='max-w-full' placeholder='DD.MM.YYYY' value={date} onChange={handleDateInputChange} onBlur={handleBlur} />
          </div>

          <TimeInput
            className='w-[153px] max-w-full'
            label={<LabelWithInfoTooltip tooltipContent={t('options.specifiedByUser.description')}>{t('options.specifiedByUser.time.label')}</LabelWithInfoTooltip>}
            value={time}
            onChange={handleTimeInputChange}
            onBlur={handleBlur}
          />
        </div>
      )}
    </div>
  );
};
