import styled from 'styled-components';

import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import tw from 'tailwind-styled-components';

const Container = tw.div`
  bg-gray-800
  w-full
  rounded
  shadow-xl
  pb-px
`;

const StyledWrapper = styled.table`
  min-width: 800px;
  width: 100%;
`;

const Head = tw.thead`

`;
const Body = tw.tbody``;
const Row = tw.tr``;
const HeadCell = tw.th`
  py-2.5
  px-5
  text-sm
  font-normal
  text-left
  text-grayscale-500/70
  whitespace-nowrap
`;
const HeadCellCenter = tw(HeadCell)`
  text-center
`;
const HeadCellSlim = tw.th`
  py-2
  px-5
  text-sm
  text-grayscale-500/70
  text-left
  font-medium
  whitespace-nowrap
`;
const HeadSlimCellCenter = tw(HeadCellSlim)`
  text-center
`;
const DataCell = tw.td`
  py-2.5
  px-5
`;
const DataCellCenter = tw(DataCell)`
  text-center
`;
const DataCellSlim = tw.td`
  py-2
  px-5
  font-medium
`;

const DataCellSuperSlim = tw.td`
  py-1
  px-5
  font-medium
`;

const DataCellSlimCenter = tw(DataCell)`
  text-center
`;

const DataFlex = tw.div`
  flex
  items-center
  text-xs
  text-gray-100
  text-left
  leading-6
 `;

const Text = tw.p`
  pl-2
  text-sm
  font-medium
  text-gray-100
  text-left
  leading-6
 `;

type Props = PropsWithChildren<{ type: 'buy' | 'sell' | string; className?: string }>;

const ItemWithBackground: FC<Props> = ({ children, type, className }) => (
  <div
    className={clsx(`flex items-center justify-center py-0.5 px-2.5 w-min rounded-2xl bg-grayscale-700 text-center capitalize text-sm ${className}`, {
      'text-success-500': type === 'buy',
      'text-danger-500': type === 'sell',
      'bg-danger-500/20': type === 'cancelled',
      'bg-success-500/20': type === 'filled',
    })}
  >
    {children}
  </div>
);

const Wrapper = ({ children, className = '' }) => (
  <div className={clsx('overflow-x-auto', className)}>
    <StyledWrapper>{children}</StyledWrapper>
  </div>
);

export const Table = {
  Container,
  Wrapper,
  Head,
  Body,
  Row,
  HeadCell,
  HeadCellCenter,
  HeadCellSlim,
  DataCell,
  DataCellCenter,
  DataCellSlim,
  DataFlex,
  DataCellSlimCenter,
  Text,
  HeadSlimCellCenter,
  ItemWithBackground,
  DataCellSuperSlim,
};
