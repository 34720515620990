import { QueryFunction } from 'react-query';

import { getProxyServers } from '@root/modules/accounts/services/get-proxy-servers.service';
import { ProxyServers } from '@root/modules/accounts/types/proxy-servers';

export type GetProxyServersQueryKey = ['proxy-servers'];
export type GetProxyServersData = ProxyServers[];

export const GET_PROXY_SERVERS: QueryFunction<GetProxyServersData, GetProxyServersQueryKey> = async () => {
  const result = await getProxyServers();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
