import { createSlice } from '@reduxjs/toolkit';

import { ICountry } from '@root/modules/countries/types';
import { Remote } from '@root/shared/utils/types';

export type ICountriesState = Remote<ICountry[]>;

export const countriesInitialState: ICountriesState = {
  isLoading: false,
  data: [],
  error: null,
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState: countriesInitialState,
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchRejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
