import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ResetTwoFaForm, ResetTwoRequestInfo } from '@root/shared-files/modules/auth/types/two-fa';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type SendResetConfirmationCodeResponse = IHttpResponse<200, { success: boolean; errorCode: string }> | IHttpResponse<400, string>;

export const sendResetConfirmationCode = async (): Promise<SendResetConfirmationCodeResponse> => {
  try {
    const response = await fetcher.post(`/auth/2fa/reset-requests/send-confirmation-code`);

    if (response.data?.success) {
      return {
        status: 200,
        payload: response.data,
      };
    }

    return {
      status: 400,
      payload: response.data?.errorCode || '',
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
