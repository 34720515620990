import { useCallback, useEffect } from 'react';

import { useChart } from '@root/modules/charting-library/contexts/chart-instance.context';
import { useDrawingsContext } from '@root/modules/charting-library/contexts/drawings-context';
import { Drawing, DrawingType } from '@root/modules/charting-library/types/draw-types';

export const useDrawings = () => {
  const { chartInstance } = useChart();
  const { drawings, addDrawing, updateDrawing, removeDrawing, clearDrawings } = useDrawingsContext();

  const applyDrawingsToChart = useCallback(() => {
    if (!chartInstance) return;

    // const chart = chartInstance.activeChart();
    // // chart.removeAllShapes();
    //
    // drawings.forEach((drawing) => {
    //   // console.log('drawing', drawing);
    //
    //   try {
    //     switch (
    //       drawing.type
    //       // case DrawingType.OrderLine: {
    //       //   drawing.points.forEach((line) => {
    //       //     widget.showOrderLine(chartInstance, line);
    //       //   });
    //       //
    //       //   break;
    //       // }
    //
    //       // case DrawingType.HorizontalLine: {
    //       //   widget.deleteLineShapes(chartInstance);
    //       //   drawing.points.forEach((point) => widget.createLineShape(chartInstance, point));
    //       //   break;
    //       // }
    //       //
    //       // case DrawingType.VerticalLine: {
    //       //   drawing.points.forEach((point) => {
    //       //     chart.createShape(
    //       //       { time: point.time },
    //       //       {
    //       //         shape: 'vertical_line',
    //       //         overrides: {
    //       //           linecolor: point.color || drawing.options.color,
    //       //           linewidth: point.lineWidth || drawing.options.lineWidth,
    //       //           linestyle: point.lineStyle || drawing.options.lineStyle,
    //       //           showLabel: true,
    //       //           text: point.text || drawing.options.text || '',
    //       //         },
    //       //       },
    //       //     );
    //       //   });
    //       //   break;
    //       // }
    //     ) {
    //     }
    //   } catch (error) {
    //     console.error(`Failed to apply drawing point:`, error);
    //   }
    // });
  }, [chartInstance]);

  useEffect(() => {
    applyDrawingsToChart();
  }, [applyDrawingsToChart]);

  const createDrawing = useCallback(
    (type: DrawingType, id: Drawing['id'], points: Drawing['points']) => {
      addDrawing({ type, points, id });
    },
    [addDrawing],
  );

  const updateDrawingById = useCallback(
    (id: string, updates: Partial<Omit<Drawing, 'id'>>) => {
      updateDrawing(id, updates);
    },
    [updateDrawing],
  );

  const removeDrawingById = useCallback(
    (id: string) => {
      removeDrawing(id);
      applyDrawingsToChart();
    },
    [removeDrawing, applyDrawingsToChart],
  );

  const clearAllDrawings = useCallback(() => {
    clearDrawings();
    if (chartInstance) {
      chartInstance.activeChart().removeAllShapes();
    }
  }, [clearDrawings, chartInstance]);

  return {
    drawings,
    createDrawing,
    updateDrawing: updateDrawingById,
    removeDrawing: removeDrawingById,
    clearDrawings: clearAllDrawings,
    applyDrawingsToChart,
  };
};
