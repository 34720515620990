import { useCallback, useState } from 'react';

import { IOrder } from '@root/modules/orders/types/orders';

export enum ModalType {
  Delete = 'delete',
  Modify = 'modify',
  EditTrade = 'edit-trade',
  Logs = 'logs',
  Share = 'share',
  PartialClose = 'partial-close',
}

export const useOrderActions = <T extends IOrder>() => {
  const [selectedOrder, setSelectedOrder] = useState<T | null>(null);

  const [modalType, setModalType] = useState<ModalType | null>(null);

  const deleteOrder = useCallback((order: T) => {
    setSelectedOrder(order);
    setModalType(ModalType.Delete);
  }, []);

  const modifyOrder = useCallback((order: T) => {
    setSelectedOrder(order);
    setModalType(ModalType.Modify);
  }, []);

  const modifyTrade = useCallback((order: T) => {
    setSelectedOrder(order);
    setModalType(ModalType.EditTrade);
  }, []);

  const modifyPartialClose = useCallback((order: T) => {
    setSelectedOrder(order);
    setModalType(ModalType.PartialClose);
  }, []);

  const shareOrder = useCallback((order: T) => {
    setSelectedOrder(order);
    setModalType(ModalType.Share);
  }, []);

  const showOrderLogs = useCallback((order: T) => {
    setSelectedOrder(order);
    setModalType(ModalType.Logs);
  }, []);

  const closeModal = useCallback(() => {
    setModalType(null);
    setSelectedOrder(null);
  }, []);

  const state = { selectedOrder, modalType };
  const handlers = { deleteOrder, modifyOrder, modifyPartialClose, showOrderLogs, modifyTrade, closeModal, shareOrder };

  return [state, handlers] as [typeof state, typeof handlers];
};
