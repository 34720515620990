import { Formik, FormikProps } from 'formik';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SentryFeedbackDto } from '@root/modules/user-settings/dtos/sentry-feedback.dto';
import { useLeaveFeedback } from '@root/modules/user-settings/hooks/use-leave-feedback';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { TextareaField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { Modal } from '@root/shared/ui/modal';

const FormikComponent: FC<FormikProps<SentryFeedbackDto>> = ({ isSubmitting, handleSubmit, handleReset }) => {
  const feedbackOpened = useSelector(userSettingsSelector.feedbackOpened);
  const { t } = useTranslation('user-settings');
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    handleReset();
    dispatch(userSettingsSlice.actions.setFeedbackModal(false));
  }, [dispatch, handleReset]);

  return (
    <Modal isOpen={feedbackOpened} onCancel={handleClose} title={t('feedback.title') as string} footer={null}>
      <form onSubmit={handleSubmit}>
        <div className='grid grid-cols-12 gap-x-4'>
          <div className='col-span-12'>
            <TextareaField name='comments' label={t('feedback.fields.feedback.label')} placeholder={t('feedback.fields.feedback.placeholder') as string} rows={4} />
          </div>
          <div className='col-span-12 flex items-center justify-between mt-5'>
            <Button onClick={handleClose} outlined>
              {t('feedback.cancel')}
            </Button>
            <Button loading={isSubmitting} type='submit'>
              {t('feedback.sendFeedback')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export const FeedbackModal: FC = () => {
  const { initialValues, onSubmit, validationSchema } = useLeaveFeedback();

  return <Formik<SentryFeedbackDto> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} component={FormikComponent} />;
};
