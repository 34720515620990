import * as yup from 'yup';

import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { queryClient } from '@root/infra/query';
import { UpdateAccountDto } from '@root/modules/accounts/dtos/update-account.dto';
import { UpdateAccountDtoMapper } from '@root/modules/accounts/mappers/update-account-dto.mapper';
import { GET_ACCOUNT } from '@root/modules/accounts/queries/get-account.query';
import { updateAccountService } from '@root/modules/accounts/services/update-account.service';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { DrawdownForm } from '@root/modules/accounts/types/drawdown';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';

export type UpdateAccountForm = Omit<UpdateAccountDto, 'maxDrawDownLimit'> & {
  maxDrawDownLimit: DrawdownForm;
};

export const useUpdateAccount = () => {
  const { t } = useTranslation('forex-accounts');
  const { t: yupT } = useTranslation('yup');

  const dispatch = useDispatch();
  const accountId = useSelector(accountsSelector.updateAccountId);
  const userId = useSelector(authSelector.userId);

  const navigate = useNavigate();

  const {
    data,
    isLoading: isInitializing,
    refetch,
    error,
  } = useQuery({
    queryKey: ['fx', userId, 'accounts', accountId || undefined],
    queryFn: GET_ACCOUNT,
    enabled: !!accountId && !!userId,
  });

  useEffect(() => {
    if (error === null) {
      return;
    }

    dispatch(accountsSlice.actions.accountEditClosed());
  }, [error, dispatch]);

  const initialValues = useMemo<UpdateAccountForm | undefined>(() => {
    if (!!data && !!userId) {
      const values = UpdateAccountDtoMapper.toDomain(data, userId);
      return {
        ...values,
        maxDrawDownLimit: values.maxDrawDownLimit
          ? {
              use: !!values.maxDrawDownLimit,
              percent: values.maxDrawDownLimit?.percent.toString() || '',
              basedOn: values.maxDrawDownLimit?.basedOn.toString() || '0',
              period: values.maxDrawDownLimit?.period.toString() || '0',
            }
          : {
              use: true,
              percent: '20',
              basedOn: '0',
              period: '2',
            },
      };
    }
  }, [data, userId]);

  const onSubmit = useCallback(
    async (values: UpdateAccountForm, helpers: FormikHelpers<UpdateAccountForm>) => {
      const data = {
        ...values,
        maxDrawDownLimit: values.maxDrawDownLimit?.use
          ? {
              percent: Number(values.maxDrawDownLimit.percent),
              basedOn: Number(values.maxDrawDownLimit.basedOn),
              period: Number(values.maxDrawDownLimit.period),
            }
          : null,
      };

      if (data.password) {
        data.password = data.password.trim();
      } else {
        data.username = undefined;
        data.password = undefined;
        data.brokerServerId = undefined;
      }

      const result = await updateAccountService(data);
      queryClient.invalidateQueries({ queryKey: ['accounts'] });
      refetch();

      if (result.status === 200) {
        await navigate(`/accounts`);
        dispatch(accountsSlice.actions.accountEditClosed());
      } else {
        notify({
          type: 'danger',
          title: result.payload,
        });

        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
      }
    },
    [refetch, navigate, dispatch],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        userId: yup.string().required(yupT('mixed.requiredNoPath')),
        accountId: yup.string().required(yupT('mixed.requiredNoPath')),
        username: yup
          .number()
          .required(yupT('mixed.requiredNoPath'))
          .typeError(t('number.type', { ns: 'yup' }))
          .label(t('fields.userName.validationLabel')),
        password: yup.string().label(t('fields.password.label')),
        proxyServerId: yup
          .string()
          .nullable()
          .test('invalid proxy', t('fields.proxyServers.invalidProxy'), (value) => !!value)
          .label(t('fields.proxyServers.label')),
        serviceAccountId: yup.string().nullable().required(yupT('mixed.requiredNoPath')).label(t('fields.serviceAccountId.label')),
        maxDrawDownLimit: yup.object().shape({
          use: yup.boolean(),
          percent: yup
            .number()
            .when('use', {
              is: true,
              then: (schema) =>
                schema
                  .required(yupT('mixed.requiredNoPath'))
                  .min(1)
                  .max(100, yupT('number.maxWithoutPath', { field: 100 })),
            })
            .label(t('fields.drawdown.percent.label')),
        }),
      }),
    [t, yupT],
  );
  return { initialValues, isInitializing, onSubmit, validationSchema };
};
