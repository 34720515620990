import { createSlice } from '@reduxjs/toolkit';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { EditCopiedExpertDto } from '@root/modules/experts/dtos/edit-copied-expert.dto';
import { ExpertEventsModalData } from '@root/modules/experts/types/expert';
import { IPreset } from '@root/modules/presets/types/preset';

interface IExpertCopyPresetData {
  preset: IPreset;
  formData: {
    account: CreateExpertDto['account'];
  };
}

export type IExpertsState = {
  editExpertModalData: EditCopiedExpertDto | null;
  expertEventsModalData: ExpertEventsModalData | null;
  runExpertId: string | null;
  runExpertSymbols: string[];
  copyPresetData: IExpertCopyPresetData | null;
  runExpertQueue: Array<{ accountId: string; expertId: string; symbols: string[] }>;
};

const initialState: IExpertsState = {
  runExpertId: null,
  runExpertSymbols: [],
  editExpertModalData: null,
  expertEventsModalData: null,
  copyPresetData: null,
  runExpertQueue: [],
};

export const expertsSlice = createSlice({
  name: 'experts',
  initialState,
  reducers: {
    runExpertOpened: (state, action) => {
      state.runExpertId = action.payload.id;
      state.runExpertSymbols = action.payload.symbols;
    },
    runExpertClosed: (state) => {
      state.runExpertId = null;
      state.runExpertSymbols = [];
    },
    setEditExpertData: (state, action) => {
      state.editExpertModalData = action.payload;
    },
    setExpertEventsData: (state, action) => {
      state.expertEventsModalData = action.payload;
    },
    setCopyPresetData: (state, action) => {
      state.copyPresetData = action.payload;
    },
    runExpertQueueAdd: (state, action) => {
      state.runExpertQueue.push(action.payload);
    },
    runExpertQueueRemove: (state, action) => {
      state.runExpertQueue = state.runExpertQueue.filter((item) => item.expertId !== action.payload.expertId);
    },
  },
});
