import { useQuery } from 'react-query';

import {
  GET_ACTIVE_SUBSCRIPTIONS,
  GetActiveSubscriptionsData,
  GetActiveSubscriptionsQueryKey,
} from '@root/shared-files/modules/subscriptions/queries/get-active-subscriptions.query';

export const useGetActiveSubscriptions = () => {
  return useQuery<GetActiveSubscriptionsData, Error, GetActiveSubscriptionsData, GetActiveSubscriptionsQueryKey>({
    queryKey: ['active-subscriptions'],
    queryFn: GET_ACTIVE_SUBSCRIPTIONS,
  });
};
