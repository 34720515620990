import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useChart } from '@root/modules/charting-library/contexts/chart-instance.context';
import { useShareConfig } from '@root/modules/magic-terminal/contexts/share-config.context';
import { useMagicTerminalInitialValues } from '@root/modules/magic-terminal/hooks/use-magic-terminal-initial-values';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { getObjectDiff } from '@root/shared/utils/objects/get-diff';

export const useCreateSharedTerminalLink = () => {
  const [link, setLink] = useState<string | null>(null);
  const { values } = useFormikContext();
  const { initialValuesData } = useMagicTerminalInitialValues();
  const user = useSelector(authSelector.user);
  const { saveConfig } = useShareConfig();
  const { exportChartSettings } = useChart();

  const generateLink = async () => {
    const orderData = getObjectDiff(values, initialValuesData);
    const chartData = await exportChartSettings();

    const id = await saveConfig({
      orderData: {
        ...(orderData || {}),
        user: user?.fullName,
      },
      chartData: chartData || undefined,
    });
    if (id) {
      setLink(`${window.location.origin}/magic-terminal/?sharedConfigId=${id}`);
    }
  };

  return { link, generateLink };
};
