import clsx from 'clsx';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

type Value = string;

type ChangeHandler = (value: any) => void;

type ContextValue = {
  value?: Value;
  onChange: ChangeHandler;
};

export type SimpleTabsProps = PropsWithChildren<{
  value?: Value;
  onChange: ChangeHandler;
  className?: string;
}>;

export type SimpleTabsStatic = {
  Nav: typeof SimpleTabsNav;
  Tab: typeof Tab;
};

type TabProps = PropsWithChildren<{
  value: string;
}>;

const Context = createContext<ContextValue>({
  value: undefined,
  onChange: () => undefined,
});

const baseClasses = 'px-6 py-2 relative transition whitespace-nowrap';
const borderClasses = 'after:content-[""] after:block after:h-1 after:w-4 after:absolute after:bottom-0 after:rounded-sm after:left-1/2 after:-translate-x-1/2 after:bg-transparent';
const activeClasses = 'after:bg-current! text-primary-400';

const Tab: FC<TabProps> = ({ value, children }) => {
  const { value: currentValue, onChange } = useContext(Context);
  const isActive = value === currentValue;

  return (
    <button className={clsx(baseClasses, borderClasses, isActive && activeClasses)} onClick={() => onChange(value)}>
      {children}
    </button>
  );
};

// TODO: Implement auto scroll to the acive tab item
const SimpleTabsNav: FC<PropsWithChildren> = ({ children }) => (
  <div className='overflow-x-auto'>
    <div className='flex flex-nowrap gap-4'>{children}</div>
  </div>
);

export const SimpleTabs: FC<SimpleTabsProps> & SimpleTabsStatic = ({ className, value, onChange, children }) => {
  return (
    <Context.Provider value={{ value, onChange }}>
      <div className={className}>{children}</div>
    </Context.Provider>
  );
};

SimpleTabs.Nav = SimpleTabsNav;
SimpleTabs.Tab = Tab;
