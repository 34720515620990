import { JSXElementConstructor } from 'react';
import { useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { isMagicOrder, isSfxOrder } from '@root/modules/orders/utils/orders';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { BoldIcon } from '@root/shared/icons/bold-icon';
import { DashedLoadingIcon } from '@root/shared/icons/dashed-loading-icon';
import { ExpertsIcon } from '@root/shared/icons/experts-icon';
import { MagicTerminalIcon } from '@root/shared/icons/magic-terminal-icon';

export enum LimitItemKey {
  connectedBrokerAccounts,
  runningAssists,
  runningSaWithTvAlert,
  openMagicTerminalOrders,
}

export type LimitItemConfig = {
  Icon: JSXElementConstructor<{ width?: number; height?: number; className?: string }>;
  key: LimitItemKey;
  link: PageLinks;
  value: number | undefined;
  limit: number | undefined;
};

export const useLimits = (): LimitItemConfig[] => {
  const { data } = useGetExperts();
  const { data: accounts } = useGetAccounts();
  const maxEACount = useSelector(authSelector.maxEACount);
  const maxExperts = useSelector(authSelector.getMaxExperts);
  const maxMagicActiveSmartTrades = useSelector(authSelector.getMaxMagicActiveSmartTrades);
  const activeBrokerCount = useSelector(authSelector.activeBrokerCount);
  const runningExperts = data?.filter((expert) => expert.isEnabled);
  const runningExpertsWithTVAlert = runningExperts?.filter((expert) => !expert.providerId);
  const activeAccounts = accounts?.filter((account) => account.isSignedIn);

  const { openOrders } = useGetOpenOrders({ enabled: true, selectedBroker: 'all', mtType: undefined });

  const openedMagicOrders = openOrders.filter(isSfxOrder).filter(isMagicOrder);

  return [
    {
      Icon: BoldIcon,
      key: LimitItemKey.connectedBrokerAccounts,
      link: PageLinks.accounts,
      value: activeAccounts?.length,
      limit: activeBrokerCount,
    },
    {
      Icon: ExpertsIcon,
      key: LimitItemKey.runningAssists,
      link: PageLinks.experts,
      value: runningExperts?.length,
      limit: maxEACount,
    },
    {
      Icon: DashedLoadingIcon,
      key: LimitItemKey.runningSaWithTvAlert,
      link: PageLinks.experts,
      value: runningExpertsWithTVAlert?.length,
      limit: maxExperts,
    },
    {
      Icon: MagicTerminalIcon,
      key: LimitItemKey.openMagicTerminalOrders,
      link: PageLinks.magicTerminal,
      value: openedMagicOrders?.length,
      limit: maxMagicActiveSmartTrades,
    },
  ];
};
