import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingIcon } from '@root/shared/icons/loading-icon';

export const ChartLongTimeLoader = () => {
  const { t } = useTranslation('ai-assist');
  const [showMessage, setShowMessage] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShowMessage(true), 7_000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className='absolute top-0 left-0 bottom-0 right-0 bg-gray-800/60 flex items-center justify-center z-1 px-4'>
      <div className={clsx('rounded-lg flex flex-col mb-5 items-center max-w-md', { 'py-3 px-5': showMessage })}>
        <span className={clsx('font-bold text-sm mr-4 py-3 px-5 transition opacity-0', { 'opacity-100': showMessage })}>{t('chartLoadingMessage')}...</span>
        <span className='text-3xl text-primary-400'>
          <LoadingIcon width={40} height={40} />
        </span>
      </div>
    </div>
  );
};
