import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useSyncClosedOrders } from '@root/modules/orders/hooks/use-sync-closed-orders';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { TransferIcon } from '@root/shared/icons/transfer-icon';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';

export const SyncHistory: FC = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'trades' });
  const [{ loading }, { sync }] = useSyncClosedOrders();

  const Icon = loading ? LoadingIcon : () => <TransferIcon />;

  return (
    <div className='flex items-start justify-between gap-1'>
      <InfoTooltip content={<Text size='sm'>{t('sync')}</Text>}>
        <button className='p-2 bg-gray-700 text-sm rounded-sm' onClick={sync}>
          <Icon />
        </button>
      </InfoTooltip>
    </div>
  );
};
