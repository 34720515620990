import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const SmallScreenIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 8.96019C21 8.6632 20.882 8.37838 20.672 8.16839C20.462 7.95839 20.1772 7.84041 19.8802 7.84041H16.1596V4.11977C16.1596 3.82279 16.0416 3.53797 15.8316 3.32797C15.6216 3.11798 15.3368 3 15.0398 3C14.7428 3 14.458 3.11798 14.248 3.32797C14.038 3.53797 13.92 3.82279 13.92 4.11977V8.96019C13.92 9.57979 14.4202 10.08 15.0398 10.08H19.8802C20.1772 10.08 20.462 9.96198 20.672 9.75199C20.882 9.54199 21 9.25717 21 8.96019ZM15.0398 21C15.3368 21 15.6216 20.882 15.8316 20.672C16.0416 20.462 16.1596 20.1772 16.1596 19.8802V16.1581H19.8802C20.1772 16.1581 20.462 16.0401 20.672 15.8301C20.882 15.6201 21 15.3353 21 15.0383C21 14.7413 20.882 14.4565 20.672 14.2465C20.462 14.0365 20.1772 13.9185 19.8802 13.9185H15.0398C14.7428 13.9185 14.458 14.0365 14.248 14.2465C14.038 14.4565 13.92 14.7413 13.92 15.0383V19.8802C13.92 20.4983 14.4202 21 15.0398 21ZM8.96019 21C8.6632 21 8.37838 20.882 8.16839 20.672C7.95839 20.462 7.84041 20.1772 7.84041 19.8802V16.1581H4.11977C3.82279 16.1581 3.53797 16.0401 3.32797 15.8301C3.11798 15.6201 3 15.3353 3 15.0383C3 14.7413 3.11798 14.4565 3.32797 14.2465C3.53797 14.0365 3.82279 13.9185 4.11977 13.9185H8.96019C9.25717 13.9185 9.54199 14.0365 9.75199 14.2465C9.96198 14.4565 10.08 14.7413 10.08 15.0383V19.8802C10.08 20.1772 9.96198 20.462 9.75199 20.672C9.54199 20.882 9.25717 21 8.96019 21ZM3 8.96019C3 8.6632 3.11798 8.37838 3.32797 8.16839C3.53797 7.95839 3.82279 7.84041 4.11977 7.84041H7.84041V4.11977C7.84041 3.82279 7.95839 3.53797 8.16839 3.32797C8.37838 3.11798 8.6632 3 8.96019 3C9.25717 3 9.54199 3.11798 9.75199 3.32797C9.96198 3.53797 10.08 3.82279 10.08 4.11977V8.96019C10.08 9.25717 9.96198 9.54199 9.75199 9.75199C9.54199 9.96198 9.25717 10.08 8.96019 10.08H4.11977C3.82279 10.08 3.53797 9.96198 3.32797 9.75199C3.11798 9.54199 3 9.25717 3 8.96019Z'
        fill='#FCFCFC'
      />
    </svg>
  );
};
