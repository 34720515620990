import clsx from 'clsx';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import infoImg from '@root/assets/images/cases/info.webp';
import { notionLinks } from '@root/infra/constants/links';
import { Image } from '@root/shared/ui/image';
import { Text } from '@root/shared/ui/typography';

type Props = {
  className?: string;
  textKey?: string;
  withLink?: boolean;
};

const Disclaimer: FC<Props> = ({ textKey = 'disclaimer.presets', className, withLink }) => {
  const { t } = useTranslation('common');

  return (
    <div className={clsx('flex items-center flex-col md:flex-row', className)}>
      <div className='flex items-start justify-between gap-2.5'>
        <div className='hidden md:block w-[28px] h-[28px] -mb-1 shrink-0'>
          <Image src={infoImg} width={220} height={220} alt='warning' />
        </div>
        <span className='text-sm'>{t(textKey)}</span>
      </div>
      {withLink && (
        <Text size='sm' className='w-max px-5'>
          <Trans
            i18nKey={'common:learn_more'}
            components={{
              a: <a className='text-primary-400 font-semibold whitespace-nowrap' target='_blank' rel='noreferrer' href={notionLinks.terms}></a>,
            }}
          />
        </Text>
      )}
    </div>
  );
};

export default Disclaimer;
