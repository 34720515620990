import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ShareConfigServiceRequest } from '@root/modules/magic-terminal/services/share-config';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetSharedConfigServiceResponse = IHttpResponse<200, ShareConfigServiceRequest['data']> | IHttpResponse<400, string>;

export const getSharedConfigService = async (id: string): Promise<GetSharedConfigServiceResponse> => {
  try {
    const response = await fetcher.get(`/shared-config/${id}`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });
    return {
      status: 200,
      payload: response.data.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
