import { call } from '@redux-saga/core/effects';

import { SagaReturnType, put, takeLatest } from 'redux-saga/effects';

import { getAccountBalanceService } from '@root/modules/accounts/services/get-account-balance.service';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { authSlice } from '@root/shared-files/modules/auth/store';

const { fetchBalanceFulfilled, fetchBalancePending, fetchBalanceRejected } = accountsSlice.actions;

function* handleFetchBalance() {
  const result: SagaReturnType<typeof getAccountBalanceService> = yield call(getAccountBalanceService);

  if (result.status === 200) {
    yield put(fetchBalanceFulfilled({ list: result.payload, source: 'rest' }));
  } else {
    yield put(fetchBalanceRejected(result.payload));
  }
}

export function* accountsSaga() {
  yield takeLatest([fetchBalancePending, authSlice.actions.fetchProfileFulfilled], handleFetchBalance);
}
