import { Navigate, Route, Routes } from 'react-router-dom';

import { ErrorPage } from '@root/infra/layout/containers/error';
import { ChartProvider } from '@root/modules/charting-library/contexts/chart-instance.context';
import { ChartVisualModeProvider } from '@root/modules/charting-library/contexts/chart-visual-mode-context';
import { ShareConfigProvider } from '@root/modules/magic-terminal/contexts/share-config.context';
import { SignalProvider } from '@root/modules/magic-terminal/contexts/signal.context';
import { MagicTerminalPage } from '@root/modules/magic-terminal/pages';
import { RetrySignalPage } from '@root/modules/magic-terminal/pages/signal';

export const MagicTerminalRouter = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ChartProvider>
            <ShareConfigProvider>
              <ChartVisualModeProvider>
                <MagicTerminalPage />
              </ChartVisualModeProvider>
            </ShareConfigProvider>
          </ChartProvider>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path='/signal/:id'
        element={
          <ChartProvider>
            <ShareConfigProvider>
              <ChartVisualModeProvider>
                <SignalProvider>
                  <RetrySignalPage />
                </SignalProvider>
              </ChartVisualModeProvider>
            </ShareConfigProvider>
          </ChartProvider>
        }
        errorElement={<ErrorPage />}
      />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};
