export const equal = <T>(a: T, b: T) => a === b;
export const lesOrEqual = <T>(a: T, b: T) => a <= b;
export const greaterOrEqual = <T>(a: T, b: T) => a >= b;

const createOrderChecker =
  <T>(invertCondition: (a: T, b: T) => boolean) =>
  (array: T[]) => {
    let prevValue: T = array[0];

    for (let i = 1; i < array.length; i++) {
      const value = array[i];

      if (invertCondition(prevValue, value)) {
        return false;
      }

      prevValue = value;
    }

    return true;
  };

export const isStrictlyAscending = createOrderChecker(lesOrEqual);
export const isStrictlyDescending = createOrderChecker(greaterOrEqual);
export const isNotEqualPrevious = createOrderChecker(equal);
