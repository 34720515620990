export type DrawLineProps = {};

export type DrawItemProps = {
  type: 'order_type_buy' | 'order_type_sell' | 'order_type_neutral';
  price: number;
  timestamp?: string;
  amount?: number;
};

export type OrderLine = {
  type: 'takeProfit' | 'stopLoss' | 'upperStopLoss' | 'lowerStopLoss' | 'upward' | 'downward' | 'partialTakeProfit' | 'trailingStop';
  price?: number;
  percentage?: number;
  isAbleToMove?: boolean;
  trailing?: boolean;
  lowPrice?: number;
  highPrice?: number;
  isReversedColors?: boolean;
  getValidPrice?: (price: number) => number;
  text: string;
  onMove?: (price: number) => void;
};

export enum DrawingType {
  OrderLine = 'orderLine',
}

export interface Drawing {
  type: DrawingType;
  id: string;
  // in case we need more types should be OrderLine | SomeOtherType
  points: Array<OrderLine>;
}
