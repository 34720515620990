import isFunction from 'lodash/isFunction';

import clsx from 'clsx';
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LayoutElements } from '@root/infra/layout/components';
import { FeedbackContent } from '@root/infra/layout/containers/feedback-tooltip';
import { LimitItem } from '@root/infra/layout/containers/limit-item';
import { LimitItemKey, useLimits } from '@root/infra/layout/containers/use-limits';
import { useSm, useXl } from '@root/infra/layout/hooks/media';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { helpLinks } from '@root/shared/constants/social-links';
import { useOutsideClick } from '@root/shared/hooks/use-outside-click';
import { usePrevious } from '@root/shared/hooks/use-previous';
import { ArrowIcon } from '@root/shared/icons/arrow-icon';
import { BoldIcon } from '@root/shared/icons/bold-icon';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { DashboardIcon } from '@root/shared/icons/dashboard-icon';
import { DiscordWhiteIcon } from '@root/shared/icons/discord-icon';
import { ExpertsIcon } from '@root/shared/icons/experts-icon';
import { FeedbackIcon } from '@root/shared/icons/feedback-icon';
import { HelixIcon } from '@root/shared/icons/helix-icon';
import { KnowledgeBaseOutlinedIcon } from '@root/shared/icons/knowledge-base-outlined-icon';
import { LightBulbIcon } from '@root/shared/icons/light-bulb';
import { MagicTerminalIcon } from '@root/shared/icons/magic-terminal-icon';
import { ProvidersIcon } from '@root/shared/icons/providers-icon';
import { ScannersIcon } from '@root/shared/icons/scanners-icon';
import { SignalsIcon } from '@root/shared/icons/signals-icon';
import { TelegramWhiteIcon } from '@root/shared/icons/telegram-white-icon';
import { YoutubeWhiteIcon } from '@root/shared/icons/youtube-white-icon';
import { ActiveLink } from '@root/shared/link/active-link';
import { BaseButton } from '@root/shared/ui/button/base-button';
import { Text } from '@root/shared/ui/typography';
import { invertBoolean } from '@root/shared/utils/boolean/invert-boolean';

const { HelpItem, Help, Wrapper, Item } = LayoutElements.Sidebar;

export const staticMenu = {
  top: [
    {
      title: 'dashboard',
      Icon: DashboardIcon,
      url: '/',
    },

    {
      title: 'accounts',
      Icon: BoldIcon,
      url: '/accounts',
    },
    // {
    //   title: 'templates',
    //   iconId: 'templates',
    //   url: '/templates',
    // },
    {
      title: 'experts',
      Icon: ExpertsIcon,
      url: '/assists',
    },
    {
      title: 'magicTerminal',
      Icon: MagicTerminalIcon,
      url: '/magic-terminal',
      newLabel: 'beta',
    },
    // {
    //   title: 'remap',
    //   iconId: 'transfer',
    //   url: '/symbol-remap',
    // },
  ],
  center: [
    {
      title: 'providers',
      Icon: ProvidersIcon,
      url: '/marketplace',
    },
    {
      title: 'presets',
      Icon: SignalsIcon,
      url: '/presets',
    },
  ],
  scanners: {
    info: {
      title: 'scanners',
      Icon: ScannersIcon,
    },
    items: [
      {
        title: 'heliX',
        Icon: HelixIcon,
        url: '/scanners/helix',
        // url: 'http://hpa.sagemaster.io/'
      },
    ],
  },
  help: [
    // {
    //   titleTranslationId: 'help.report',
    //   Icon: CursorIcon,
    //   link: helpLinks.submitFeature,
    // },
    // {
    //   titleTranslationId: 'help.roadmap',
    //   Icon: RoadmapIcon,
    //   link: helpLinks.roadmap,
    // },
    {
      titleTranslationId: 'help.knowledgeBase',
      Icon: KnowledgeBaseOutlinedIcon,
      link: helpLinks.knowbase,
    },
    {
      titleTranslationId: 'help.telegram',
      Icon: TelegramWhiteIcon,
      link: helpLinks.telegram,
    },
    {
      titleTranslationId: 'help.youtube',
      Icon: YoutubeWhiteIcon,
      link: helpLinks.youtube,
    },
    {
      titleTranslationId: 'help.discord',
      Icon: DiscordWhiteIcon,
      link: helpLinks.discord,
    },
    // {
    //   titleTranslationId: 'help.chat',
    //   Icon: ChatIcon,
    //   link: helpLinks.chat,
    // },
  ],
};

enum GroupKey {
  marketplace,
  scanners,
  help,
}

const translationIdByLimitItemKeyMap = {
  [LimitItemKey.connectedBrokerAccounts]: 'limits.connectedBrokerAccounts',
  [LimitItemKey.runningAssists]: 'limits.runningAssists',
  [LimitItemKey.runningSaWithTvAlert]: 'limits.runningSaWithTvAlert',
  [LimitItemKey.openMagicTerminalOrders]: 'limits.openMagicTerminalOrders',
};

const translationIdMobileOverrideByLimitItemKeyMap = {
  [LimitItemKey.runningSaWithTvAlert]: 'limits.runningSaWithTvAlertShort',
  [LimitItemKey.openMagicTerminalOrders]: 'limits.openMagicTerminalOrdersShort',
};

export const Sidebar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common', { keyPrefix: 'sideBar' });
  const menuOpened = useSelector(userSettingsSelector.menuOpened);

  const [{ [GroupKey.marketplace]: isMarketplaceOpen, [GroupKey.scanners]: isScannersOpen, [GroupKey.help]: isHelpOpen }, setGroupOpenedByKeyMap] = useState<
    Record<GroupKey, boolean>
  >({
    [GroupKey.marketplace]: true,
    [GroupKey.scanners]: true,
    [GroupKey.help]: false,
  });

  const setGroupOpenStateByKey = (key: GroupKey, open: boolean | ((state: boolean) => boolean)) => {
    setGroupOpenedByKeyMap((prev) => ({ ...prev, [key]: isFunction(open) ? open(prev[key]) : open }));
  };

  const sideBarRef = useRef<HTMLDivElement>(null);
  const helpContainerRef = useRef<HTMLButtonElement>(null);

  const { isGhostLogin } = useGhostLogin();
  const sm = useSm();
  const xl = useXl();
  const previousXl = usePrevious(xl);

  const limitItems = useLimits();

  const handleMenuClick = useCallback(() => {
    dispatch(userSettingsSlice.actions.setMenuOpened(!menuOpened));
  }, [dispatch, menuOpened]);

  const createGroupToggleButtonClickHandler =
    (key: GroupKey): MouseEventHandler<HTMLElement> =>
    () => {
      setGroupOpenStateByKey(key, invertBoolean);
    };

  const getTranslationIdBylimitItemKey = (key: LimitItemKey): string => {
    if (!sm && Object.hasOwn(translationIdMobileOverrideByLimitItemKeyMap, key)) {
      return translationIdMobileOverrideByLimitItemKeyMap[key];
    }

    return translationIdByLimitItemKeyMap[key];
  };

  useEffect(() => {
    if (previousXl === xl) {
      return;
    }

    if (xl) {
      if (isHelpOpen) {
        setGroupOpenStateByKey(GroupKey.help, false);
      }
    } else {
      if (!isHelpOpen) {
        setGroupOpenStateByKey(GroupKey.help, true);
      }
    }
  }, [previousXl, xl, isHelpOpen]);

  useOutsideClick(helpContainerRef, () => {
    if (!xl) {
      return;
    }

    setGroupOpenStateByKey(GroupKey.help, false);
  });

  useOutsideClick(sideBarRef, () => {
    if (xl) {
      return;
    }

    dispatch(userSettingsSlice.actions.setMenuOpened(false));
  });

  return (
    <Wrapper>
      <div className='pt-5 xl:pt-0' ref={sideBarRef}>
        {!xl && (
          <div className='flex flex-col px-5'>
            <div className='flex flex-col p-4 gap-y-2 mb-2 bg-gray-900 rounded-sm'>
              {limitItems.map(({ key, ...props }) => (
                <LimitItem key={key} title={t(getTranslationIdBylimitItemKey(key))} {...props} />
              ))}
            </div>
          </div>
        )}

        <div className='-my-1 xl:my-0'>
          {staticMenu.top.map((item, index) => (
            <ActiveLink key={index} exact={index === 0} href={item.url} className='block w-auto my-1 xl:my-0'>
              <Item
                title={xl ? t(item.title) : <span className='text-sm'>{t(item.title)}</span>}
                icon={<item.Icon width={20} height={20} />}
                className={clsx({ 'px-5 xl:px-4': menuOpened, 'items-center': !menuOpened })}
                menuOpened={menuOpened}
                newLabel={item.newLabel ? t(item.newLabel) : undefined}
              />
            </ActiveLink>
          ))}
        </div>

        <div className='flex flex-col border-t-2 border-black py-0.5'>
          {menuOpened && (
            <button className='flex justify-between items-center px-5 py-2.5 xl:py-4 cursor-pointer' onClick={createGroupToggleButtonClickHandler(GroupKey.marketplace)}>
              <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                {t('marketplace')}
              </Text>
              <ChevronIcon className={clsx('text-gray-400', { 'rotate-180': !isMarketplaceOpen })} />
            </button>
          )}
          {((menuOpened && isMarketplaceOpen) || !menuOpened) && (
            <div className='-my-1 xl:my-0'>
              {staticMenu.center.map((item, index) => (
                <ActiveLink key={index} href={item.url} className='block my-1 xl:my-0'>
                  <Item
                    title={xl ? t(item.title) : <span className='text-sm'>{t(item.title)}</span>}
                    icon={<item.Icon width={20} height={20} />}
                    className={clsx({ 'px-5 xl:px-4': menuOpened, 'items-center': !menuOpened })}
                    menuOpened={menuOpened}
                  />
                </ActiveLink>
              ))}
            </div>
          )}
        </div>

        <div className='flex flex-col border-t-2 border-b-2 border-black py-0.5'>
          {menuOpened && (
            <button className='flex justify-between items-center px-5 py-2.5 xl:py-4 cursor-pointer' onClick={createGroupToggleButtonClickHandler(GroupKey.scanners)}>
              <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                {t(staticMenu.scanners.info.title)}
              </Text>
              <ChevronIcon className={clsx('text-gray-400', { 'rotate-180': !isScannersOpen })} />
            </button>
          )}
          {((menuOpened && isScannersOpen) || !menuOpened) && (
            <div className='-my-1 xl:my-0'>
              {staticMenu.scanners.items.map((item, index) => (
                <ActiveLink key={index} href={item.url} className='block my-1 xl:my-0'>
                  <Item
                    title={xl ? t(item.title) : <span className='text-sm'>{t(item.title)}</span>}
                    icon={<item.Icon width={20} height={20} />}
                    className={clsx({ 'px-5 xl:px-4': menuOpened, 'items-center': !menuOpened })}
                    menuOpened={menuOpened}
                    disabled={isGhostLogin}
                  />
                </ActiveLink>
              ))}
            </div>
          )}
        </div>

        <div className='w-full'>
          <button data-featurebase-feedback-portal className='w-full bg-transparent border-none outline-hidden'>
            <Item
              title={t('community')}
              icon={<LightBulbIcon />}
              className={clsx({ 'pl-4': menuOpened, 'items-center': !menuOpened })}
              menuOpened={menuOpened}
              newLabel={t('new')}
            />
          </button>
        </div>

        {xl ? (
          <div className='justify-center px-2 py-2'>
            <Help
              onClick={createGroupToggleButtonClickHandler(GroupKey.help)}
              className={clsx('flex items-center w-full py-0!', {
                'flex-row-reverse justify-center gap-x-4': menuOpened,
                'flex-col': !menuOpened,
              })}
              ref={helpContainerRef}
            >
              <HelpItem className='py-0'>
                <div className='relative'>
                  <div className='flex justify-center items-center gap-x-2 hover:text-primary-400 cursor-pointer'>
                    {menuOpened ? <Text size='sm'>{t('help.menuText')}</Text> : null}
                    <FeedbackIcon width={20} height={20} />
                  </div>
                  {isHelpOpen && <FeedbackContent />}
                </div>
              </HelpItem>
            </Help>
          </div>
        ) : (
          <div className='flex flex-col'>
            <button className='flex justify-between items-center gap-x-4 px-5 py-2.5 xl:py-4 cursor-pointer' onClick={createGroupToggleButtonClickHandler(GroupKey.help)}>
              <Text size='sm' className='uppercase text-grayscale-500 font-bold'>
                {t('help.shortTitle')}
              </Text>
              <ChevronIcon className={clsx('text-gray-400', { 'rotate-180': !isHelpOpen })} />
            </button>

            <div className='-my-1 xl:my-0'>
              {isHelpOpen &&
                staticMenu.help.map(({ titleTranslationId, Icon, link }, index) => (
                  <ActiveLink key={index} href={link} className='block my-1 xl:my-0'>
                    <Item
                      title={xl ? t(titleTranslationId) : <span className='text-sm'>{t(titleTranslationId)}</span>}
                      icon={<Icon width={20} height={20} />}
                      className={clsx({ 'px-5 xl:px-4': menuOpened, 'items-center': !menuOpened })}
                      menuOpened={menuOpened}
                      disabled={isGhostLogin}
                    />
                  </ActiveLink>
                ))}
            </div>
          </div>
        )}
      </div>
      {xl && (
        <div
          className={clsx('py-4 border-t-2 border-black flex flex-col gap-2 fixed bottom-0 left-0 rtl:right-0 mt-[100%] z-100 bg-gray-800', menuOpened ? 'w-[200px]' : 'w-[64px]', {
            'left-[3px] bottom-[4px]': isGhostLogin,
          })}
        >
          <BaseButton className={clsx('group items-center justify-center gap-x-3', menuOpened && 'justify-start! ltr:pl-5 rtl:pr-5')} onClick={handleMenuClick}>
            <div className='w-8! h-8! px-0! m-0 flex justify-center items-center rounded-sm text-gray-100 group-hover:text-primary-400 relative before:rounded-sm before:absolute before:-inset-[2px] before:-z-1 before:bg-linear-to-r before:from-mint-200 before:to-mint-100 before:transition-opacity group-hover:before:opacity-0 border-2 border-transparent transition-[border-color] group-hover:border-primary-300'>
              <ArrowIcon
                className={clsx('transform transition duration-200', {
                  '-rotate-90 rtl:rotate-90': menuOpened,
                  'rotate-90 rtl:-rotate-90': !menuOpened,
                })}
                width={24}
                height={16}
              />
            </div>
            {menuOpened && <Text className='text-[14px]!'>{t('hideMenu')}</Text>}
          </BaseButton>
        </div>
      )}
    </Wrapper>
  );
};
