import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import emailSentImage from '@root/assets/images/email-sent.webp';
import { sendVerificationEmailService } from '@root/shared-files/modules/auth/services';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { Text, Title } from '@root/shared/ui/typography';

export const VerificationReminder = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const isVerified = useSelector(authSelector.isEmailVerified);
  const { t } = useTranslation('verification-reminder');

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const result = await sendVerificationEmailService();

    if (result.status === 200) {
      setIsSubmitted(true);
    }
    setIsSubmitting(false);
  }, []);

  useEffect(() => {
    if (!isVerified) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [isVerified]);

  if (isVerified) {
    return null;
  }

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 bg-gray-800/95 flex items-center z-40'>
      <div className='p-4 lg:p-36'>
        <Image quality={100} height={97} width={94} src={emailSentImage} wrapperClassName='mb-6' alt='E-Email sent illustration' />
        <Title className='mb-4' level={5} bold>
          {t('title')}
        </Title>
        <Text className='mb-8 text-lg lg:w-8/12'>{t('description')}</Text>
        <Button onClick={handleSubmit} loading={isSubmitting} disabled={isSubmitted} variant='info' outlined>
          {t(!isSubmitted ? 'submit' : 'sent')}
        </Button>
      </div>
    </div>
  );
};
