import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { Drawing } from '@root/modules/charting-library/types/draw-types';

interface DrawingsContextType {
  drawings: Drawing[];
  addDrawing: (drawing: Drawing) => void;
  updateDrawing: (id: string, updates: Partial<Omit<Drawing, 'id'>>) => void;
  removeDrawing: (id: string) => void;
  clearDrawings: () => void;
}

const DrawingsContext = createContext<DrawingsContextType | undefined>(undefined);

interface DrawingsProviderProps {
  children: ReactNode;
}

export const DrawingsProvider: React.FC<DrawingsProviderProps> = ({ children }) => {
  const [drawings, setDrawings] = useState<Drawing[]>([]);

  const addDrawing = useCallback((drawing: Drawing) => {
    setDrawings((prevDrawings) => {
      const existingDrawingIndex = prevDrawings.findIndex((d) => d.id === drawing.id);
      if (existingDrawingIndex >= 0) {
        return prevDrawings.map((d, i) => (i === existingDrawingIndex ? drawing : d));
      }
      return [...prevDrawings, drawing];
    });
  }, []);

  const updateDrawing = useCallback((id: string, updates: Partial<Omit<Drawing, 'id'>>) => {
    setDrawings((prevDrawings) => prevDrawings.map((drawing) => (drawing.id === id ? { ...drawing, ...updates } : drawing)));
  }, []);

  const removeDrawing = useCallback((id: string) => {
    setDrawings((prevDrawings) => prevDrawings.filter((drawing) => drawing.id !== id));
  }, []);

  const clearDrawings = useCallback(() => {
    setDrawings([]);
  }, []);

  const value = {
    drawings,
    addDrawing,
    updateDrawing,
    removeDrawing,
    clearDrawings,
  };

  return <DrawingsContext.Provider value={value}>{children}</DrawingsContext.Provider>;
};

export const useDrawingsContext = (): DrawingsContextType => {
  const context = useContext(DrawingsContext);
  if (context === undefined) {
    throw new Error('useDrawingsContext must be used within a DrawingsProvider');
  }
  return context;
};
