import { PropsWithChildren, ReactNode, forwardRef } from 'react';

export const DensebleDoubleFieldRowLayout = forwardRef<HTMLDivElement, PropsWithChildren<{ densed: boolean; firstSlot: ReactNode; secondSlot: ReactNode }>>(
  function DensebleDoubleFieldRowLayout({ densed, firstSlot, secondSlot, children }, ref) {
    if (densed) {
      return (
        <div ref={ref} className={'flex flex-col gap-y-2'}>
          {firstSlot}

          <div className={'flex gap-x-2'}>
            {secondSlot}

            {children}
          </div>
        </div>
      );
    }

    return (
      <div ref={ref} className='flex gap-x-3'>
        <div className={'grid grid-cols-2 flex-auto gap-x-3'}>
          {firstSlot}

          {secondSlot}
        </div>

        {children}
      </div>
    );
  },
);
