import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const ShareDotsIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Group'>
        <g id='Group_2'>
          <path
            id='Path'
            d='M5.70199 6.74269C6.39634 7.43704 6.39634 8.56281 5.70199 9.25716C5.00763 9.95152 3.88187 9.95152 3.18751 9.25716C2.49316 8.56281 2.49316 7.43704 3.18751 6.74269C3.88187 6.04834 5.00763 6.04834 5.70199 6.74269'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Path_2'
            d='M12.8126 3.18739C13.5069 3.88174 13.5069 5.00751 12.8126 5.70186C12.1182 6.39622 10.9925 6.39622 10.2981 5.70186C9.60376 5.00751 9.60376 3.88174 10.2981 3.18739C10.9925 2.49304 12.1182 2.49304 12.8126 3.18739'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Path_3'
            d='M12.8126 10.2981C13.5069 10.9925 13.5069 12.1182 12.8126 12.8126C12.1182 13.5069 10.9925 13.5069 10.2981 12.8126C9.60376 12.1182 9.60376 10.9925 10.2981 10.2981C10.9925 9.60376 12.1182 9.60376 12.8126 10.2981'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path id='Path_4' d='M6.02661 7.20661L9.97328 5.23328' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
          <path id='Path_5' d='M6.02661 8.79333L9.97328 10.7667' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        </g>
      </g>
    </svg>
  );
};
