import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { SfxOrder } from '@root/modules/orders/types/orders';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ModifyPartialCloseServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export type SfxPartialCloseModifyingDto = Pick<SfxOrder, 'ticket' | 'symbol'> & {
  lots: number;
  orderIsMagic: boolean;
};

export const modifyPartialCloseService = async (
  { accountId, expertId }: Pick<SfxOrder, 'expertId' | 'accountId'>,
  { lots, ticket, symbol, orderIsMagic }: SfxPartialCloseModifyingDto,
): Promise<ModifyPartialCloseServiceResponse> => {
  try {
    if (orderIsMagic) {
      await fetcher.delete(`/accounts/${accountId}/magic`, {
        data: {
          ticket,
          lots,
        },
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
      });
    } else {
      await fetcher.delete(`/experts/${expertId}/orders/instant`, {
        data: {
          ticket,
          symbol,
          lots,
        },
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
      });
    }
    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
