import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';

import { Button } from '@root/shared/ui/button';

export const ConfimationActionsModalLayout: FC<PropsWithChildren<{ onCancel: () => void; cancelButtonTitle: ReactNode; confirmButtonTitle: ReactNode; onConfirm: () => void }>> = ({
  onCancel,
  cancelButtonTitle,
  onConfirm,
  confirmButtonTitle,
  children,
}) => {
  const handleCancelButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    onCancel();
  };

  const handleConfirmButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    onConfirm();
  };

  return (
    <div className='flex flex-col items-center gap-y-5'>
      {children}
      <div className='w-full flex justify-between items-center'>
        <Button type='submit' outlined onClick={handleCancelButtonClick}>
          {cancelButtonTitle}
        </Button>
        <Button type='button' onClick={handleConfirmButtonClick}>
          {confirmButtonTitle}
        </Button>
      </div>
    </div>
  );
};
