import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSignal } from '@root/modules/magic-terminal/contexts/signal.context';
import { gerOrderType } from '@root/modules/orders/utils/orders';
import { Button } from '@root/shared/ui/button';
import { Text } from '@root/shared/ui/typography';
import { invertBoolean } from '@root/shared/utils/boolean/invert-boolean';

export const FailedOrderReason = () => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'magicTerminal.failedOrderReason' });
  const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
  const { signalData } = useSignal();

  if (!signalData) {
    return null;
  }

  const {
    signal: { symbol, price, type, stoploss, takeprofit },
    reason,
  } = signalData;

  const details = [
    { key: t('type'), value: gerOrderType(type) },
    { key: t('symbol'), value: symbol.toUpperCase() },
    { key: t('entryPrice'), value: price },
  ];

  if (takeprofit) {
    details.push({ key: t('takeProfit'), value: takeprofit });
  }

  if (stoploss) {
    details.push({ key: t('stopLoss'), value: stoploss });
  }

  return (
    <div className='flex flex-col gap-1 items-start mb-1'>
      <Text size='sm' className='font-bold'>
        {t('title')}
      </Text>
      <Text size='sm'>{reason.message} </Text>

      {isDetailsVisible && (
        <>
          <Text size='sm' className='font-bold'>
            {t('detailedTitle')}
            {' - '}
            {details.filter(Boolean).map(({ key, value }, index) => (
              <span key={key} className='font-normal'>
                {key}: {value}
                {index !== details.length - 1 ? ', ' : ''}
              </span>
            ))}
          </Text>
          <Text size='sm'>{t('adjustText')}</Text>
        </>
      )}

      <Button link className='font-semibold! p-0!' onClick={() => setIsDetailsVisible(invertBoolean)}>
        {t(isDetailsVisible ? 'hideDetails' : 'seeDetails')}
      </Button>
    </div>
  );
};
