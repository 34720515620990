import { FormikValues, useFormikContext } from 'formik';
import { useMemo } from 'react';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import AdvancedChart from '@root/modules/charting-library/components/advanced-chart';

const MIN_WIDGET_HEIGHT = 542.09;

const indicesMapper = {
  NAS100: 'NAS100USD',
  US30: 'US30USD',
  DE30: 'DE30EUR',
  SPX500: 'SPX500USD',
};

const TradingView = <T extends FormikValues>() => {
  const { values } = useFormikContext<T>();
  const { data: accounts } = useGetAccounts();
  const selectedAccount = useMemo(() => accounts?.find((item) => (item.id as string) === values.account), [accounts, values.account]);

  // alias we use to get candles from QDF,
  // and if QDF service doesn't support some broker we use default one
  const widgetId = useMemo(
    () => `${(selectedAccount?.alias || 'oanda').toUpperCase()}:${indicesMapper[values.symbol] || values.symbol || 'EURUSD'}`,
    [selectedAccount?.alias, values.symbol],
  );

  return (
    <div className='gap-y-5'>
      <div className='flex flex-col gap-4 lg:gap-0 lg:flex-row p-2.5 bg-gray-800 rounded-sm'>
        <div className='flex-1'>
          <AdvancedChart symbol={widgetId as string} height={MIN_WIDGET_HEIGHT} id='magic-form-chart' />
        </div>
      </div>
    </div>
  );
};

export default TradingView;
