import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SymbolType } from '@root/modules/accounts/enums';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useGetSymbolsWithType } from '@root/modules/accounts/hooks/use-get-symbols-with-type';
import { ProviderMarketTypeToSymbolTypeMap } from '@root/modules/experts/helpers/provider-market-type-to-symbol-type-map';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { MagicTerminalForm, MagicTerminalOrderMarketType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { useProviderTraders } from '@root/modules/marketplace/hooks/use-provider-traders';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';
import { quotesSlice } from '@root/modules/quotes/store/quotes.slice';

export const useMagicTerminalOrderSettings = () => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext<MagicTerminalForm>();
  const { traders } = useProviderTraders();
  const { data: accounts } = useGetAccounts();
  const quotesData = useSelector(quotesSelector.quotesData);
  const subscribedSymbols = useSelector(quotesSelector.subscribedSymbols);
  const selectedAccount = useMemo(() => accounts?.find((item) => (item.id as string) === values.account), [accounts, values.account]);
  const { data } = useGetSymbolsWithType();

  const mappedSymbol = selectedAccount?.symbolsMapping?.[values.symbol];

  const marketPrice = useMemo(() => {
    if (selectedAccount && quotesData) {
      const quoteByAccount = quotesData[selectedAccount?.companyId] || {};
      const quote = quoteByAccount[mappedSymbol || values.symbol];
      if (values.marketType === MagicTerminalOrderMarketType.SELL) {
        return quote?.bid || '';
      } else if (values.marketType === MagicTerminalOrderMarketType.BUY) {
        return quote?.ask || '';
      } else {
        return '';
      }
    }

    return '-';
  }, [selectedAccount, quotesData, mappedSymbol, values.symbol, values.marketType]);

  const allowedSymbolType = useMemo(() => {
    if (values.strategy.type === 'signal' && values.providerId) {
      const selectedProvider = traders.find(({ id }) => id === values.providerId);
      if (selectedProvider) return ProviderMarketTypeToSymbolTypeMap[selectedProvider.market as SymbolType];
    }
    return null;
  }, [traders, values.strategy.type, values.providerId]);

  useEffect(() => {
    const toSubscribeQuote = selectedAccount && values.symbol ? `quote-${selectedAccount?.companyId}-${mappedSymbol}` : undefined;
    if (toSubscribeQuote && !subscribedSymbols.find((item) => item === toSubscribeQuote)) {
      dispatch(quotesSlice.actions.subscribe([toSubscribeQuote]));
    }
  }, [selectedAccount, values.symbol, dispatch, subscribedSymbols, mappedSymbol]);

  useEffect(() => {
    const type = data?.find((item) => item.name === values.symbol)?.symbolType;

    if (type) {
      if (['INDEX', 'SYNTHETIC'].includes(type) && values.symbol !== 'XAUUSD') {
        setFieldValue('usePips', false);
        setFieldValue('unitType', TemplateUnitType.POINTS);
      }
      if (['CURRENCY'].includes(type) || values.symbol === 'XAUUSD') {
        setFieldValue('usePips', true);
        setFieldValue('unitType', TemplateUnitType.PIPS);
      }
    }
  }, [values.symbol, setFieldValue, data]);

  const state = {
    allowedSymbolType,
    marketPrice,
  };

  const handlers = {};

  return [state, handlers] as [typeof state, typeof handlers];
};
