import { AnyAction, Middleware } from 'redux';

export class ReduxActionListener {
  public listeners = new Map<string, ((action: AnyAction) => void)[]>();

  public subscribe(type: string | string[], cb: (action: AnyAction) => void) {
    if (typeof type === 'string') {
      if (this.listeners.has(type)) {
        this.listeners.get(type)?.push(cb);
      } else {
        this.listeners.set(type, [cb]);
      }
    } else {
      type.forEach((type) => {
        if (this.listeners.has(type)) {
          this.listeners.get(type)?.push(cb);
        } else {
          this.listeners.set(type, [cb]);
        }
      });
    }

    return () => this.unsubscribe(type, cb);
  }

  public publish = (action: AnyAction) => {
    this.listeners.get(action.type)?.forEach((cb) => cb(action));
  };

  public unsubscribe(type: string | string[], cb: (action: AnyAction) => void) {
    if (typeof type === 'string') {
      const listeners = this.listeners.get(type) || [];
      const index = listeners.findIndex((listener) => listener === cb);

      listeners.splice(index, 1);
    } else {
      type.forEach((type) => {
        const listeners = this.listeners.get(type) || [];
        const index = listeners.findIndex((listener) => listener === cb);

        listeners.splice(index, 1);
      });
    }
  }

  public middleware: Middleware = (store) => {
    return (next) => {
      return (action) => {
        next(action);

        this.publish(action as AnyAction);
      };
    };
  };
}
