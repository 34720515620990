import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const RefreshIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3615 5.3677C14.9609 4.42298 13.2972 3.94592 11.6087 4.00488C9.92034 4.06384 8.29394 4.6558 6.96265 5.69592C5.63136 6.73603 4.66352 8.17091 4.19785 9.7949C4.04562 10.3258 3.49184 10.6328 2.96095 10.4805C2.43006 10.3283 2.12309 9.77452 2.27532 9.24363C2.85741 7.21364 4.06721 5.42004 5.73133 4.11989C7.39544 2.81974 9.42844 2.07979 11.5389 2.00609C13.6495 1.93239 15.7291 2.52873 17.4799 3.70963C19.2306 4.89053 20.5626 6.59537 21.2849 8.5798C21.4738 9.09878 21.2062 9.67262 20.6872 9.86152C20.1682 10.0504 19.5944 9.78282 19.4055 9.26384C18.8277 7.67629 17.7621 6.31242 16.3615 5.3677Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.65261 18.7867C8.96588 19.6063 10.4887 20.0273 12.0365 19.9986C13.5843 19.9699 15.0906 19.4928 16.3726 18.6251C17.6546 17.7573 18.6573 16.5363 19.2591 15.11C19.4738 14.6012 20.0603 14.3627 20.5692 14.5774C21.078 14.7921 21.3164 15.3787 21.1017 15.8875C20.3495 17.6704 19.0961 19.1967 17.4936 20.2813C15.8911 21.366 14.0083 21.9624 12.0736 21.9983C10.1389 22.0341 8.23527 21.5079 6.59368 20.4833C4.9521 19.4588 3.64303 17.98 2.82524 16.2262C2.59183 15.7256 2.80839 15.1307 3.30893 14.8973C3.80947 14.6638 4.40445 14.8804 4.63785 15.3809C5.29209 16.784 6.33934 17.967 7.65261 18.7867Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.70357 14.7874C6.62256 14.2411 6.11401 13.8639 5.5677 13.9449L3.00075 14.3255C2.6894 14.3717 2.41795 14.5618 2.26808 14.8386L1.03243 17.1205C0.769457 17.6062 0.949975 18.213 1.43563 18.476C1.92129 18.739 2.52817 18.5585 2.79115 18.0728L3.78872 16.2306L5.86107 15.9232C6.40739 15.8422 6.78458 15.3337 6.70357 14.7874Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.1441 9.87669C17.2251 10.423 17.7336 10.8002 18.28 10.7192L20.8469 10.3385C21.1583 10.2924 21.4297 10.1023 21.5796 9.82551L22.8152 7.54356C23.0782 7.0579 22.8977 6.45101 22.412 6.18804C21.9264 5.92506 21.3195 6.10558 21.0565 6.59124L20.0589 8.43351L17.9866 8.74082C17.4403 8.82183 17.0631 9.33038 17.1441 9.87669Z'
        fill='currentColor'
      />
    </svg>
  );
};
