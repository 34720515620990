import { json2csv } from 'json-2-csv';

import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';

import { ExportClosedOrdersQuery, exportClosedOrders } from '@root/modules/orders/services/export-closed-orders.service';
import { notify } from '@root/shared/utils/notification';

export const useExportToCsv = (queryParams: ExportClosedOrdersQuery) => {
  const [loading, setLoading] = useState<boolean>(false);

  const exportToCsv = useCallback(async () => {
    setLoading(true);
    const response = await exportClosedOrders(queryParams);

    if (response.status === 200) {
      const csv = json2csv(response.payload, {
        keys: [
          { field: 'brokerCompanyName', title: 'Broker Company Name' },
          { field: 'closePrice', title: 'Close Price' },
          { field: 'closeTime', title: 'Close Time' },
          { field: 'comment', title: 'Comment' },
          { field: 'commission', title: 'Commission' },
          { field: 'createdAt', title: 'Created At' },
          { field: 'deletedAt', title: 'Deleted At' },
          { field: 'expirationTime', title: 'Expiration Time' },
          { field: 'lots', title: 'Lots' },
          { field: 'magicNumber', title: 'Magic Number' },
          { field: 'openPrice', title: 'Open Price' },
          { field: 'openTime', title: 'Open Time' },
          { field: 'openedBy', title: 'Opened By' },
          { field: 'profit', title: 'Profit' },
          { field: 'providerId', title: 'Provider ID' },
          { field: 'rateClose', title: 'Rate Close' },
          { field: 'rateMargin', title: 'Rate Margin' },
          { field: 'rateOpen', title: 'Rate Open' },
          { field: 'signalId', title: 'Signal ID' },
          { field: 'status', title: 'Status' },
          { field: 'stopLoss', title: 'Stop Loss' },
          { field: 'swap', title: 'Swap' },
          { field: 'symbol', title: 'Symbol' },
          { field: 'takeProfit', title: 'Take Profit' },
          { field: 'ticket', title: 'Ticket' },
          { field: 'type', title: 'Type' },
          { field: 'updatedAt', title: 'Updated At' },
          { field: 'volume', title: 'Volume' },
        ],
      });
      const blobToSave = new Blob([csv], { type: 'text/plain' });
      const name = `sfx-closed-orders-export-${Date.now()}.csv`;
      if (window.saveAs) {
        window.saveAs(blobToSave, name);
      } else {
        saveAs(blobToSave, name);
      }
    } else {
      notify({
        title: response.payload,
        type: 'danger',
      });
    }

    setLoading(false);
  }, [queryParams]);

  const state = {
    loading,
  };

  const handlers = {
    exportToCsv,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
