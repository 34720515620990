import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const ArrowIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} fill='transparent' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M3.33301 6.47827L7.99967 2.00022L12.6663 6.47827' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <line x1='8.35449' y1='3.17798' x2='8.35449' y2='13' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};
