import { FxBreakevenType, FxPartialCloseType, FxPriceToleranceType, FxSessionManagementActionType, FxTpProximityType, FxTradingSession } from '@3lgn/shared/dist/constants/fx';

import { doesOrderTypeSupportTimeInForce } from '@root/modules/experts/components/time-in-force/time-in-force-predefined-values';
import {
  CreateExpertDto,
  CreationExpertPersistetDto,
  PartialTakeProfitEditingDto,
  TimeBasedPartialTakeProfitEditingDto,
  TimeInForcePredefinedValue,
} from '@root/modules/experts/dtos/create-expert.dto';
import { createPartialCloseEditingDto, makeExpertFormValues } from '@root/modules/experts/helpers/make-expert-form-values';
import { StrategyOptionType, shouldUseSignalSchema } from '@root/modules/experts/hooks/use-create-expert-options';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { ExpertOrderDirection, ExpertOrderType, TemplateUnitType } from '@root/modules/experts/types/common';
import { IExpert, PartialClosePersistetDto, PartialTakeProfitPersistetDto, TimeBasedPartialTakeProfitPersistetDto } from '@root/modules/experts/types/expert';
import { MagicTerminalForm, MagicTerminalOrderMarketType, MagicTerminalOrderPendingType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { SfxOrder } from '@root/modules/orders/types/orders';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export abstract class TimeBasedPartialTakeProfitEditingDtoMapper {
  public static toDomain = (dto: TimeBasedPartialTakeProfitPersistetDto): TimeBasedPartialTakeProfitEditingDto => ({
    amount: dto.amount.toString(),
    afterMinutes: dto.afterMinutes.toString(),
  });

  public static toPersistence = (dto: TimeBasedPartialTakeProfitEditingDto): TimeBasedPartialTakeProfitPersistetDto => ({
    amount: parseFloat(dto.amount),
    afterMinutes: parseFloat(dto.afterMinutes),
  });
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export abstract class PartialTakeProfitEditingDtoMapper {
  public static toDomain = (dto: PartialTakeProfitPersistetDto): PartialTakeProfitEditingDto => ({
    amount: dto.amount.toString(),
    percent: dto.percent.toString(),
    timeBasedTakeProfits: dto.timeBasedTakeProfits?.map(TimeBasedPartialTakeProfitEditingDtoMapper.toDomain) ?? [],
  });

  public static toPersistence = (dto: PartialTakeProfitEditingDto): PartialTakeProfitPersistetDto => {
    const result: PartialTakeProfitPersistetDto = {
      amount: parseFloat(dto.amount),
      percent: parseFloat(dto.percent),
    };

    if (dto.timeBasedTakeProfits.length > 0) {
      result.timeBasedTakeProfits = dto.timeBasedTakeProfits.map(TimeBasedPartialTakeProfitEditingDtoMapper.toPersistence);
    }

    return result;
  };
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export abstract class CreateExpertDtoMapper {
  public static toMagicTerminalData(order: SfxOrder): MagicTerminalForm {
    const initialValuesData = makeExpertFormValues();

    const expert = order.expertConfiguration;

    if (expert) {
      return {
        useAdvancedSettings: expert.partialClose.use || expert.trailStop.use || expert.breakEven.use,
        symbol: order.symbol,
        account: expert.accountId,
        price: '',
        // TODO: check if providerId is always present
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        providerId: expert.providerId!,
        expertPresetId: expert.expertPresetId,
        timeInForce: expert.timeInForce as TimeInForcePredefinedValue,
        symbols: [order.symbol],
        marketType: order.type.toLowerCase() as MagicTerminalOrderMarketType,
        pendingType: MagicTerminalOrderPendingType.MARKET,
        name: expert.name,
        description: expert?.description || '',
        magicNumber: order.magicNumber,
        useSlippage: expert.slippage?.toString() !== '0',
        slippage: expert.slippage?.toString(),
        usePips: expert.usePips,
        unitType: expert.usePips ? TemplateUnitType.PIPS : TemplateUnitType.POINTS,
        maxManualActiveTrades: expert.maxManualActiveTrades?.toString() || '1',
        maxSignalActiveTrades: expert.maxSignalActiveTrades?.toString() || '1',
        allowClone: expert.allowClone || initialValuesData.allowClone,
        allowShare: expert.allowShare || initialValuesData.allowShare,
        strategy: initialValuesData.strategy,
        //   {
        //   type: (expert.useSignalTrades && 'signal') || (expert.useManualTrades && 'manual') || '',
        //   usePriceTolerance: expert?.priceTolerance !== 0,
        //   priceTolerance: expert.priceTolerance?.toString(),
        //   priceToleranceType: expert.priceToleranceType === FxPriceToleranceType.PERCENT ? '1' : '0',
        //   timeTolerance: Number(expert.timeTolerance || 0) % 60 === 0 ? (Number(expert.timeTolerance) / 60).toString() : expert.timeTolerance.toString(),
        //   timeToleranceType: Number(expert.timeTolerance || 0) % 60 === 0 ? '1' : '0',
        //   usePostSignalProximity: expert.tpProximity !== 0,
        //   tpProximity: expert.tpProximity?.toString(),
        //   tpProximityType: expert?.tpProximityType === FxTpProximityType.PERCENT ? '1' : '0',
        // },
        manualMoneyManagement: {
          type: expert.manualMoneyManagement.type?.toString(),
          lotsize: expert.manualMoneyManagement.lotsize?.toString(),
          riskPercent: expert.manualMoneyManagement.riskPercent?.toString(),
          basedOn: expert.manualMoneyManagement.basedOn?.toString(),
          k: expert.manualMoneyManagement.k?.toString(),
        },
        signalMoneyManagement: expert.signalMoneyManagement?.type
          ? {
              type: expert.signalMoneyManagement?.type?.toString(),
              lotsize: expert.signalMoneyManagement?.lotsize?.toString(),
              riskPercent: expert.signalMoneyManagement?.riskPercent?.toString(),
              basedOn: expert.signalMoneyManagement?.basedOn?.toString(),
              k: expert.signalMoneyManagement?.k?.toString(),
              xPercent: expert.signalMoneyManagement?.xPercent?.toString(),
            }
          : initialValuesData.signalMoneyManagement,
        maxDrawDownLimit: expert.maxDrawDownLimit
          ? {
              use: !!expert.maxDrawDownLimit.percent,
              percent: expert.maxDrawDownLimit.percent?.toString() || '',
              basedOn: expert.maxDrawDownLimit.basedOn?.toString() || '0',
              period: expert.maxDrawDownLimit.period?.toString() || '0',
            }
          : initialValuesData.maxDrawDownLimit,

        breakEven: {
          use: expert.breakEven.use,
          type: [FxBreakevenType.PIPS, FxBreakevenType.PERCENT].includes(expert.breakEven.type as FxBreakevenType)
            ? expert.breakEven.type === FxBreakevenType.PERCENT
              ? '1'
              : '0'
            : null,
          levels: expert.breakEven.levels.map((item) => ({
            afterX: item.afterX?.toString(),
            setToY: item.setToY?.toString(),
          })),
        },
        trailStop: expert.trailStop
          ? {
              use: expert.trailStop.use,
              afterX: expert.trailStop.afterX?.toString(),
              followY: expert.trailStop.followY?.toString(),
            }
          : initialValuesData.trailStop,
        partialClose: expert.partialClose.use
          ? {
              use: expert.partialClose.use,
              calculationType: expert.partialClose.type === 'classic' ? '0' : '1',
              takeprofits: expert.partialClose.takeprofits.map(PartialTakeProfitEditingDtoMapper.toDomain),
            }
          : initialValuesData.partialClose,
        conditionalClosure: expert.conditionalClosure
          ? {
              use: expert.conditionalClosure?.use,
              amount: expert.conditionalClosure?.amount?.toString(),
            }
          : initialValuesData.conditionalClosure,
        manualSlTp: expert.manualSlTp
          ? {
              type: expert.manualSlTp.type?.toString(),
              fixedSl: expert.manualSlTp.fixedSl?.toString(),
              fixedTp: expert.manualSlTp.fixedTp?.toString(),
              tpRatio: expert.manualSlTp.tpRatio.toString(),
              riskRatio: expert.manualSlTp.riskRatio || 1,
            }
          : // TODO: check if this is correct
            (initialValuesData.manualSlTp.tpRatio as unknown as CreateExpertDto['manualSlTp']),
        signalSlTp: expert.signalSlTp
          ? {
              type: expert.signalSlTp?.type?.toString(),
              fixedSl: expert.signalSlTp?.fixedSl?.toString(),
              fixedTp: expert.signalSlTp?.fixedTp?.toString(),
              tpRatio: expert.signalSlTp?.tpRatio,
              riskRatio: expert.signalSlTp?.riskRatio || 1,
              profitCalculationType: '0',
              slType: '0',
            }
          : initialValuesData.signalSlTp,
        sessionManagement: expert.sessionManagement
          ? {
              use: !!expert.sessionManagement.use,
              type: expert.sessionManagement.type,
              sessions:
                Object.values(FxTradingSession).every((enumValue) => expert.sessionManagement?.sessions.includes(enumValue)) || !expert.sessionManagement.sessions.length
                  ? ['all']
                  : expert.sessionManagement?.sessions,
              threshold: expert.sessionManagement.threshold?.toString(),
            }
          : initialValuesData.sessionManagement,
        orderType: expert.orderType || initialValuesData.orderType,
        orderDirection: expert.orderDirection || initialValuesData.orderDirection,
      };
    }
    return {
      ...initialValuesData,
      name: 'magic',
      strategy: { ...initialValuesData.strategy, type: 'manual' },
      partialClose: { ...initialValuesData.partialClose, use: false, calculationType: '0' },
      manualSlTp: { ...initialValuesData.manualSlTp, tpRatio: '1', type: '0' },
      price: '',
      symbol: 'EURUSD',
      marketType: MagicTerminalOrderMarketType.BUY,
      pendingType: MagicTerminalOrderPendingType.MARKET,
    };
  }

  public static toDomain(expert: Omit<IExpert, 'isEnabled'>, megicOrderExpertConfigMapping?: boolean): CreateExpertDto {
    const signalSlTp: Partial<CreateExpertDto['signalSlTp']> = {};

    if ([4, 5].includes(expert.signalSlTp?.type)) {
      signalSlTp.type = '4';
      if (expert.signalSlTp.type === 5) {
        signalSlTp.slType = '1';
      } else {
        signalSlTp.slType = '0';
      }

      if (expert.signalSlTp.tpRatio) {
        signalSlTp.profitCalculationType = '0';
        signalSlTp.fixedTp = '0';
      } else {
        signalSlTp.profitCalculationType = '1';
        signalSlTp.fixedTp = expert.signalSlTp.fixedTp?.toString() || '0';
      }
    }

    return {
      id: expert.id,
      name: expert.name,
      description: expert?.description || '',
      account: expert.accountId || '',
      providerId: expert.providerId || '',
      expertPresetId: expert.expertPresetId,
      symbols: expert.symbols,
      magicNumber: expert.magicNumber,
      useSlippage: expert.slippage?.toString() !== '0',
      slippage: expert.slippage?.toString(),
      usePips: expert.usePips,
      unitType: expert.usePips ? TemplateUnitType.PIPS : TemplateUnitType.POINTS,
      maxManualActiveTrades: expert.maxManualActiveTrades?.toString() || '1',
      maxSignalActiveTrades: expert.maxSignalActiveTrades?.toString() || '1',
      allowClone: expert.allowClone,
      allowShare: expert.allowShare,
      cloneFromExpertId: expert.cloneFromExpertId,
      strategy: {
        type: megicOrderExpertConfigMapping
          ? ''
          : expert.providerId !== null
            ? StrategyOptionType.signal
            : expert.privateWebhookVersion === 1
              ? StrategyOptionType.manualV2
              : StrategyOptionType.manual,
        usePriceTolerance: expert?.priceTolerance !== 0,
        priceTolerance: expert.priceTolerance?.toString(),
        priceToleranceType: expert.priceToleranceType === FxPriceToleranceType.PERCENT ? '1' : '0',
        timeTolerance: Number(expert.timeTolerance || 0) % 60 === 0 ? (Number(expert.timeTolerance) / 60).toString() : expert.timeTolerance.toString(),
        timeToleranceType: Number(expert.timeTolerance || 0) % 60 === 0 ? '1' : '0',
        usePostSignalProximity: expert.tpProximity !== 0,
        tpProximity: expert.tpProximity?.toString(),
        tpProximityType: expert?.tpProximityType === FxTpProximityType.PERCENT ? '1' : '0',
      },
      manualMoneyManagement: {
        type: expert.manualMoneyManagement.type?.toString(),
        lotsize: expert.manualMoneyManagement.lotsize?.toString(),
        riskPercent: expert.manualMoneyManagement.riskPercent?.toString(),
        basedOn: expert.manualMoneyManagement.basedOn?.toString(),
        k: expert.manualMoneyManagement.k?.toString(),
      },
      signalMoneyManagement: {
        type: expert.signalMoneyManagement?.type?.toString(),
        lotsize: expert.signalMoneyManagement?.lotsize?.toString(),
        riskPercent: expert.signalMoneyManagement?.riskPercent?.toString(),
        basedOn: expert.signalMoneyManagement?.basedOn?.toString(),
        k: expert.signalMoneyManagement?.k?.toString(),
        xPercent: expert.signalMoneyManagement?.xPercent?.toString(),
      },
      maxDrawDownLimit: expert.maxDrawDownLimit
        ? {
            use: !!expert.maxDrawDownLimit.percent,
            percent: expert.maxDrawDownLimit.percent?.toString(),
            basedOn: expert.maxDrawDownLimit.basedOn?.toString(),
            period: expert.maxDrawDownLimit.period?.toString(),
          }
        : {
            use: false,
            percent: '',
            basedOn: '0',
            period: '0',
          },
      hitMaxDrawDownLimitUntil: expert.hitMaxDrawDownLimitUntil,
      breakEven: {
        use: expert.breakEven.use,
        type: [FxBreakevenType.PIPS, FxBreakevenType.PERCENT].includes(expert.breakEven.type as FxBreakevenType)
          ? expert.breakEven.type === FxBreakevenType.PERCENT
            ? '1'
            : '0'
          : null,
        levels: expert.breakEven.levels.map((item) => ({
          afterX: item.afterX?.toString(),
          setToY: item.setToY?.toString(),
        })),
      },
      trailStop: {
        use: expert.trailStop.use,
        afterX: expert.trailStop.afterX?.toString(),
        followY: expert.trailStop.followY?.toString(),
      },
      partialClose: createPartialCloseEditingDto({
        use: expert.partialClose.use,
        calculationType: expert.partialClose.type === FxPartialCloseType.SMART ? '1' : '0',
        takeprofits: expert.partialClose.takeprofits?.map(PartialTakeProfitEditingDtoMapper.toDomain),
      }),
      conditionalClosure: {
        use: expert.conditionalClosure?.use,
        amount: expert.conditionalClosure?.amount?.toString(),
      },
      manualSlTp: {
        type: expert.manualSlTp.type?.toString(),
        fixedSl: expert.manualSlTp.fixedSl?.toString(),
        fixedTp: expert.manualSlTp.fixedTp?.toString(),
        tpRatio: expert.manualSlTp.tpRatio.toString(),
        riskRatio: expert.manualSlTp.riskRatio || 1,
      },
      signalSlTp: {
        type: expert.signalSlTp?.type?.toString(),
        fixedSl: expert.signalSlTp?.fixedSl?.toString(),
        fixedTp: expert.signalSlTp?.fixedTp?.toString(),
        tpRatio: expert.signalSlTp?.tpRatio,
        riskRatio: expert.signalSlTp?.riskRatio || 1,
        profitCalculationType: '0',
        slType: '0',
        ...signalSlTp,
      },
      sessionManagement: expert.sessionManagement
        ? {
            use: !!expert.sessionManagement.use,
            type: expert.sessionManagement.type,
            sessions:
              Object.values(FxTradingSession).every((enumValue) => expert.sessionManagement?.sessions.includes(enumValue)) || !expert.sessionManagement.sessions.length
                ? ['all']
                : expert.sessionManagement?.sessions,
            threshold: expert.sessionManagement.threshold?.toString(),
          }
        : {
            use: false,
            type: FxSessionManagementActionType.LEAVE_RUNNING,
            sessions: ['all'],
            threshold: '',
          },
      orderType: expert.orderType,
      orderDirection: expert.orderDirection,
      timeInForce: (expert.timeInForce as TimeInForcePredefinedValue | null) ?? TimeInForcePredefinedValue.goodTillCancelled,
      meta: {
        step: CreateExpertStepKey.Trigger,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static toPersistence({ id, ...values }: Omit<CreateExpertDto, 'meta'>): CreationExpertPersistetDto {
    const signalSlTp: Partial<IExpert['signalSlTp']> = {};

    if (values.signalSlTp.type === '4') {
      signalSlTp.type = parseFloat(values.signalSlTp.slType === '0' ? '4' : '5');

      if (values.signalSlTp.profitCalculationType === '0') {
        signalSlTp.fixedTp = 0;
        signalSlTp.tpRatio = values.signalSlTp.tpRatio || 0;
        signalSlTp.riskRatio = values.signalSlTp.riskRatio || 1;
      } else {
        signalSlTp.tpRatio = 0;
        signalSlTp.riskRatio = 1;
        signalSlTp.fixedTp = parseFloat(values.signalSlTp.fixedTp) || 0;
      }
    }

    const partialClose: PartialClosePersistetDto = {
      use: values.partialClose.use,
      type: values.partialClose.calculationType === '1' ? FxPartialCloseType.SMART : FxPartialCloseType.CLASSIC,
      takeprofits: values.partialClose.use ? values.partialClose.takeprofits.map(PartialTakeProfitEditingDtoMapper.toPersistence) : [],
    };

    const signalSchema = shouldUseSignalSchema(values.strategy.type as StrategyOptionType);

    return {
      name: values.name,
      description: values?.description === '' ? null : values?.description || undefined,
      slippage: values.useSlippage ? parseFloat(values.slippage) : 0,
      symbols: values.symbols,
      providerId: values.strategy.type === 'signal' ? values.providerId : null,
      expertPresetId: values.expertPresetId || null,
      accountId: values.account,
      magicNumber: values.magicNumber,
      usePips: values.usePips,
      useManualTrades: !signalSchema,
      useSignalTrades: signalSchema,
      allowClone: values.allowClone,
      allowShare: values.allowShare,
      cloneFromExpertId: values.cloneFromExpertId,
      timeTolerance: signalSchema
        ? values.strategy.usePriceTolerance
          ? values.strategy.timeToleranceType === '1'
            ? parseFloat(values.strategy.timeTolerance) * 60
            : parseFloat(values.strategy.timeTolerance)
          : 0
        : 0,
      maxManualActiveTrades: parseInt(values.maxManualActiveTrades),
      maxSignalActiveTrades: parseInt(values.maxSignalActiveTrades),
      priceTolerance: signalSchema && values.strategy.usePriceTolerance ? parseFloat(values.strategy.priceTolerance) : 0,
      priceToleranceType: values.strategy.priceToleranceType === '0' ? FxPriceToleranceType.PIPS : FxPriceToleranceType.PERCENT,
      tpProximity: signalSchema && values.strategy.usePostSignalProximity ? parseFloat(values.strategy.tpProximity) : 0,
      tpProximityType: values.strategy.tpProximityType === '0' ? FxTpProximityType.PIPS : FxTpProximityType.PERCENT,
      manualMoneyManagement: {
        type: parseFloat(values.manualMoneyManagement.type),
        lotsize: parseFloat(values.manualMoneyManagement.lotsize),
        riskPercent: parseFloat(values.manualMoneyManagement.riskPercent),
        basedOn: parseFloat(values.manualMoneyManagement.basedOn),
        k: parseFloat(values.manualMoneyManagement.k),
      },
      signalMoneyManagement: {
        type: parseFloat(values.signalMoneyManagement.type),
        lotsize: parseFloat(values.signalMoneyManagement.lotsize),
        riskPercent: parseFloat(values.signalMoneyManagement.riskPercent),
        basedOn: parseFloat(values.signalMoneyManagement.basedOn),
        k: parseFloat(values.signalMoneyManagement.k),
        xPercent: parseFloat(values.signalMoneyManagement.xPercent),
      },
      breakEven: {
        use: values.breakEven.use,
        levels: values.breakEven.use
          ? values.breakEven.levels.map((item) => ({
              afterX: parseFloat(item.afterX),
              setToY: parseFloat(item.setToY),
            }))
          : [],
        type: values.breakEven.type === '1' ? FxBreakevenType.PERCENT : FxBreakevenType.PIPS,
      },
      trailStop: {
        use: values.trailStop.use,
        afterX: parseFloat(values.trailStop.afterX),
        followY: parseFloat(values.trailStop.followY),
      },
      partialClose,
      conditionalClosure: {
        use: values.conditionalClosure.use,
        amount: Number(values.conditionalClosure.amount) || 0,
      },
      maxDrawDownLimit: values.maxDrawDownLimit?.use
        ? {
            percent: Number(values.maxDrawDownLimit.percent),
            basedOn: parseFloat(values.maxDrawDownLimit.basedOn),
            period: parseFloat(values.maxDrawDownLimit.period),
          }
        : null,
      sessionManagement: values.sessionManagement?.use
        ? {
            use: values.sessionManagement.use,
            sessions: values.sessionManagement.sessions.includes('all') ? Object.values(FxTradingSession).map((enumValue) => enumValue) : values.sessionManagement.sessions,
            type: values.sessionManagement.type,
            threshold: values.sessionManagement.type === FxSessionManagementActionType.CLOSE_IF_IN_PROFIT ? parseFloat(values.sessionManagement.threshold || '0') : undefined,
          }
        : null,
      manualSlTp: {
        type: parseFloat(values.manualSlTp.type),
        fixedSl: parseFloat(values.manualSlTp.fixedSl),
        fixedTp: parseFloat(values.manualSlTp.fixedTp),
        tpRatio: values.manualSlTp.type === '0' ? 0 : parseFloat(values?.manualSlTp?.tpRatio as unknown as string) || 0,
        riskRatio: values.manualSlTp.riskRatio || 1,
      },
      signalSlTp: {
        type: parseFloat(values.signalSlTp.type),
        fixedSl: parseFloat(values.signalSlTp.fixedSl) || 0,
        fixedTp: parseFloat(values.signalSlTp.fixedTp) || 0,
        tpRatio: parseFloat(values.signalSlTp.tpRatio as unknown as string) || 0,
        riskRatio: values.signalSlTp.riskRatio || 1,
        ...signalSlTp,
      },
      orderType: values.orderType || ExpertOrderType.NEUTRAL,
      orderDirection: values.orderDirection || ExpertOrderDirection.NEUTRAL,
      timeInForce: doesOrderTypeSupportTimeInForce(values.orderType) ? values.timeInForce : null,
      privateWebhookVersion: values.strategy.type === 'manual' ? 0 : values.strategy.type === 'manualV2' ? 1 : undefined,
    };
  }
}
