import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { QuizState } from '@root/shared-files/modules/auth/types/quiz-state';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetQuizStateResponse = IHttpResponse<200, QuizState> | IHttpResponse<400, string>;

export const getQuizState = async (): Promise<GetQuizStateResponse> => {
  try {
    const response = await fetcher.get('/auth/quiz/state');

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
