import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ChartCandleDto } from '@root/modules/charting-library/dtos/chart-candle.dto';
import { CandlesMapper } from '@root/modules/charting-library/mappers/candles.mapper';
import { ChartCandle } from '@root/modules/charting-library/types/candle';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetCandlesServiceResponse = IHttpResponse<200, ChartCandle[]> | IHttpResponse<204, string> | IHttpResponse<400, string>;

export const getCandles = async (params: ChartCandleDto): Promise<GetCandlesServiceResponse> => {
  try {
    const { start, end, market, interval, exchange } = params;

    const response = await fetcher(`/candles/?brokerAlias=${exchange}&symbols[]=${market}&from=${start}&to=${end}&timeframe=${interval}`, {
      baseURL: 'https://candles.fx-core.3lgn.com',
    });

    return {
      status: 200,
      payload: CandlesMapper.toBars(response.data),
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
