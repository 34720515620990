import clsx from 'clsx';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import { ColorVariant } from '@root/infra/theme';

export interface AlertProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant: ColorVariant;
}

export const Alert: FC<AlertProps> = ({ variant, className, ...props }) => {
  return (
    <div
      className={clsx(
        `p-4 rounded-xl text-white`,
        {
          'bg-danger-500/50': variant === 'danger',
          'bg-warning-500/50': variant === 'warning',
          'bg-primary-500/50': variant === 'primary',
          'bg-success-500/50': variant === 'success',
          'bg-info-500/50': variant === 'info',
          'bg-muted-500/50': variant === 'muted',
          'bg-light-500/50': variant === 'light',
          'bg-gray-500/50': variant === 'gray',
          'bg-grayscale-500/50': variant === 'grayscale',
          'bg-secondary-500/50': variant === 'secondary',
          'bg-pink-500/50': variant === 'pink',
          'bg-mint-500/50': variant === 'mint',
        },
        className,
      )}
      {...props}
    />
  );
};
