import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const DashboardIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.76357 10.7143H7.52214C8.49429 10.7143 9.28571 9.92286 9.28571 8.95071V1.76357C9.28571 0.791429 8.49429 0 7.52214 0H1.76357C0.791429 0 0 0.791429 0 1.76357V8.95071C0 9.92286 0.791429 10.7143 1.76357 10.7143ZM1.42871 1.76371C1.42871 1.57871 1.57871 1.42871 1.76371 1.42871H7.52228C7.70728 1.42871 7.85728 1.57871 7.85728 1.76371V8.95085C7.85728 9.13585 7.70728 9.28585 7.52228 9.28585H1.76371C1.57871 9.28585 1.42871 9.13585 1.42871 8.95085V1.76371ZM18.236 7.85714H12.4774C11.5053 7.85714 10.7139 7.06571 10.7139 6.09357V1.76357C10.7139 0.791429 11.5053 0 12.4774 0H18.236C19.2082 0 19.9996 0.791429 19.9996 1.76357V6.09357C19.9996 7.06571 19.2082 7.85714 18.236 7.85714ZM12.4776 1.42871C12.2926 1.42871 12.1426 1.57871 12.1426 1.76371V6.09371C12.1426 6.27871 12.2926 6.42871 12.4776 6.42871H18.2361C18.4212 6.42871 18.5711 6.27871 18.5711 6.09371V1.76371C18.5711 1.57871 18.4212 1.42871 18.2361 1.42871H12.4776ZM18.236 19.9999H12.4774C11.5053 19.9999 10.7139 19.2085 10.7139 18.2364V11.0492C10.7139 10.0771 11.5053 9.28564 12.4774 9.28564H18.236C19.2082 9.28564 19.9996 10.0771 19.9996 11.0492V18.2364C19.9996 19.2085 19.2082 19.9999 18.236 19.9999ZM12.4776 10.7139C12.2926 10.7139 12.1426 10.8639 12.1426 11.0489V18.236C12.1426 18.421 12.2926 18.571 12.4776 18.571H18.2361C18.4212 18.571 18.5711 18.421 18.5711 18.236V11.0489C18.5711 10.8639 18.4212 10.7139 18.2361 10.7139H12.4776ZM7.52214 20.0002H1.76357C0.791429 20.0002 0 19.2088 0 18.2366V13.9066C0 12.9345 0.791429 12.1431 1.76357 12.1431H7.52214C8.49429 12.1431 9.28571 12.9345 9.28571 13.9066V18.2366C9.28571 19.2088 8.49429 20.0002 7.52214 20.0002ZM1.76371 13.5713C1.57871 13.5713 1.42871 13.7213 1.42871 13.9063V18.2363C1.42871 18.4213 1.57871 18.5713 1.76371 18.5713H7.52228C7.70728 18.5713 7.85728 18.4213 7.85728 18.2363V13.9063C7.85728 13.7213 7.70728 13.5713 7.52228 13.5713H1.76371Z'
        fill='currentColor'
      />
    </svg>
  );
};
