import styled, { css } from 'styled-components';

import clsx from 'clsx';
import { ButtonHTMLAttributes, DetailedHTMLProps, PropsWithoutRef, ReactNode, forwardRef, useEffect, useRef, useState } from 'react';

import { ColorVariant } from '@root/infra/theme/theme';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { BaseButton } from '@root/shared/ui/button/base-button';

export type ButtonExtraProps = {
  asChild?: boolean;
  size?: 'large' | 'base';
  tint?: string;
  variant?: ColorVariant;
  prefix?: ReactNode;
  suffix?: ReactNode;
  link?: boolean;
  outlined?: boolean;
  loading?: boolean;
  animated?: boolean;
  width?: number;
  height?: number;
  prefixWidth?: number;
};

export interface ButtonProps extends PropsWithoutRef<Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'size' | 'prefix'>>, ButtonExtraProps {}

export const StyledButton = styled(BaseButton)
  .attrs<ButtonExtraProps>(({ variant, outlined, link }) => ({
    className: clsx(
      'inline-flex items-center justify-center z-0 disabled:opacity-70 disabled:no-underline !disabled:hover:no-underline font-semibold border-2 transition relative rounded-sm',
      {
        "relative border-transparent before:block before:content-[''] before:absolute": variant === 'primary' && !outlined && !link,
      },
    ),
  }))
  .withConfig({
    shouldForwardProp: (prop) =>
      !['animated', 'suffix', 'prefix', 'size', 'variant', 'link', 'outlined', 'loading', 'tint', 'width', 'height', 'prefixWidth', 'theme'].includes(prop),
  })<ButtonExtraProps>`
  ${({ variant, outlined, link, loading, theme }) =>
    variant === 'primary' &&
    !outlined &&
    !link &&
    css`
      &:before {
        background: ${theme.gradient['6']};
        opacity: ${() => (loading ? '0.3' : '1')};
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        z-index: -1;
        border-radius: 0.25rem;
        opacity: ${() => (loading ? 0.5 : 1)};
      }

      &:disabled {
        color: rgba(255, 255, 255, 0.5);
        opacity: 0.7;
        text-decoration: none;
        cursor: not-allowed;
      }
    `}

  ${({ variant, outlined, link, tint, theme, animated, width, height, prefixWidth }) => {
    const isNormal = !link && !outlined;
    const colorVariant = (variant as string) || 'primary';
    const colorTint = (tint as string) || '300';

    return `
    ${
      isNormal
        ? `
      background: ${theme[colorVariant][colorTint]};

      &:hover:not([disabled]) {
        background: transparent;
        color: ${theme[colorVariant][colorTint]};
        border-color: ${theme[colorVariant][colorTint]};
        &::before {
         background: transparent;
        }
        
      }

      &:disabled {
        color: rgba(255, 255, 255, 0.5);
        background: theme[colorVariant]?.[colorTint]};
      }
    `
        : ''
    }
  
    ${
      outlined
        ? `
        color: ${theme[colorVariant][colorTint]};
        border-color: ${theme[colorVariant][colorTint]};
        &:hover {
          color: ${theme.gray[100]};
          border-color: ${theme.gray[100]};
        }
      `
        : ''
    }
    
   

    ${
      link
        ? `
        color: ${theme[colorVariant][colorTint]};
        border-color: transparent;
        font-weight: 400;
        &:hover {
          text-decoration: underline;
        }

        &:disabled {
          color: rgba(${theme[colorVariant][300]}, 0.5);
        }
      `
        : ''
    }
      
      ${
        animated &&
        `
          position: relative;
          transition: width 0.2s, padding 0.2s ease;
          width: ${width}px;
          padding: 7px 0 7px 20px;
          height: ${height}px;
          .children {
            z-index: 10;
            overflow: hidden;
            position: absolute;
            top: calc(50% - 11px);
            right: 14px;
          }
          .prefix {
            position: absolute;
            left: 16px;
            top: calc(50% - 8px);
            transition: left 0.2s ease;
          }
          &:hover {
            width: ${(width || 0) - ((prefixWidth || 0) + 4)}px;
            padding: 7px 0 7px 0;
            .children {
              right: 16px;
            }
          }
        `
      }

    ${
      !link && variant !== 'primary'
        ? `
      border-color: ${theme[colorVariant][colorTint]};
    `
        : ''
    }
    
    
    

    ${
      variant === 'light' && isNormal
        ? `
      color: black;

      &:disabled {
        color: rgba(0, 0, 0, 0.5);
      }
    `
        : ''
    }

    ${
      variant === 'light' && outlined
        ? `
      color: white;
    `
        : ''
    }
    `;
  }}
`;

export const createButtonClassName = ({ size, loading }: Pick<ButtonExtraProps, 'link' | 'outlined' | 'variant' | 'size' | 'loading' | 'tint'>): string => {
  const sizeStyles = { 'px-8 py-2.5 text-lg': size === 'large', 'px-6 py-1.5 text-sm': size === 'base' };
  const loadingStyles = 'pointer-events-none';

  return clsx(sizeStyles, loading && loadingStyles);
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { asChild, loading, className, outlined, link, prefix, suffix, size = 'base', variant = 'primary', tint = '300', type = 'button', width, ...props },
  ref,
) {
  const [buttonWidth, setButtonWidth] = useState(0);
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (childrenRef.current && props.animated && props.prefixWidth) {
      setButtonWidth(childrenRef?.current?.clientWidth + (props.prefixWidth || 0) + 39);
    }
  }, [props.animated, props.prefixWidth, props.children]);

  return (
    <>
      <span className='invisible fixed text-sm font-semibold' ref={childrenRef}>
        {props.children}
      </span>

      <StyledButton
        asChild={asChild}
        ref={ref}
        variant={variant}
        link={link}
        outlined={outlined}
        loading={loading ? true : undefined}
        type={type || 'button'}
        tint={tint}
        width={props.animated && buttonWidth ? buttonWidth : undefined}
        className={clsx('whitespace-nowrap gap-x-2', createButtonClassName({ size, loading }), className)}
        {...props}
      >
        {asChild ? (
          props.children
        ) : (
          <>
            {!!prefix && <span className='prefix -mb-0.5 inline-block'>{prefix}</span>}

            {props.children}
            {!!suffix && <span className='-mb-0.5 inline-block'>{suffix}</span>}
            {loading && (
              <span className='text-2xl text-gray-100 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
                <LoadingIcon />
              </span>
            )}
          </>
        )}
      </StyledButton>
    </>
  );
});
