export type Comparator = <T extends string | number>(a: T, b: T) => number;

export const compareAsc: Comparator = (a, b) => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

export const compareDesc: Comparator = (a, b) => compareAsc(b, a);

export const createComparator =
  <T>(select: (item: T) => string | number, compare: Comparator = compareAsc) =>
  (a: T, b: T) =>
    compare(select(a), select(b));

export const compareIsoUtcStrings = (a: string, b: string) => {
  if (a === b) {
    return 0;
  }

  if (a > b) {
    return 1;
  }

  return -1;
};

export enum SortDirection {
  'asc' = 'asc',
  'desc' = 'desc',
}

export type DirectionNumber = 1 | -1;

export const getDirectionNumber = (direction: SortDirection): DirectionNumber => (direction === SortDirection.asc ? 1 : -1);

export type SortRule<T extends string> = {
  field: T;
  direction: SortDirection;
};

export const createComparatorByRules = <T extends object, K extends string | number | symbol = keyof T>(
  // TODO: SortRule<K>[],
  sortRules: SortRule<string>[],
  comparatorCreatorByFieldMap: Partial<{ [k in K]?: (direction: DirectionNumber) => (a: T, b: T) => number }>,
) => {
  const fieldComparators = sortRules.flatMap((sortRule) => {
    const createComparator = comparatorCreatorByFieldMap[sortRule.field];

    if (createComparator === undefined) {
      return [];
    }

    return [createComparator(getDirectionNumber(sortRule.direction))];
  });

  const compare = (a: T, b: T) => {
    for (const compare of fieldComparators) {
      const result = compare(a, b);

      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };

  return compare;
};

export const toggleSortRule = <T extends string>(paginationSortRules: SortRule<T>[], field: T) => {
  const fieldSortRuleIndex = paginationSortRules.findIndex((rule) => rule.field === field);
  const fieldSortDirection = paginationSortRules[fieldSortRuleIndex]?.direction ?? null;

  const nextPaginationSortRules = [...paginationSortRules];

  if (fieldSortDirection === null) {
    nextPaginationSortRules.push({ field, direction: SortDirection.asc });
  } else if (fieldSortDirection === SortDirection.asc) {
    nextPaginationSortRules.splice(fieldSortRuleIndex, 1, { field, direction: SortDirection.desc });
  } else {
    nextPaginationSortRules.splice(fieldSortRuleIndex, 1);
  }

  return nextPaginationSortRules;
};

export const toggleSortRuleDirection = <T extends string>(paginationSortRules: SortRule<T>[], field: T) => {
  const fieldSortRuleIndex = paginationSortRules.findIndex((rule) => rule.field === field);
  const fieldSortDirection = paginationSortRules[fieldSortRuleIndex]?.direction ?? null;

  const nextPaginationSortRules = [...paginationSortRules];

  if (fieldSortDirection === null) {
    nextPaginationSortRules.push({ field, direction: SortDirection.asc });
  } else if (fieldSortDirection === SortDirection.asc) {
    nextPaginationSortRules.splice(fieldSortRuleIndex, 1, { field, direction: SortDirection.desc });
  } else {
    nextPaginationSortRules.splice(fieldSortRuleIndex, 1, { field, direction: SortDirection.asc });
  }

  return nextPaginationSortRules;
};
