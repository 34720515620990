import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { OpenOrdersDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { IOrder } from '@root/modules/orders/types/orders';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetOrderServiceResponse = IHttpResponse<200, IOrder[]> | IHttpResponse<400, string>;
export type GetOrderServiceQuery = { accountId: string; currency: string };

export const getOpenOrdersService = async ({ accountId, currency }: GetOrderServiceQuery): Promise<GetOrderServiceResponse> => {
  try {
    const response = await fetcher(`/accounts/${accountId}/orders/open`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    const nowUtcIso = new Date().toISOString();

    return {
      status: 200,
      payload: response.data
        .filter((item) => !!item.symbol)
        .map((item) =>
          OpenOrdersDtoMapper.toDomain(item, {
            accountId,
            currency,
            nowUtcIso,
          }),
        ),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
