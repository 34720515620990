import { FC, PropsWithChildren } from 'react';
import { ScrollRestoration } from 'react-router-dom';

import { SageLiveChat } from '@root/infra/live-chat';

export const RootLayout: FC<PropsWithChildren> = ({ children }) => (
  <>
    {children}
    <SageLiveChat />
    <ScrollRestoration />
  </>
);
