import { createSlice } from '@reduxjs/toolkit';

import { TelegramAccount } from '@root/modules/user-settings/types/telegram';

export type IUserSettingsState = {
  feedbackOpened: boolean;
  menuOpened: boolean;
  tgAccounts: TelegramAccount[];
};

const initialState: IUserSettingsState = {
  feedbackOpened: false,
  menuOpened: false,
  tgAccounts: [],
};

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setFeedbackModal: (state, action) => {
      state.feedbackOpened = action.payload;
    },
    setMenuOpened: (state, action) => {
      state.menuOpened = action.payload;
    },
    tgAccountLinked: () => {},
    tgAccountUnlinked: () => {},
    setTgAccounts: (state, action) => {
      state.tgAccounts = action.payload;
    },
  },
});
