import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { countriesSelector, countriesSlice } from '@root/modules/countries/store';
import { SignUpDto } from '@root/shared-files/modules/auth/dtos';
import { signUpService } from '@root/shared-files/modules/auth/services';
import { createSignUpValidation } from '@root/shared-files/modules/auth/validations';
import { IFormStatus } from '@root/shared/form';

export const accessCode = 'WelcomeSage01';

export const useSignUp = () => {
  const { t } = useTranslation('auth-validation');
  const countries = useSelector(countriesSelector.main);
  const dispatch = useDispatch();

  const initialValues = useMemo<SignUpDto>(
    () => ({
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
      countryCode: '',
      accessCode,
      captchaToken: '',
    }),
    [],
  );

  const schema = useMemo(() => createSignUpValidation(t), []);

  const onSubmit = useCallback(async (values: SignUpDto, helpers: FormikHelpers<SignUpDto>) => {
    helpers.setStatus(undefined);
    const trimmed = {
      fullName: values.fullName.trim(),
      email: values.email.trim().toLowerCase(),
      password: values.password.trim(),
      confirmPassword: values.confirmPassword.trim(),
      countryCode: values.countryCode.trim(),
      accessCode: values.accessCode.trim(),
    };

    const result = await signUpService({ ...values, ...trimmed });

    if (result.status === 200) {
      helpers.setStatus({ type: 'success', message: null } as IFormStatus);
    } else {
      helpers.setFieldValue('captcha', null);
      helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
    }
  }, []);

  useEffect(() => {
    if (!countries.data.length) {
      dispatch(countriesSlice.actions.fetchPending());
    }
  }, [countries.data.length, dispatch]);

  return { initialValues, onSubmit, validationSchema: schema };
};
