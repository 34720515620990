export enum SocketEventTypes {
  // MT4 - MetaTrader 4
  AccountInfoChange = 'accountInfoChange',
  OpenOrdersListChange = 'listOpenOrders',
  Quote = 'Quote',
  ExpertStopped = 'ws_event_fx_expert_stopped',
  ExpertStarted = 'ws_event_fx_expert_started',
  AccountStatusChanged = 'accountStatusChange',
  AccountConnectError = 'accountConnectError',
  MaintenanceMode = 'maintenance_mode',
  TelegramLinked = 'userSettings/tgAccountLinked',
  TelegramUnlinked = 'userSettings/tgAccountUnlinked',
  AccountOrderClosed = 'accountOrderClosed',
  signalRetryResult = 'signalRetryResult',
  // Quote
}
