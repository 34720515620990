import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const SignalsIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} fill='none' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3.33447 3.47003C4.97516 1.9574 7.35115 1 10.0043 1C12.6574 1 15.0333 1.9574 16.674 3.47003'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.55835 6.1867C6.65181 5.27932 8.23546 4.70508 10.0042 4.70508C11.7729 4.70508 13.3566 5.27932 14.4501 6.1867'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.78137 9.00368C8.3286 8.54949 9.11993 8.26237 10.0043 8.26237C10.8887 8.26237 11.681 8.54949 12.2272 9.00368'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.40708 9.00293L1.05351 15.7697C0.718373 17.4444 1.99991 19.0071 3.70862 19.0071H16.3009C18.0086 19.0071 19.2901 17.4444 18.955 15.7697L17.6014 9.00293'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
        style={{ fill: 'none' }}
      />
    </svg>
  );
};
