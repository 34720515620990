import styled from 'styled-components';

import { ComponentType, FC, SVGProps } from 'react';

import { Text } from '@root/shared/ui/typography';

type Props = {
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
  label: string;
  value: string | number;
};

const ShareItemCardWrapper = styled.div`
  background: rgba(239, 240, 246, 0.1);
  width: 6.375rem;
`;

export const ShareItemCard: FC<Props> = ({ Icon, label, value }) => {
  return (
    <ShareItemCardWrapper className='flex px-2 py-1.5 gap-2 rounded-sm align-top'>
      <Icon fontSize='0.875rem' />
      <div>
        <Text size='xs' className='text-grayscale-500'>
          {label}
        </Text>
        <Text size='base' className='text-grayscale-200 font-medium'>
          {value}
        </Text>
      </div>
    </ShareItemCardWrapper>
  );
};
