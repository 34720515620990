import get from 'lodash/get';

import { FieldArray, useFormikContext } from 'formik';
import { FC, MouseEventHandler } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { notionLinks } from '@root/infra/constants/links';
import { LabelSafeArea } from '@root/infra/form/label-safe-area';
import { LabelWithInfoTooltip } from '@root/infra/form/label-with-info-tooltip';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { createTimeBasedPartialTakeProfitEditingDto } from '@root/modules/experts/helpers/make-expert-form-values';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { timeBasedLevelsLimit } from '@root/modules/experts/validations/common.validation';
import { TextField } from '@root/shared/form';
import { resolveFieldPath } from '@root/shared/form/form-utils';
import { CloseIcon } from '@root/shared/icons/close-icon';
import { PlusIcon } from '@root/shared/icons/plus-icon';
import { Button } from '@root/shared/ui/button';
import { FilledTonalButton } from '@root/shared/ui/button/filled-tonal-button';
import { Text } from '@root/shared/ui/typography';

export const PartialTakeProfitTimeBasedLevelsFieldset: FC<{
  viewOnly?: boolean;
  nameSufix: string;
  unitType: TemplateUnitType | '%';
}> = ({ nameSufix, unitType, viewOnly }) => {
  const { values, errors, setFieldTouched } = useFormikContext<CreateExpertDto>();

  const levels = get(values, nameSufix);

  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.partialClose.levels.timeBasedTakeProfit' });

  const levelsLimitIsNotReached = levels.length < timeBasedLevelsLimit;

  return (
    <FieldArray
      name={nameSufix}
      render={(levelsHelpers) => {
        const timeBasedTakeProfitErrors = get(errors, nameSufix);

        const handleLevelAddingButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
          if (timeBasedTakeProfitErrors !== undefined && levels.length > 0) {
            levels.forEach((_, index) => {
              setFieldTouched(resolveFieldPath(nameSufix, index, 'amount'), true);
              setFieldTouched(resolveFieldPath(nameSufix, index, 'afterMinutes'), true);
            });

            return;
          }

          levelsHelpers.push(createTimeBasedPartialTakeProfitEditingDto());
        };

        return (
          <div className='flex flex-col gap-y-2'>
            {levels.map((_, index) => {
              const first = index === 0;

              const handleLevelRemovingButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
                levelsHelpers.remove(index);
              };

              const levelRemovingButton = (
                <FilledTonalButton onClick={handleLevelRemovingButtonClick}>
                  <CloseIcon />
                </FilledTonalButton>
              );

              return (
                <div key={index} className={'flex gap-x-2'}>
                  <TextField
                    name={resolveFieldPath(nameSufix, index, 'amount')}
                    disabled={viewOnly}
                    wrapperClassName='flex-auto'
                    label={
                      first ? (
                        <LabelWithInfoTooltip
                          keepTooltipOnMouseLeave
                          tooltipContent={
                            <Trans
                              i18nKey={'forex-experts:fields.partialClose.levels.timeBasedTakeProfit.amount.description'}
                              components={{
                                a: <a className='text-primary-400 font-semibold whitespace-nowrap' target='_blank' rel='noreferrer' href={notionLinks.timeBasedTpKnowledgeBase} />,
                              }}
                            />
                          }
                        >
                          {t('amount.label')}
                        </LabelWithInfoTooltip>
                      ) : undefined
                    }
                    placeholder={t('amount.placeholder')}
                    suffix={unitType}
                  />

                  <TextField
                    name={resolveFieldPath(nameSufix, index, 'afterMinutes')}
                    disabled={viewOnly}
                    wrapperClassName='flex-auto'
                    label={first ? t('time.label') : undefined}
                    placeholder={t('time.placeholder')}
                    suffix={t('time.suffix')}
                  />

                  {!viewOnly && (first ? <LabelSafeArea>{levelRemovingButton}</LabelSafeArea> : levelRemovingButton)}
                </div>
              );
            })}

            {typeof timeBasedTakeProfitErrors === 'string' && (
              <Text size='sm' className='text-danger-500 text-right'>
                {timeBasedTakeProfitErrors}
              </Text>
            )}

            {!viewOnly && levelsLimitIsNotReached && (
              <Button outlined onClick={handleLevelAddingButtonClick} className='self-start border-none px-0!' prefix={<PlusIcon />}>
                {t('add')}
              </Button>
            )}
          </div>
        );
      }}
    />
  );
};
