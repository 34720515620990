import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { PosibliPendingOrderModifyingDto, mapPosibliPendingOrderModifyingDtoToPersistance } from '@root/modules/orders/dtos/modify-order.dto';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ModifyOrderServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export type ExternalOrderModifyingDto = PosibliPendingOrderModifyingDto;

export const modifyExternalOrderService = async (accountId: string, dto: ExternalOrderModifyingDto): Promise<ModifyOrderServiceResponse> => {
  try {
    const data = mapPosibliPendingOrderModifyingDtoToPersistance(dto);

    await fetcher.put(`/accounts/${accountId}/orders`, data, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
