import { QueryFunction } from 'react-query';

import { getAccountsService } from '@root/modules/accounts/services/get-accounts.service';
import { IAccountResponse } from '@root/modules/accounts/types/account';

export type GetAccountsQueryKey = ['accounts'];
export type GetAccountsData = IAccountResponse[];

export const GET_ACCOUNTS: QueryFunction<GetAccountsData, GetAccountsQueryKey> = async () => {
  const result = await getAccountsService();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
