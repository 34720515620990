import { keepPreviousData, useQuery } from 'react-query';

import { GetClosedOrdersPnLServiceResponse, getClosedOrdersPnL } from '@root/modules/orders/services/get-closed-orders-pnl.service';
import { Id } from '@root/shared/utils/types';

type Props = {
  mtType?: string;
  expertId?: string;
  isMagicTerminal?: boolean;
  accountIds?: Id[];
  currencies?: string[];
  fromDate?: string;
  toDate?: string;
};

export const useGetClosedOrdersProfit = ({ accountIds, currencies, expertId, isMagicTerminal, fromDate, toDate }: Props) => {
  const pnlQueries = useQuery<GetClosedOrdersPnLServiceResponse>({
    queryKey: [
      'fx',
      {
        accountIds,
        expertId,
        isMagicTerminal: !!isMagicTerminal,
        fromDate: fromDate || undefined,
        toDate: toDate || undefined,
        currencies,
      },
      'orders-history-pnl',
    ],
    queryFn: () =>
      getClosedOrdersPnL({
        accountIds,
        expertId,
        isMagicTerminal: !!isMagicTerminal,
        fromDate: fromDate || undefined,
        toDate: toDate || undefined,
        currencies,
      }),
    enabled: true,
    staleTime: 1000 * 60 * 10,
    placeholderData: keepPreviousData,
  });

  return pnlQueries.data;
};
