import React, { FC, ReactNode } from 'react';

import { useCheckIsIframe } from '@root/shared/hooks/use-check-is-iframe';

export const IframeChecker: FC<{ children: ReactNode }> = ({ children }) => {
  const { openedInIframe } = useCheckIsIframe();

  if (openedInIframe) {
    return <h3 className='w-full text-center pt-5'> Someone is trying to steal your data. Please check the site address.</h3>;
  }

  return <>{children}</>;
};
