import { FC, ReactElement, useLayoutEffect } from 'react';

import { Size, useElementSize } from '@root/infra/layout/hooks/use-element-size';

export const DynamicSafeArea: FC<{ element: HTMLElement | null; children: (size: Size | null) => ReactElement }> = ({ element, children }) => {
  const [setElement, size] = useElementSize();

  useLayoutEffect(() => {
    setElement(element);
  }, [element, setElement]);

  return children(size);
};
