import merge from 'lodash/merge';

import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { useChart } from '@root/modules/charting-library/contexts/chart-instance.context';
import { createExpertValidation } from '@root/modules/experts/validations/common.validation';
import { useShareConfig } from '@root/modules/magic-terminal/contexts/share-config.context';
import { useMagicTerminalInitialValues } from '@root/modules/magic-terminal/hooks/use-magic-terminal-initial-values';
import { CreateMagicTerminalOrderMapper } from '@root/modules/magic-terminal/mappers/create-magic-terminal-order-dto.mapper';
import { createMagicTerminalOrder } from '@root/modules/magic-terminal/services/create-order';
import { MagicTerminalForm } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { createMagicTerminalOrderValidation } from '@root/modules/magic-terminal/validations/create-magic-terminal-order.validation';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';

export const useMagicTerminalForm = () => {
  const { t } = useTranslation('forex-experts');
  const { t: yupT } = useTranslation('yup');
  const { initialValuesData } = useMagicTerminalInitialValues();
  const accounts = useGetAccounts();
  const { sharedConfig } = useShareConfig();
  const { chartInstance, importChartSettings } = useChart();

  const subscriptionAllowFxMagicBreakeven = useSelector(authSelector.subscriptionAllowFxMagicBreakeven);
  const subscriptionAllowFxMagicTrailingStop = useSelector(authSelector.subscriptionAllowFxMagicTrailingStop);
  const subscriptionAllowFxMagicPartialTakeProfit = useSelector(authSelector.subscriptionAllowFxMagicPartialTakeProfit);

  const activeAccountOptions = useMemo(
    () =>
      accounts.data
        ?.filter((item) => item.isSignedIn)
        ?.sort((a, b) => {
          const connectedA = a.status === 'CONNECTED';
          const connectedB = b.status === 'CONNECTED';
          return Number(connectedB) - Number(connectedA);
        })
        .map((item) => ({
          value: item.id,
          label: `${item.username} ${item.company} - ${item.mtType}`,
        })),
    [accounts.data],
  );

  const initialValues = useMemo<MagicTerminalForm>(() => {
    if (sharedConfig?.orderData === undefined) {
      return initialValuesData;
    }

    const sharedConfigToMixin = sharedConfig?.orderData;

    if (subscriptionAllowFxMagicBreakeven) {
      delete sharedConfigToMixin.breakEven;
    }

    if (subscriptionAllowFxMagicTrailingStop) {
      delete sharedConfigToMixin.trailStop;
    }

    if (subscriptionAllowFxMagicPartialTakeProfit) {
      delete sharedConfigToMixin.partialClose;
    }

    const values = merge(initialValuesData, sharedConfigToMixin);

    if (activeAccountOptions?.some((item) => item.value !== values.account)) {
      values.account = activeAccountOptions[0].value;
    }

    return values;
  }, [
    activeAccountOptions,
    initialValuesData,
    sharedConfig?.orderData,
    subscriptionAllowFxMagicBreakeven,
    subscriptionAllowFxMagicPartialTakeProfit,
    subscriptionAllowFxMagicTrailingStop,
  ]);

  const validationSchema = useMemo(() => createExpertValidation(t, yupT).concat(createMagicTerminalOrderValidation(t, yupT)).omit(['name', 'symbols']), [t, yupT]);

  const onSubmit = useCallback(
    async (values: MagicTerminalForm, helpers: FormikHelpers<MagicTerminalForm>) => {
      const data = CreateMagicTerminalOrderMapper.toPersistance(values);

      const response = await createMagicTerminalOrder(values.account, data);

      if (response.status === 200) {
        helpers.setStatus({ type: 'success', message: 'Order successfully created' } as IFormStatus);
        notify({
          type: 'success',
          text: t('magicTerminal.notifications.orderCreated'),
        });

        helpers.resetForm();

        helpers.setValues(
          {
            ...initialValues,
            symbol: values.symbol,
            account: values.account,
            usePips: values.usePips,
            unitType: values.unitType,
          },
          false,
        );
      } else {
        helpers.setStatus({ type: 'error', message: response.payload } as IFormStatus);
        notify({ type: 'danger', text: response.payload }, { data: response.errorData });
      }
    },
    [initialValues, t],
  );

  useEffect(() => {
    if (chartInstance && sharedConfig?.chartData && sharedConfig?.chartData) {
      importChartSettings(sharedConfig?.chartData);
    }
    history.replaceState(null, '', window.location.pathname);
  }, [chartInstance, importChartSettings, sharedConfig?.chartData]);

  return {
    initialValues,
    schema: validationSchema,
    onSubmit,
  };
};
