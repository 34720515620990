import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const InfoIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99967 4C8.36786 4 8.66634 4.29848 8.66634 4.66667V8C8.66634 8.36819 8.36786 8.66667 7.99967 8.66667C7.63148 8.66667 7.33301 8.36819 7.33301 8L7.33301 4.66667C7.33301 4.29848 7.63148 4 7.99967 4Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99967 10C8.36786 10 8.66634 10.2985 8.66634 10.6667V11C8.66634 11.3682 8.36786 11.6667 7.99967 11.6667C7.63148 11.6667 7.33301 11.3682 7.33301 11V10.6667C7.33301 10.2985 7.63148 10 7.99967 10Z'
      />
    </svg>
  );
};
