import { useTranslation } from 'react-i18next';

import connectionImg from '@root/assets/images/connection-grayed.webp';
import { Layout } from '@root/infra/layout/containers';
import { RemapSymbolTable } from '@root/modules/accounts/components/remap-symbol-table';
import { Image } from '@root/shared/ui/image';
import { Text, Title } from '@root/shared/ui/typography';

const SymbolRemapPage = () => {
  const { t } = useTranslation('forex-accounts');

  return (
    <Layout>
      <Title bold level={5} className='mb-6'>
        {t('remapSymbol.title')}
      </Title>
      <div className='flex flex-col lg:flex-row lg:items-center w-full mb-8 lg:flex-1'>
        <Image wrapperClassName='shrink-0 self-center' src={connectionImg} height={124} width={124} alt='Connection' />
        <div className='flex flex-col items-start lg:ml-8 mt-4 lg:mt-0 max-w-(--breakpoint-md) gap-1.5'>
          <Title level={6}>{t('remapSymbol.subtitle')}</Title>
          <Text>{t('remapSymbol.explanation1')}</Text>
          <Text>{t('remapSymbol.explanation2')}</Text>
        </div>
      </div>
      <RemapSymbolTable />
    </Layout>
  );
};

export default SymbolRemapPage;
