import { FC, PropsWithChildren, ReactElement } from 'react';

import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { IconLabelLayout } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';

export const LabelWithInfoTooltip: FC<PropsWithChildren<{ keepTooltipOnMouseLeave?: boolean; tooltipContent: ReactElement }>> = ({
  keepTooltipOnMouseLeave,
  tooltipContent,
  children,
}) => (
  <IconLabelLayout
    icon={
      <InfoTooltip keepOnMouseLeave={keepTooltipOnMouseLeave} content={<Text size='sm'>{tooltipContent}</Text>}>
        <InfoTooltipIcon />
      </InfoTooltip>
    }
  >
    {children}
  </IconLabelLayout>
);
