import { IOrder } from '@root/modules/orders/types/orders';

export type OrderBaseModifyingDto = Pick<IOrder, 'ticket'> & {
  takeprofit: string;
  stoploss: string;
};

export const mapOrderBaseModifyingDtoToPersistance = ({ stoploss, takeprofit, ticket }: OrderBaseModifyingDto) => ({
  stoploss: parseFloat(stoploss),
  takeprofit: parseFloat(takeprofit),
  ticket,
});

export type PosibliPendingOrderModifyingDto = OrderBaseModifyingDto &
  Pick<IOrder, 'expirationTime'> & {
    price: string;
  };

export const mapPosibliPendingOrderModifyingDtoToPersistance = ({ price, expirationTime, ...rest }: PosibliPendingOrderModifyingDto) => ({
  ...mapOrderBaseModifyingDtoToPersistance(rest),
  price: parseFloat(price),
  expirationTime,
});
