import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const SubscriptionsIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 7.50033C0 5.65938 1.49238 4.16699 3.33333 4.16699H16.6667C18.5076 4.16699 20 5.65938 20 7.50033V15.8337C20 17.6746 18.5076 19.167 16.6667 19.167H3.33333C1.49238 19.167 0 17.6746 0 15.8337V7.50033ZM3.33333 5.83366C2.41286 5.83366 1.66667 6.57985 1.66667 7.50033V15.8337C1.66667 16.7541 2.41286 17.5003 3.33333 17.5003H16.6667C17.5871 17.5003 18.3333 16.7541 18.3333 15.8337V7.50033C18.3333 6.57985 17.5871 5.83366 16.6667 5.83366H3.33333Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.83325 3.33301C5.83325 1.9523 6.95254 0.833008 8.33325 0.833008H11.6666C13.0473 0.833008 14.1666 1.9523 14.1666 3.33301V4.99967H12.4999V3.33301C12.4999 2.87277 12.1268 2.49967 11.6666 2.49967H8.33325C7.87301 2.49967 7.49992 2.87277 7.49992 3.33301V4.99967H5.83325V3.33301Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.397959 12.3772C0.397735 12.377 0.397526 12.3769 0.833373 11.6666C1.26922 10.9564 1.26904 10.9563 1.26888 10.9562C1.26848 10.9559 1.26891 10.9562 1.26888 10.9562L1.27475 10.9597L1.30334 10.9767C1.32996 10.9924 1.37156 11.0166 1.42739 11.048C1.53908 11.1109 1.70754 11.2029 1.92685 11.3142C2.36581 11.5372 3.0064 11.8368 3.80138 12.137C5.39638 12.7393 7.58517 13.3333 10 13.3333C12.4149 13.3333 14.6037 12.7393 16.1987 12.137C16.9937 11.8368 17.6343 11.5372 18.0732 11.3142C18.2925 11.2029 18.461 11.1109 18.5727 11.048C18.6285 11.0166 18.6701 10.9924 18.6967 10.9767L18.7253 10.9597L18.731 10.9563C18.7309 10.9564 18.7309 10.9564 19.1667 11.6666C19.6026 12.3769 19.6023 12.377 19.6021 12.3772L19.6003 12.3783L19.5965 12.3806L19.5844 12.3879C19.5744 12.3939 19.5605 12.4023 19.5427 12.4127C19.5071 12.4337 19.4562 12.4632 19.3907 12.5001C19.2597 12.5739 19.0701 12.6773 18.8279 12.8003C18.3438 13.0461 17.6476 13.3714 16.7875 13.6962C15.0723 14.3439 12.6778 15 10 15C7.32231 15 4.92778 14.3439 3.21259 13.6962C2.35248 13.3714 1.65626 13.0461 1.17219 12.8003C0.929978 12.6773 0.740367 12.5739 0.609364 12.5001C0.543847 12.4632 0.492941 12.4337 0.457397 12.4127C0.439623 12.4023 0.425685 12.3939 0.415677 12.3879L0.403621 12.3806L0.399824 12.3783L0.397959 12.3772Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.33325 10.0003C8.33325 9.54009 8.70635 9.16699 9.16659 9.16699H10.8333C11.2935 9.16699 11.6666 9.54009 11.6666 10.0003C11.6666 10.4606 11.2935 10.8337 10.8333 10.8337H9.16659C8.70635 10.8337 8.33325 10.4606 8.33325 10.0003Z'
        fill='currentColor'
      />
    </svg>
  );
};
