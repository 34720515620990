import { QueryFunction } from 'react-query';

import { getActiveSubscriptions } from '@root/shared-files/modules/subscriptions/services/get-active-subscriptions.service';
import { SubscriptionInfo } from '@root/shared-files/modules/subscriptions/types/subscription-products';

export type GetActiveSubscriptionsQueryKey = ['active-subscriptions'];
export type GetActiveSubscriptionsData = SubscriptionInfo[];

export const GET_ACTIVE_SUBSCRIPTIONS: QueryFunction<GetActiveSubscriptionsData, GetActiveSubscriptionsQueryKey> = async () => {
  const result = await getActiveSubscriptions();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
