import React, { FC } from 'react';

import { IconProps, IconSvg } from '@root/shared/icons/icon-svg';

export const PlusIcon: FC<IconProps> = (props) => (
  <IconSvg {...props} viewBox='0 0 16 16'>
    <path d='M8 2V14' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2 8L14 8' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </IconSvg>
);
