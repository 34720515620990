import { useFormikContext } from 'formik';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { useSignalTakeProfitFields } from '@root/modules/experts/hooks/use-signal-take-profit-fields';
import { SelectField, TextField } from '@root/shared/form';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { IconLabelLayout } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { SelectButton } from '@root/shared/ui/select-button/select-button';
import { Text, Title } from '@root/shared/ui/typography';

type StopLossTakeProfitFieldsetProps = {
  fieldsSchema: 'signal' | 'manual';
  isPartialEdit?: boolean;
  withDescription?: boolean;
};

export const StopLossTakeProfitFieldset: FC<StopLossTakeProfitFieldsetProps> = ({ fieldsSchema, isPartialEdit, withDescription = true }) => {
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();
  const { t } = useTranslation('forex-experts');

  const unitType = values.unitType;

  const options = useCreateExpertFieldOptions(values?.unitType);

  const fieldsSchemaIsSignal = fieldsSchema === 'signal';
  const type = fieldsSchemaIsSignal ? values.signalSlTp.type : values.manualSlTp.type;
  const profitCalculation = values.signalSlTp.profitCalculationType;
  const slType = values.signalSlTp.slType;

  const { onCalculationTypeChange, onSlTpTypeChange, onRiskRatioChange, onTpRatioChange } = useSignalTakeProfitFields(isPartialEdit);

  const onTypeChange = useCallback(
    (option) => {
      const value = option.value;
      if (fieldsSchemaIsSignal) {
        setFieldValue('signalSlTp.type', value);
      } else {
        setFieldValue('manualSlTp.type', value);
      }
    },
    [setFieldValue, fieldsSchemaIsSignal],
  );

  const fieldPrefix = fieldsSchemaIsSignal ? 'signalSlTp' : 'manualSlTp';

  const translationIdPrefix = fieldsSchemaIsSignal ? 'signalStopLossTakeProfit' : 'manualStopLossTakeProfit';

  return (
    <div className='flex flex-col gap-y-4'>
      {(fieldsSchemaIsSignal || withDescription) && <Title level={7}>{t('steps.stopLossTakeProfit.title')}</Title>}

      {withDescription && (
        <Text size='sm' className='text-gray-500'>
          {t(`fields.${translationIdPrefix}.type.description`)}
        </Text>
      )}

      <SelectField
        name={`${fieldPrefix}.type`}
        label={
          <IconLabelLayout
            icon={
              <InfoTooltip content={<Text size='sm'>{t(`fields.${translationIdPrefix}.type.description`)}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            {t(`fields.${translationIdPrefix}.type.label`)}
          </IconLabelLayout>
        }
        placeholder={t(`fields.${translationIdPrefix}.type.placeholder`)}
        options={fieldsSchemaIsSignal ? options.signalStopLossTakeProfit : options.manualStopLossTakeProfitType}
        onChange={fieldsSchemaIsSignal ? onSlTpTypeChange : onTypeChange}
      />

      <div className='grid sm:grid-cols-2 gap-4'>
        {(!fieldsSchemaIsSignal || ['0', '1'].includes(type)) && (
          <TextField
            name={`${fieldPrefix}.fixedSl`}
            type='number'
            label={
              <IconLabelLayout
                icon={
                  <InfoTooltip content={<Text size='sm'>{t(`fields.${translationIdPrefix}.fixedSl.description`)}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t(`fields.${translationIdPrefix}.fixedSl.label`)}
              </IconLabelLayout>
            }
            placeholder={t(`fields.${translationIdPrefix}.fixedSl.placeholder`)}
            suffix={fieldsSchemaIsSignal ? undefined : unitType}
            suffixClassName={fieldsSchemaIsSignal ? undefined : 'max-w-[30px] sm:max-w-none text-sm truncate'}
          />
        )}
        {['0'].includes(type) && (
          <TextField
            name={`${fieldPrefix}.fixedTp`}
            type='number'
            label={
              <IconLabelLayout
                icon={
                  <InfoTooltip content={<Text size='sm'>{t(`fields.${translationIdPrefix}.fixedTp.description`)}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t(`fields.${translationIdPrefix}.fixedTp.label`)}
              </IconLabelLayout>
            }
            placeholder={t(`fields.${translationIdPrefix}.fixedTp.placeholder`)}
            suffix={fieldsSchemaIsSignal ? undefined : unitType}
            suffixClassName={fieldsSchemaIsSignal ? undefined : 'max-w-[30px] sm:max-w-none text-sm truncate'}
          />
        )}
        {['1'].includes(type) && (
          <TextField
            name={`${fieldPrefix}.tpRatio`}
            type='number'
            label={
              <IconLabelLayout
                icon={
                  <InfoTooltip content={<Text size='sm'>{t(`fields.${translationIdPrefix}.tpRatio.description`)}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t(`fields.${translationIdPrefix}.tpRatio.label`)}
              </IconLabelLayout>
            }
            placeholder={t(`fields.${translationIdPrefix}.tpRatio.placeholder`)}
          />
        )}
      </div>

      {fieldsSchemaIsSignal && ['4'].includes(type) && (
        <>
          <SelectField
            name='signalSlTp.profitCalculationType'
            label={t('fields.signalStopLossTakeProfit.profitCalculationType.label')}
            placeholder={t('fields.signalStopLossTakeProfit.profitCalculationType.placeholder')}
            options={options.smartEntryProfitCalculationType}
            onChange={onCalculationTypeChange}
          />
          <div className='flex flex-col gap-y-2'>
            <div className='flex justify-start items-center gap-x-4'>
              <SelectButton type='button' selected={slType === '0'} onClick={() => setFieldValue('signalSlTp.slType', '0')}>
                <Text size='sm'>{options.slTypeOptions[0].label}</Text>
              </SelectButton>
              <SelectButton type='button' selected={slType === '1'} onClick={() => setFieldValue('signalSlTp.slType', '1')}>
                <Text size='sm'>{options.slTypeOptions[1].label}</Text>
              </SelectButton>
            </div>
            {slType === '0' && withDescription && (
              <Text size='sm' className='text-grayscale-400'>
                <Trans
                  i18nKey='forex-experts:fields.signalStopLossTakeProfit.slType.slByPriceDescription'
                  components={{
                    // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                    a: <a className='text-primary-400 visited:text-primary-400' />,
                  }}
                />
              </Text>
            )}
            {slType === '1' && withDescription && (
              <Text size='sm' className='text-grayscale-400'>
                <Trans
                  i18nKey='forex-experts:fields.signalStopLossTakeProfit.slType.slByPipsDescription'
                  components={{
                    // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                    a: <a className='text-primary-400 visited:text-primary-400' />,
                  }}
                  values={{ unitType: values?.unitType }}
                />
              </Text>
            )}
          </div>
          {profitCalculation === '0' ? (
            <>
              <div className='grid col-span-2 grid-cols-2 gap-4'>
                <SelectField
                  name='signalSlTp.riskRatio'
                  label={
                    <IconLabelLayout
                      icon={
                        <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.riskRatio.description')}</Text>}>
                          <InfoTooltipIcon />
                        </InfoTooltip>
                      }
                    >
                      {t('fields.signalStopLossTakeProfit.riskRatio.label')}
                    </IconLabelLayout>
                  }
                  placeholder={t('fields.signalStopLossTakeProfit.riskRatio.placeholder')}
                  options={options.rrRatioOptions}
                  onChange={onRiskRatioChange}
                />
                <SelectField
                  name='signalSlTp.tpRatio'
                  label={
                    <IconLabelLayout
                      icon={
                        <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.tpRatio.description')}</Text>}>
                          <InfoTooltipIcon />
                        </InfoTooltip>
                      }
                    >
                      {t('fields.signalStopLossTakeProfit.tpRatio.altLabel')}
                    </IconLabelLayout>
                  }
                  placeholder={t('fields.signalStopLossTakeProfit.tpRatio.placeholder')}
                  options={options.rrRatioOptions}
                  onChange={onTpRatioChange}
                />
              </div>

              {withDescription && (
                <div className='col-span-2'>
                  {values.signalSlTp.riskRatio < values.signalSlTp.tpRatio && (
                    <Text size='sm' className='text-grayscale-400'>
                      <Trans i18nKey='forex-experts:fields.signalStopLossTakeProfit.riskRatio.explanation.rrRatioLessThanReword' />
                    </Text>
                  )}
                  {values.signalSlTp.riskRatio > values.signalSlTp.tpRatio && (
                    <Text size='sm' className='text-grayscale-400'>
                      <Trans i18nKey='forex-experts:fields.signalStopLossTakeProfit.riskRatio.explanation.rrRatioGreaterThanReword' />
                    </Text>
                  )}
                  {values.signalSlTp.riskRatio === values.signalSlTp.tpRatio && (
                    <Text size='sm' className='text-grayscale-400'>
                      <Trans i18nKey='forex-experts:fields.signalStopLossTakeProfit.riskRatio.explanation.rrRatioIsEqualToReword' />
                    </Text>
                  )}
                </div>
              )}
            </>
          ) : (
            <TextField
              name='signalSlTp.fixedTp'
              type='number'
              label={
                <IconLabelLayout
                  icon={
                    <InfoTooltip content={<Text size='sm'>{t('fields.signalStopLossTakeProfit.smartFixedTp.description')}</Text>}>
                      <InfoTooltipIcon />
                    </InfoTooltip>
                  }
                >
                  {t('fields.signalStopLossTakeProfit.smartFixedTp.label')}
                </IconLabelLayout>
              }
              placeholder={t('fields.signalStopLossTakeProfit.smartFixedTp.placeholder')}
              suffix={values?.unitType}
            />
          )}
        </>
      )}
    </div>
  );
};
