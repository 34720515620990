import clsx from 'clsx';
import { FC, Suspense } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';

import warningImage from '@root/assets/images/cases/warning.webp';
import { notionLinks } from '@root/infra/constants/links';
import { AccountCard } from '@root/modules/accounts/components/account-card';
import { useServiceAccountModal } from '@root/modules/accounts/hooks/use-service-account-modal';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { Button } from '@root/shared/ui/button';
import { Select } from '@root/shared/ui/form';
import { Image } from '@root/shared/ui/image';
import { Modal } from '@root/shared/ui/modal';
import { Text } from '@root/shared/ui/typography/text';
import { Title } from '@root/shared/ui/typography/title';

export const ServiceAccountModal: FC = () => {
  const { t } = useTranslation('forex-accounts');
  const [{ isShown, isLoading, accountChanged, carouselRef, accounts, modalOpen }, { closeModal, selectServiceAccountOption, handleSave }] = useServiceAccountModal();
  const { hideActions } = useGhostLogin();

  if (!accounts?.length) {
    return null;
  }

  if (isShown) {
    return null;
  }

  return (
    <Modal isOpen={modalOpen} onCancel={closeModal} footer={null} className='pt-0! max-w-[800px]!'>
      <div className='flex flex-col items-center'>
        <div className='flex flex-col items-center px-5 mb-4'>
          <div className='w-16 h-16'>
            <Image src={warningImage} width={220} height={220} objectFit='contain' alt='warning' unoptimized={true} />
          </div>
          <Title level={7} className='font-bold text-center'>
            {t('update.selectAccountTypeTitle')}
          </Title>
        </div>

        <Carousel
          ref={carouselRef}
          additionalTransfrom={0}
          arrows={false}
          partialVisible={true}
          containerClass='container overflow-y-visible'
          draggable={false}
          focusOnSelect={false}
          infinite={false}
          itemClass='justify-center flex items-center'
          keyBoardControl
          responsive={{
            desktop: {
              breakpoint: {
                max: 2000,
                min: 400,
              },
              items: 1,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
            },
          }}
          showDots={false}
          swipeable={false}
        >
          {accounts?.map((account) => {
            const options = account?.serviceAccounts.map((item) => ({
              label: item.name,
              value: item.id,
            }));

            const selectedValue = options.find((item) => item.value === account?.serviceAccountId);

            const symbolsInMapping = Object.values(account.symbolsMapping as Record<string, string>);
            const wrongMappingSymbols = symbolsInMapping.filter((symbol) => symbol && !account.symbols.includes(symbol));

            return (
              <div className={clsx('w-full max-w-[800px] mx-5 ')} key={account.id}>
                <Text size='sm'>{t('update.mismatchedSymbolsText')}</Text>
                <ul className='list-disc pl-6 mb-2'>
                  {wrongMappingSymbols.slice(0, 3).map((symbol) => (
                    <li key={symbol}>
                      <Text size='sm'>{symbol}</Text>
                    </li>
                  ))}
                  {wrongMappingSymbols.length > 3 && (
                    <li>
                      <Text size='sm'>{t('update.etc')}...</Text>
                    </li>
                  )}
                </ul>
                <Text size='sm' className='mb-4'>
                  <Trans i18nKey={'forex-accounts:update.mismatchedSymbolsWarning'} components={{ br: <br /> }} />
                </Text>
                <div className='border-solid rounded-sm border border-primary-400 flex flex-col gap-1'>
                  <Suspense fallback={<LoadingIcon />}>
                    <AccountCard data={account} isPreviewCard />
                  </Suspense>
                  <div className='pb-5 px-5'>
                    <Suspense fallback={<LoadingIcon />}>
                      <Text size='sm' className='mb-0'>
                        {t('update.brokerType')}
                      </Text>
                      <Select options={options} name='account' value={selectedValue} onChange={selectServiceAccountOption} />
                    </Suspense>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>

        <Text size='sm' className='w-full px-5 mt-1'>
          <Trans
            i18nKey={'forex-accounts:update.notionLink'}
            components={{ a: <a className='text-primary-400' target='_blank' rel='noreferrer' href={notionLinks.accountType}></a> }}
          />
        </Text>

        <div className='w-full flex justify-center items-center mt-4'>
          <Button loading={isLoading} disabled={hideActions} onClick={handleSave}>
            {accountChanged ? t('update.actions.saveChanges') : t('update.actions.yesCorrect')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
