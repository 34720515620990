import { WS_EVENT_TG_ACCOUNT_LINKED, WS_EVENT_TG_ACCOUNT_UNLINKED } from '@3lgn/shared/dist/constants/ws_event';
import { SagaReturnType, call, fork, race, take } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';
import { Socket } from 'socket.io-client';

import { END, eventChannel } from 'redux-saga';
import { delay, put, select } from 'redux-saga/effects';

import { SocketEventPayloads } from '@root/infra/socket/event-payloads';
import { SocketEventTypes } from '@root/infra/socket/event-types';
import { SocketEvents } from '@root/infra/socket/events';
import { updateAccountStatus } from '@root/infra/socket/helpers';
import { SocketDispatchAction } from '@root/infra/socket/socket-action-creator';
import { createSocket } from '@root/infra/socket/socket-instance';
import { SocketActionTypes } from '@root/infra/socket/socket-types';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { OpenOrdersDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { deleteAccountActionCreator, ordersSlice } from '@root/modules/orders/store/orders.slice';
import { IOrder } from '@root/modules/orders/types/orders';
import { isPersistedSfxOpenOrder } from '@root/modules/orders/utils/orders';
import { QuoteDataMapper } from '@root/modules/quotes/mappers/quote-data.mapper';
import { quotesSlice } from '@root/modules/quotes/store/quotes.slice';
import { authSlice } from '@root/shared-files/modules/auth/store';
import { currentSiteMaintenanceKey } from '@root/shared-files/modules/maintenance/mappers/maintenance.mapper';
import { maintenanceSlice } from '@root/shared-files/modules/maintenance/store/maintenance.slice';
import { notify } from '@root/shared/utils/notification';

interface ICache {
  ordersByTicket: Record<string, IOrder>;
  quotes: Record<string, SocketEventPayloads.QuoteMessage>;
}

export const cache: ICache = {
  quotes: {},
  ordersByTicket: {},
};

type SocketChannel = SagaReturnType<typeof socketChannel>;
type SocketAction =
  | SocketEvents.AccountInfoChange
  | SocketEvents.OpenOrdersListChange
  | SocketEvents.OnQuote
  | SocketEvents.OnMaintenanceModeChange
  | SocketEvents.OnTelegramLinked
  | SocketEvents.OnTelegramUnlinked
  | SocketEvents.AccountOrderClosed
  | SocketEvents.SignalRetryResultSocketMessageAction;

function* handleOpenOrdersUpdate() {
  yield put(ordersSlice.actions.setOrdersData(cache.ordersByTicket));

  yield delay(1000);
  yield call(handleOpenOrdersUpdate);
}

function* handleAccountDeleted() {
  while (true) {
    const action = yield take(deleteAccountActionCreator);

    cache.ordersByTicket = Object.fromEntries(Object.entries(cache.ordersByTicket).filter(([_, entry]) => entry.accountId !== action.payload));
  }
}

function* handleDebouncedQuotesUpdate() {
  const quotes = Object.values(cache.quotes);
  if (quotes.length) {
    yield put(quotesSlice.actions.setQuotesData(quotes.map(QuoteDataMapper.toDomain)));
  }
  yield delay(1000);
  yield call(handleDebouncedQuotesUpdate);
}

function handleAccountConnectionError(payload: SocketEventPayloads.AccountConnectError) {
  notify(
    {
      text: payload.errorMessage,
      type: 'danger',
    },
    { data: { isCustom: true, accountId: payload.accountId, code: 'invalid_account' } },
  );
}

function socketChannel(socket: Socket) {
  return eventChannel<SocketAction>((emit) => {
    const accountInfoChangeHandler = (payload: SocketEventPayloads.AccountInfoChange) => {
      emit({ type: SocketEventTypes.AccountInfoChange, payload: { ...payload, source: 'socket' } });
    };
    const openOrdersListChangeHandler = (payload: SocketEventPayloads.OpenOrders) => {
      if (payload.res.length) {
        const nowUtcIso = new Date().toISOString();

        const newOrdersByTicketMap: Record<string, IOrder> = Object.fromEntries(
          payload.res.map((persistedOrder) => {
            const order = OpenOrdersDtoMapper.toDomain(persistedOrder, {
              currentPrice: undefined,
              accountId: payload.accountId,
              // TODO: For now we can't get currency for external orders here
              // so we have to normalize currency in useGetOpenOrders hook
              currency: isPersistedSfxOpenOrder(persistedOrder) ? persistedOrder.currency : '',
              nowUtcIso,
              orderFromSocket: true,
            });

            return [order.ticket, order];
          }),
        );

        cache.ordersByTicket = { ...cache.ordersByTicket, ...newOrdersByTicketMap };
      }
    };
    const quoteTickChangeHandler = (payload: SocketEventPayloads.QuoteMessage) => {
      cache.quotes[payload.pattern] = payload;
    };
    const maintenanceModeChangeHandler = (payload: SocketEventPayloads.MaintenanceMode) => {
      emit({ type: SocketEventTypes.MaintenanceMode, payload });
    };
    const accountOrderClosedHandlers = (payload: SocketEventPayloads.AccountOrderClosed) => {
      try {
        const { [payload.ticket]: _, ...rest } = cache.ordersByTicket;
        cache.ordersByTicket = rest;
      } catch (e) {
        console.log('e', e);
      }

      emit({ type: SocketEventTypes.AccountOrderClosed, payload });
    };

    const signalRetryResultHandler = (payload: SocketEventPayloads.SignalRetryResultSocketMessage) => {
      emit({ type: SocketEventTypes.signalRetryResult, payload });
    };

    const errorHandler = () => emit(END);
    socket.on(SocketEventTypes.AccountStatusChanged, updateAccountStatus);
    socket.on(SocketEventTypes.AccountConnectError, handleAccountConnectionError);
    socket.on(SocketEventTypes.AccountInfoChange, accountInfoChangeHandler);
    socket.on(SocketEventTypes.OpenOrdersListChange, openOrdersListChangeHandler);
    socket.on(SocketEventTypes.Quote, quoteTickChangeHandler);
    socket.on(SocketEventTypes.MaintenanceMode, maintenanceModeChangeHandler);
    socket.on(SocketEventTypes.AccountOrderClosed, accountOrderClosedHandlers);
    socket.on(SocketEventTypes.signalRetryResult, signalRetryResultHandler);
    socket.on(
      WS_EVENT_TG_ACCOUNT_LINKED,
      debounce((payload) => {
        return emit({
          type: SocketEventTypes.TelegramLinked,
          payload,
        });
      }, 2000),
    );

    socket.on(
      WS_EVENT_TG_ACCOUNT_UNLINKED,
      debounce(
        (payload) =>
          emit({
            type: SocketEventTypes.TelegramUnlinked,
            payload,
          }),
        2000,
      ),
    );
    socket.on('connect_error', errorHandler);

    return () => {
      socket.off(SocketEventTypes.signalRetryResult, signalRetryResultHandler);
      socket.off(SocketEventTypes.AccountStatusChanged, updateAccountStatus);
      socket.off(SocketEventTypes.AccountConnectError, handleAccountConnectionError);
      socket.off(SocketEventTypes.AccountInfoChange, accountInfoChangeHandler);
      socket.off(SocketEventTypes.OpenOrdersListChange, openOrdersListChangeHandler);
      socket.off(SocketEventTypes.Quote, quoteTickChangeHandler);
      socket.off(SocketEventTypes.MaintenanceMode, maintenanceModeChangeHandler);
      socket.off(SocketEventTypes.AccountOrderClosed, accountOrderClosedHandlers);
      socket.off('connect_error', errorHandler);
      socket.off('disconnect', errorHandler);
    };
  });
}

function* socketActionHandler({ type, payload }: SocketAction) {
  switch (type) {
    case SocketEventTypes.AccountInfoChange: {
      const { accountId } = payload;
      const isBalanceFetchedData = yield select(accountsSelector.isBalanceFetchedData);

      if (!isBalanceFetchedData[accountId]) {
        yield put(accountsSlice.actions.setAccountBalanceFetched(accountId));
        yield put(accountsSlice.actions.fetchBalanceFulfilled({ list: [payload], source: 'rest' }));
      } else {
        yield put(accountsSlice.actions.fetchBalanceFulfilled({ list: [payload] }));
      }
      return;
    }
    case SocketEventTypes.MaintenanceMode: {
      if (payload.key === currentSiteMaintenanceKey) {
        yield put(maintenanceSlice.actions.setIsMaintenance(payload.value));
      }
      return;
    }
    case SocketEventTypes.AccountOrderClosed: {
      yield put(ordersSlice.actions.setClosedOrders(payload.ticket));
      return;
    }

    default: {
      yield put({ type, payload });
      return;
    }
  }
}

function* socketChannelHandler(socket: Socket, channel: SocketChannel) {
  while (true) {
    const { socketAction, shouldCancel }: { socketAction?: SocketAction; shouldCancel?: PayloadAction } = yield race({
      socketAction: take(channel),
      shouldCancel: take([authSlice.actions.signedOut]), // add more actions here to cancel socket
    });

    if (socketAction) {
      yield socketActionHandler(socketAction);
    }

    if (shouldCancel) {
      cache.ordersByTicket = {};
      cache.quotes = {};
      channel.close();
      socket.disconnect();
    }
  }
}

function* writeWorker(socket: Socket) {
  while (true) {
    const action: SocketDispatchAction = yield take(SocketActionTypes.EMMIT_EVENT);
    const { eventName, params } = action.payload;
    socket.emit(eventName, params);
  }
}

export function* socketSaga() {
  while (true) {
    yield take(authSlice.actions.fetchProfileFulfilled);

    const socket = createSocket();
    const channel: SocketChannel = yield call(socketChannel, socket);

    yield fork(writeWorker, socket);
    yield fork(socketChannelHandler, socket, channel);
    yield fork(handleOpenOrdersUpdate);
    yield fork(handleDebouncedQuotesUpdate);
    yield fork(handleAccountDeleted);
  }
}
