import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const SheetIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='16' height='20' viewBox='0 0 16 20' fill='none' {...props}>
      <path
        d='M1.5 3.83301C1.5 2.72844 2.39543 1.83301 3.5 1.83301H4.99708C7.3569 1.83301 9.59633 2.87487 11.1164 4.67992L12.6193 6.46463C13.8339 7.907 14.5 9.73205 14.5 11.6177V16.1663C14.5 17.2709 13.6046 18.1663 12.5 18.1663H3.5C2.39543 18.1663 1.5 17.2709 1.5 16.1663V3.83301Z'
        stroke='#FCFCFC'
        strokeWidth='2'
      />
    </svg>
  );
};
