import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { countriesSelector, countriesSlice } from '@root/modules/countries/store';
import { ResetTwoFaForm, ResetTwoFaFormSteps } from '@root/shared-files/modules/auth/types/two-fa';
import { resetTwoFaFormValidation } from '@root/shared-files/modules/auth/validations/two-fa.validation';

export const useResetTwoFaForm = () => {
  const { t } = useTranslation('two-fa');
  const countries = useSelector(countriesSelector.main);
  const dispatch = useDispatch();

  const initialValues = useMemo<ResetTwoFaForm>(() => {
    return {
      id: '',
      step: ResetTwoFaFormSteps.WarningStep,
      accountInfo: {
        registrationEmail: '',
        fullName: '',
        country: '',
        reason: '',
        reasonComment: '',
      },
      activityQuestions: {
        hasCryptoActivities: true,
        orders: [],
        hasForexActivities: true,
        tickets: ['', '', ''],
      },
      supportRequest: {
        step: 1,
        contactEmail: '',
        comment: '',
        identificationDocument: '',
        identificationDocumentUrl: '',
        registrationDate: null,
      },
      confirmationCode: '',
    };
  }, []);

  const schema = useMemo(() => resetTwoFaFormValidation(t), [t]);

  const onSubmit = useCallback(async (values: ResetTwoFaForm, helpers: FormikHelpers<ResetTwoFaForm>) => {}, []);

  useEffect(() => {
    if (!countries.data.length) {
      dispatch(countriesSlice.actions.fetchPending());
    }
  }, [countries.data.length, dispatch]);

  return {
    initialValues,
    schema,
    onSubmit,
  };
};
