import styled from 'styled-components';

import { FC, memo } from 'react';

import { ChartHeaderControls } from '@root/modules/charting-library/components/chart-header-controls';
import { ChartLongTimeLoader } from '@root/modules/charting-library/components/chart-long-time-loader';
import { useChartingLibrary } from '@root/modules/charting-library/hooks/use-charting-library';
import { IChartContainerProps } from '@root/modules/charting-library/types/widget-props';
import { LoadingIcon } from '@root/shared/icons/loading-icon';

const ChartContainer = styled.div`
  border: 1px solid #000;
  position: relative;
`;

const AdvancedChart: FC<IChartContainerProps> = memo(
  (props) => {
    const { chartLoaded, dataLoading } = useChartingLibrary(props);

    return (
      <>
        {!chartLoaded && (
          <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-gray-800/50'>
            <LoadingIcon width={40} height={40} />
          </div>
        )}

        <ChartContainer className='chart-container' id={props.id}>
          {chartLoaded && <ChartHeaderControls />}
        </ChartContainer>

        {chartLoaded && dataLoading && <ChartLongTimeLoader />}
      </>
    );
  },
  (prevProps: IChartContainerProps, nextProps: IChartContainerProps) => {
    return prevProps.symbol === nextProps.symbol;
  },
);

AdvancedChart.displayName = 'TVChartContainer';

export default AdvancedChart;
