import uniq from 'lodash/uniq';

import { useCallback, useMemo } from 'react';
import { keepPreviousData, useQueries } from 'react-query';
import { useSelector } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { useAccountByIdMap } from '@root/modules/orders/hooks/use-account-by-Id-map';
import { OpenOrdersDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { getOpenOrdersService } from '@root/modules/orders/services/get-open-orders.service';
import { ordersSelector } from '@root/modules/orders/store/orders.selector';
import { IOrder } from '@root/modules/orders/types/orders';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';
import { IQuote } from '@root/modules/quotes/types';
import { Id } from '@root/shared/utils/types';

const selectTicket = (order: IOrder) => order.ticket;

export const useGetOpenOrders = ({ enabled, currencies, selectedBroker, mtType }: { enabled?: boolean; currencies?: string[]; selectedBroker?: string; mtType?: string }) => {
  const { data: accounts } = useGetAccounts();
  const quotesData: Record<string, Record<string, IQuote | undefined> | undefined> = useSelector(quotesSelector.quotesData);
  const balancesCurrency = useSelector(accountsSelector.balanceCurrency);
  const closedOrderTickets = useSelector(ordersSelector.closedOrderTickets);
  const socketOrdersByTicket = useSelector(ordersSelector.ordersByTicket);

  const doesAccountIdMatch = useCallback((accountId: string) => selectedBroker === undefined || selectedBroker === 'all' || selectedBroker === accountId, [selectedBroker]);
  const doesCurrencyMatch = useCallback((currency: string) => currencies === undefined || currencies.includes(currency), [currencies]);

  const openOrdersQueries = useQueries({
    queries:
      accounts
        ?.filter(
          (account) =>
            account.status === 'CONNECTED' && (mtType === undefined || account.mtType === mtType) && doesAccountIdMatch(account.id) && doesCurrencyMatch(account.currency),
        )
        .map(({ id, isSignedIn }) => ({
          queryKey: ['fx', id, 'open-orders'],
          queryFn: () => getOpenOrdersService({ accountId: id, currency: balancesCurrency[id] }),
          enabled: enabled && isSignedIn,
          placeholderData: keepPreviousData,
        })) ?? [],
  });

  const loading = openOrdersQueries.some((query) => query.isLoading);

  const accountByIdMap = useAccountByIdMap();

  const openOrders = useMemo(() => {
    if (!enabled) {
      return [];
    }

    const apiOrders: IOrder[] = openOrdersQueries.flatMap((query) => (query.data?.status === 200 ? query.data.payload : []));
    const apiOrderIds = apiOrders.map(selectTicket);
    const apiOrdersByTicket: Record<string, IOrder | undefined> = Object.fromEntries(apiOrders.map((order) => [order.ticket, order]));

    const filteredSocketOrderTicketIds = Object.values(socketOrdersByTicket)
      .filter((order) => doesAccountIdMatch(order.accountId) && doesCurrencyMatch(balancesCurrency[order.accountId]))
      .map(selectTicket);

    return uniq([...apiOrderIds, ...filteredSocketOrderTicketIds])
      .map((ticket) => {
        const apiOrder = apiOrdersByTicket[ticket];
        const socketOrder = socketOrdersByTicket[ticket];

        if (apiOrder !== undefined && socketOrder !== undefined) {
          return apiOrder.updatedAt > socketOrder.updatedAt ? apiOrder : socketOrder;
        }

        return apiOrder ?? socketOrder;
      })
      .filter((order) => !closedOrderTickets.includes(order?.ticket) && (mtType === undefined || order.mtType === mtType))
      .map((order) => {
        if (accountByIdMap === undefined) {
          return order;
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we have for sure account of an open order
        const companyId = accountByIdMap[order.accountId]!.companyId;

        const quote = quotesData?.[companyId]?.[order.symbol];

        if (order.isSocketOrder) {
          return {
            ...OpenOrdersDtoMapper.addCurrentPrice(order, quote),
            currency: balancesCurrency[order.accountId],
          };
        }

        return OpenOrdersDtoMapper.addCurrentPrice(order, quote);
      });
  }, [enabled, openOrdersQueries, socketOrdersByTicket, doesAccountIdMatch, doesCurrencyMatch, balancesCurrency, closedOrderTickets, mtType, quotesData, accountByIdMap]);

  const invalidateOpenOrderQueries = useCallback(() => {
    accounts?.map((account) => {
      queryClient.invalidateQueries({ queryKey: ['fx', account?.id as Id, 'open-orders'], exact: true });
    });
  }, [accounts]);

  return { openOrders, invalidateOpenOrderQueries, loading };
};
