import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { SfxOrderDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { IOrder } from '@root/modules/orders/types/orders';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ExportClosedOrdersResponse = IHttpResponse<200, IOrder[]> | IHttpResponse<400, string>;
export type ExportClosedOrdersQuery = { accounts?: string[]; experts?: string[] };

export const exportClosedOrders = async ({ accounts, experts }: ExportClosedOrdersQuery): Promise<ExportClosedOrdersResponse> => {
  try {
    const params = new URLSearchParams();
    if (accounts && accounts.length) params.append('accounts', accounts.toString());
    if (experts && experts.length) params.append('experts', experts.toString());
    const orders = await fetcher.get(`/users/orders/closed/export?${params.toString()}`, {
      baseURL: import.meta.env.VITE_FX_CORE_HOST,
    });

    const nowUtcIso = new Date().toISOString();

    return {
      status: 200,
      payload: orders.data.map((item) => SfxOrderDtoMapper.toDomain(item, { nowUtcIso, orderFromSocket: false })),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
