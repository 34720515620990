import { SignUpDto } from '@root/shared-files/modules/auth/dtos';

export class SignUpDtoMapper {
  public static toPersistence(dto: SignUpDto) {
    return {
      fullName: dto.fullName,
      email: dto.email,
      password: dto.password,
      country: dto.countryCode,
      captchaToken: dto.captchaToken,
      registrationAccessCode: dto.accessCode,
    };
  }
}
