import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Pnl } from '@root/modules/orders/components/pnl';
import { Profit } from '@root/modules/orders/types/profit';
import { Text } from '@root/shared/ui/typography';

type Props = {
  profitByCurrencyMap: Record<string, Profit>;
  title?: string;
};

export const TableWidgetItem: FC<Props> = ({ title, profitByCurrencyMap }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'trades' });

  return (
    <Text size='sm' className='flex gap-x-4 text-gray-500'>
      {t(title || 'total')}

      <Pnl profitByCurrencyMap={profitByCurrencyMap} />
    </Text>
  );
};
