import { FC, PropsWithChildren, useState } from 'react';
import { useSelector } from 'react-redux';

import { LayoutElements } from '@root/infra/layout/components';
import Disclaimer from '@root/infra/layout/components/disclaimer';
import { DynamicSafeArea } from '@root/infra/layout/components/dynamic-safe-area';
import { SidebarSafeArea } from '@root/infra/layout/components/sidebar-safe-area';
import { Header } from '@root/infra/layout/containers/header';
import { Sidebar } from '@root/infra/layout/containers/sidebar';
import { useIsDesktop } from '@root/infra/user-agent';
import { FeedbackModal } from '@root/modules/user-settings/containers/feedback-modal';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { VerificationReminder } from '@root/shared-files/modules/auth/containers';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

interface Props {
  hideSocial?: boolean;
  showDisclaimer?: boolean;
  isFullScreenMode?: boolean;
}

export const Layout: FC<PropsWithChildren<Props>> = ({ children, showDisclaimer, isFullScreenMode }) => {
  const isDesktop = useIsDesktop();
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const { isGhostLogin } = useGhostLogin();
  const [disclaimerContainer, setDisclaimerContainer] = useState<HTMLElement | null>(null);
  return (
    <LayoutElements.SafeArea isDesktop={isDesktop} menuOpened={menuOpened} isGhostLogin={isGhostLogin} isFullScreenMode={isFullScreenMode}>
      {!isFullScreenMode && <Sidebar />}

      <Header />
      <LayoutElements.Content>
        <DynamicSafeArea element={disclaimerContainer}>
          {(size) => (
            <div style={{ paddingBottom: size?.height }}>
              {/* <div className='mb-4'>
          <WarningBanner />
        </div> */}
              {children}
              {showDisclaimer && (
                <SidebarSafeArea className='fixed inset-x-0 bottom-0'>
                  <div ref={setDisclaimerContainer} className='p-4 m-5 rounded-md bg-grayscale-700 shadow-lg z-2'>
                    <Disclaimer withLink />
                  </div>
                </SidebarSafeArea>
              )}
            </div>
          )}
        </DynamicSafeArea>
      </LayoutElements.Content>
      <FeedbackModal />
      <VerificationReminder />
    </LayoutElements.SafeArea>
  );
};
