import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WarningImg } from '@root/infra/images/warning-img';
import { ConfimationActionsModalLayout } from '@root/infra/layout/components/modal/confimation-actions-modal-layout';
import { InfoContentDetailsModalLayout } from '@root/infra/layout/components/modal/info-content-details-modal-layout';
import { InfoContentModalLayout } from '@root/infra/layout/components/modal/info-content-modal-layout';
import { Modal } from '@root/shared/ui/modal';

export const InsufficientFundsModal: FC<{ open: boolean; onCancel: () => void; onTryAgain: () => void }> = ({ open, onCancel, onTryAgain }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'events.modals' });

  return (
    <Modal isOpen={open} onCancel={onCancel} footer={null} className='pt-0! max-w-[800px]!'>
      <ConfimationActionsModalLayout onCancel={onCancel} cancelButtonTitle={t('cancel')} confirmButtonTitle={t('tryAgain')} onConfirm={onTryAgain}>
        <InfoContentModalLayout leading={<WarningImg />} title={t('insufficientFundsModal.title')}>
          <InfoContentDetailsModalLayout
            description={t('insufficientFundsModal.description')}
            whatThisMeans={t('insufficientFundsModal.whatThisMeans')}
            whatYouCanDo={[t('insufficientFundsModal.whatYouCanDo.1'), t('insufficientFundsModal.whatYouCanDo.2'), t('insufficientFundsModal.whatYouCanDo.3')]}
          />
        </InfoContentModalLayout>
      </ConfimationActionsModalLayout>
    </Modal>
  );
};
