import { toJpeg } from 'html-to-image';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { IOrder } from '@root/modules/orders/types/orders';
import { isSfxOrder } from '@root/modules/orders/utils/orders';
import { getUnitData } from '@root/shared/utils/forex';
import { globalRound } from '@root/shared/utils/number/round';

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const useShareOrder = (order: IOrder) => {
  const orderIsSfx = isSfxOrder(order);
  const elementRef = useRef<HTMLDivElement>(null);
  const [preview, setPreview] = useState<string>();
  const [itemsToShow, setItemsToShow] = useState({
    pnl: false,
    price: false,
    positionSize: false,
    tpAndSl: false,
  });

  const tpLevels = useMemo(() => {
    if (orderIsSfx && order?.dynamicPartialCloseTakeProfits?.length) return order?.dynamicPartialCloseTakeProfits;
    if (orderIsSfx && order?.expertConfiguration?.partialClose?.takeprofits?.length) return order?.expertConfiguration?.partialClose?.takeprofits;
    const unitSize = orderIsSfx && order?.expertConfiguration?.usePips ? getUnitData(order?.symbol).pipSize : 1;
    const amount = globalRound(Math.abs((order?.takeProfit - order?.openPrice) / unitSize), 0);
    if (!amount) return;
    const level = { amount, percent: 100 };
    return [level];
  }, [order, orderIsSfx]);
  const slLevels = useMemo(() => {
    const unitSize = orderIsSfx && order?.expertConfiguration?.usePips ? getUnitData(order?.symbol).pipSize : 1;
    const amount = globalRound(Math.abs((order?.stopLoss - order?.openPrice) / unitSize), 0);
    if (isNaN(amount)) return;
    const level = { amount, percent: 100 };
    return [level];
  }, [order, orderIsSfx]);

  const onDownload = useCallback(async () => {
    if (!preview) return;
    const name = `sfx-share-${order.ticket}.jpeg`;
    const file = dataURLtoFile(preview, name);

    // if (!!navigator?.share && navigator?.canShare({ files: [file] })) {
    //   await navigator.share({ files: [file] });
    // } else if (window.saveAs) {
    //   window.saveAs(preview, name);
    // } else {
    //   saveAs(preview, name);
    // }

    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [order.ticket, preview]);

  const updatePreview = useCallback(async () => {
    if (!elementRef.current) return;
    elementRef.current.style.display = 'block';
    const imgDataUrl = await toJpeg(elementRef.current);
    elementRef.current.style.display = 'none';
    setPreview(imgDataUrl);
  }, [setPreview]);

  const onShowItemsChanged = useCallback(
    async (fieldName: keyof typeof itemsToShow, value: boolean) => {
      setItemsToShow({
        ...itemsToShow,
        [fieldName]: value,
      });
      await updatePreview();
    },
    [itemsToShow, updatePreview],
  );

  useEffect(() => {
    updatePreview();
  }, [updatePreview]);

  useEffect(() => {
    setItemsToShow({
      pnl: !!order?.profit,
      price: !!order?.closePrice,
      positionSize: !!order?.lots,
      tpAndSl: false,
    });
    updatePreview();
  }, [order, updatePreview]);

  return [
    { preview, elementRef, itemsToShow, tpLevels, slLevels },
    { onDownload, updatePreview, onShowItemsChanged },
  ];
};
