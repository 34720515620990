import { useCallback, useEffect, useRef, useState } from 'react';

export type UsePopoverOptions = { trigger: 'click' | 'hover' };

export const usePopover = ({ trigger }: UsePopoverOptions = { trigger: 'hover' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isHoveredRef = useRef<boolean>(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined);

  const onClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onMouseEnter = useCallback(() => {
    isHoveredRef.current = true;
    setIsOpen(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    isHoveredRef.current = false;
    timeoutRef.current = setTimeout(() => {
      if (!isHoveredRef.current) {
        setIsOpen(false);
      }
    }, 250);
  }, []);

  const onContentMouseEnter = useCallback(() => {
    isHoveredRef.current = true;
  }, []);

  const onContentMouseLeave = useCallback(() => {
    isHoveredRef.current = false;
    setIsOpen(false);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const props = {
    isOpen,
    onClickOutside: trigger === 'click' ? onOutsideClick : undefined,
  };
  const childrenProps = {
    onMouseEnter: trigger === 'hover' ? onMouseEnter : undefined,
    onMouseLeave: trigger === 'hover' ? onMouseLeave : undefined,
    onClick: trigger === 'click' ? onClick : undefined,
  };
  const contentProps = {
    onMouseEnter: trigger === 'hover' ? onContentMouseEnter : undefined,
    onMouseLeave: trigger === 'hover' ? onContentMouseLeave : undefined,
  };

  return { props, childrenProps, contentProps };
};
