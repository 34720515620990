import { QueryFunction } from 'react-query';

import { getTwoFaMethods } from '@root/shared-files/modules/auth/services/two-fa/get-two-fa-methods.service';
import { TwoFAMethodData } from '@root/shared-files/modules/auth/types/two-fa';

export type GetTwoFaMethodsQueryKey = ['two-fa-methods'];
export type GetTwoFaMethodsData = TwoFAMethodData[];

export const GET_TWO_FA_METHODS: QueryFunction<GetTwoFaMethodsData, GetTwoFaMethodsQueryKey> = async () => {
  const result = await getTwoFaMethods();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
