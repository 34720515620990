import styled from 'styled-components';

import clsx from 'clsx';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MenuItem } from '@root/infra/layout/components/header.elements';
import { useXl } from '@root/infra/layout/hooks/media';
import { useDropdown } from '@root/infra/layout/hooks/use-dropdown';
import { useSubscriptionTitles } from '@root/infra/layout/hooks/use-subscription-titles';
import { CheckTwoFaBadge } from '@root/shared-files/modules/auth/components/two-fa/check-two-fa-enabled';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { BellIcon } from '@root/shared/icons/bell-icon';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { HidePasswordIcon } from '@root/shared/icons/hide-password-icon';
import { LogoutIcon } from '@root/shared/icons/log-out';
import { ProfileIcon } from '@root/shared/icons/profile-icon';
import { SettingsIcon } from '@root/shared/icons/settings-icon';
import { ShowPasswordIcon } from '@root/shared/icons/show-password-icon';
import { SubscriptionsIcon } from '@root/shared/icons/subscriptions-icon';
import { Text } from '@root/shared/ui/typography';

export type DropdownProps = {
  wrapperClassName?: string;
};

const DropdownContent = styled.div`
  box-shadow: 0px 10px 24px rgba(12, 12, 12, 0.6);
`;

const UserInfo: FC<DropdownProps & { isOpen: boolean }> = ({ wrapperClassName, isOpen }) => {
  const { title, subTitle, icon, className } = useSubscriptionTitles();

  const xl = useXl();

  const Icon = icon;

  return (
    <div className={clsx('text-left text-xs relative flex justify-between items-center gap-4 w-full', wrapperClassName)}>
      <div className='flex items-center gap-x-4 overflow-hidden'>
        <BellIcon className='flex-none text-base hidden xl:block' />
        <span className='flex flex-col overflow-hidden'>
          <span className='font-medium text-sm whitespace-nowrap text-ellipsis'>{title}</span>
          <div className='flex items-center gap-x-2'>
            {subTitle && (
              <div className={clsx('flex items-center gap-x-1', className)}>
                {Icon ? <Icon className='flex-none' /> : null}
                <div className='whitespace-nowrap'>{subTitle}</div>
              </div>
            )}
            <CheckTwoFaBadge />
          </div>
        </span>
      </div>
      {xl && (
        <span className='ml-4 -mt-2'>
          <ChevronIcon className={clsx('transform transition-200', { 'rotate-180': !isOpen })} />
        </span>
      )}
    </div>
  );
};

export const HeaderDropdown: FC<DropdownProps> = (props) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const user = useSelector(authSelector.user);
  const xl = useXl();

  const [{ isBalanceVisible }, { hideBalance, logout }] = useDropdown();

  return (
    <div className='relative' onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      {xl ? (
        <div className='cursor-pointer w-[255px] p-4'>
          <UserInfo {...props} isOpen={isOpen} />
        </div>
      ) : (
        <button className='flex items-center p-2 gap-x-2.5 ltr:-mr-2 rtl:-ml-2 rounded-sm'>
          <ProfileIcon width={24} height={24} />
          <ChevronIcon className={'text-grayscale-300 transform rotate-180'} />
        </button>
      )}
      {isOpen && (
        <DropdownContent className='absolute top-[calc(100%+10px)] xl:top-0 ltr:right-0 rtl:left-0 w-[255px] max-w-[100vw] rounded-sm p-4 bg-gray-800 z-1000'>
          <div className='xl:hidden absolute bottom-[100%] ltr:right-0 rtl:left-0 w-[50%] h-[10px]' />
          <UserInfo {...props} isOpen={isOpen} wrapperClassName='mb-2' />

          <div className='py-2 xl:pl-8 border-t border-b border-gray-700 xl:border-gray-600'>
            <Text size='sm' className='font-medium text-ellipsis max-w-full overflow-hidden'>
              {user?.email as string}
            </Text>
          </div>
          {/* <MenuItem onClick={showNotifications}>
            <BellIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.notifications')}
            </Text>
          </MenuItem> */}
          <div className='flex flex-col -mx-4 xl:mx-0'>
            <MenuItem onClick={hideBalance}>
              {isBalanceVisible ? (
                <>
                  <ShowPasswordIcon />
                  <Text size='sm' className='font-semibold'>
                    {t('header.dropdown.hideBalance')}
                  </Text>
                </>
              ) : (
                <>
                  <HidePasswordIcon />
                  <Text size='sm' className='font-semibold'>
                    {t('header.dropdown.showBalance')}
                  </Text>
                </>
              )}
            </MenuItem>
            <MenuItem link={'/subscriptions'}>
              <SubscriptionsIcon />{' '}
              <Text size='sm' className='font-semibold'>
                {t('header.dropdown.subscriptions')}
              </Text>
            </MenuItem>
            {/* <MenuItem link={'/my-portfolios'}>
            <BagIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.myPortfolios')}
            </Text>
          </MenuItem> */}
            {/* <MenuItem link={'/subscription'}>
            <ScrollIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.subscription')}
            </Text>
          </MenuItem> */}
            {/* <MenuItem onClick={inviteFriends}>
            <TwoPeople />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.inviteFriends')}
            </Text>
          </MenuItem> */}
            {/* <MenuItem link={'/settings'}>
            <SettingsIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.settings')}
            </Text>
          </MenuItem> */}
            {/* <MenuItem link={'/exchanges/connect'}>
            <PaletteIcon />{' '}
            <Text size='sm' className='font-semibold'>
              {t('header.dropdown.api')}
            </Text>
          </MenuItem> */}

            <MenuItem link={'/settings'} className='justify-between'>
              <div className='flex items-center gap-3'>
                <SettingsIcon />{' '}
                <Text size='sm' className='font-semibold'>
                  {t('header.dropdown.settings')}
                </Text>
              </div>
              <CheckTwoFaBadge />
            </MenuItem>

            <MenuItem onClick={logout}>
              <Text size='sm' className='font-semibold'>
                {t('header.dropdown.logout')}
              </Text>
              <LogoutIcon />
            </MenuItem>
          </div>
        </DropdownContent>
      )}
    </div>
  );
};
