import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import successImg from '@root/assets/images/cases/success.webp';
import { CopyIcon } from '@root/shared/icons/copy-icon';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Modal } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';
import { notify } from '@root/shared/utils/notification';

type Props = {
  onClose(): void;
  link: string | null;
  isError?: boolean;
};

export const ShareAssistContent: FC<Props> = ({ onClose, link, isError }) => {
  const { t } = useTranslation('forex-experts');
  const Icon = !isError && !link ? LoadingIcon : () => <CopyIcon />;

  const handleCopy = useCallback(() => {
    copyToClipboard(link);
    notify({
      text: t('sharing.copied'),
      type: 'success',
    });
  }, [link, t]);

  return (
    <Modal isOpen={true} onCancel={onClose} footer={null}>
      <div className='flex flex-col items-center gap-1'>
        <div className='w-[60px] h-[60px]'>
          <Image src={successImg} width={220} height={220} objectFit='contain' alt='success' unoptimized />
        </div>

        <Title level={6} className='font-bold text-center mt-4'>
          {t('sharedTerminal.shareSetup')}
        </Title>

        {link && (
          <Text size='base' className='text-gray-400'>
            {t('sharedTerminal.linkCreated')}
          </Text>
        )}

        <div
          className={clsx('w-full rounded-sm flex items-stretch border border-grayscale-600 overflow-hidden', {
            'cursor-not-allowed opacity-80': isError,
          })}
        >
          <div className='w-full grow bg-grayscale-800 text-grayscale-300 overflow-hidden p-2'>
            <Text size='sm' className='overflow-hidden whitespace-nowrap text-ellipsis'>
              {link}
            </Text>
          </div>

          <button
            disabled={isError}
            className={clsx('shrink-0 w-10 flex justify-center items-center border-l border-grayscale-600 hover:bg-primary-400', {
              'pointer-events-none': isError,
            })}
            onClick={handleCopy}
          >
            <InfoTooltip content={<Text size='sm'>{t('sharedTerminal.copy')}</Text>}>
              <div className='w-[40px] h-[40px] flex items-center justify-center '>
                <Icon />
              </div>
            </InfoTooltip>
          </button>
        </div>

        {isError && (
          <Text size='base' className='text-warning-600'>
            {t('sharedTerminal.noDataToShare')}
          </Text>
        )}

        <div className='w-full flex justify-center items-center mt-4'>
          <Button outlined onClick={onClose}>
            {t('common:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
