// TODO Change return type to T | null to keep it closer to the original localStorage.getItem type
export const getFromLocalStorage = <T>(key: string): T | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);

    if (!item || item === 'undefined') {
      return undefined;
    }

    return JSON.parse(item);
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return undefined;
  }
};

export const setToLocalStorage = (key: string, value: unknown) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.log(error);
  }
};

export const removeFromLocalStorage = (key: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.log(error);
  }
};
