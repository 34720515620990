import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ExternalOrder } from '@root/modules/orders/types/orders';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ModifyExternalPartialCloseServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export type ExternalPartialCloseModifyingDto = Pick<ExternalOrder, 'ticket'> & {
  lots: number;
};

export const modifyExternalPartialCloseService = async (
  accountId: string,
  { lots, ticket }: ExternalPartialCloseModifyingDto,
): Promise<ModifyExternalPartialCloseServiceResponse> => {
  try {
    const data = {
      ticket,
      lots,
    };

    await fetcher.delete(`/accounts/${accountId}/orders/instant`, { data, baseURL: import.meta.env.VITE_FX_CORE_HOST });
    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
