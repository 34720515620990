import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ChartSettings } from '@root/modules/charting-library/contexts/chart-instance.context';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ShareConfigServiceRequest = {
  data: {
    chartData?: ChartSettings;
    orderData: Record<string, unknown>;
  };
};

export type ShareConfigServiceResponse = IHttpResponse<200, { id: string }> | IHttpResponse<400, string>;

export const shareConfigService = async (data: ShareConfigServiceRequest): Promise<ShareConfigServiceResponse> => {
  try {
    const response = await fetcher.post('/shared-config', data, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
