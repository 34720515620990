import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@root/shared/ui/typography';

export const InfoContentDetailsModalLayout: FC<{ description: string; whatThisMeans?: string; whatYouCanDo: string[] }> = ({ description, whatThisMeans, whatYouCanDo }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'events.baseModalInfoView' });

  return (
    <>
      <Text size='sm' className='w-full text-gray-400 whitespace-pre-wrap'>
        {description}
      </Text>
      {whatThisMeans !== undefined && (
        <Text size='sm' className='w-full text-gray-400'>
          <b>{t('whatThisMeans')}</b>
          <br />
          {whatThisMeans}
        </Text>
      )}
      <Text size='sm' className='w-full'>
        <b>{t('whatYouCanDo')}</b>

        <ul className='list-disc pl-6'>
          {whatYouCanDo.map((item, key) => (
            <li key={key}>{item}</li>
          ))}
        </ul>
      </Text>
    </>
  );
};
