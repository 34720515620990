import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TimeInForceCustomInputOption } from '@root/modules/experts/components/time-in-force/time-in-force-custom-input-option';
import { TimeInForcePredefinedValuesFieldset } from '@root/modules/experts/components/time-in-force/time-in-force-predefined-values-fieldset';
import { TimeInForcePredefinedValue } from '@root/modules/experts/dtos/create-expert.dto';
import { OrderModifyingDto, useModifyOrder } from '@root/modules/orders/hooks/use-modify-order';
import { IOrder } from '@root/modules/orders/types/orders';
import { isBuyOrderType, isLimitOrderType, isSellOrderType } from '@root/modules/orders/utils/orders';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { TextField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { Modal } from '@root/shared/ui/modal';
import { Text } from '@root/shared/ui/typography';
import { Title } from '@root/shared/ui/typography/title';
import { getEndOfTheDay, startDateIso, toIsoStringWithoutTimezone } from '@root/shared/utils/date';

export type ModifyModalProps = {
  isOpen: boolean;
  closeModal(): void;
  order: IOrder;
};

const mapExpirationTimeToForceInTime = (value: string) => {
  if (value === '') {
    return '';
  }

  if (value === startDateIso) {
    return TimeInForcePredefinedValue.goodTillCancelled;
  }

  const date = new Date(value);

  if (value === toIsoStringWithoutTimezone(getEndOfTheDay(date))) {
    return TimeInForcePredefinedValue.today;
  }

  return value;
};

const mapTimeInForceToExpirationTime = (value: string) => {
  if (value === TimeInForcePredefinedValue.goodTillCancelled) {
    return startDateIso;
  }

  if (value === TimeInForcePredefinedValue.today) {
    const date = new Date();

    return toIsoStringWithoutTimezone(getEndOfTheDay(date));
  }

  return value;
};

const FormComponent: FC<FormikProps<OrderModifyingDto> & ModifyModalProps> = ({
  isOpen,
  handleSubmit,
  closeModal,
  order,
  values,
  touched,
  errors,
  isValid,
  setFieldValue,
  setFieldTouched,
}) => {
  const { t } = useTranslation('forex-orders');

  const { hideActions } = useGhostLogin();

  return (
    <Modal
      isOpen={isOpen}
      onCancel={closeModal}
      footer={null}
      title={
        <div className='flex w-full gap-x-6 px-2'>
          <Title level={6}>{order.symbol}</Title>
          <Text
            size='sm'
            className={clsx('flex justify-center items-center bg-gray-700 py-0.5 px-3 rounded-full font-medium', {
              'text-danger-500': isSellOrderType(order.type),
              'text-success-400': isBuyOrderType(order.type),
            })}
          >
            {order.type}
          </Text>
        </div>
      }
    >
      <form className='flex flex-col -mt-4 w-full' onSubmit={handleSubmit}>
        <Text size='sm'>{t('CurrentValues')}</Text>

        <div className='flex flex-col sm:flex-row justify-between px-4 py-2 w-full rounded-sm bg-gray-900 mt-1'>
          <div className='flex justify-between w-full'>
            <Text className='text-grayscale-400'>{t('fields.sl.label')}</Text>
            <Text>{order.stopLoss}</Text>
          </div>
          <div className='hidden sm:block w-px h-auto bg-color-divider mx-4 sm:mx-10' />
          <div className='flex justify-between w-full'>
            <Text className='text-grayscale-400'>{t('fields.tp.label')}</Text>
            <Text>{order.takeProfit}</Text>
          </div>
        </div>

        <div className='flex flex-col gap-4 w-full mt-4 md:mt-10'>
          <Text>{t('updateTpSl')}</Text>
          <TextField name='takeprofit' label={t('fields.tp.label')} placeholder={t('fields.tp.placeholder')} />

          <TextField name='stoploss' label={t('fields.sl.label')} placeholder={t('fields.sl.placeholder')} />

          {isLimitOrderType(order.type) && (
            <>
              <TextField
                name='price'
                label={t('manualOrders.pending.fields.price.label', { ns: 'forex-experts' })}
                placeholder={t('manualOrders.pending.fields.price.placeholder', { ns: 'forex-experts' })}
              />

              <TimeInForcePredefinedValuesFieldset
                value={mapExpirationTimeToForceInTime(values.expirationTime)}
                touched={'expirationTime' in touched && (touched.expirationTime ?? false)}
                error={'expirationTime' in errors ? errors.expirationTime : undefined}
                onTouched={() => {
                  setFieldTouched('expirationTime', true);
                }}
                onChange={(value) => {
                  setFieldValue('expirationTime', mapTimeInForceToExpirationTime(value));
                }}
                extendWithCutomOpton={TimeInForceCustomInputOption}
              />
            </>
          )}
        </div>

        <div className='w-full flex justify-between mt-8'>
          <Button type='button' onClick={closeModal} outlined>
            {t('Close', { ns: 'forex-common' })}
          </Button>
          <Button type='submit' variant='primary' disabled={!isValid || hideActions}>
            {t('saveChanges', { ns: 'settings' })}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const ModifyOrderModal: FC<ModifyModalProps> = memo(
  (props) => {
    const { initialValues, onSubmit, validationSchema } = useModifyOrder(props);

    return (
      <Formik<OrderModifyingDto>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}
        enableReinitialize
        component={(formProps) => <FormComponent {...formProps} {...props} />}
      />
    );
  },
  (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen && prevProps.order.ticket === nextProps.order.ticket,
);

ModifyOrderModal.displayName = 'ModifyOrderModal';
