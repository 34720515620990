import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IExpert } from '@root/modules/experts/types/expert';
import { CreateMagicTerminalOrderData } from '@root/modules/magic-terminal/types/magic-terminal-order';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetExpertServiceResponse = IHttpResponse<200, IExpert> | IHttpResponse<400, string>;

export const createMagicTerminalOrder = async (accountId: string, data: CreateMagicTerminalOrderData): Promise<GetExpertServiceResponse> => {
  try {
    const response = await fetcher.post(`/accounts/${accountId}/magic`, data, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
