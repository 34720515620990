import styled from 'styled-components';

import clsx from 'clsx';
import { ComponentProps, FC, PropsWithChildren, ReactNode, forwardRef } from 'react';

const SwitchContainer = styled.div`
  display: flex;
`;

export const Switch: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return <SwitchContainer className={clsx('rtl:flex-row-reverse', className)}>{children}</SwitchContainer>;
};

type TabButtonExtraProps = { active?: boolean };
type TabButtonProps = ComponentProps<'button'>;

const SwitchButton = styled.button<{ $active?: boolean }>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(7)}px`};
  color: ${({ theme }) => theme.gray['100']};
  transition: color 0.2s;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-color: ${({ theme, $active }) => ($active ? 'transparent' : theme.grayscale[500])};
  border-width: 1px;
  border-style: solid;
  z-index: 0;
  outline: none;

  &:before {
    display: block;
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: ${({ theme, $active }) => $active && theme.gradient['6']};
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: -1;
  }

  &:hover {
    color: ${({ theme, $active }) => ($active ? theme.gray[100] : theme.primary[400])};
    border-color: ${({ theme, $active }) => ($active ? 'none' : theme.primary[400])};
  }

  &:disabled {
    color: ${({ theme }) => theme.gray[600]};
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &:before {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:before {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export type TabProps = TabButtonProps & TabButtonExtraProps & { icon?: ReactNode };

export const SwitchItem: FC<TabProps> = forwardRef<HTMLButtonElement, TabProps>(function Tab({ icon, children, type = 'button', active, ...props }, ref) {
  return (
    <SwitchButton className='w-full whitespace-nowrap' ref={ref} type={type} $active={active} {...props}>
      {icon && <span className='mr-2 text-lg'>{icon}</span>}
      {children}
    </SwitchButton>
  );
});
