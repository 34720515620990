import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { PresetsDtoMapper } from '@root/modules/presets/mappers/preset-dto.mapper';
import { IPreset } from '@root/modules/presets/types/preset';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetPresetServiceResponse = IHttpResponse<200, IPreset> | IHttpResponse<400, string>;

export const getPreset = async (id: string): Promise<GetPresetServiceResponse> => {
  try {
    const response = await fetcher.get(`/experts/presets/${id}`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: PresetsDtoMapper.toDomain(response.data),
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
