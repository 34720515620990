import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useChartVisualMode } from '@root/modules/charting-library/contexts/chart-visual-mode-context';
import { ShareAssistContent } from '@root/modules/magic-terminal/containers/share/share-magic-terminal-modal';
import { useCreateSharedTerminalLink } from '@root/modules/magic-terminal/hooks/use-create-shared-terminal-link';
import { FullScreenIcon } from '@root/shared/icons/full-screen';
import { ShareChartIcon } from '@root/shared/icons/share-chart-icon';
import { SmallScreenIcon } from '@root/shared/icons/small-screen';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';

export const ChartHeaderControls = () => {
  const { t } = useTranslation('common');
  const { isFullScreenMode, setIsFullScreenMode } = useChartVisualMode();
  const { link, generateLink } = useCreateSharedTerminalLink();
  const [isError, setIsError] = useState<boolean>(false);
  const [isShareModelOpen, setIsShareModelOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isShareModelOpen) {
      setIsError(false);
      generateLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShareModelOpen]);

  return (
    <div className='flex items-center gap-2 absolute top-0 ltr:right-0 rtl:left-0'>
      <InfoTooltip content={isFullScreenMode ? t('exitFullscreen') : t('fullscreen')}>
        <button type='button' onClick={() => setIsFullScreenMode(!isFullScreenMode)} className='p-2 hover:bg-grayscale-700 rounded-sm'>
          {isFullScreenMode ? <SmallScreenIcon /> : <FullScreenIcon />}
        </button>
      </InfoTooltip>

      <InfoTooltip content={t('shareChartDrawing')}>
        <button type='button' onClick={() => setIsShareModelOpen(true)} className='p-2 hover:bg-grayscale-700 rounded-sm'>
          <ShareChartIcon />
        </button>
      </InfoTooltip>

      {isShareModelOpen && <ShareAssistContent isError={isError} link={link} onClose={() => setIsShareModelOpen(false)} />}
    </div>
  );
};
