import { FxOrderSource } from '@3lgn/shared/dist/constants/fx';

import { MainOrderType } from '@root/modules/orders/enums/orders';
import { CurrentPrice, ExternalOrder, IOrder, PersistedExternalOpenOrder, PersistedOpenOrder, PersistedSfxOrder, SfxOrder } from '@root/modules/orders/types/orders';
import { Profit } from '@root/modules/orders/types/profit';
import { hasIntersectRange } from '@root/shared/utils/number-utils';
import { globalRound } from '@root/shared/utils/number/round';

enum OrderType {
  buy = 'Buy',
  sell = 'Sell',
  buyLimit = 'Buy Limit',
  sellLimit = 'Sell Limit',
  buyStop = 'Buy Stop',
  sellStop = 'Sell Stop',
}

export const isSellOrderType = (type: string) => [OrderType.sell, OrderType.sellLimit, OrderType.sellStop].includes(type as OrderType);
export const isBuyOrderType = (type: string) => [OrderType.buy, OrderType.buyLimit, OrderType.buyStop].includes(type as OrderType);
export const isMarketOrderType = (type: string) => [OrderType.buy, OrderType.sell].includes(type as OrderType);
export const isLimitOrderType = (type: string) => [OrderType.buyLimit, OrderType.sellLimit, OrderType.buyStop, OrderType.sellStop].includes(type as OrderType);

export const isOrderSlOrTpReached = (order: IOrder, currentPrice: CurrentPrice) => {
  if (!isMarketOrderType(order.type)) {
    return false;
  }

  if (order.takeProfit < order.stopLoss) {
    return hasIntersectRange([order.takeProfit, order.stopLoss] as const, currentPrice.ask);
  }

  return hasIntersectRange([order.stopLoss, order.takeProfit] as const, currentPrice.bid);
};

export const gerOrderType = (type): string => {
  switch (type) {
    case MainOrderType.BUY:
      return OrderType.buy;
    case MainOrderType.SELL:
      return OrderType.sell;
    case MainOrderType.BUY_LIMIT:
      return OrderType.buyLimit;
    case MainOrderType.SELL_LIMIT:
      return OrderType.sellLimit;
    case MainOrderType.BUY_STOP:
      return OrderType.buyStop;
    case MainOrderType.SELL_STOP:
      return OrderType.sellStop;
    default:
      return '-';
  }
};

export const isMagicPersistedOrder = (order: PersistedSfxOrder) => order.signalId === 'magic' || order.comment === 'magic';

export const isMagicOrder = (order: SfxOrder) => order.magic;

export const displayCurrentPrice = (currentPrice: number | null) => {
  if (currentPrice === null) {
    return '-';
  }

  return globalRound(currentPrice, 6);
};

export const unknownCurrency = 'XXX';

export const isNotUnknownCurrencyEntry = ([currency]: [currency: string, profit: Profit]) => currency !== unknownCurrency;

export const isPersistedSfxOpenOrder = (order: PersistedOpenOrder): order is PersistedSfxOrder => {
  return order.source === FxOrderSource.SFX;
};

export const isPersistedExternalOpenOrder = (order: PersistedOpenOrder): order is PersistedExternalOpenOrder => {
  return order.source === FxOrderSource.EXTERNAL;
};

export const isSfxOrder = (order: IOrder): order is SfxOrder => {
  return order.source === FxOrderSource.SFX;
};

export const isExternalOrder = (order: IOrder): order is ExternalOrder => {
  return order.source === FxOrderSource.EXTERNAL;
};
