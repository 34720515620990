import styled from 'styled-components';

import clsx from 'clsx';
import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, forwardRef } from 'react';

import { useElementSize } from '@root/infra/layout/hooks/use-element-size';
import { useRtl } from '@root/shared/hooks/use-rtl';

export interface InputProps extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'prefix' | 'size'> {
  prefix?: ReactNode;
  suffix?: ReactNode;
  prefixClassName?: string;
  suffixClassName?: string;
  variant?: 'primary' | 'danger';
  size?: 'base' | 'small';
  overrideReadOnly?: boolean;
}

const StyledInputWrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, variant, prefix, size, suffix, prefixClassName, suffixClassName, overrideReadOnly, style, ...props },
  ref,
) {
  const rtl = useRtl();
  const [setSuffixContainer, suffixSize] = useElementSize<HTMLSpanElement>();
  const sufixIndentFromEdge = 20;

  return (
    <StyledInputWrapper className='relative z-0'>
      {prefix && (
        <span
          className={clsx(
            'prefix absolute flex ltr:left-5 rtl:right-5 top-1/2 transform -translate-y-1/2 -z-1',
            {
              'text-danger-500': variant === 'danger',
              'text-primary-500': variant === 'primary',
            },
            prefixClassName,
          )}
        >
          {prefix}
        </span>
      )}
      <input
        className={clsx(
          'custom-input-class w-full bg-white/10 rounded-sm text-sm transition outline-hidden border z-1',
          size === 'small' ? 'h-8 py-[7px]' : 'h-10 py-3',
          prefix ? 'ltr:pl-12 rtl:pr-12' : 'ltr:pl-4 rtl:pr-4',
          {
            'border-transparent active:border-white focus:border-white': !variant,
            'border-danger-500': variant === 'danger',
            'border-primary-500': variant === 'primary',
          },
          className,
        )}
        {...props}
        style={{
          [rtl ? 'paddingLeft' : 'paddingRight']: `${suffix ? (suffixSize?.width ?? 20) + sufixIndentFromEdge + 8 : 16}px`,
          ...style,
        }}
        readOnly={overrideReadOnly}
        type={props.type || 'text'}
        ref={ref}
      />
      {suffix && (
        <span
          ref={setSuffixContainer}
          className={clsx(
            'suffix absolute flex ltr:right-5 rtl:left-5 top-1/2 transform -translate-y-1/2 -z-1',
            {
              'text-danger-500': variant === 'danger',
              'text-primary-500': variant === 'primary',
            },
            suffixClassName,
          )}
        >
          {suffix}
        </span>
      )}
    </StyledInputWrapper>
  );
});
