import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { TwoFaErrorResponse } from '@root/shared-files/modules/auth/types/two-fa-field-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type VerifyResetTwoFaRequestResponse = IHttpResponse<200, { success: boolean; errorCode: string }> | IHttpResponse<400, string> | IHttpResponse<418, TwoFaErrorResponse>;

export const verifyResetTwoFaRequest = async (id: string, data: { confirmationCode: string }): Promise<VerifyResetTwoFaRequestResponse> => {
  try {
    const response = await fetcher.post(`/auth/2fa/reset-requests/${id}/verify`, data);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      if (typeof error.response.data.message === 'object') {
        return {
          status: 418,
          payload: error.response.data.message,
        };
      }

      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
