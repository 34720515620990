import isObject from 'lodash/isObject';

export type ErrorLike = {
  name: string;
  message: string;
};

export const hasMessage = (object: object): object is { message: string } => 'message' in object && typeof object['message'] === 'string';

export const isErrorLike = (maybeErrorLike: unknown): maybeErrorLike is ErrorLike =>
  isObject(maybeErrorLike) && hasMessage(maybeErrorLike) && 'name' in maybeErrorLike && typeof maybeErrorLike.name === 'string';
