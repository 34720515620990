import { useLayoutEffect } from 'react';

import { useEvent } from '@root/shared/hooks/use-event';

export const useResizeObserver = (element: HTMLElement | null, callback: ResizeObserverCallback) => {
  const triggerCallback: ResizeObserverCallback = useEvent(callback);

  useLayoutEffect(() => {
    if (element === null) {
      return;
    }

    const resizeObserver = new ResizeObserver(triggerCallback);

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, [element, triggerCallback]);
};
