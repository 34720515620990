import { FxOrderSource } from '@3lgn/shared/dist/constants/fx';

import { PartialTakeProfitMapper } from '@root/modules/experts/mappers/expert-dto.mapper';
import { selectCurrentPrice } from '@root/modules/orders/hooks/use-order-current-price';
import {
  BaseOrder,
  CurrentPrice,
  ExternalOrder,
  IOrder,
  PersistedBaseOrder,
  PersistedExternalOpenOrder,
  PersistedOpenOrder,
  PersistedSfxOrder,
  SfxOrder,
} from '@root/modules/orders/types/orders';
import { gerOrderType, isMagicPersistedOrder, isPersistedExternalOpenOrder, isSellOrderType, isSfxOrder } from '@root/modules/orders/utils/orders';
import { IQuote } from '@root/modules/quotes/types';
import { nullDateIso, startDateIso } from '@root/shared/utils/date';
import { globalRound } from '@root/shared/utils/number/round';

const calculateUnitsByBaseUnit = (unit: number, order: BaseOrder) => {
  let units = 0;

  if (!order.symbol) {
    return 0;
  }

  // units for history orders
  if (order.closePrice && order.openPrice && order.closeTime) {
    units = (order.closePrice - order.openPrice) / unit;
  }

  // units for open orders
  if (order.currentPrice !== null && order.openPrice && !order.closeTime && order.profit) {
    units = ((selectCurrentPrice(order) ?? 0) - order.openPrice) / unit;
  }

  return isSellOrderType(order.type) ? units * -1 : units;
};

abstract class BaseOrderDtoMapper {
  public static toDomain<T extends PersistedBaseOrder>(
    data: T,
    {
      currentPrice,
      accountId,
      currency,
      updatedAt,
      orderFromSocket,
    }: { currentPrice?: CurrentPrice; accountId: string; currency: string; updatedAt: string; orderFromSocket: boolean },
  ): BaseOrder {
    const type = gerOrderType(Number(data.type));

    return {
      ticket: data.ticket,
      openTime: data.openTime,
      closeTime: data.closeTime?.includes('1970') ? 'null' : data.closeTime,
      type,
      accountId,
      currency,
      updatedAt,
      lots: data.lots || 0,
      symbol: data.symbol,
      openPrice: data.openPrice,
      stopLoss: data.stopLoss || 0,
      takeProfit: data.takeProfit || 0,
      closePrice: data.closePrice || 0,
      magicNumber: data.magicNumber || 0,
      swap: data.swap || 0,
      commission: data.commission || 0,
      comment: data.comment,
      profit: data.profit || 0,
      rateOpen: globalRound(data.rateOpen || 0, 5),
      rateClose: globalRound(data.rateClose || 0, 5),
      rateMargin: globalRound(data.rateMargin || 0, 5),
      volume: data.volume,
      expirationTime: [nullDateIso, null, undefined].includes(data?.expirationTime) ? startDateIso : data.expirationTime,
      mtType: data.mtType,

      // synthetic propertyies
      isSocketOrder: orderFromSocket,
      currentPrice: currentPrice !== undefined ? { ask: globalRound(currentPrice.ask, 6), bid: globalRound(currentPrice.bid, 6) } : null,
      calculatedUnits: 0,
      takeProfitUnits: 0,
      stopLossUnits: 0,
    };
  }
}

export abstract class ExternalOpenOrderDtoMapper {
  public static toDomain(
    data: PersistedExternalOpenOrder,
    {
      currentPrice,
      accountId,
      currency,
      nowUtcIso,
      orderFromSocket,
    }: { currentPrice?: CurrentPrice; accountId: string; currency: string; nowUtcIso: string; orderFromSocket: boolean },
  ): ExternalOrder {
    const baseOrder = BaseOrderDtoMapper.toDomain(data, { currentPrice, accountId, currency, updatedAt: nowUtcIso, orderFromSocket });

    return {
      ...baseOrder,
      calculatedUnits: calculateUnitsByBaseUnit(1, baseOrder),
      source: FxOrderSource.EXTERNAL,
    };
  }
}

const getSfxOrderUnit = (usePips: boolean, symbol: string, symbolBeforeMapping?: string) => {
  if (!usePips) {
    return 1;
  }

  const isJPY = symbol.includes('JPY');
  const isXAU = symbol.includes('XAU') || symbol.includes('GOLD_USD') || symbolBeforeMapping?.includes('XAU');

  return isJPY ? 0.01 : isXAU ? 0.1 : 0.0001;
};

export abstract class SfxOrderDtoMapper {
  public static toDomain(
    data: PersistedSfxOrder,
    { currentPrice, nowUtcIso, orderFromSocket }: { currentPrice?: CurrentPrice; nowUtcIso: string; orderFromSocket: boolean },
  ): SfxOrder {
    const baseOrder = BaseOrderDtoMapper.toDomain(data, { currentPrice, accountId: data.accountId, currency: data.currency, updatedAt: nowUtcIso, orderFromSocket });

    const unit = getSfxOrderUnit(data.expertConfiguration?.usePips ?? false, data.symbol, data.additionalDetails?.symbolBeforeMapping);

    baseOrder.calculatedUnits = calculateUnitsByBaseUnit(unit, baseOrder);

    return {
      ...baseOrder,
      magic: isMagicPersistedOrder(data),
      source: FxOrderSource.SFX,
      id: data.id,
      expertId: data.expertId,
      openedBy: data.openedBy,
      signalId: data.signalId,
      providerId: data.providerId,
      brokerCompanyName: data?.brokerCompanyName,
      additionalDetails: data?.additionalDetails,
      dynamicPartialCloseTakeProfits: data.dynamicPartialCloseTakeProfits?.map(PartialTakeProfitMapper.toDomain) || [],
      expertConfiguration: data?.expertConfiguration,
      createdAt: data.createdAt,
      deletedAt: data.deletedAt,
      status: data.status,
      childOrders: data.childOrders?.map((childOrder) => SfxOrderDtoMapper.toDomain(childOrder, { currentPrice, nowUtcIso, orderFromSocket })) ?? [],
    };
  }
}

type DomainOrder<T extends PersistedOpenOrder> = T extends PersistedSfxOrder ? SfxOrder : T extends PersistedExternalOpenOrder ? ExternalOrder : IOrder;

export class OpenOrdersDtoMapper {
  public static addCurrentPrice(order: IOrder, currentPrice?: IQuote): IOrder {
    const unit = isSfxOrder(order) ? getSfxOrderUnit(order.expertConfiguration?.usePips ?? false, order.symbol, order.additionalDetails?.symbolBeforeMapping) : 1;

    const units = calculateUnitsByBaseUnit(unit, order);

    return {
      ...order,
      calculatedUnits: units,
      currentPrice: currentPrice ? { ask: globalRound(currentPrice.ask, 6), bid: globalRound(currentPrice.bid, 6) } : null,
    };
  }

  public static toDomain(
    data: PersistedOpenOrder,
    {
      currentPrice,
      accountId,
      currency,
      nowUtcIso,
      orderFromSocket = false,
    }: { currentPrice?: CurrentPrice; accountId: string; currency: string; nowUtcIso: string; orderFromSocket?: boolean },
  ): DomainOrder<PersistedOpenOrder> {
    if (isPersistedExternalOpenOrder(data)) {
      return ExternalOpenOrderDtoMapper.toDomain(data, { currentPrice, accountId, currency, nowUtcIso, orderFromSocket });
    }

    return SfxOrderDtoMapper.toDomain(data, { currentPrice, nowUtcIso, orderFromSocket });
  }
}
