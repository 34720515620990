import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { ProviderFullStats } from '@root/modules/marketplace/mappers/get-marketplace-trade-idea-provider-statistics.mapper';
import { formatProviderStatisticsNumber, formatProviderStatisticsValue } from '@root/modules/marketplace/marketplace-utils';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { IconLabelLayout } from '@root/shared/ui/form';
import { PureInfoTooltip } from '@root/shared/ui/info-tooltip';
import { formatMoney } from '@root/shared/ui/money';
import { Text } from '@root/shared/ui/typography';
import { toDaysAndHours } from '@root/shared/utils/date';

dayjs.extend(utc);

export const getColorByStatisticValue = (value: number) => {
  if (value > 0) {
    return 'text-success-400';
  }

  if (value < 0) {
    return 'text-danger-500';
  }
};

export const ProviderMainStatistics: FC<{ tradeIdeaProviderStatistics: ProviderFullStats | undefined }> = ({ tradeIdeaProviderStatistics }) => {
  const { t } = useTranslation('marketplace-trade-idea-provider-details', { keyPrefix: 'statistics' });

  const formatHours = (hours: number | undefined | null) => {
    if (hours === undefined || hours === null) {
      return '-';
    }

    if (hours === 0) {
      return '0h';
    }

    const { days, hours: remainingHours } = toDaysAndHours(hours);

    return `${days === 0 ? '' : `${days}d `}${remainingHours === 0 ? '' : `${formatProviderStatisticsNumber(remainingHours)}h`}`;
  };

  const statistics = [
    {
      label: t('winRate.label'),
      description: t('winRate.description'),
      value: formatProviderStatisticsValue(tradeIdeaProviderStatistics?.providerStats.winRate, { postfix: '%' }),
      className: 'text-success-400',
    },
    {
      label: t('profitFactor.label'),
      description: t('profitFactor.description'),
      value: formatProviderStatisticsValue(tradeIdeaProviderStatistics?.providerStats.profitFactor),
      className: 'text-success-400',
    },
    {
      label: t('expectancy.label'),
      description: t('expectancy.description'),
      value:
        tradeIdeaProviderStatistics?.providerStats.expectancy === undefined || tradeIdeaProviderStatistics?.providerStats.expectancy === null
          ? '-'
          : formatMoney(formatProviderStatisticsNumber(tradeIdeaProviderStatistics?.providerStats.expectancy), 'USD'),
      className: getColorByStatisticValue(tradeIdeaProviderStatistics?.providerStats.expectancy ?? 0),
    },
    {
      label: t('sharpRatio.label'),
      description: t('sharpRatio.description'),
      value: formatProviderStatisticsValue(tradeIdeaProviderStatistics?.providerStats.sharpRatio),
      className: getColorByStatisticValue(tradeIdeaProviderStatistics?.providerStats.sharpRatio ?? 0),
    },
    {
      label: t('riskRewardRatio.label'),
      description: t('riskRewardRatio.description'),
      value: formatProviderStatisticsValue(tradeIdeaProviderStatistics?.providerStats.riskReward),
      className: 'text-success-400',
    },
    {
      label: t('avgTp.label'),
      description: t('avgTp.description'),
      value: formatProviderStatisticsValue(tradeIdeaProviderStatistics?.providerStats.averageTp),
      className: 'text-success-400',
    },

    {
      label: t('avgSl.label'),
      description: t('avgSl.description'),
      value: formatProviderStatisticsValue(tradeIdeaProviderStatistics?.providerStats.averageSl),
      className: 'text-danger-500',
    },
    {
      label: t('longTradeIdeas.label'),
      description: t('longTradeIdeas.description'),
      value: tradeIdeaProviderStatistics?.providerStats.longTradeIdeas ?? '-',
      className: 'text-success-400',
    },
    {
      label: t('shortTradeIdeas.label'),
      description: t('shortTradeIdeas.description'),
      value: tradeIdeaProviderStatistics?.providerStats.shortTradeIdeas ?? '-',
      fullWidthOnMobile: true,
      className: 'text-success-400',
    },
    {
      label: t('provAvgDur.label'),
      description: t('provAvgDur.description'),
      value: formatHours(tradeIdeaProviderStatistics?.providerStats.averageTradeDuration),
      fullWidthOnMobile: true,
    },
    {
      label: t('subAvgDur.label'),
      description: t('subAvgDur.description'),
      value: formatHours(tradeIdeaProviderStatistics?.subscribersStats.averageTradeDuration),
      fullWidthOnMobile: true,
    },
    {
      label: t('avgSubLotSize.label'),
      description: t('avgSubLotSize.description'),
      value: formatProviderStatisticsValue(tradeIdeaProviderStatistics?.subscribersStats.averageLotSize),
      fullWidthOnMobile: true,
    },
  ];

  const loading = tradeIdeaProviderStatistics === undefined;

  return (
    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2.5'>
      {statistics.map(({ value, label, description, fullWidthOnMobile, className }, index) => (
        <div
          key={index}
          className={clsx(
            'flex flex-col items-center justify-center gap-y-1 px-4 sm:px-5 py-2.5 bg-gray-800 rounded-sm',
            fullWidthOnMobile ? 'col-span-2 sm:col-span-1' : 'col-span-1',
          )}
        >
          {loading ? (
            <Skeleton containerClassName='block w-10' className='h-6' />
          ) : (
            <Text size='base' bold className={className}>
              {value}
            </Text>
          )}

          <Text size='sm' className='text-grayscale-500'>
            <IconLabelLayout
              className='block! text-center'
              icon={
                <PureInfoTooltip content={description}>
                  <InfoTooltipIcon className='inline-block' />
                </PureInfoTooltip>
              }
            >
              {label}
            </IconLabelLayout>
          </Text>
        </div>
      ))}
    </div>
  );
};
