import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const CheckRoundIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.99998 1.65806C3.88431 1.65806 1.35856 4.18381 1.35856 7.29948C1.35856 10.4151 3.88431 12.9409 6.99998 12.9409C10.1156 12.9409 12.6414 10.4151 12.6414 7.29948C12.6414 4.18381 10.1156 1.65806 6.99998 1.65806ZM0.333313 7.29948C0.333313 3.61758 3.31808 0.632812 6.99998 0.632812C10.6819 0.632812 13.6666 3.61758 13.6666 7.29948C13.6666 10.9814 10.6819 13.9661 6.99998 13.9661C3.31808 13.9661 0.333313 10.9814 0.333313 7.29948Z'
        fill='currentColor'
      />
      <path
        d='M9.74615 5.29858C9.94634 5.49877 9.94634 5.82335 9.74615 6.02354L6.48623 9.28345C6.46581 9.3164 6.4413 9.34753 6.4127 9.37613C6.21251 9.57632 5.88794 9.57632 5.68775 9.37613L4.20599 7.89437C4.0058 7.69418 4.0058 7.3696 4.20599 7.16941C4.40618 6.96922 4.73076 6.96922 4.93095 7.16941L6.04065 8.27912L9.02119 5.29858C9.22138 5.09839 9.54595 5.09839 9.74615 5.29858Z'
        fill='currentColor'
      />
    </svg>
  );
};
