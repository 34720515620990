import { useCallback, useState } from 'react';

import { getFromLocalStorage, setToLocalStorage } from '@root/shared/utils/local-storage';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    const value = getFromLocalStorage<T>(key);

    if (value !== undefined) {
      return value;
    }

    return initialValue;
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setToLocalStorage(key, valueToStore);
      // Save state
      setStoredValue(valueToStore);
    },
    [key, storedValue],
  );

  return [storedValue, setValue] as const;
};
