import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

export const getObjectDiff = (obj1, obj2) => {
  return transform(obj1, (result, value, key) => {
    if (!isEqual(value, obj2[key])) {
      (result as Record<symbol, any>)[key] = isObject(value) && isObject(obj2[key]) ? getObjectDiff(value, obj2[key]) : value;
    }
  });
};
