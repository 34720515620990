import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { authSelector, authSlice } from '@root/shared-files/modules/auth/store';
import { SHOULD_REFRESH_TOKEN, SUBSCRIPTION_PURCHASE_URL } from '@root/shared-files/modules/shared/constants/local-storage-keys';
import { useGetActiveSubscriptions } from '@root/shared-files/modules/subscriptions/hooks/use-get-active-subscriptions';
import { useGetSubscriptionProducts } from '@root/shared-files/modules/subscriptions/hooks/use-get-subscription-products';
import { cancelSubscription } from '@root/shared-files/modules/subscriptions/services/cancel-subscription.service';
import { getPaymentUrl } from '@root/shared-files/modules/subscriptions/services/get-payment-url.service';
import { purchaseSubscriptionPlan } from '@root/shared-files/modules/subscriptions/services/purchase-subscription-plan.service';
import { reactivateSubscription } from '@root/shared-files/modules/subscriptions/services/reactivate-subscription.service';
import { SubscriptionPeriod } from '@root/shared-files/modules/subscriptions/types/subscription-period';
import { SubscriptionProductType } from '@root/shared-files/modules/subscriptions/types/subscription-product-type';
import { SubscriptionInfo, SubscriptionProduct } from '@root/shared-files/modules/subscriptions/types/subscription-products';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { notify } from '@root/shared/utils/notification';

export const useSubscriptionProducts = () => {
  const [period, setPeriod] = useState<SubscriptionPeriod>(SubscriptionPeriod.Yearly);
  const user = useSelector(authSelector.user);
  const { data: activeSubscriptions, refetch } = useGetActiveSubscriptions();
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionProduct | null>(null);
  const [retryPaymentLoading, setRetryPaymentLoading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const { data, isLoading } = useGetSubscriptionProducts();
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);
  const [reactivateLoading, setReactivateLoading] = useState<boolean>(false);
  const userSubscriptionInfo = useSelector(authSelector.getSubscriptionInfo);
  const [, setSubscriptionPurchaseUrl] = useLocalStorage<string>(SUBSCRIPTION_PURCHASE_URL, '');
  const { t } = useTranslation('subscriptions');

  const isUserFromShop = user?.sourceUser === 'shop';

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');
  const id = searchParams.get('id');

  const subscriptions = useMemo(() => {
    if (data) {
      return {
        [SubscriptionProductType.Trial]: data.find((item) => item.type === SubscriptionProductType.Trial),
        [SubscriptionProductType.Basic]: data.find((item) => item.type === SubscriptionProductType.Basic),
        [SubscriptionProductType.Advanced]: data.find((item) => item.type === SubscriptionProductType.Advanced),
        // [SubscriptionProductType.Pro]: data.find((item) => item.type === SubscriptionProductType.Pro),
      };
    }
  }, [data]);

  const { currentSubscription, activeSubscription } = useMemo(() => {
    if (subscriptions && activeSubscriptions) {
      let activeSubscription: SubscriptionInfo | null = null;
      let subscription: SubscriptionProduct | null = null;
      activeSubscriptions.forEach((item) => {
        Object.values(subscriptions)
          .filter(Boolean)
          .forEach(
            (sb) =>
              (sb as SubscriptionProduct)?.plans.forEach((plan) => {
                if (plan.id === item.planId && !activeSubscription && !subscription) {
                  activeSubscription = item;
                  subscription = sb as SubscriptionProduct;
                }
              }),
          );
      });

      return {
        currentSubscription: (subscription || null) as SubscriptionProduct | null,
        activeSubscription: activeSubscription as SubscriptionInfo | null,
      };
    }

    return {
      currentSubscription: null,
      activeSubscription: null,
    };
  }, [subscriptions, activeSubscriptions]);

  const isSubscribedFromShop = useMemo(() => {
    if (
      !activeSubscriptions?.length &&
      userSubscriptionInfo?.roles?.filter(
        (role) => role?.toLowerCase()?.includes('forex') && !role?.toLowerCase()?.includes('post subscription') && !role?.toLowerCase()?.includes('provider'),
      )?.length
    ) {
      return true;
    }
    return false;
  }, [activeSubscriptions, userSubscriptionInfo]);

  const { canDowngrade, canUpgrade } = useMemo(() => {
    const canDowngrade = {
      [SubscriptionProductType.Trial]: false,
      [SubscriptionProductType.Basic]: false,
      [SubscriptionProductType.Advanced]: false,
      [SubscriptionProductType.Pro]: false,
    };

    const canUpgrade = {
      [SubscriptionProductType.Trial]: false,
      [SubscriptionProductType.Basic]: false,
      [SubscriptionProductType.Advanced]: false,
      [SubscriptionProductType.Pro]: false,
    };

    if (!currentSubscription) {
      return { canDowngrade, canUpgrade };
    }

    if (currentSubscription?.type === SubscriptionProductType.Pro) {
      canDowngrade[SubscriptionProductType.Advanced] = true;
      canDowngrade[SubscriptionProductType.Basic] = true;
    }

    if (currentSubscription?.type === SubscriptionProductType.Advanced) {
      canDowngrade[SubscriptionProductType.Basic] = true;
    }

    if (currentSubscription?.type === SubscriptionProductType.Basic) {
      canUpgrade[SubscriptionProductType.Advanced] = true;
      canUpgrade[SubscriptionProductType.Pro] = true;
    }

    if (currentSubscription?.type === SubscriptionProductType.Advanced) {
      canUpgrade[SubscriptionProductType.Pro] = true;
    }

    return { canDowngrade, canUpgrade };
  }, [currentSubscription]);

  const handleSubscribeClick = useCallback((subscription: SubscriptionProduct) => {
    setSelectedSubscription(subscription);
    setConfirmationOpen(true);
  }, []);

  const handleUpgrade = useCallback(
    (subscription: SubscriptionProduct) => {
      if (isUserFromShop) {
        return notify({
          type: 'info',
          title: '',
          text: t('upgradeShopUsersMessage'),
        });
      }

      handleSubscribeClick(subscription);
    },
    [handleSubscribeClick, isUserFromShop, t],
  );

  const handleCancelSubscription = useCallback(async () => {
    setCancelLoading(true);
    if (activeSubscription?.id) {
      const response = await cancelSubscription(activeSubscription.id);
      if (response.status === 200) {
        notify({
          title: t('actions.cancelSuccess'),
          type: 'success',
        });
        const timeout = setTimeout(() => {
          refetch();
          dispatch(authSlice.actions.refreshSession());
          clearTimeout(timeout);
        }, 3000);
      } else {
        notify({
          title: response.payload,
          type: 'danger',
        });
      }
    }
    setCancelOpen(false);
    setCancelLoading(false);
  }, [activeSubscription?.id, dispatch, refetch, t]);

  const handleReactivateSubscription = useCallback(async () => {
    setReactivateLoading(true);
    if (activeSubscription?.id) {
      const response = await reactivateSubscription(activeSubscription.id);
      if (response.status === 200) {
        notify({
          title: t('actions.renewSuccess'),
          type: 'success',
        });
        const timeout = setTimeout(() => {
          refetch();
          dispatch(authSlice.actions.refreshSession());
          clearTimeout(timeout);
        }, 3000);
      } else {
        notify({
          title: response.payload,
          type: 'danger',
        });
      }
    }
    setReactivateLoading(false);
  }, [activeSubscription?.id, dispatch, refetch, t]);

  const handleRetryPayment = useCallback(async () => {
    setRetryPaymentLoading(true);
    const response = await getPaymentUrl(activeSubscription?.gatewaySubscription?.id || '');
    if (response.status === 200) {
      window.location.href = response.payload.paymentUrl;
      localStorage.setItem(SHOULD_REFRESH_TOKEN, 'true');
    } else {
      notify({
        title: response.payload,
        type: 'danger',
      });
    }
    setRetryPaymentLoading(false);
  }, [activeSubscription]);

  const handleConfirmationClick = useCallback(async () => {
    setPurchaseLoading(true);
    if (selectedSubscription && selectedSubscription.plans?.length) {
      const suitablePlan =
        selectedSubscription.type === SubscriptionProductType.Trial
          ? selectedSubscription.plans?.[0]
          : selectedSubscription.plans.find((item) => {
              if (period === SubscriptionPeriod.Monthly) {
                return item.recurringIntervalUnit === 'month';
              } else {
                return item.recurringIntervalUnit === 'year';
              }
            });

      if (suitablePlan) {
        const planId = suitablePlan?.id;
        const response = await purchaseSubscriptionPlan(planId);
        if (response.status === 200) {
          const approvalUrl = response.payload?.data?.fields?.['_links']?.find((item) => item.rel === 'approvalUrl')?.href || '';
          if (approvalUrl) {
            setSubscriptionPurchaseUrl(approvalUrl);
            window.location.href = approvalUrl;
            localStorage.setItem(SHOULD_REFRESH_TOKEN, 'true');
          }
        } else {
          notify({ type: 'danger', title: response.payload });
        }
      } else {
        notify({ type: 'danger', title: t('noSuitablePlan') });
      }
    }
    setPurchaseLoading(false);
  }, [selectedSubscription, period, setSubscriptionPurchaseUrl, t]);

  const handleSuccessClick = useCallback(() => {
    navigate(`/subscriptions`);
    setSuccessOpen(false);
  }, [navigate]);

  useEffect(() => {
    if (id && result === 'approved') {
      setSuccessOpen(true);
    }
  }, [id, result]);

  const state = {
    activeSubscription,
    subscriptions,
    isLoading,
    period,
    selectedSubscription,
    currentSubscription,
    confirmationOpen,
    purchaseLoading,
    successOpen,
    retryPaymentLoading,
    cancelLoading,
    reactivateLoading,
    cancelOpen,
    isUserFromShop,
    canUpgrade,
    canDowngrade,
  };

  const handlers = {
    setPeriod,
    setSelectedSubscription,
    setConfirmationOpen,
    handleSubscribeClick,
    handleConfirmationClick,
    setPurchaseLoading,
    handleSuccessClick,
    handleCancelSubscription,
    handleRetryPayment,
    handleReactivateSubscription,
    setCancelOpen,
    handleUpgrade,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
