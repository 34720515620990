import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { TextField } from '@root/shared/form';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { IconLabelLayout } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';

export const TrailingStop: FC<{
  use: CreateExpertDto['trailStop']['use'];
  unitType?: CreateExpertDto['unitType'];
  isTradeUpdate?: boolean;
  disabled?: boolean;
}> = ({ use, unitType = TemplateUnitType.POINTS, isTradeUpdate, disabled }) => {
  const { t } = useTranslation('forex-experts');

  const switchField = (
    <SwitchField
      disabled={disabled}
      name='trailStop.use'
      label={
        <IconLabelLayout
          className='mb-0'
          icon={
            <InfoTooltip content={<Text size='sm'>{t('fields.trailingStop.use.description')}</Text>}>
              <InfoTooltipIcon />
            </InfoTooltip>
          }
        >
          <Title level={7}>{t('fields.trailingStop.use.label')}</Title>
        </IconLabelLayout>
      }
      wrapperClassName={isTradeUpdate ? 'mb-1' : 'mb-4'}
    />
  );

  return (
    <div>
      <div className='flex'>{disabled ? <InfoTooltip content={t('common:unsubscriptionSupportedFunctionality')}>{switchField}</InfoTooltip> : switchField}</div>
      {!isTradeUpdate && (
        <Text size='sm' className={clsx('text-gray-500', { 'mb-4': use })}>
          {t('fields.trailingStop.use.description')}
        </Text>
      )}
      {use && (
        <div className='grid sm:grid-cols-2 gap-4'>
          <TextField
            name='trailStop.afterX'
            type='number'
            disabled={!use}
            label={
              <IconLabelLayout
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.trailingStop.fromX.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.trailingStop.fromX.label')}
              </IconLabelLayout>
            }
            placeholder={t('fields.trailingStop.fromX.placeholder')}
            suffix={unitType}
            suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
          />
          <TextField
            name='trailStop.followY'
            disabled={!use}
            type='number'
            label={
              <IconLabelLayout
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('fields.trailingStop.toY.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('fields.trailingStop.toY.label')}
              </IconLabelLayout>
            }
            placeholder={t('fields.trailingStop.toY.placeholder')}
            suffix={unitType}
            suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
          />
        </div>
      )}
    </div>
  );
};
