import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmptyDDModal } from '@root/modules/accounts/components/empty-dd-modal';
import { useCheckEmptyDDAccounts } from '@root/modules/accounts/hooks/use-check-empty-dd-accounts';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { IAccount } from '@root/modules/accounts/types/account';

export const EmptyDDAccounts: FC = () => {
  const editingAccountId = useSelector(accountsSelector.updateAccountId);
  const { emptyDDAccounts } = useCheckEmptyDDAccounts();
  const dispatch = useDispatch();

  const onEnabledDDClick = useCallback(
    (account: IAccount) => {
      dispatch(accountsSlice.actions.accountEditOpened(account.id));
    },
    [dispatch],
  );

  if (emptyDDAccounts?.length && !editingAccountId) {
    return <EmptyDDModal isOpen={true} onOk={() => onEnabledDDClick(emptyDDAccounts[0])} />;
  }

  return null;
};
