import styled from 'styled-components';

import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckRoundIcon } from '@root/shared/icons/check-round';
import { Text } from '@root/shared/ui/typography';

type Props = {
  isTp?: boolean;
  label: string;
  levels: {
    amount: number;
    percent: number;
  }[];
  currentLevel?: number;
  unitType: 'pips' | 'points';
};

const Indicator = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
`;

export const ShareTpSlCard: FC<Props> = ({ isTp = false, label, levels, unitType, currentLevel = 0 }) => {
  const { t } = useTranslation('forex-orders');
  const showMore = levels?.length > 3;
  const slicedLevels = useMemo(() => levels.slice(0, 3), [levels]);
  return (
    <div className='flex flex-col px-2 py-1.5 gap-2 rounded-sm align-top min-w-32'>
      <div className='flex items-center'>
        <Indicator className={'mr-1 ' + (isTp ? 'bg-success-900' : 'bg-danger-500')} />
        <Text size='xs' className='text-grayscale-500'>
          {label}
        </Text>
      </div>
      <div className='flex flex-col gap-1'>
        {slicedLevels?.map((takeProfit, index) => (
          <div key={index} className='flex '>
            <CheckRoundIcon
              fontSize='0.6rem'
              className={clsx('mr-1 mt-0.5', {
                'text-gray-100': currentLevel > index,
                'text-gray-700': currentLevel <= index,
              })}
            />
            <Text size='xs' className='py-0.5 px-2 rounded-xs' style={{ background: 'rgba(239, 240, 246, 0.10)', minWidth: '7rem' }}>
              <span className='text-gray-100'>
                {t(isTp ? 'share.tp' : 'share.sl')} #{index + 1}: {takeProfit?.amount} {unitType} ({takeProfit?.percent}%)
              </span>
            </Text>
          </div>
        ))}
        {showMore && (
          <div className='flex '>
            <CheckRoundIcon
              fontSize='0.6rem'
              className={clsx('mr-1 mt-0.5', {
                'text-gray-100': currentLevel > 3,
                'text-gray-700': currentLevel <= 3,
              })}
            />
            <Text size='xs' className='py-0.5 px-2 rounded-xs' style={{ background: 'rgba(239, 240, 246, 0.10)', width: '7rem' }}>
              <span className='text-gray-100'>
                +{levels?.length - 3} {t(isTp ? 'share.tp' : 'share.sl')} {t('share.more')}
              </span>
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
