import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AuthActions, authSlice } from '@root/shared-files/modules/auth/store';
import { SUBSCRIPTION_PURCHASE_URL } from '@root/shared-files/modules/shared/constants/local-storage-keys';
import { defaultUserSubscriptionInfo } from '@root/shared-files/modules/subscriptions/constants/default-user-subscription-info';
import { subscriptionsSelector } from '@root/shared-files/modules/subscriptions/store/subscriptions.selector';
import { subscriptionsSlice } from '@root/shared-files/modules/subscriptions/store/subscriptions.slice';
import { UserSubscriptionInfo } from '@root/shared-files/modules/subscriptions/types/user-subscription-info';
import { Logger } from '@root/shared/utils/logger';
import { notify } from '@root/shared/utils/notification';

function* notifyAboutSubscription() {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get('result') === 'canceled') {
    localStorage.removeItem(SUBSCRIPTION_PURCHASE_URL);
  }

  const purchaseUrl = localStorage.getItem(SUBSCRIPTION_PURCHASE_URL);
  const isUpgradedSubscription = localStorage.getItem(SUBSCRIPTION_PURCHASE_URL);

  if (purchaseUrl && searchParams.get('result')) {
    const subscriptionInfo = yield select(subscriptionsSelector.userSubscriptionInfo);

    if (subscriptionInfo?.roles?.length) {
      localStorage.removeItem('subscriptionPurchaseUrl');
      if (isUpgradedSubscription) {
        notify({ type: 'success', title: 'You have successfully upgraded your subscription!' });
      } else {
        notify({ type: 'success', title: 'You have successfully purchased a new subscription!' });
      }
    } else {
      notify({ type: 'info', title: 'Your transaction is being processed, please wait and refresh the page.' }, { autoClose: false });
    }
  }
}

function* setUserSubscription(response: AuthActions.FetchProfileFulfilled) {
  try {
    yield put(subscriptionsSlice.actions.setUserSubscriptionInfo(response.payload.userSubscriptionInfo));
    yield call(notifyAboutSubscription);
  } catch (e) {
    Logger.captureMessage('Failed to get users data from token');
    yield put(subscriptionsSlice.actions.setUserSubscriptionInfo(defaultUserSubscriptionInfo as unknown as UserSubscriptionInfo));
    yield call(notifyAboutSubscription);
  }
}

export function* subscriptionsSaga() {
  yield takeLatest([authSlice.actions.fetchProfileFulfilled, authSlice.actions.signedIn, authSlice.actions.signedInWithGoogle], setUserSubscription);
}
