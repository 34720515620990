import { ChangeEventHandler, ComponentProps, FC } from 'react';

import { TimeIcon } from '@root/shared/icons/time-icon';
import { TextInput } from '@root/shared/ui/form/text-input';

const inputTimeRegex = /^([0-2]|([0-1][0-9]|2[0-3]))?(:([0-5][0-9]?)?)?$/;

export const TimeInput: FC<
  Omit<ComponentProps<typeof TextInput>, 'name' | 'onChange'> &
    Partial<Pick<ComponentProps<typeof TextInput>, 'name'>> & {
      onChange: (value: string) => void;
    }
> = ({ value, onChange, placeholder, pattern, prefix, ...props }) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const rawValue = event.target.value.substring(0, 5);

    if (!inputTimeRegex.test(rawValue)) {
      return;
    }

    onChange(rawValue);
  };

  return (
    <TextInput
      value={value}
      onChange={handleChange}
      {...props}
      prefix={prefix ?? <TimeIcon />}
      placeholder={placeholder ?? 'HH:MM'}
      pattern={pattern ?? '([0-1]?[0-9]|2[0-3]):[0-5][0-9]'}
    />
  );
};
