import { FC, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DensebleDoubleFieldRowLayout } from '@root/infra/form/denseble-double-field-row-layout';
import { LabelWithInfoTooltip } from '@root/infra/form/label-with-info-tooltip';
import { useElementSize } from '@root/infra/layout/hooks/use-element-size';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { TextField } from '@root/shared/form';
import { resolveFieldPath } from '@root/shared/form/form-utils';

export const ClassicalPartialTakeProfitsFieldset: FC<
  PropsWithChildren<{
    nameSufix: string;
    unitType: TemplateUnitType;
    densed: boolean;
    viewOnly?: boolean;
  }>
> = ({ nameSufix, unitType, densed, viewOnly, children }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.partialClose' });

  const [setElement, size] = useElementSize();

  const width = size?.width;

  const localDensed = useMemo(() => {
    if (width === undefined) {
      return densed;
    }

    return densed || width < 450;
  }, [width, densed]);

  return (
    <DensebleDoubleFieldRowLayout
      ref={setElement}
      densed={localDensed}
      firstSlot={
        <TextField
          disabled={viewOnly}
          wrapperClassName='flex-auto'
          name={resolveFieldPath(nameSufix, 'amount')}
          label={<LabelWithInfoTooltip tooltipContent={t('levels.amount.description')}>{t('levels.amount.label')}</LabelWithInfoTooltip>}
          placeholder={t('levels.amount.placeholder')}
          suffix={unitType}
          suffixClassName='max-w-[30px] sm:max-w-none text-sm sm:text-[16px] truncate'
        />
      }
      secondSlot={
        <TextField
          name={resolveFieldPath(nameSufix, 'percent')}
          disabled={viewOnly}
          wrapperClassName='flex-auto'
          label={<LabelWithInfoTooltip tooltipContent={t('levels.percent.description')}>{t('levels.percent.label')}</LabelWithInfoTooltip>}
          placeholder={t('levels.percent.placeholder')}
          suffix='%'
        />
      }
    >
      {children}
    </DensebleDoubleFieldRowLayout>
  );
};
