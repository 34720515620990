import dayjs from 'dayjs';

import { milisecondsPerSecond, secondsPerMinute } from '@root/shared/constants/date';

export const startDateIso = '1970-01-01T00:00:00';

/** @deprecated it uses only in server response mapping. Use startDateIso instead! */
export const nullDateIso = '0001-01-01T00:00:00';

export const getStartOfTheDay = (date: Date) => {
  return dayjs(date).startOf('day').toDate();
};

export const getEndOfTheDay = (date: Date) => {
  return dayjs(date).endOf('day').toDate();
};

export const toLeadingZeroString = (value: number) => (value < 10 ? `0${value}` : value.toString());

export const toIsoStringWithoutTimezone = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const getDistanceInMinutesBetween = (firstTimestamp: number, secondTimestamp: number) => (firstTimestamp - secondTimestamp) / milisecondsPerSecond / secondsPerMinute;

export const toDaysAndHours = (totalHours: number) => {
  const days = Math.floor(totalHours / 24);
  const remainingHours = totalHours % 24;

  return {
    days,
    hours: remainingHours,
  };
};
