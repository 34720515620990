import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const BellIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 20 23' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2871 1.69264C11.2871 0.894797 10.5534 0.375977 9.64247 0.375977C8.73284 0.375977 7.99883 0.894797 7.99851 1.69264C7.99851 2.19482 7.97857 2.56952 7.63794 2.6655C4.73311 3.47583 2.87043 5.3752 2.87043 7.84182V11.9876C2.87043 13.722 2.16801 14.1933 1.24099 14.8152C1.15281 14.8744 1.06259 14.9349 0.970757 14.998C-0.562233 16.0485 -0.212276 18.2358 1.54683 18.2343H17.7388C19.4979 18.2358 19.8478 16.0485 18.3148 14.998C18.2235 14.9354 18.1338 14.8752 18.0461 14.8165C17.1174 14.1939 16.4152 13.7231 16.4152 11.9876V7.84182C16.4152 5.3752 14.5525 3.47583 11.6476 2.6655C11.3061 2.56952 11.2871 2.19482 11.2871 1.69264ZM11.3125 22.1341C12.3463 21.5822 12.9828 20.7381 12.9828 19.635H6.30373C6.30373 20.7381 6.93996 21.5822 7.97407 22.1341C9.0056 22.6856 10.279 22.6856 11.3125 22.1341Z'
      />
    </svg>
  );
};
