import { SymbolType } from '@root/modules/accounts/enums';
import { DrawdownForm } from '@root/modules/accounts/types/drawdown';
import { CreateExpertStepKey } from '@root/modules/experts/hooks/use-create-expert-steps';
import { ExpertOrderDirection, ExpertOrderType, TemplateUnitType } from '@root/modules/experts/types/common';
import { IExpert, PartialClose, PartialClosePersistetDto } from '@root/modules/experts/types/expert';

export enum TimeInForcePredefinedValue {
  goodTillCancelled = 'GOOD_TILL_CANCELLED',
  today = 'TODAY',
}

export type TimeBasedPartialTakeProfitEditingDto = {
  amount: string;
  afterMinutes: string;
};

export type PartialTakeProfitEditingDto = {
  amount: string;
  percent: string;
  timeBasedTakeProfits: TimeBasedPartialTakeProfitEditingDto[];
};

export type PartialCloseEditingDto = Pick<PartialClose, 'use'> & {
  calculationType: string;
  takeprofits: PartialTakeProfitEditingDto[];
};

export interface CreateExpertDto {
  id?: string;
  name: string;
  description: string;
  providerId: string;
  expertPresetId: string | null;
  account: string;
  timeInForce: TimeInForcePredefinedValue;
  symbols: string[];
  magicNumber: number;
  useSlippage: boolean;
  slippage: string;
  usePips: boolean;
  unitType?: TemplateUnitType;
  allowShare: boolean;
  allowClone: boolean;
  cloneFromExpertId?: string;
  strategy: {
    type: string;
    usePriceTolerance: boolean;
    timeTolerance: string;
    timeToleranceType: string;
    priceTolerance: string;
    priceToleranceType: string;
    usePostSignalProximity: boolean;
    tpProximity: string;
    tpProximityType: string;
  };
  maxManualActiveTrades: string;
  maxSignalActiveTrades: string;
  manualMoneyManagement: {
    type: string;
    lotsize: string;
    riskPercent: string;
    basedOn: string;
    k: string;
  };
  signalMoneyManagement: {
    type: string;
    lotsize: string;
    riskPercent: string;
    basedOn: string;
    k: string;
    xPercent: string;
  };
  breakEven: {
    use: boolean;
    type: string | null;
    levels: {
      afterX: string;
      setToY: string;
    }[];
  };
  partialClose: PartialCloseEditingDto;
  manualSlTp: {
    type: string;
    fixedSl: string;
    fixedTp: string;
    tpRatio: string;
    riskRatio: number;
  };
  signalSlTp: {
    type: string;
    profitCalculationType: string;
    slType: string;
    fixedSl: string;
    fixedTp: string;
    tpRatio: number;
    riskRatio: number;
  };
  trailStop: {
    use: boolean;
    afterX: string;
    followY: string;
  };
  maxDrawDownLimit: DrawdownForm;
  hitMaxDrawDownLimitUntil?: string | null;
  sessionManagement?: {
    use: boolean;
    type: string;
    sessions: Array<string>;
    threshold?: string;
  } | null;
  conditionalClosure: {
    use: boolean;
    amount: string;
  };
  orderDirection: ExpertOrderDirection;
  orderType: ExpertOrderType;
  meta: {
    step: CreateExpertStepKey;
    shouldPresetValidate?: boolean;
    allowedSymbolType?: SymbolType;
  };
}

export type CreationExpertPersistetDto = Omit<IExpert, 'id' | 'isEnabled' | 'createdAt' | 'lastPresetSyncDate' | 'timeInForce' | 'partialClose' | 'privateWebhookVersion'> &
  Partial<Pick<IExpert, 'timeInForce'>> & {
    partialClose: PartialClosePersistetDto;
    privateWebhookVersion?: 0 | 1;
  };
