import { FC, PropsWithChildren, ReactNode, lazy } from 'react';

import { usePopover } from '@root/shared/hooks/use-popover';

const Popover = lazy(() => import('react-tiny-popover').then(({ Popover }) => ({ default: Popover })));

export const SimplePopover: FC<PropsWithChildren & { content: ReactNode }> = ({ children, content }) => {
  const popover = usePopover({ trigger: 'click' });

  return (
    <Popover {...popover.props} positions={['bottom']} align='end' padding={4} content={<div>{content}</div>}>
      <div {...popover.childrenProps}>{children}</div>
    </Popover>
  );
};
