import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useProxyServerOptions } from '@root/modules/accounts/hooks/use-proxy-server-options';
import { UpdateAccountForm, useUpdateAccount } from '@root/modules/accounts/hooks/use-update-account';
import { accountsSelector } from '@root/modules/accounts/store/accounts.selector';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { Drawdown } from '@root/modules/experts/components/drawdown';
import { useGetBrokers } from '@root/modules/experts/hooks/use-get-brokers';
import { PasswordField, SelectField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { Modal } from '@root/shared/ui/modal';
import { Text } from '@root/shared/ui/typography';

const FormComponent: FC<FormikProps<UpdateAccountForm>> = ({ setFieldValue, values, handleSubmit, isSubmitting, isValid }) => {
  const { t } = useTranslation('forex-accounts');
  const dispatch = useDispatch();

  const { data } = useGetBrokers();
  const { proxyServersOptions } = useProxyServerOptions();

  const brokerData = data?.dataByBrokerServerId[values?.brokerServerId as string];

  const mtType = useMemo(() => {
    if (brokerData?.companyId) {
      return data?.list.find((item) => item.id === brokerData?.companyId)?.mtType;
    }

    return null;
  }, [brokerData?.companyId, data?.list]);

  const mtTypeOptions = useMemo(() => {
    return [
      {
        label: 'MT4',
        value: 'MT4',
      },
      {
        label: 'MT5',
        value: 'MT5',
      },
    ];
  }, []);

  const mtTypeSelectOption = useMemo(() => mtTypeOptions.find((item) => item.value === mtType), [mtType, mtTypeOptions]);

  const serverOptions = useMemo(
    () => data?.list.find((item) => item.id === brokerData?.companyId)?.servers?.map((item) => ({ value: item.id, label: item.server })) || [],
    [data, brokerData?.companyId],
  );

  const accountTypeOptions = useMemo(
    () => data?.list.find((item) => item.id === brokerData?.companyId)?.serviceAccounts?.map((item) => ({ value: item.id, label: item.name })) || [],
    [data, brokerData?.companyId],
  );

  const handleServerChange = useCallback(
    (option) => {
      setFieldValue('server', option.value);
    },
    [setFieldValue],
  );

  const handleAccountTypeChange = useCallback(
    (option) => {
      setFieldValue('serviceAccountId', option.value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (proxyServersOptions?.length && !values.proxyServerId) {
      setFieldValue('proxyServerId', proxyServersOptions.find((item) => item.label?.toLowerCase().includes('europe'))?.value || proxyServersOptions[0].value);
    }
  }, [proxyServersOptions, values.proxyServerId, setFieldValue]);

  const onClose = useCallback(() => {
    dispatch(accountsSlice.actions.accountEditClosed());
  }, [dispatch]);

  return (
    <Modal title={t('update.title')} onCancel={onClose} footer={null} isOpen>
      <form onSubmit={handleSubmit} className='-mt-4'>
        <div className='flex flex-col gap-y-4'>
          <div className='w-full'>
            <PasswordField name='password' label={t('fields.password.label')} placeholder={t('fields.password.placeholder')} />
            <Text size='sm' className='text-grayscale-600'>
              {t('fields.password.hint')}
            </Text>
          </div>
          <div className={clsx('grid gap-4', { 'grid-cols-2': !!accountTypeOptions?.length })}>
            {!!accountTypeOptions?.length && (
              <div>
                <SelectField
                  options={accountTypeOptions}
                  onChange={handleAccountTypeChange}
                  isDisabled={!serverOptions?.length}
                  name='serviceAccountId'
                  label={t('fields.serviceAccountId.label')}
                  placeholder={t('fields.serviceAccountId.placeholder')}
                />
                {!values.serviceAccountId && (
                  <Text size='sm' className='text-secondary-200'>
                    * {t('emptyServiceId')}
                  </Text>
                )}
              </div>
            )}
            <SelectField
              options={proxyServersOptions}
              name='proxyServerId'
              onChange={(option) => setFieldValue('proxyServerId', option.value)}
              label={t('fields.proxyServers.label')}
              placeholder={t('fields.proxyServers.placeholder')}
            />
          </div>
          <Drawdown />
          <div className='flex justify-between'>
            <Button onClick={onClose} outlined variant='danger' type='button'>
              {t('update.actions.cancel')}
            </Button>
            <Button loading={isSubmitting} disabled={!isValid} type='submit'>
              {t('update.actions.submit')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

const UpdateAccountFormComponent = () => {
  const { initialValues, onSubmit, validationSchema } = useUpdateAccount();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} component={FormComponent} enableReinitialize />;
};

export const UpdateAccount = () => {
  const editingAccountId = useSelector(accountsSelector.updateAccountId);

  if (!editingAccountId) {
    return null;
  }

  return <UpdateAccountFormComponent />;
};
