import { useCallback, useState } from 'react';

export const useModalState = (initialState: boolean = false) => {
  const [modalIsOpen, setModalIsOpen] = useState(initialState);

  const open = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  return [modalIsOpen, open, close] as const;
};
