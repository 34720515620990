import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const TransferIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 22 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.48092 5.12671C2.32736 5.12671 1.39237 6.0617 1.39237 7.21526V10H0V7.21526C0.00220956 5.29379 1.55945 3.73655 3.48092 3.73434H17.1163L14.3664 0.984448L15.3509 0L19.2892 3.9383C19.561 4.21025 19.561 4.6508 19.2892 4.92275L15.3509 8.86105L14.3664 7.8766L17.1163 5.12671H3.48092ZM16.0122 14.8733C17.1656 14.8733 18.1008 13.9381 18.1008 12.7847V10H19.4932V12.7847C19.4908 14.7062 17.9337 16.2633 16.0122 16.2657H2.37682L5.12671 19.0156L4.14226 20L0.20396 16.0617C-0.0678168 15.7898 -0.0678168 15.3492 0.20396 15.0772L4.14226 11.1389L5.12671 12.1234L2.37682 14.8733H16.0122Z'
      />
    </svg>
  );
};
