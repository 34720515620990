import { ExchangeType } from '@3lgn/shared/dist/constants/ccxt';

import {
  MarketPlaceTradeIdeaItem,
  MarketPlaceTradeIdeaPlan,
  MarketPlaceTradeIdeaProduct,
  MarketPlaceTradeIdeaSubscription,
  PersistedProviderForexStatistics,
  PersistedProviderSlTpAnalysis,
  PersistedProviderStatsChartEntry,
  ProviderForexStatistics,
  ProviderSlTpAnalysis,
  ProviderStatsChartEntry,
} from '@root/modules/marketplace/types/marketplace-trade-idea';
import { globalRound } from '@root/shared/utils/number/round';

export const mapProviderStatsChartEntryToDomain = (data: PersistedProviderStatsChartEntry): ProviderStatsChartEntry => ({
  label: data.label,
  value: data.value,
});

export const mapProviderSlTpAnalysisToDomain = (data: PersistedProviderSlTpAnalysis): ProviderSlTpAnalysis => ({
  sl: data.sl,
  tp: data.tp,
  risk: data.risk,
  reward: data.reward,
  minWinRate: data.minWinRate,
  providerWinRate: data.providerWinRate,
});

const mapForexStatisticsToDomain = (data: PersistedProviderForexStatistics): ProviderForexStatistics => ({
  id: data.id,
  providerId: data.provider_id,
  trades: data.trades,
  winCount: data.winCount,
  lossCount: data.lossCount,
  winRate: data.winRate,
  profitFactor: data.profitFactor,
  expectancy: data.expectancy,
  sharpRatio: data.sharpRatio,
  riskReward: data.riskReward,
  averageTp: data.averageTp,
  averageSl: data.averageSl,
  longTradeIdeas: data.longTradeIdeas,
  shortTradeIdeas: data.shortTradeIdeas,
  averageTradeDuration: data.averageTradeDuration,
  tpHitDistribution: data.tpHitDistribution?.map(mapProviderStatsChartEntryToDomain) ?? [],
  slUsage: data.slUsage?.map(mapProviderStatsChartEntryToDomain) ?? [],
  qtyOfTradeIdeasMonthly: data.qtyOfTradeIdeasMonthly?.map(mapProviderStatsChartEntryToDomain) ?? [],
  winRateMonthly: data.winRateMonthly?.map(mapProviderStatsChartEntryToDomain) ?? [],
  assetsTraded: data.assetsTraded?.map(mapProviderStatsChartEntryToDomain),
  slTpAnalysis: data.slTpAnalysis.map(mapProviderSlTpAnalysisToDomain),
  createdAt: data.created_at,
  updatedAt: data.updated_at,
});

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MarketPlaceTradeIdeaProductMapper {
  public static toDomain(data): MarketPlaceTradeIdeaProduct {
    return {
      id: data.id,
      name: data.name,
      moduleId: data.moduleId,
      description: data.description,
      group: data.group,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MarketPlaceTradeIdeaPlanMapper {
  public static toDomain(data): MarketPlaceTradeIdeaPlan {
    return {
      id: data.id,
      name: data.name,
      productId: data.productId,
      product: MarketPlaceTradeIdeaProductMapper.toDomain(data.product),
      price: (data.price || 0) / 100,
      currency: data.currency,
      recurringIntervalUnit: data.recurringIntervalUnit,
      recurringIntervalLength: data.recurringIntervalLength,
      renewable: data.renewable,
      isTrialOnly: data.isTrialOnly,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MarketPlaceTradeIdeaSubscriptionMapper {
  public static toDomain(data): MarketPlaceTradeIdeaSubscription {
    return {
      createdAt: data.createdAt,
      customer: data.customer,
      customerId: data.customerId,
      expiresAt: data.expiresAt,
      gatewaySubscription: data.gatewaySubscription,
      id: data.id,
      plan: MarketPlaceTradeIdeaPlanMapper.toDomain(data.plan),
      planId: data.planId,
      updatedAt: data.updatedAt,
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class MarketplaceTradeIdeaMapper {
  public static toDomain(data): MarketPlaceTradeIdeaItem {
    const totalGain = data.statistics.gain_per_month.reduce((acc, item) => acc + item.gain, 0);

    const avgMonthProfit = totalGain ? totalGain / data.statistics.gain_per_month.length : 0;
    const avgWinRatio = Number(data.statistics.wins) ? Number(data.statistics.wins) / (Number(data.statistics.wins) + Number(data.statistics.losses)) : 0;
    return {
      id: data.id,
      name: data.name,
      logo: data.image_url,
      forexStatistics: data.forex_statistics !== null && data.forex_statistics !== undefined ? mapForexStatisticsToDomain(data.forex_statistics) : null,
      statistics: {
        weeklyGain: data.statistics.gain_week,
        monthlyGain: data.statistics.gain_month,
        yearlyGain: data.statistics.gain_year,
        price: 0,
        precision: data.statistics.precision,
        subscribersCount: data.statistics.subscribers_count,
        pnl: data.statistics.pnl,
        avgMonthProfit: (globalRound(avgMonthProfit, 2) || 0).toString(),
        avgWinRatio: globalRound(avgWinRatio * 100, 2),
        profitFactor: 0,
        avgMonthLoss: '0',
        maxDrawDown: '0',
      },
      subscriptionStatus: data.subscription_status,
      plans: data.plans?.map((item) => MarketPlaceTradeIdeaPlanMapper.toDomain(item)),
      exchanges:
        data.statistics?.exchanges
          ?.filter((item) => item.name !== ExchangeType.FTX)
          .map((item) => ({
            exchange: item.name,
            coins: item.coins,
          })) || [],
      showSlWonInfo: data.show_sl_won_info,
      allowDca: data.allowed_dca,
      allowSimple: data.allowed_simple,
      allowGrid: data.allowed_grid,
      gainPerMonth: data.statistics.gain_per_month.map((obj) => {
        return {
          date: new Date(`${obj.year} ${obj.month} 01 UTC`),
          value: obj.gain,
        };
      }),
      subscription: data.subscription ? MarketPlaceTradeIdeaSubscriptionMapper.toDomain(data.subscription) : undefined,
      market: data.market,
      marketLabel: data.market === 'forex' ? 'Currencies (FX)' : data.market,
    };
  }
}
