import { Layout } from '@root/infra/layout/containers';
import { ChartVisualModeProvider, useChartVisualMode } from '@root/modules/charting-library/contexts/chart-visual-mode-context';
import { DrawingsProvider } from '@root/modules/charting-library/contexts/drawings-context';
import { MagicTerminalForm } from '@root/modules/magic-terminal/containers/magic-terminal-form';
import { TradesTable } from '@root/modules/orders/containers/trades-table';
import { SelectedBrokerProvider } from '@root/modules/orders/contexts/selected-broker.context';
import { useQuoteSubscriber } from '@root/modules/orders/hooks/use-quote-subscriber';

import { ShareConfigProvider } from '../contexts/share-config.context';

export const MagicTerminalPage = () => {
  useQuoteSubscriber('all', true);
  const { isFullScreenMode } = useChartVisualMode();

  return (
    <Layout isFullScreenMode={isFullScreenMode}>
      <SelectedBrokerProvider storagePrefix='magic-terminal'>
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-12'>
            <DrawingsProvider>
              <MagicTerminalForm />
            </DrawingsProvider>
          </div>

          {!isFullScreenMode && (
            <div className='col-span-12'>
              <TradesTable doNotDisplayUnknownCurrency />
            </div>
          )}
        </div>
      </SelectedBrokerProvider>
    </Layout>
  );
};
