import '@3lgn/shared/dist/constants/roles';

import { useMemo } from 'react';
import { keepPreviousData, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { TEST_PROVIDER_NAME } from '@root/modules/marketplace/constants/test-providers';
import {
  GET_MARKETPLACE_TRADE_IDEA_LIST,
  GetMarketplaceTradeIdeaListData,
  GetMarketplaceTradeIdeaListQueryKey,
} from '@root/modules/marketplace/query/get-marketplace-trade-idea-list.query';
import { GetMarketplaceTradeIdeaListQuery } from '@root/modules/marketplace/services/get-marketplace-trade-idea-list.service';
import { checkTestProvidersAvailable } from '@root/modules/marketplace/utils/check-test-providers-available';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { subscriptionsSelector } from '@root/shared-files/modules/subscriptions/store';

export const useGetMarketplaceTradeIdeaList = (params: GetMarketplaceTradeIdeaListQuery, enabled = true) => {
  const isAuthed = useSelector(authSelector.isAuthed);
  const subscriptionInfo = useSelector(subscriptionsSelector.userSubscriptionInfo);

  const isQAMember = useMemo(() => checkTestProvidersAvailable(subscriptionInfo?.roles), [subscriptionInfo]);

  const result = useQuery<GetMarketplaceTradeIdeaListData, Error, GetMarketplaceTradeIdeaListData, GetMarketplaceTradeIdeaListQueryKey>({
    queryKey: ['marketplace-trade-idea-list', params],
    queryFn: GET_MARKETPLACE_TRADE_IDEA_LIST,
    placeholderData: keepPreviousData,
    enabled: enabled && isAuthed,
  });

  const data = useMemo(() => {
    const list = result.data?.data?.filter((item) => (!isQAMember ? !item?.name?.includes(TEST_PROVIDER_NAME) : true));

    if (result?.data) {
      return {
        ...result.data,
        data: list,
      };
    }

    return result.data;
  }, [result, isQAMember]);

  return { ...result, data };
};
