import { createSlice } from '@reduxjs/toolkit';

export type IMarketplaceState = {
  isUpdatedAfterSubscription: boolean;
};

export const marketplaceState: IMarketplaceState = {
  isUpdatedAfterSubscription: false,
};

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState: marketplaceState,
  reducers: {
    setUpdatedAfterSubscription: (state, action) => {
      state.isUpdatedAfterSubscription = action.payload;
    },
  },
});
