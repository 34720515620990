import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { queryClient } from '@root/infra/query';
import { IAccount } from '@root/modules/accounts';
import {
  SIGN_IN_ACCOUNT_MUTATION,
  SignInAccountMutationData,
  SignInAccountMutationError,
  SignInAccountMutationValues,
} from '@root/modules/accounts/mutations/sign-in-account.mutation';
import {
  SIGN_OUT_ACCOUNT_MUTATION,
  SignOutAccountMutationData,
  SignOutAccountMutationError,
  SignOutAccountMutationValues,
} from '@root/modules/accounts/mutations/sign-out-account.mutation';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { notify } from '@root/shared/utils/notification';

// TODO: use this hook in the account card component

interface IUseConnectDisconnectAccountParams {
  accountId: string;
  showErrorMessage?: boolean;
  showSuccessMessage?: boolean;
}

export const useConnectDisconnectAccount = () => {
  const dispatch = useDispatch();

  const signInMutation = useMutation<SignInAccountMutationData, SignInAccountMutationError, SignInAccountMutationValues>({
    mutationFn: SIGN_IN_ACCOUNT_MUTATION,
  });
  const signOutMutation = useMutation<SignOutAccountMutationData, SignOutAccountMutationError, SignOutAccountMutationValues>({
    mutationFn: SIGN_OUT_ACCOUNT_MUTATION,
  });

  const setIsPending = (accountId: string, isPending: boolean) => {
    queryClient.setQueryData<IAccount[] | void>(['accounts'], (oldData) => {
      if (!oldData) {
        return oldData;
      }
      return oldData.map((account) => {
        if (account.id === accountId) {
          return {
            ...account,
            isPending,
          };
        }
        return account;
      });
    });
  };

  const disconnectAccountAsync = ({ accountId, showErrorMessage }: IUseConnectDisconnectAccountParams) => {
    setIsPending(accountId, true);
    return signOutMutation.mutateAsync(
      { accountId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['experts'], exact: false });
          dispatch(accountsSlice.actions.clearAccountBalance({ id: accountId }));
          queryClient.invalidateQueries({ queryKey: ['accounts'], exact: false });
        },
        onError: (error: Error) => {
          setIsPending(accountId, false);

          showErrorMessage &&
            notify({
              type: 'danger',
              text: error.message,
            });
        },
      },
    );
  };

  const connectAccountAsync = ({ accountId, showErrorMessage }: IUseConnectDisconnectAccountParams) => {
    setIsPending(accountId, true);
    return signInMutation.mutateAsync(
      { accountId },
      {
        onSuccess: (data) => {
          if (data.status === 400) {
            showErrorMessage &&
              notify(
                {
                  type: 'danger',
                  text: data.payload,
                },
                { data: data.errorData },
              );

            return;
          }

          queryClient.invalidateQueries({ queryKey: ['experts'], exact: false });
          queryClient.invalidateQueries({ queryKey: ['accounts'], exact: false });
        },
      },
    );
  };

  return { disconnectAccountAsync, connectAccountAsync };
};
