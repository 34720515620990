import HCaptcha from '@hcaptcha/react-hcaptcha';
import isEqual from 'lodash/isEqual';

import { Formik, FormikProps } from 'formik';
import { FC, memo, useCallback, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { SignInVericationModal } from '@root/shared-files/modules/auth/components/two-fa/sign-in-verification-modal';
import { SignInDto } from '@root/shared-files/modules/auth/dtos';
import { useSignIn, useSignInWithGoogle } from '@root/shared-files/modules/auth/hooks';
import { TwoFAResponse } from '@root/shared-files/modules/auth/types/two-fa';
import { bottomLinks } from '@root/shared-files/modules/subscriptions/constants/bottom-links';
import { IFormStatus, PasswordField, TextField } from '@root/shared/form';
import { AppleIcon } from '@root/shared/icons/apple-icon';
import { GoogleIcon } from '@root/shared/icons/google-icon';
import { Alert } from '@root/shared/ui/alert';
import { Button } from '@root/shared/ui/button';
import Link from '@root/shared/ui/common/static-locales-link';
import { Text, Title } from '@root/shared/ui/typography';

type FormProps = FormikProps<SignInDto> & {
  twoFaResponse: TwoFAResponse | null;
  setTwoFaResponse: (response: TwoFAResponse | null) => void;
};

const FormComponent: FC<FormProps> = memo(
  ({ status: rawStatus, handleSubmit, twoFaResponse, setTwoFaResponse, isSubmitting, isValid, errors, touched, setValues }) => {
    const captchaRef = useRef<any>(null);
    const { t } = useTranslation('sign-in');
    const googleSignIn = useSignInWithGoogle(setTwoFaResponse);
    const status: IFormStatus | undefined = rawStatus;
    const hasError = status?.type === 'error' || (!!touched.captchaToken && !!errors.captchaToken);
    const error = status?.message || 'Captcha not passed';
    const captchaKey = import.meta.env.VITE_CAPTCHA_SITE_KEY;

    const onCaptchaSuccess = useCallback(
      async (token) => {
        setValues((prevState) => ({
          ...prevState,
          captchaToken: token,
        }));
      },
      [setValues],
    );

    useEffect(() => {
      if (captchaRef.current && status?.type === 'error') {
        captchaRef.current?.resetCaptcha();
      }
    }, [captchaRef, status]);

    return (
      <div className='w-full'>
        <div className='flex justify-between items-center mb-4'>
          <Title level={5} bold>
            {t('title')}
          </Title>
          <Link href='/sign-up'>
            <a className='text-primary-400 text-xl font-bold hover:underline hover:text-gray-100'>{t('signUp')}</a>
          </Link>
        </div>
        <div className='flex mb-4' style={{ direction: 'ltr' }}>
          <Button disabled prefix={<AppleIcon />} variant='light' className='flex-1 mr-2' outlined>
            Apple ID
          </Button>
          <Button
            loading={googleSignIn.isLoading}
            disabled={!googleSignIn.isReady}
            onClick={googleSignIn.signIn}
            prefix={<GoogleIcon />}
            variant='light'
            className='flex-1 ml-2'
            outlined
          >
            Google
          </Button>
        </div>
        <div className='flex items-center justify-center pt-4 pb-10'>
          <span className='border-b-2 border-gray-500 w-32' />
        </div>
        <form onSubmit={handleSubmit}>
          {hasError && (
            <Alert className='mb-4' variant='danger'>
              {error}
            </Alert>
          )}
          <div className='w-full flex flex-col gap-y-5'>
            <TextField name='email' label={t('fields.email.label')} placeholder={t('fields.email.placeholder') as string} />
            <div>
              <PasswordField name='password' autoComplete='new-password' label={t('fields.password.label')} placeholder={t('fields.password.placeholder') as string} />
              <div className='w-full flex justify-end items-center'>
                <Link href='/forgot-password'>
                  <a className='block text-right font-bold hover:underline hover:text-primary-400'>{t('forgotPassword')}</a>
                </Link>
              </div>
            </div>
            <div className='w-full mt-5'>
              <HCaptcha ref={captchaRef} sitekey={captchaKey} size='normal' theme='dark' onVerify={onCaptchaSuccess} />
            </div>
            <Button size='large' loading={isSubmitting} disabled={!isValid} type='submit' className='mt-4 w-full'>
              {t('submit')}
            </Button>
            <div className='px-16 mt-4'>
              <Text size='sm' className='text-center'>
                <Trans
                  i18nKey='sign-in:terms'
                  components={[
                    <a
                      key='0'
                      className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                      href={bottomLinks.termsOfUse}
                      rel='noreferrer noopener'
                      target='_blank'
                    />,
                    <a
                      key='1'
                      className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                      href={bottomLinks.privacyPolicy}
                      rel='noreferrer noopener'
                      target='_blank'
                    />,
                    <a
                      key='2'
                      className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                      href={bottomLinks.refundPolicy}
                      rel='noreferrer noopener'
                      target='_blank'
                    />,
                    <a
                      key='3'
                      className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                      href={bottomLinks.acceptableUsePolicy}
                      rel='noreferrer noopener'
                      target='_blank'
                    />,
                    <a
                      key='4'
                      className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                      href={bottomLinks.cookiesPolicy}
                      rel='noreferrer noopener'
                      target='_blank'
                    />,
                    <a
                      key='5'
                      className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                      href={bottomLinks.riskWarning}
                      rel='noreferrer noopener'
                      target='_blank'
                    />,
                    <a
                      key='6'
                      className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                      href={bottomLinks.disclaimers}
                      rel='noreferrer noopener'
                      target='_blank'
                    />,
                  ]}
                />
              </Text>
            </div>
          </div>
        </form>
        {twoFaResponse && <SignInVericationModal isOpen twoFaResponse={twoFaResponse} onCancel={() => setTwoFaResponse(null)} />}
        {/* <AccessCodeModal isOpen={googleSignIn.isOpen} onClose={googleSignIn.onClose} onCodeSubmit={googleSignIn.onCodeSubmit} /> */}
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

FormComponent.displayName = 'FormComponent';

export const SignIn = () => {
  const { initialValues, twoFaResponse, setTwoFaResponse, onSubmit, validationSchema } = useSignIn();

  return (
    <Formik<SignInDto>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}
      component={(props) => <FormComponent {...props} twoFaResponse={twoFaResponse} setTwoFaResponse={setTwoFaResponse} />}
    />
  );
};
