import clsx from 'clsx';
import { FC } from 'react';

import warningImg from '@root/assets/images/cases/warning.svg';
import { Image } from '@root/shared/ui/image';

export enum CaseImgSize {
  small,
  medium,
}

export const WarningImg: FC<{ size?: CaseImgSize }> = ({ size = CaseImgSize.medium }) => (
  <Image
    src={warningImg}
    wrapperClassName={clsx('flex-none', {
      'w-[24px] h-[24px]': size === CaseImgSize.small,
      'w-[120px] h-[120px]': size === CaseImgSize.medium,
    })}
    className='w-[170.833333%]! max-w-none! h-auto! -mt-[16.6666667%] -mr-[54.1666667%] -mb-[62.5%] -ml-[16.6666667%]'
    alt='warning'
  />
);
