import styled from 'styled-components';

import clsx from 'clsx';
import { FC, MouseEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LimitItem } from '@root/infra/layout/containers/limit-item';
import { LimitItemKey, useLimits } from '@root/infra/layout/containers/use-limits';
import { CreateExpertDropdown } from '@root/modules/experts/components/create-expert-dropdown';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { convertPermissionLimitToHumanReadable } from '@root/shared-files/modules/auth/helpers';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { useOutsideClick } from '@root/shared/hooks/use-outside-click';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { ExpertsIcon } from '@root/shared/icons/experts-icon';
import { BaseButton } from '@root/shared/ui/button/base-button';
import { Text } from '@root/shared/ui/typography';
import { invertBoolean } from '@root/shared/utils/boolean/invert-boolean';

const Wrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 372px;
  max-width: 100vw;
  box-shadow: 0 10px 24px rgba(12, 12, 12, 0.6);
`;

const translationIdBylimitItemKeyMap = {
  [LimitItemKey.connectedBrokerAccounts]: 'assistWidget.limits.connectedBrokerAccounts',
  [LimitItemKey.runningAssists]: 'assistWidget.limits.runningAssists',
  [LimitItemKey.runningSaWithTvAlert]: 'assistWidget.limits.runningSaWithTvAlert',
  [LimitItemKey.openMagicTerminalOrders]: 'assistWidget.limits.openMagicTerminalOrders',
};

export const AssistWidget: FC = () => {
  const { t } = useTranslation('common');
  const { data } = useGetExperts();
  const maxEACount = useSelector(authSelector.maxEACount);
  const [isLimitsOpen, setIsLimitsOpen] = useState(false);
  const runningExperts = data?.filter((expert) => expert.isEnabled);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownTriggerRef = useRef<HTMLDivElement>(null);

  const limitItems = useLimits();

  const handleShowLimitsDropdownButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    setIsLimitsOpen(invertBoolean);
  };

  useOutsideClick([dropdownTriggerRef, dropdownRef], () => {
    setIsLimitsOpen(false);
  });

  return (
    <div className='bg-white/5 rounded-sm text-base'>
      <div className='relative flex items-center gap-x-2 p-0.5 h-11'>
        <CreateExpertDropdown buttonText={t('header.setupSmartAssist')} />

        {/*<Button className={clsx('flex items-center gap-x-2 px-2! py-0.5! rounded-sm')} onClick={handleRedirectExpert}>*/}
        {/*  <SvgSpriteIcon id='plus' />*/}
        {/*  <span className='whitespace-nowrap leading-5 py-px'>{t('header.eaWizard')}</span>*/}
        {/*</Button>*/}

        <BaseButton
          className={clsx('h-10 items-center justify-center gap-x-4 py-1 px-2 rounded-sm hover:bg-white/10 cursor-pointer transition', {
            'bg-white/10': isLimitsOpen,
          })}
          onClick={handleShowLimitsDropdownButtonClick}
        >
          <div className='flex items-center gap-x-2'>
            <ExpertsIcon width={20} height={20} />
            <Text className='font-bold'>
              {runningExperts?.length ?? 0}/{convertPermissionLimitToHumanReadable(maxEACount)}
            </Text>
          </div>
          <ChevronIcon className={clsx('transform transition-200', { 'rotate-180': !isLimitsOpen })} />
        </BaseButton>
        {isLimitsOpen && (
          <Wrapper className='rounded-sm bg-gray-800 p-4 flex flex-col gap-2' ref={dropdownRef}>
            {limitItems.map(({ key, ...props }) => (
              <LimitItem key={key} title={t(translationIdBylimitItemKeyMap[key])} {...props} />
            ))}
          </Wrapper>
        )}
      </div>
    </div>
  );
};
