import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useExportToCsv } from '@root/modules/orders/hooks/use-export-to-csv';
import { ExportClosedOrdersQuery } from '@root/modules/orders/services/export-closed-orders.service';
import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { SheetIcon } from '@root/shared/icons/sheet-icon';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text } from '@root/shared/ui/typography';

type ExportToCsvParams = {
  queryParams: ExportClosedOrdersQuery;
};

export const ExportToCsv: FC<ExportToCsvParams> = ({ queryParams }: ExportToCsvParams) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'trades' });
  const [{ loading }, { exportToCsv }] = useExportToCsv(queryParams);

  const Icon = loading ? LoadingIcon : () => <SheetIcon height={13} width={13} id='export-to-csv' />;

  return (
    <div className='flex items-start justify-between gap-1'>
      <InfoTooltip content={<Text size='sm'>{t('exportToCsv')}</Text>}>
        <button className='p-2 bg-gray-700 text-sm rounded-sm' onClick={exportToCsv}>
          <Icon />
        </button>
      </InfoTooltip>
    </div>
  );
};
