import { ProviderStatsChartEntry } from '@root/modules/marketplace/types/marketplace-trade-idea';

export const formatProviderStatisticsNumber = (value: number) => {
  if (value > 1) {
    return Math.floor(value);
  }

  if (value > 0) {
    return Math.floor(value * 100) / 100;
  }

  if (value > -1) {
    return Math.ceil(value * 100) / 100;
  }

  return Math.ceil(value);
};

export const formatProviderStatisticsValue = (
  value: number | undefined | null,
  {
    postfix = '',
  }: {
    postfix?: string;
  } = {},
) => {
  if (value === undefined || value === null) {
    return '-';
  }

  return `${formatProviderStatisticsNumber(value)}${postfix}`;
};

const maxTp = 300;

export const isValidProviderStatsChartTpEntry = (entry: ProviderStatsChartEntry) => Number(entry.label) < maxTp;
