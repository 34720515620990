import { Selector, createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';
import { SettingsState } from '@root/modules/app-settings/store/settings.slice';
import { TradingMode } from '@root/modules/app-settings/types/trading-mode';

export const settingsSelector = {
  canViewBalance: createSelector<[Selector<IAppState, SettingsState>], boolean>([(state) => state.settings], (settings) => settings.canViewBalance),
  isBalanceVisible: createSelector<[Selector<IAppState, SettingsState>], boolean>([(state) => state.settings], (settings) => settings.isBalanceVisible),
  tradingMode: createSelector<[Selector<IAppState, SettingsState>], TradingMode>([(state) => state.settings], (settings) => settings.tradingMode),
  isPaperTrading: createSelector<[Selector<IAppState, SettingsState>], boolean>([(state) => state.settings], (settings) => settings.tradingMode === TradingMode.PaperTrading),
};
