export const supportedResolutions = [
  // '1T', // 1 tick
  // '1S', // 1 second
  '1', // 1 minute
  '3', // 3 minutes
  '5', // 5 minutes
  '15', // 15 minutes
  '30', // 30 minutes
  '45', // 45 minutes
  '60', // 1 hour
  '120', // 2 hours
  '180', // 3 hours
  '240', // 4 hours
  '1D', // 1 day
  '1W', // 1 week
  '1M', // 1 month
  '12M', // 1 year
];

export const nomicsResolutions = {
  '1': '1m',
  '3': '3m',
  '5': '5m',
  '15': '15m',
  '30': '30m',
  '45': '45m',
  '60': '1h',
  '120': '2h',
  '180': '3h',
  '240': '4h',
  '1D': '1d',
  '1W': '1w',
  '1M': '1m',
  '12M': '1y',
};
