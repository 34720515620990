import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { SignalDtoMapper } from '@root/modules/magic-terminal/mappers/signal-dto.mapper';
import { ISignalData } from '@root/modules/magic-terminal/types/signal';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetSignalsServiceResponse = IHttpResponse<200, ISignalData> | IHttpResponse<400, string>;

export const getSignalService = async (id: string): Promise<GetSignalsServiceResponse> => {
  try {
    const response = await fetcher(`/signals/${id}`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: SignalDtoMapper.toDomain(response.data),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
