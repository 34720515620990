import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useChart } from '@root/modules/charting-library/contexts/chart-instance.context.jsx';
import { IChartContainerProps } from '@root/modules/charting-library/types/widget-props';
import { widget } from '@root/modules/charting-library/utils/chart';
import { getChartSupportedLocale } from '@root/modules/charting-library/utils/locales-helper';

export const useChartingLibrary = (props: IChartContainerProps) => {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const { chartInstance, setChartInstance } = useChart();
  const { i18n } = useTranslation();

  //! widget initialization
  useEffect(() => {
    if (!chartInstance) {
      const data = {
        symbol: props.symbol,
        container: props.id as string,
        locale: getChartSupportedLocale(i18n.language),
      };

      const callbackFn = (instance) => {
        instance
          .activeChart()
          .onDataLoaded()
          .subscribe(null, () => {
            setDataLoaded(true);
          });

        instance
          .activeChart()
          .onSymbolChanged()
          .subscribe(null, () => {
            instance.applyOverrides({ 'priceScaleProperties.autoScale': true });
            // widget.deleteLineShapes(instance);
            // instance.activeChart().setResolution(props.interval);
          });

        if (instance) {
          setChartInstance(instance);
        }
      };

      widget.initInstance(data, callbackFn);
    }
  }, [chartInstance, i18n.language, props.id, props.symbol, setChartInstance]);

  //! Dynamically update chart symbol
  useEffect(() => {
    try {
      if (chartInstance && chartInstance.activeChart().symbol() !== props.symbol) {
        chartInstance.activeChart().setSymbol(props.symbol);
      }
    } catch (e) {
      console.log('setSymbol', e);
    }
  }, [chartInstance, props.symbol]);

  useEffect(() => {
    return () => {
      if (chartInstance) {
        chartInstance?.remove?.();
      }
    };
  }, [chartInstance]);

  return {
    chartLoaded: !!chartInstance,
    dataLoading: !dataLoaded,
  };
};
