import { PartialTakeProfitMapper } from '@root/modules/experts/mappers/expert-dto.mapper';
import { IPreset } from '@root/modules/presets/types/preset';

export class PresetsDtoMapper {
  public static toDomain(presetData: IPreset): IPreset {
    return {
      ...presetData,
      partialClose: {
        ...presetData.partialClose,
        takeprofits: presetData.partialClose.takeprofits.map(PartialTakeProfitMapper.toDomain),
      },
    };
  }
}
