import { QueryFunction } from 'react-query';

import { getSupportedBroker } from '@root/modules/accounts/services/get-supported-brokers.service';
import { IGetBrokersServiceResponse } from '@root/modules/accounts/types/servers-with-company';

export type GetBrokersQueryKey = ['brokers'];
export type GetBrokersData = IGetBrokersServiceResponse;

export const GET_BROKERS: QueryFunction<GetBrokersData, GetBrokersQueryKey> = async () => {
  const result = await getSupportedBroker();

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
