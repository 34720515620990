import { Selector, createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';
import { IExpertsState } from '@root/modules/experts/store/experts.slice';

const getState = (state: IAppState) => state.experts;
const getRunExpertId = (state: IExpertsState) => state.runExpertId;
const getRunExpertSymbols = (state: IExpertsState) => state.runExpertSymbols;
const getEditExpertModalData = (state: IExpertsState) => state.editExpertModalData;
const getExpertEventsModalData = (state: IExpertsState) => state.expertEventsModalData;
const getCopyPresetData = (state: IExpertsState) => state.copyPresetData;
const getRunExpertQueue = (state: IExpertsState) => state.runExpertQueue;

export const expertsSelector = {
  runExpertId: createSelector<[Selector<IAppState, IExpertsState>], IExpertsState['runExpertId']>([getState], getRunExpertId),
  runExpertSymbols: createSelector<[Selector<IAppState, IExpertsState>], IExpertsState['runExpertSymbols']>([getState], getRunExpertSymbols),
  editExpertModalData: createSelector<[Selector<IAppState, IExpertsState>], IExpertsState['editExpertModalData']>([getState], getEditExpertModalData),
  expertEventsModalData: createSelector<[Selector<IAppState, IExpertsState>], IExpertsState['expertEventsModalData']>([getState], getExpertEventsModalData),
  copyPresetData: createSelector<[Selector<IAppState, IExpertsState>], IExpertsState['copyPresetData']>([getState], getCopyPresetData),
  runExpertQueue: createSelector<[Selector<IAppState, IExpertsState>], IExpertsState['runExpertQueue']>([getState], getRunExpertQueue),
};
