import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AllOptionAddingStrategy, useGetAccountsOptions } from '@root/modules/accounts/hooks/use-get-accounts-options';
import { useGetSelectedBroker } from '@root/modules/orders/contexts/selected-broker.context';
import { Select } from '@root/shared/ui/form';

type Props = {
  tiny?: boolean;
  signedOnly?: boolean;
  allOptionAddingStrategy?: AllOptionAddingStrategy;
};

export const BrokerAccountSelect: FC<Props> = ({ tiny, signedOnly, allOptionAddingStrategy }) => {
  const { t } = useTranslation('dashboard');
  const { selectedBroker, selectBroker, mtType, setMtType } = useGetSelectedBroker();

  const options = useGetAccountsOptions({
    mtType,
    signedOnly,
    allOptionAddingStrategy,
  });
  const selectedOption = useMemo(() => options?.find((option) => option.value === selectedBroker), [options, selectedBroker]);

  const mtTypeOptions = useMemo(() => {
    return [
      { value: undefined, label: t('filters.mtType.all') },
      { value: 'MT4', label: 'MT4' },
      { value: 'MT5', label: 'MT5' },
    ];
  }, [t]);

  const selectedMtTypeOption = mtTypeOptions?.find((option) => option.value === mtType);

  const handleBrokerChange = useCallback(
    (option) => {
      selectBroker(option.value);
    },
    [selectBroker],
  );

  useEffect(() => {
    if (options === null || options.length === 0) {
      return;
    }

    if (selectedOption !== undefined) {
      return;
    }

    const onlyBrokerOptions = options.filter((option) => option?.value !== 'all');

    if (onlyBrokerOptions.length === 1) {
      selectBroker(onlyBrokerOptions[0].value);

      return;
    }

    selectBroker(options[0].value);
  }, [options, selectedOption, selectBroker]);

  return (
    <div className='flex gap-x-2'>
      <Select tiny={tiny} onChange={(option) => setMtType(option.value)} options={mtTypeOptions || []} value={selectedMtTypeOption} alignMenuToRight />
      <Select tiny={tiny} onChange={handleBrokerChange} options={options || []} value={selectedOption} alignMenuToRight />
    </div>
  );
};
