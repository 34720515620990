import { lineStyles } from '@root/modules/charting-library/constants/line-styles';
import { supportedResolutions } from '@root/modules/charting-library/constants/supported-resolutions';
import { OrderLine } from '@root/modules/charting-library/types/draw-types';
import { IChartContainerProps } from '@root/modules/charting-library/types/widget-props';
import { DataFeed } from '@root/modules/charting-library/utils/datafeed';

import { IChartingLibraryWidget, IOrderLineAdapter } from '../../../../public/charting_library';

// import { CustomTimezones } from '../../../../public/charting_library';

type HighLowLine = Record<'high' | 'low' | 'takeProfit' | 'stopLoss' | 'breakEvenPrice', IOrderLineAdapter | undefined>;

const highLowIds: HighLowLine = {
  high: undefined,
  low: undefined,
  takeProfit: undefined,
  stopLoss: undefined,
  breakEvenPrice: undefined,
};

type IOrderLineAdapterWithLine = IOrderLineAdapter & { _line: unknown };

type InitInstance = (
  props: Pick<IChartContainerProps, 'symbol'> & {
    container: string;
    locale: string;
  },
  callbackFn: (inst: IChartingLibraryWidget) => void,
) => void;

// type ScaleChartPriceRange = (
//   chartInstance: IChartingLibraryWidget,
//   props: {
//     high: number;
//     low: number;
//     interval: string;
//   },
// ) => void;

type ShowOrderLine = (chartInstance: IChartingLibraryWidget, props: OrderLine) => void;
type CreateBreakEvenPrice = (chartInstance: IChartingLibraryWidget, price: number) => void;
type DeleteLineShapes = (chartInstance: IChartingLibraryWidget) => void;

export const colorPalette = {
  sellLine: 'rgba(129, 57, 65, 1)',
  buyLine: 'rgba(29, 75, 71, 1)',
  verticalLine: 'rgb(255, 235, 59)',
  breakEven: 'rgba(229, 197, 80, 1)',
  highLowLine: 'rgba(39, 119, 198, 1)',
  candleDown: 'rgba(255, 84, 102, 1)',
  candleUp: 'rgba(0, 194, 174, 1)',
  blue: 'rgba(39, 119, 198, 1)',
  green: 'rgba(35, 134, 124, 1)',
  red: 'rgba(163, 80, 88, 1)',
  buyDot: 'rgba(0, 194, 174, 1)',
  sellDot: 'rgba(255, 84, 102, 1)',
};

/**
 * Initialize chart instance
 * @props: {
 *   @symbol: string,
 *   @interval: string,
 *   @container: string,
 *   @datafeed: datafeed object,
 *   @disabled_features: string[]
 * }
 * @callbackFn (inst) => void
 */

const initInstance: InitInstance = (props, callbackFn) => {
  const { symbol, container, locale } = props;
  try {
    new window.CustomTradingView.widget({
      symbol,
      interval: '60',
      fullscreen: true,
      container,
      datafeed: new DataFeed(),
      locale,
      theme: 'Dark',
      library_path: '/charting_library/',
      custom_css_url: '/charting_library/override-styles.css',
      disabled_features: [
        'header_symbol_search',
        'header_compare',
        'compare_symbol',
        'control_bar',
        'context_menus',
        'timeframes_toolbar',
        'symbol_info',
        'symbol_search_hot_key',
        'border_around_the_chart',
        'remove_library_container_border',
        'symbol_info',
        'header_interval_dialog_button',
        'show_interval_dialog_on_key_press',
        'header_undo_redo',
        'header_screenshot',
        'header_settings',
        'header_fullscreen_button',
        'header_quick_search',
        'header_fullscreen_button',
      ],
      enabled_features: [
        'dont_show_boolean_study_arguments',
        'hide_last_na_study_output',
        'use_localstorage_for_settings',
        'snapshot_trading_drawings',
        'side_toolbar_in_fullscreen_mode',
        'cl_feed_return_all_data',
        'timeframes_toolbar',
        'saveload_separate_drawings_storage',
        'customFormatters',
      ],
      supported_resolutions: supportedResolutions,
      timezone: 'Etc/UTC',
      toolbar_bg: '#000',
      overrides: {
        'paneProperties.background': 'rgba(32, 32, 32, 1)',
        'paneProperties.backgroundType': 'solid',
        'mainSeriesProperties.candleStyle.borderUpColor': colorPalette.candleUp,
        'mainSeriesProperties.candleStyle.downColor': colorPalette.candleDown,
        'mainSeriesProperties.candleStyle.borderDownColor': colorPalette.candleDown,
        'scalesProperties.textColor': 'rgba(252, 252, 252, 1)',
        'dataWindowProperties.font': 'Poppins',
      },

      timeframe: '1M',
    }).onChartReady(function (this: IChartingLibraryWidget) {
      // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

      // if (timeZone) {
      //   const chartTimeZone = this.activeChart().getTimezoneApi();
      //   chartTimeZone?.setTimezone(timeZone as CustomTimezones);
      // }

      // this.subscribe('onTick', (bar) => updateHighLowLinesOnTick(bar, container));

      callbackFn(this);
    });
  } catch (error) {
    console.log('error', error);
  }
};

// const updateHighLowLinesOnTick = (bar, containerId) => {
//   try {
//     for (const key in highLowIds[containerId]) {
//       const orderLineAdapter = highLowIds[containerId][key] as IOrderLineAdapterWithLine;
//
//       if (orderLineAdapter?._line) {
//         const currentMarketPrice = bar.close;
//         const linePrice = orderLineAdapter.getPrice();
//
//         let percentage = 0;
//
//         if (['low', 'stopLoss'].includes(key) && currentMarketPrice && linePrice) {
//           percentage = globalRound(new Decimal(currentMarketPrice).minus(linePrice).div(currentMarketPrice).mul(100).toNumber(), 2);
//         }
//
//         if (['high', 'takeProfit'].includes(key) && currentMarketPrice && linePrice) {
//           percentage = globalRound(new Decimal(linePrice).minus(currentMarketPrice).div(currentMarketPrice).mul(100).toNumber(), 2);
//         }
//
//         if (percentage) {
//           const prevText = orderLineAdapter.getText();
//           let nextText = '';
//
//           // if (containsNumbers(prevText)) {
//           //   nextText = prevText.replace(prevText.split(' ').slice(-1)[0], `${linePrice > currentMarketPrice ? '+' : '-'}${Math.abs(percentage)}%`);
//           // } else {
//           //   nextText = prevText + ` ${linePrice > currentMarketPrice ? '+' : '-'}${Math.abs(percentage)}%`;
//           // }
//
//           orderLineAdapter.setText(nextText);
//         }
//       }
//     }
//   } catch (e) {
//     console.log('failed to update prices on tick', e);
//   }
// };

/**
 * Centered chart to current price
 * @chartInstance: IChartingLibraryWidget
 * @props: {
 *   @interval: string,
 *   @high: number,
 *   @low: number
 * }
 */
// const scaleChartPriceRange: ScaleChartPriceRange = (chartInstance, props) => {
//   try {
//     // moved this part to useEffect on symbol change (remove)
//
//     // if (props.interval === '60' && chartInstance) {
//     //   chartInstance.activeChart().setVisibleRange({
//     //     from: dayjs(new Date()).subtract(14, 'day').toDate().getTime() / 1000,
//     //     to: new Date().getTime() / 1000 + 36000,
//     //   });
//     // }
//
//     (async () => {
//       const [exchange, symbol] = chartInstance.activeChart().symbol().split(':');
//       const instance = CCXT.getInstance(exchange.toLowerCase());
//       await instance.initializeExchange();
//
//       const callbackFn = (values) => {
//         const ask = values[symbol]?.value?.ask || 0;
//         if (ask) {
//           instance.observableCurrentPrices.unsubscribe(callbackFn);
//           const currentPrice = ask;
//           const priceInRange = props?.high && props?.low && Number(currentPrice || 0) >= props.low && Number(currentPrice || 0) <= props.high;
//           const currentPriceRange = chartInstance.activeChart().getPanes()[0].getMainSourcePriceScale()?.getVisiblePriceRange();
//
//           // we dont want to scale chart till high and low in visible range
//           if (currentPriceRange && currentPriceRange.from < props.low && currentPriceRange.to > props.high) {
//             return;
//           }
//
//           if (priceInRange) {
//             chartInstance
//               .activeChart()
//               .getPanes()[0]
//               .getMainSourcePriceScale()
//               ?.setVisiblePriceRange({
//                 from: props.low - props.low * 0.07,
//                 to: props.high * 1.07,
//               });
//           } else {
//             if (props?.high && props?.low && currentPrice) {
//               chartInstance
//                 .activeChart()
//                 .getPanes()[0]
//                 .getMainSourcePriceScale()
//                 ?.setVisiblePriceRange({
//                   from: Math.min(props.low - props.low * 0.07, currentPrice - currentPrice * 0.1),
//                   to: Math.max(props.high * 1.07, currentPrice * 1.07),
//                 });
//             }
//           }
//         }
//       };
//
//       instance.subscribeToCurrentPrice(symbol, callbackFn);
//     })();
//   } catch (e) {
//     console.log('Unable to scale chart price range', e);
//   }
// };

/**
 * Show high/low lines on the chart and update the prices when the user moves the line
 * and grid setup is manual
 * @chartInstance: IChartingLibraryWidget
 * @dot: {
 *   @timestamp: number,
 *   @price: number,
 * }
 * @onMoveCallback: (price: number) => void
 */
const showOrderLine: ShowOrderLine = (chartInstance, line, chartId = 'magic_terminal') => {
  if (chartInstance) {
    try {
      if (!highLowIds[chartId]) {
        highLowIds[chartId] = {};
      }

      console.log('line', line);

      const orderLineAdapter = highLowIds[chartId][line.type] as IOrderLineAdapterWithLine;

      if (orderLineAdapter?._line && line.type === 'downward' && !line.price) {
        orderLineAdapter.remove();
      }

      if (orderLineAdapter?._line && line.type === 'upward' && !line.price) {
        orderLineAdapter.remove();
      }

      if (orderLineAdapter?._line && line.type === 'takeProfit' && !line.price) {
        orderLineAdapter.remove();
      }

      if (orderLineAdapter?._line && line.type === 'upperStopLoss' && !line.price) {
        orderLineAdapter.remove();
      }

      if (orderLineAdapter?._line && line.type === 'lowerStopLoss' && !line.price) {
        orderLineAdapter.remove();
      }

      // if (orderLineAdapter?._line && line.type === 'startTriggerPrice' && !line.price) {
      //   orderLineAdapter.remove();
      // }

      if (orderLineAdapter?._line) {
        orderLineAdapter.remove();
      }

      const styleProps = lineStyles[line.type];

      if (line.price) {
        const inst = (highLowIds[chartId][line.type] = chartInstance
          ?.activeChart()
          ?.createOrderLine()
          .setQuantity('')
          .setText(line.text)
          .setBodyTextColor(styleProps.textColor)
          .setLineColor(styleProps.lineColor)
          .setLineStyle(2)
          .setBodyBackgroundColor(styleProps.backgroundColor)
          .setBodyBorderColor(styleProps.borderColor)
          .setPrice(line.price));

        if (line?.onMove && (inst as IOrderLineAdapterWithLine)._line) {
          highLowIds[chartId][line.type]?.onMove(function (this) {
            const price = this.getPrice();

            if (line.type === 'stopLoss' && price >= (line?.highPrice as number)) {
              const nextPrice = (line?.highPrice as number) * 0.999;
              line?.onMove?.(nextPrice);
              this.setPrice(nextPrice);
            } else if (line.type === 'takeProfit' && price <= (line?.lowPrice as number)) {
              const nextPrice = (line?.lowPrice as number) * 1.001;
              line?.onMove?.(nextPrice);
              this.setPrice(nextPrice);
            } else {
              line?.onMove?.(price);
              this.setPrice(price);
            }
          });
        }
      }
    } catch (e) {
      console.log('Unable to show high/low lines', e);
    }
  }
};

/**
 * Creates a line on the chart from the order data
 * @chartInstance: IChartingLibraryWidget
 * @props: {
 *   @containerId: string,
 *   @lines: Array<{ type: string, price: number }>
 * }
 */
const showBreakEvenLine: CreateBreakEvenPrice = (chartInstance, price) => {
  try {
    const orderLineAdapter = highLowIds['breakEvenPrice'] as IOrderLineAdapterWithLine;

    if (orderLineAdapter?._line) {
      orderLineAdapter?.remove();
    }

    if (!price) return;

    const inst = (highLowIds['breakEvenPrice'] = chartInstance
      ?.activeChart()
      ?.createOrderLine()
      .setText('Break Even Price')
      .setExtendLeft(true)
      .setBodyTextColor('#000')
      .setLineColor(colorPalette.breakEven)
      .setLineStyle(2)
      .setExtendLeft(true)
      .setBodyBackgroundColor('#f8f5f5')
      .setBodyBorderColor(colorPalette.breakEven)
      .setPrice(price));

    console.log('inst', inst);
  } catch (e) {
    console.log('Unable to create line', e);
  }
};

/**
 * Removes all the lines from the chart
 * @containerId: string
 */
const deleteLineShapes: DeleteLineShapes = (chartInstance) => {
  try {
    const shapes = chartInstance?.activeChart().getAllShapes();
    if (shapes.length) {
      shapes.forEach((shape) => {
        chartInstance?.activeChart().getShapeById(shape.id)?.setUserEditEnabled(true);
      });
      chartInstance?.activeChart().removeAllShapes();
    }
  } catch (e) {
    console.log(e, 'unable to delete');
  }
};

export const widget = {
  initInstance,
  scaleChartPriceRange: () => null,
  showOrderLine,
  deleteLineShapes,
  showBreakEvenLine,
};
