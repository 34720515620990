import clsx from 'clsx';
import { ComponentProps, FC } from 'react';

import { BaseButton } from '@root/shared/ui/button/base-button';

export const FilledTonalButton: FC<ComponentProps<'button'>> = ({ className, ...props }) => {
  return (
    <BaseButton
      {...props}
      className={clsx(
        'h-10 justify-center items-center gap-2.5 px-3 md:px-5 text-xs font-semibold transition-opacity disabled:opacity-70 bg-white/10 enabled:hover:bg-white/5 rounded-sm',
        className,
      )}
    />
  );
};
