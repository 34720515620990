import clsx from 'clsx';
import { ComponentProps, FC, ReactNode } from 'react';

import { Field, FieldHint } from '@root/shared/ui/form/field';
import { Input } from '@root/shared/ui/form/input';
import { Label } from '@root/shared/ui/form/label';

export const TextInput: FC<ComponentProps<typeof Input> & { className?: string; label?: ReactNode; touched?: boolean; error?: string }> = ({
  className,
  label,
  variant,
  touched,
  error,
  ...props
}) => {
  const showError = touched && error !== undefined;

  return (
    <Field className={clsx({ 'pointer-events-none': props.disabled, 'opacity-30': props.disabled }, className)}>
      {label !== undefined && <Label>{label}</Label>}
      <Input variant={showError ? 'danger' : variant} {...props} />
      {showError && <FieldHint variant='danger'>{error}</FieldHint>}
    </Field>
  );
};
