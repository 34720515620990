import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router';
import { Link, useLocation } from 'react-router';

import { Layout } from '@root/infra/layout/containers/layout';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { Button } from '@root/shared/ui/button';
import { Title } from '@root/shared/ui/typography';
import { externalRedirect } from '@root/shared/utils/helpers/external-redirect';
import { Logger } from '@root/shared/utils/logger';

const LAST_REDIRECTED_TIME = 'lastRedirectedTime';
const REDIRECT_COUNT = 'redirectCount';

export const ErrorPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const error = useRouteError();
  const [isUnhandledError, setIsUnhandledError] = useState(false);
  const [lastRedirectedTime, setLastRedirectedTime] = useLocalStorage<string | null>(LAST_REDIRECTED_TIME, null);
  const [redirectCount, setRedirectCount] = useLocalStorage<number>(REDIRECT_COUNT, 0);
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    try {
      const errorString = (error as Error)?.message as string;

      // eslint-disable-next-line no-debugger -- we want to use debugger here
      debugger;

      if (errorString.includes('dynamically imported')) {
        const lastTime = lastRedirectedTime ? new Date(lastRedirectedTime) : new Date();
        if ((new Date().getTime() - lastTime.getTime()) / 1000 < 60) {
          if (redirectCount < 2) {
            externalRedirect(window.location.href);
            setRedirectCount((val) => val + 1);
          } else {
            setIsUnhandledError(true);
          }
        } else {
          externalRedirect(window.location.href);
          setRedirectCount(1);
        }
        setLastRedirectedTime(new Date().toISOString());
      } else {
        setIsUnhandledError(true);
      }
    } catch (e) {
      console.log('ErrorPage message: ', error);
      Logger.captureMessage(`ErrorPage message: ${e}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to run this only when error changes
  }, [error]);

  if (!isUnhandledError) {
    return null;
  }

  return (
    <Layout hideSocial>
      <div className='flex justify-center items-center w-full p-10 bg-gray-800'>
        <div className=''>
          <div className='flex justify-start items-end'>
            <Title level={5} className='font-bold mr-2.5'>
              <Trans i18nKey='common:unexpectedError' components={{ br: <br /> }} />
            </Title>
          </div>
          <Title level={6} className='mt-3 mb-8 leading-8'>
            {t('common:somethingWentTechnicallyWrong')}
          </Title>

          {!isHomePage && (
            <Link to={'/'}>
              <Button color='primary'>{t('common:Back to Dashboard')}</Button>
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};
