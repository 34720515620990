import { ar } from 'date-fns/locale/ar';
import { de } from 'date-fns/locale/de';
import { es } from 'date-fns/locale/es';
import { fr } from 'date-fns/locale/fr';
import { it } from 'date-fns/locale/it';
import { ja } from 'date-fns/locale/ja';
import { nb } from 'date-fns/locale/nb';
import { pt } from 'date-fns/locale/pt';
import { sk } from 'date-fns/locale/sk';
import { th } from 'date-fns/locale/th';
import { vi } from 'date-fns/locale/vi';
import { zhCN } from 'date-fns/locale/zh-CN';

import clsx from 'clsx';
import { FC, useCallback, useRef, useState } from 'react';
import { DatePickerProps, registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { CalendarIcon } from '@root/shared/icons/calendar-icon';
import { ChevronIcon } from '@root/shared/icons/chevron-icon';
import { DatePicker } from '@root/shared/ui/form/date-input/date-picker.styled';
import { Input } from '@root/shared/ui/form/input';
import { OmitUnion } from '@root/shared/utils/type-utils';

export type SingleValueType = Date | null;
export type RangeValueType = readonly [SingleValueType, SingleValueType];

export type DateInputProps = OmitUnion<DatePickerProps, 'value'> & {
  placeholder?: string;
  size?: 'base' | 'small';
  outlined?: boolean;
  fullWidth?: boolean;
  className?: string;
  inputReadonly?: boolean;
  value: SingleValueType | SingleValueType[];
};

registerLocale('it', it);
registerLocale('es', es);
registerLocale('ja', ja);
registerLocale('fr', fr);
registerLocale('de', de);
registerLocale('zh', zhCN);
registerLocale('th', th);
registerLocale('no', nb);
registerLocale('pt', pt);
registerLocale('sk', sk);
registerLocale('vi', vi);
registerLocale('ar', ar);

export const DateInput: FC<DateInputProps> = ({ className, placeholder, size, fullWidth = false, value, outlined, inputReadonly, ...props }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const pickerRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onCalendarOpen = useCallback(() => setIsOpen(true), []);
  const onCalendarClose = useCallback(() => setIsOpen(false), []);

  const handleIconsClick = useCallback(() => {
    pickerRef.current?.setOpen(false);
  }, []);

  const selectedDate = Array.isArray(value) ? value[0] : value;
  const startDate = Array.isArray(value) ? value[0] : undefined;
  const endDate = Array.isArray(value) ? value[1] : undefined;

  return (
    <DatePicker
      locale={language}
      selected={selectedDate}
      startDate={startDate}
      endDate={endDate}
      onCalendarOpen={onCalendarOpen}
      onCalendarClose={onCalendarClose}
      showPopperArrow={false}
      dateFormat='dd.MM.yyyy'
      placeholderText={placeholder}
      wrapperClassName={fullWidth ? 'w-full' : 'w-auto'}
      ref={pickerRef}
      customInput={
        <Input
          size={size}
          style={{ minWidth: fullWidth ? '100%' : 280 }}
          prefixClassName={clsx({ 'z-2! cursor-text': isOpen })}
          suffixClassName={clsx({ 'z-2! cursor-text': isOpen })}
          overrideReadOnly={inputReadonly}
          prefix={
            <div onClick={handleIconsClick}>
              <CalendarIcon />
            </div>
          }
          className={clsx(className, { 'bg-transparent! border border-gray-700! active:border-gray-100! focus:border-gray-100!': outlined })}
          suffix={
            <div onClick={handleIconsClick}>
              <ChevronIcon
                className={clsx('transform transition-transform', {
                  'text-xs': size === 'small',
                  'rotate-180': !isOpen,
                })}
              />
            </div>
          }
          ref={inputRef}
        />
      }
      {...props}
    />
  );
};
