import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import {
  GET_SUBSCRIPTION_PRODUCTS,
  GetSubscriptionProductsData,
  GetSubscriptionProductsQueryKey,
} from '@root/shared-files/modules/subscriptions/queries/get-subscription-products.query';

export const useGetSubscriptionProducts = () => {
  const user = useSelector(authSelector.user);

  return useQuery<GetSubscriptionProductsData, Error, GetSubscriptionProductsData, GetSubscriptionProductsQueryKey>({
    queryKey: ['subscription-products'],
    queryFn: GET_SUBSCRIPTION_PRODUCTS,
    enabled: !!user,
  });
};
