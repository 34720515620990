import { useCallback, useEffect, useRef } from 'react';
import { Location, useBeforeUnload, useBlocker } from 'react-router';

type Props = {
  shouldBlock: boolean;
  message: string;
  showNativeConfirmation: boolean;
  beforeUnload: boolean;
  onBlock: (location: Location) => void;
};

export const usePrompt = ({ shouldBlock, message, showNativeConfirmation, beforeUnload, onBlock }: Props) => {
  const handleBlock = useCallback(
    ({ nextLocation }) => {
      if (typeof message === 'string') {
        onBlock(nextLocation);
        if (showNativeConfirmation) {
          return window.confirm(message);
        }
        return true;
      } else {
        return false;
      }
    },
    [message, onBlock, showNativeConfirmation],
  );

  const blocker = useBlocker(shouldBlock ? handleBlock : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  const beforeUnloadCallback = useCallback(
    (event) => {
      if (beforeUnload && typeof message === 'string') {
        event.preventDefault();
        event.returnValue = message;
      }
    },
    [message, beforeUnload],
  );

  useBeforeUnload(beforeUnloadCallback, { capture: true });
};
