import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const FullScreenIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 4.75C5.31 4.75 4.75 5.31 4.75 6V9C4.75 9.19891 4.67098 9.38968 4.53033 9.53033C4.38968 9.67098 4.19891 9.75 4 9.75C3.80109 9.75 3.61032 9.67098 3.46967 9.53033C3.32902 9.38968 3.25 9.19891 3.25 9V6C3.25 5.27065 3.53973 4.57118 4.05546 4.05546C4.57118 3.53973 5.27065 3.25 6 3.25H9C9.19891 3.25 9.38968 3.32902 9.53033 3.46967C9.67098 3.61032 9.75 3.80109 9.75 4C9.75 4.19891 9.67098 4.38968 9.53033 4.53033C9.38968 4.67098 9.19891 4.75 9 4.75H6ZM14.25 4C14.25 3.80109 14.329 3.61032 14.4697 3.46967C14.6103 3.32902 14.8011 3.25 15 3.25H18C18.7293 3.25 19.4288 3.53973 19.9445 4.05546C20.4603 4.57118 20.75 5.27065 20.75 6V9C20.75 9.19891 20.671 9.38968 20.5303 9.53033C20.3897 9.67098 20.1989 9.75 20 9.75C19.8011 9.75 19.6103 9.67098 19.4697 9.53033C19.329 9.38968 19.25 9.19891 19.25 9V6C19.25 5.31 18.69 4.75 18 4.75H15C14.8011 4.75 14.6103 4.67098 14.4697 4.53033C14.329 4.38968 14.25 4.19891 14.25 4ZM4 14.25C4.19891 14.25 4.38968 14.329 4.53033 14.4697C4.67098 14.6103 4.75 14.8011 4.75 15V18C4.75 18.69 5.31 19.25 6 19.25H9C9.19891 19.25 9.38968 19.329 9.53033 19.4697C9.67098 19.6103 9.75 19.8011 9.75 20C9.75 20.1989 9.67098 20.3897 9.53033 20.5303C9.38968 20.671 9.19891 20.75 9 20.75H6C5.27065 20.75 4.57118 20.4603 4.05546 19.9445C3.53973 19.4288 3.25 18.7293 3.25 18V15C3.25 14.8011 3.32902 14.6103 3.46967 14.4697C3.61032 14.329 3.80109 14.25 4 14.25ZM20 14.25C20.1989 14.25 20.3897 14.329 20.5303 14.4697C20.671 14.6103 20.75 14.8011 20.75 15V18C20.75 18.7293 20.4603 19.4288 19.9445 19.9445C19.4288 20.4603 18.7293 20.75 18 20.75H15C14.8011 20.75 14.6103 20.671 14.4697 20.5303C14.329 20.3897 14.25 20.1989 14.25 20C14.25 19.8011 14.329 19.6103 14.4697 19.4697C14.6103 19.329 14.8011 19.25 15 19.25H18C18.69 19.25 19.25 18.69 19.25 18V15C19.25 14.8011 19.329 14.6103 19.4697 14.4697C19.6103 14.329 19.8011 14.25 20 14.25Z'
        fill='#FCFCFC'
      />
    </svg>
  );
};
