import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';

export enum MagicTerminalOrderMarketType {
  BUY = 'buy',
  SELL = 'sell',
}
export enum MagicTerminalOrderPendingType {
  MARKET = 'market',
  LIMIT = 'limit',
}

export type MagicTerminalForm = Omit<CreateExpertDto, 'meta'> & {
  symbol: string;
  price: string;
  user?: string;

  marketType: MagicTerminalOrderMarketType;
  pendingType: MagicTerminalOrderPendingType;
  useAdvancedSettings?: boolean;

  lots?: string;
  takeprofit?: string;
  stoploss?: string;
  expirationTime?: string;
};
