import {
  FOREX_WIZARD_SKIPPED,
  GHOST_LOGIN_SESSION_START,
  GHOST_LOGIN_TOKEN,
  GHOST_SESSION_PAGE_LEAVE_TIME,
  GRID_SELECTED_TIMEFRAME,
  KUCOIN_CHANGE_MODAL_SHOWN,
  PRESETS_REDIRECT_TO_ASSIST_CREATION,
  PRESETS_SUBSCRIPTION_ID_KEY,
  PRESET_UPDATE_AVAILABLE_BANNER_SHOWN,
  SERVICE_ACCOUNT_MODAL_SHOWN,
  SUBSCRIPTION_PURCHASE_URL,
} from '@root/shared-files/modules/shared/constants/local-storage-keys';

export const clearLocalStorage = () => {
  if (localStorage) {
    // keys to remove
    localStorage.removeItem(SUBSCRIPTION_PURCHASE_URL);
    localStorage.removeItem(FOREX_WIZARD_SKIPPED);
    localStorage.removeItem(GHOST_LOGIN_TOKEN);
    localStorage.removeItem(GHOST_LOGIN_SESSION_START);
    localStorage.removeItem(GHOST_SESSION_PAGE_LEAVE_TIME);
    localStorage.removeItem(PRESETS_SUBSCRIPTION_ID_KEY);
    localStorage.removeItem(PRESETS_REDIRECT_TO_ASSIST_CREATION);
    localStorage.removeItem(KUCOIN_CHANGE_MODAL_SHOWN);
    localStorage.removeItem(SERVICE_ACCOUNT_MODAL_SHOWN);
    localStorage.removeItem(GRID_SELECTED_TIMEFRAME);
    localStorage.removeItem(PRESET_UPDATE_AVAILABLE_BANNER_SHOWN);
    localStorage.removeItem('twoFaAccessToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('featurebaseGlobalAuth');
    localStorage.removeItem('featurebaseIdentifyData');
    localStorage.removeItem('featurebaseRespondedSurveys');
  }
};
