import { createSlice } from '@reduxjs/toolkit';

import { IQuote } from '@root/modules/quotes/types';

export type IQuotesState = {
  quotesData: Record<string, Record<string, IQuote>>;
  symbolsToSubscribe: string[];
  symbolsSubscribed: string[];
};

const initialState: IQuotesState = {
  quotesData: {},
  symbolsToSubscribe: [],
  symbolsSubscribed: [],
};

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    subscribe: (state, action) => {
      state.symbolsToSubscribe = [...new Set([...state.symbolsToSubscribe, ...action.payload])];
    },
    setSymbolsSubscribed: (state, action) => {
      state.symbolsSubscribed = [...new Set([...state.symbolsSubscribed, ...action.payload])];
      state.symbolsToSubscribe = state.symbolsToSubscribe.filter((symbol) => !action.payload.includes(symbol));
    },
    unsubscribe: (state, action) => {
      state.symbolsSubscribed = state.symbolsSubscribed.filter((symbol) => symbol !== action.payload);
    },
    unsubscribeAll: () => {},
    setQuotesData: (state, action) => {
      state.quotesData = action.payload.reduce((acc, item) => {
        acc[item.brokerId] = {
          ...acc[item.brokerId],
          [item.symbol]: item,
        };
        return acc;
      }, {});
    },
  },
});
