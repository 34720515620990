import { FieldArray, useFormikContext } from 'formik';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { LabelSafeArea } from '@root/infra/form/label-safe-area';
import { LabelWithInfoTooltip } from '@root/infra/form/label-with-info-tooltip';
import { useSm } from '@root/infra/layout/hooks/media';
import { ClassicalPartialTakeProfitsFieldset } from '@root/modules/experts/components/partial-take-profits-fieldset/classical-partial-take-profits-fieldset';
import { PartialTakeProfitTimeBasedLevelsFieldset } from '@root/modules/experts/components/partial-take-profits-fieldset/partial-take-profit-time-based-levels-fieldset';
import { SmartPartialTakeProfitsFieldset } from '@root/modules/experts/components/partial-take-profits-fieldset/smart-partial-take-profits-fieldset';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { createPartialTakeProfitEditingDto } from '@root/modules/experts/helpers/make-expert-form-values';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { SelectField } from '@root/shared/form';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { resolveFieldPath } from '@root/shared/form/form-utils';
import { CloseIcon } from '@root/shared/icons/close-icon';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { PlusIcon } from '@root/shared/icons/plus-icon';
import { Button } from '@root/shared/ui/button';
import { FilledTonalButton } from '@root/shared/ui/button/filled-tonal-button';
import { IconLabelLayout } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';

export const PartialTakeProfitsFieldset: FC<{
  viewOnly?: boolean;
  isPartialEdit?: boolean;
  isTradeUpdate?: boolean;
  magicTerminal?: boolean;
  showDescription?: boolean;
  disabled?: boolean;
}> = ({ viewOnly, isPartialEdit, isTradeUpdate, magicTerminal, showDescription = true, disabled }) => {
  const sm = useSm();

  const densed = !sm || !!isPartialEdit;
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.partialClose' });
  const { t: tCommon } = useTranslation('common');

  const { values, errors, setFieldValue, setFieldTouched } = useFormikContext<CreateExpertDto>();

  const use = values.partialClose?.use;
  const calculationType = values.partialClose?.calculationType;

  const options = useCreateExpertFieldOptions();

  const partialCalculationOptions = useMemo(() => {
    if (magicTerminal) {
      return options.partialCalculationOptions.slice(0, 1);
    }

    return options.partialCalculationOptions;
  }, [options.partialCalculationOptions, magicTerminal]);

  const onCalculateTypeChange = useCallback(
    (option) => {
      if (calculationType === option.value) {
        return;
      }

      setFieldValue('partialClose.calculationType', option.value);

      setFieldValue('partialClose.takeprofits', [createPartialTakeProfitEditingDto({ amount: option.value === '1' ? '1' : '' })]);
    },
    [calculationType, setFieldValue],
  );

  const onUseChange = useCallback(
    (event) => {
      const checked = event.target.checked;

      if (checked) {
        setFieldValue('partialClose.use', true);
      } else {
        setFieldValue('partialClose.use', false);
      }
    },
    [setFieldValue],
  );

  const { takeprofits } = values.partialClose;

  const takeprofitsError = errors.partialClose?.takeprofits;

  const slTpType = values.signalSlTp?.type;
  const slTpProfitCalculationType = values.signalSlTp?.profitCalculationType;
  const rrLabel = values.signalSlTp.riskRatio + ':' + values.signalSlTp.tpRatio;
  const rrProfitsLength = values.signalSlTp.tpRatio - 1;

  const unitType = values.unitType ?? TemplateUnitType.POINTS;

  const levelsViewOnly = viewOnly || (!!isTradeUpdate && calculationType === '1');

  const switchField = (
    <SwitchField
      disabled={viewOnly || disabled}
      name='partialClose.use'
      onChange={onUseChange}
      label={
        <LabelWithInfoTooltip tooltipContent={t('use.description')}>
          <Title level={7}>{t('use.label')}</Title>
        </LabelWithInfoTooltip>
      }
    />
  );

  return (
    <div>
      <div className='flex'>{disabled ? <InfoTooltip content={tCommon('unsubscriptionSupportedFunctionality')}>{switchField}</InfoTooltip> : switchField}</div>

      {!isTradeUpdate && showDescription && (
        <Text size='sm' className='text-gray-500 mb-4'>
          {t('use.description')}
        </Text>
      )}
      {use && (
        <>
          <SelectField
            name='partialClose.calculationType'
            label={
              <IconLabelLayout
                icon={
                  <InfoTooltip content={<Text size='sm'>{t('calculationType.description')}</Text>}>
                    <InfoTooltipIcon />
                  </InfoTooltip>
                }
              >
                {t('calculationType.label')}
              </IconLabelLayout>
            }
            wrapperClassName='mb-4'
            placeholder={t('calculationType.placeholder')}
            options={partialCalculationOptions}
            allOptions={options.partialCalculationOptions}
            onChange={onCalculateTypeChange}
            isDisabled={viewOnly || isTradeUpdate}
          />
          <div className='flex flex-col gap-y-4'>
            {calculationType === '0' && <Text>{t('levels.label')}</Text>}

            {calculationType === '1' && showDescription && (
              <div>
                <Text size='sm' className='text-grayscale-400 mb-4'>
                  {t('calculationType.smartPartialProfitDescription')}
                </Text>
                {slTpType === '4' && slTpProfitCalculationType === '0' && (
                  <Text size='sm' className='text-grayscale-400'>
                    <Trans
                      i18nKey='forex-experts:fields.partialClose.smartLevels.label'
                      components={{ span: <span className='font-bold text-gray-100' /> }}
                      values={{ rr: rrLabel, tpLength: rrProfitsLength }}
                    />
                  </Text>
                )}
              </div>
            )}

            <FieldArray
              name='partialClose.takeprofits'
              render={(takeprofitsHelpers) => {
                const createLevelRemovingButtonClickHandler: (index: number) => MouseEventHandler<HTMLButtonElement> = (index) => () => {
                  takeprofitsHelpers.remove(index);
                };

                const handleLevelAddingButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
                  if (errors.partialClose?.takeprofits !== undefined && takeprofits.length > 0) {
                    takeprofits.forEach(({ timeBasedTakeProfits }, levelIndex) => {
                      const getName = (...parts: (string | number)[]) => resolveFieldPath('partialClose.takeprofits', levelIndex, ...parts);

                      setFieldTouched(getName('amount'), true);
                      setFieldTouched(getName('percent'), true);

                      timeBasedTakeProfits.forEach((_, index) => {
                        setFieldTouched(getName('timeBasedTakeProfits', index, 'amount'), true);
                        setFieldTouched(getName('timeBasedTakeProfits', index, 'afterMinutes'), true);
                      });
                    });

                    return;
                  }

                  takeprofitsHelpers.push(createPartialTakeProfitEditingDto({ amount: calculationType === '1' ? (takeprofits.length + 1).toString() : '' }));
                };

                return (
                  <>
                    <div className='flex flex-col gap-y-3'>
                      {takeprofits.map((_, index) => {
                        const levelRemovingButton = levelsViewOnly ? null : (
                          <LabelSafeArea>
                            <FilledTonalButton onClick={createLevelRemovingButtonClickHandler(index)} disabled={takeprofits.length === 1}>
                              <CloseIcon />
                            </FilledTonalButton>
                          </LabelSafeArea>
                        );

                        const nameSufix = resolveFieldPath('partialClose.takeprofits', index);

                        return (
                          <div key={index} className='flex flex-col gap-y-3'>
                            {calculationType === '0' && (
                              <ClassicalPartialTakeProfitsFieldset nameSufix={nameSufix} unitType={unitType} densed={densed} viewOnly={viewOnly}>
                                {levelRemovingButton}
                              </ClassicalPartialTakeProfitsFieldset>
                            )}
                            {calculationType === '1' && (
                              <SmartPartialTakeProfitsFieldset
                                nameSufix={nameSufix}
                                index={index}
                                densed={densed}
                                viewOnly={levelsViewOnly}
                                unitType={isTradeUpdate ? unitType : '%'}
                              >
                                {levelRemovingButton}
                              </SmartPartialTakeProfitsFieldset>
                            )}

                            <PartialTakeProfitTimeBasedLevelsFieldset
                              viewOnly={levelsViewOnly}
                              nameSufix={resolveFieldPath('partialClose.takeprofits', index, 'timeBasedTakeProfits')}
                              unitType={calculationType === '1' && !isTradeUpdate ? '%' : unitType}
                            />
                          </div>
                        );
                      })}
                    </div>

                    {typeof takeprofitsError === 'string' && (
                      <Text size='sm' className='text-danger-500 mb-4 text-right'>
                        {takeprofitsError}
                      </Text>
                    )}

                    {!levelsViewOnly && (
                      <Button outlined onClick={handleLevelAddingButtonClick} className='self-start border-none px-0!' prefix={<PlusIcon />}>
                        {t('levels.add')}
                      </Button>
                    )}
                  </>
                );
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
