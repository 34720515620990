import clsx from 'clsx';
import { DetailedHTMLProps, HTMLAttributes, TextareaHTMLAttributes, forwardRef } from 'react';

export interface TextAreaProps extends Omit<DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'prefix' | 'size'> {
  variant?: 'primary' | 'danger';
  inputMode?: HTMLAttributes<HTMLTextAreaElement>['inputMode'];
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function Input({ className, variant, ...props }, ref) {
  return (
    <div className='relative'>
      <textarea
        className={clsx(
          'w-full border bg-gray-100/10 rounded-sm py-1.5 px-4 m-0 outline-hidden text-gray-400 text-sm resize-none disabled:bg-grayscale-700/80 disabled:text-grayscale-600 z-1',
          {
            'border-transparent active:border-white focus:border-white': !variant,
            'border-danger-500': variant === 'danger',
            'border-primary-500': variant === 'primary',
          },
          className,
        )}
        {...props}
        ref={ref}
      />
    </div>
  );
});
