import { configureStore } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';

import { ReduxActionListener } from '@root/infra/redux/redux-utils';
import { rootReducer } from '@root/infra/store/root-reducer';
import { rootSaga } from '@root/infra/store/root-saga';

const actionListener = new ReduxActionListener();

const sagaMiddleware = createSagaMiddleware();

const _store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware, actionListener.middleware),
  devTools: import.meta.env.DEV,
});

sagaMiddleware.run(rootSaga);

export const store = { ..._store, actionListener };

export type AppStore = typeof store;

export type RootState = ReturnType<AppStore['getState']>;

export type AppDispatch = AppStore['dispatch'];
