import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { GET_PRESET, GetPresetData, GetPresetQueryKey } from '@root/modules/presets/queries/get-preset.query';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useGetPreset = (id: string) => {
  const userId = useSelector(authSelector.userId);

  return useQuery<GetPresetData, Error, GetPresetData, GetPresetQueryKey>({
    queryKey: ['presets', id],
    queryFn: GET_PRESET,
    enabled: !!id && !!userId,
  });
};
