import omit from 'lodash/omit';

import { PartialTakeProfit, PartialTakeProfitPersistetDto } from '@root/modules/experts/types/expert';

export class PartialTakeProfitMapper {
  public static toDomain = (dto: PartialTakeProfitPersistetDto): PartialTakeProfit => ({
    timeBasedTakeProfits: [],
    ...dto,
  });

  public static toPersistence = (takeprofit: PartialTakeProfit): PartialTakeProfitPersistetDto => {
    if (takeprofit.timeBasedTakeProfits.length > 0) {
      return takeprofit;
    }

    return omit(takeprofit, 'timeBasedTakeProfits');
  };
}
