import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Disclaimer from '@root/infra/layout/components/disclaimer';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { SelectField, TextField } from '@root/shared/form';
import { InfoTooltipIcon } from '@root/shared/icons/info-tooltip-icon';
import { IconLabelLayout } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';

type MoneyManagementFieldsetProps = {
  fieldsSchema: 'signal' | 'manual';
  isPartialEdit?: boolean;
  isCopyPresetModal?: boolean;
  isMagicTerminal?: boolean;
};

export const MoneyManagementFieldset: FC<MoneyManagementFieldsetProps> = ({ fieldsSchema, isPartialEdit, isCopyPresetModal = false, isMagicTerminal }) => {
  const { t } = useTranslation('forex-experts');
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();
  const options = useCreateExpertFieldOptions();

  const fieldsSchemaIsSignal = fieldsSchema === 'signal';

  const type = fieldsSchemaIsSignal ? values.signalMoneyManagement.type : values.manualMoneyManagement.type;

  const managementKey = fieldsSchemaIsSignal ? 'signalMoneyManagement' : 'manualMoneyManagement';
  const optionsKey = fieldsSchemaIsSignal ? 'signalMoneyManagementType' : 'manualMoneyManagementType';
  const basedOnOptionsKey = fieldsSchemaIsSignal ? 'signalMoneyManagementBasedOn' : 'manualMoneyManagementBasedOn';

  const basedOn = values[managementKey].basedOn;

  const onTypeChange = useCallback(
    (option) => {
      const value = option.value;

      setFieldValue(`${managementKey}.type`, value);
      setFieldValue('maxDrawDownLimit.basedOn', basedOn);
    },
    [setFieldValue, basedOn, managementKey],
  );

  const onBasedOnChange = useCallback(
    (option) => {
      const value = option.value;
      setFieldValue(`${managementKey}.basedOn`, value);
      setFieldValue('maxDrawDownLimit.basedOn', value);
    },
    [setFieldValue, managementKey],
  );

  return (
    <div>
      {!isPartialEdit && (
        <Title level={7} className='mb-4'>
          {t('steps.moneyManagement.title')}
        </Title>
      )}

      <SelectField
        name={`${managementKey}.type`}
        label={
          <IconLabelLayout
            icon={
              <InfoTooltip content={<Text size='sm'>{t(`fields.${managementKey}.type.description`)}</Text>}>
                <InfoTooltipIcon />
              </InfoTooltip>
            }
          >
            {t(`fields.${managementKey}.type.label`)}
          </IconLabelLayout>
        }
        wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
        placeholder={t(`fields.${managementKey}.type.placeholder`)}
        options={options[optionsKey]}
        onChange={onTypeChange}
      />

      {!isMagicTerminal && <Disclaimer textKey='disclaimer.money_management' className='bg-white/5 p-2.5 rounded-sm mb-4' />}

      {['0', '2'].includes(type) && (
        <TextField
          name={`${managementKey}.lotsize`}
          label={
            <IconLabelLayout
              icon={
                <InfoTooltip content={<Text size='sm'>{t(`fields.${managementKey}.lotsize.description`)}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t(`fields.${managementKey}.lotsize.label`)}
            </IconLabelLayout>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t(`fields.${managementKey}.lotsize.placeholder`)}
        />
      )}

      {['1'].includes(type) && (
        <TextField
          name={`${managementKey}.riskPercent`}
          label={
            <IconLabelLayout
              icon={
                <InfoTooltip content={<Text size='sm'>{t(`fields.${managementKey}.riskPercent.description`)}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t(`fields.${managementKey}.riskPercent.label`)}
            </IconLabelLayout>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t(`fields.${managementKey}.riskPercent.placeholder`)}
          suffix='%'
        />
      )}

      {(fieldsSchemaIsSignal ? ['1', '2', '3', '4'] : ['1', '2']).includes(type) && (
        <SelectField
          name={`${managementKey}.basedOn`}
          label={
            <IconLabelLayout
              icon={
                <InfoTooltip content={<Text size='sm'>{t(`fields.${managementKey}.basedOn.description`)}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t(`fields.${managementKey}.basedOn.label`)}
            </IconLabelLayout>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t(`fields.${managementKey}.basedOn.placeholder`)}
          options={options[basedOnOptionsKey]}
          onChange={onBasedOnChange}
        />
      )}

      {['2'].includes(type) && (
        <TextField
          name={`${managementKey}.k`}
          label={
            <IconLabelLayout
              icon={
                <InfoTooltip content={<Text size='sm'>{t(`fields.${managementKey}.k.description`)}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t(`fields.${managementKey}.k.label`)}
            </IconLabelLayout>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t(`fields.${managementKey}.k.placeholder`)}
        />
      )}

      {fieldsSchemaIsSignal && ['3', '4'].includes(type) && (
        <TextField
          name='signalMoneyManagement.xPercent'
          label={
            <IconLabelLayout
              icon={
                <InfoTooltip content={<Text size='sm'>{t('fields.signalMoneyManagement.xPercent.description')}</Text>}>
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.signalMoneyManagement.xPercent.label')}
            </IconLabelLayout>
          }
          placeholder={t('fields.signalMoneyManagement.xPercent.placeholder')}
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          suffix='%'
        />
      )}

      {!isMagicTerminal && !isCopyPresetModal && (
        <TextField
          name={fieldsSchemaIsSignal ? 'maxSignalActiveTrades' : 'maxManualActiveTrades'}
          label={
            <IconLabelLayout
              icon={
                <InfoTooltip
                  content={
                    <Text size='sm'>
                      <Trans i18nKey={'forex-templates:form.fields.manualMoneyManagement.maxActiveTrades.description'} />
                    </Text>
                  }
                  keepOnMouseLeave
                >
                  <InfoTooltipIcon />
                </InfoTooltip>
              }
            >
              {t('fields.manualMoneyManagement.maxActiveTrades.label')}
            </IconLabelLayout>
          }
          wrapperClassName={clsx({ 'mb-4': !isPartialEdit, 'mb-2': isPartialEdit })}
          placeholder={t('fields.manualMoneyManagement.maxActiveTrades.placeholder')}
        />
      )}
    </div>
  );
};
