import { all, call } from '@redux-saga/core/effects';

import { SagaReturnType, put, select, takeLatest } from 'redux-saga/effects';

import { queryClient } from '@root/infra/query';
import { accountsSlice } from '@root/modules/accounts/store/accounts.slice';
import { runExpertService } from '@root/modules/experts/services/run-expert.service';
import { expertsSelector } from '@root/modules/experts/store/experts.selector';
import { expertsSlice } from '@root/modules/experts/store/experts.slice';

function* removeFromQueue(result) {
  if (result.status === 200) {
    yield put(expertsSlice.actions.runExpertQueueRemove({ expertId: result.payload }));
  }
}

function* handleRunExpertQueue(action: SagaReturnType<typeof accountsSlice.actions.setAccountBalanceFetched>) {
  const accountId = action.payload;
  const runExpertQueue = yield select(expertsSelector.runExpertQueue);
  const experts = runExpertQueue.filter((expert) => expert.accountId === accountId);

  if (experts.length) {
    const results = yield all(experts.map((item) => call(runExpertService, { id: item.expertId, symbols: item.symbols })));

    if (results.some((result) => result.status === 200)) {
      queryClient.invalidateQueries({ queryKey: ['experts'] });
    }

    yield all(results.map((result) => call(removeFromQueue, result)));
  }
}

export function* expertsSaga() {
  yield takeLatest(accountsSlice.actions.setAccountBalanceFetched.type, handleRunExpertQueue);
}
