import clsx from 'clsx';
import { DetailedHTMLProps, FC, HTMLAttributes, LabelHTMLAttributes, ReactNode } from 'react';

export type LabelProps = DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & { disabled?: boolean };

export const Label: FC<LabelProps> = ({ className, ...props }) => {
  return <label className={clsx('block mb-1 text-sm min-h-[22px]', { 'opacity-50': props.disabled }, className)} {...props} />;
};

export type IconLabelProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & { disabled?: boolean; icon: ReactNode };

export const IconLabelLayout: FC<IconLabelProps> = ({ children, className, icon, ...props }) => {
  return (
    <span {...props} className={clsx('text-sm min-h-[22px] flex items-center gap-2', { 'opacity-50': props.disabled }, className)}>
      <span>{children}</span> <span className='text-[16px]'>{icon}</span>
    </span>
  );
};
