import clsx from 'clsx';
import getSymbolFromCurrency from 'currency-symbol-map';
import { FC } from 'react';

import { globalRound } from '@root/shared/utils/number/round';
import { formatIfNegativeAmount } from '@root/shared/utils/string';

export const formatMoney = (value: number, currency: string) => {
  const currencySymbol = getSymbolFromCurrency(currency);

  return formatIfNegativeAmount(globalRound(value, 2), currencySymbol !== undefined ? currencySymbol : currency, currency === '' ? false : currencySymbol === undefined);
};

export const AmountWithCurrency: FC<{ className?: string; currency?: string; value: number }> = ({ className, currency, value }) => {
  const formattedPrice = formatMoney(value, currency ?? '');

  return <span className={clsx('whitespace-nowrap', className)}>{formattedPrice}</span>;
};
