import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IExpert } from '@root/modules/experts/types/expert';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type GetExpertsServiceResponse = IHttpResponse<200, IExpert[]> | IHttpResponse<400, string>;

export const getExpertsService = async (): Promise<GetExpertsServiceResponse> => {
  try {
    const response = await fetcher(`/experts`, { baseURL: import.meta.env.VITE_FX_CORE_HOST });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
