import { TimeInForcePredefinedValue } from '@root/modules/experts/dtos/create-expert.dto';
import { ExpertOrderType } from '@root/modules/experts/types/common';

const orderTypesThoseSupportTimeInForce = [ExpertOrderType.NEUTRAL, ExpertOrderType.LIMIT];

export const doesOrderTypeSupportTimeInForce = (orderType: ExpertOrderType) => orderTypesThoseSupportTimeInForce.includes(orderType);

export const isTimeInForcePredefinedValue = (value: string): value is TimeInForcePredefinedValue =>
  value === TimeInForcePredefinedValue.goodTillCancelled || value === TimeInForcePredefinedValue.today;

const timeInForceOptionsTranslationPath = `forex-experts:fields.timeInForce.options`;

export const timeInForcePredefinedValueOptions = [
  {
    value: TimeInForcePredefinedValue.goodTillCancelled,
    labelTranslationId: `${timeInForceOptionsTranslationPath}.goodTillCanceled`,
  },
  {
    value: TimeInForcePredefinedValue.today,
    labelTranslationId: `${timeInForceOptionsTranslationPath}.today`,
  },
];
