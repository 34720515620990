import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useGetExpert } from '@root/modules/experts/hooks/use-get-expert';
import { IExpert } from '@root/modules/experts/types/expert';
import { getSignalService } from '@root/modules/magic-terminal/services/get-signal.service';
import { ISignalData } from '@root/modules/magic-terminal/types/signal';
import { notify } from '@root/shared/utils/notification';

interface SignalContextType {
  signalData: ISignalData | null;
  isLoading: boolean;
  error: string | null;
  expert?: IExpert;
}

const SignalContext = createContext<SignalContextType | undefined>(undefined);

interface SignalProviderProps {
  children: ReactNode;
}

export const SignalProvider: React.FC<SignalProviderProps> = ({ children }) => {
  const { id } = useParams();
  const [signalData, setSignalData] = useState<ISignalData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { data, isLoading: expertIsLoading } = useGetExpert(signalData?.expertId);

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await getSignalService(id);

        if (response.status === 200) {
          setSignalData(response.payload);
        } else {
          notify({
            text: response.payload || 'Unknown error occurred',
            type: 'danger',
          });
          setError(response.payload);
        }
      } finally {
        setIsLoading(false);
      }
    };

    id && fetchData(id);
  }, [id]);

  return <SignalContext.Provider value={{ signalData, isLoading: isLoading || expertIsLoading, error, expert: data }}>{children}</SignalContext.Provider>;
};

export const useSignal = (): SignalContextType => {
  const context = useContext(SignalContext);
  if (!context) {
    throw new Error('useSignal must be used within a SignalProvider');
  }
  return context;
};
