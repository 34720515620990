import { MagicTerminalOrderMarketType, MagicTerminalOrderPendingType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { MainOrderType } from '@root/modules/orders/enums/orders';

export const GetMagicTerminalOrderType = (marketType: MagicTerminalOrderMarketType, pendingType: MagicTerminalOrderPendingType): MainOrderType => {
  if (marketType === MagicTerminalOrderMarketType.BUY && pendingType === MagicTerminalOrderPendingType.MARKET) {
    return MainOrderType.BUY;
  } else if (marketType === MagicTerminalOrderMarketType.SELL && pendingType === MagicTerminalOrderPendingType.MARKET) {
    return MainOrderType.SELL;
  } else if (marketType === MagicTerminalOrderMarketType.BUY && pendingType === MagicTerminalOrderPendingType.LIMIT) {
    return MainOrderType.BUY_LIMIT;
  } else if (marketType === MagicTerminalOrderMarketType.SELL && pendingType === MagicTerminalOrderPendingType.LIMIT) {
    return MainOrderType.SELL_LIMIT;
  } else {
    throw new Error('Invalid marketType or pendingType');
  }
};
