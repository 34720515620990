import styled from 'styled-components';

import { FC, ReactNode } from 'react';

import { CheckIcon } from '@root/shared/icons/check-icon';
import { Text, Title } from '@root/shared/ui/typography';

export const StepIcon = styled.div<{ isActive: boolean; checked: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${({ theme, isActive, checked }) => (isActive || checked ? theme.success[400] : theme.gray[600])};
  color: ${({ theme, isActive, checked }) => (isActive || checked ? theme.success[400] : theme.gray[600])};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type Props = {
  count: number;
  title: ReactNode;
  isActive: boolean;
  checked: boolean;
  children: ReactNode;
};

export const StepItem: FC<Props> = ({ isActive, checked, count, title, children }) => {
  return (
    <div className='w-full'>
      <div className='w-full flex justify-start items-center gap-4'>
        <StepIcon
          isActive={isActive}
          checked={checked}
          // We still in need of `flex-shrink` to support tailwind v3
          className='flex-shrink shrink'
        >
          {checked ? <CheckIcon /> : <Text className='font-bold'>{count}</Text>}
        </StepIcon>
        {typeof title === 'string' ? (
          <Title level={7} className='font-semibold'>
            {title}
          </Title>
        ) : (
          title
        )}
      </div>
      {isActive && <div className='flex flex-col gap-2 ltr:ml-14 rtl:mr-14'>{children}</div>}
    </div>
  );
};
