import { GoogleOAuthProvider } from '@react-oauth/google';
import 'dayjs/locale/ar';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/nb';
import 'dayjs/locale/pt';
import 'dayjs/locale/th';
import 'dayjs/locale/zh';
import { ThemeProvider } from 'styled-components';

import 'react-datepicker/dist/react-datepicker.min.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { FeatureBaseWidget } from '@root/infra/integrations/featurebase';
import { IframeChecker } from '@root/infra/layout/containers/iframe-checker';
import { useShowConsoleMessage } from '@root/infra/layout/hooks/use-show-console-message';
import { QueryProvider } from '@root/infra/query';
import { Router } from '@root/infra/router';
import { StoreProvider } from '@root/infra/store';
import { GlobalStyles, theme } from '@root/infra/theme';
import { useSwitchLocale } from '@root/shared/hooks/use-switch-locale';
import { ToastContainer } from '@root/shared/ui/toast/toast.ui';

function App() {
  useSwitchLocale();
  useShowConsoleMessage();

  return (
    <IframeChecker>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID || ''}>
        <QueryProvider>
          <StoreProvider>
            <ThemeProvider theme={theme}>
              <SkeletonTheme baseColor='#464646' highlightColor='#8E8E8E'>
                <Router />
                <ToastContainer position='top-right' autoClose={5000} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                <FeatureBaseWidget />
                <GlobalStyles />
                <div id='absolute-portal-area' />
              </SkeletonTheme>
            </ThemeProvider>
          </StoreProvider>
        </QueryProvider>
      </GoogleOAuthProvider>
    </IframeChecker>
  );
}

export default App;
