import styled from 'styled-components';

import { ChangeEvent, KeyboardEvent, forwardRef, useCallback, useState } from 'react';

import { CrossIcon as CloseIcon } from '@root/shared/icons/cross-icon';
import { SearchIcon } from '@root/shared/icons/search-icon';
import { Input, InputProps } from '@root/shared/ui/form';

const SearchInput = styled(Input)`
  height: 37px;
  flex: 1;
  background-color: transparent !important;

  &:focus,
  &:hover {
    border-color: transparent !important;
  }
`;

const DeleteButton = styled.button`
  height: 18px;
  width: 18px;
  transform: translateY(-50%);
  top: 50%;
  right: 10px;
  position: absolute;
  display: inline-block;

  svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    fill: ${({ theme }) => theme.gray['800']};
  }

  &:hover {
    border: none;
    opacity: 0.8;
  }
`;

const Container = styled.div`
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.gray['600']}`};
  background-color: ${({ theme }) => theme.grayscale['700']};
  border-radius: 4px;
  width: 100%;
  position: relative;

  & .search-icon {
    font-size: 22px;
    color: ${({ theme }) => theme.gray['100']};
    margin-left: 14px;
    transform: translateY(0px);
  }

  &:focus-within {
    border: ${({ theme }) => `1px solid ${theme.gray['100']}`};
  }
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.gray['500']}`};
  }
`;

export interface SearchFieldProps extends InputProps {
  onSearch?: (value: string) => void;
  onClear?: () => void;
}

export const SearchField = forwardRef<any, SearchFieldProps>(function SearchField({ onSearch, onClear, size, ...inputProps }, ref) {
  const [value, setValue] = useState<string>('');

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleSearch = useCallback(() => {
    onSearch?.(typeof inputProps.value === 'string' ? inputProps.value : value);
  }, [inputProps.value, onSearch, value]);

  const handleClear = useCallback(() => {
    setValue('');
  }, []);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch],
  );

  return (
    <Container ref={ref}>
      <div className='flex items-center search-icon'>
        <SearchIcon />
      </div>
      <SearchInput value={value} onKeyPress={handleKeyPress} onChange={handleChange} {...inputProps} className='pl-2.5  pr-8 py-1' />

      {(!!inputProps.value || !!value) && (
        <DeleteButton onClick={onClear || handleClear}>
          <CloseIcon />
        </DeleteButton>
      )}
    </Container>
  );
});
