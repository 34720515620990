import * as yup from 'yup';

import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient } from '@root/infra/query';
import { ModifyModalProps } from '@root/modules/orders/components/update-order-modal';
import { OrderType } from '@root/modules/orders/enums/orders';
import { ExternalOrderModifyingDto, modifyExternalOrderService } from '@root/modules/orders/services/modify-external-order.service';
import { SfxOrderModifyingDto, modifyOrderService } from '@root/modules/orders/services/modify-order.service';
import { isSfxOrder } from '@root/modules/orders/utils/orders';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';

export type OrderModifyingDto = SfxOrderModifyingDto & ExternalOrderModifyingDto;

export const useModifyOrder = ({ order, closeModal }: ModifyModalProps) => {
  const { t } = useTranslation('forex-orders');
  const { t: yupT } = useTranslation('yup');

  const { ticket, type, symbol, stopLoss, takeProfit, openPrice, expirationTime, accountId } = order;

  const orderIsSfx = isSfxOrder(order);

  const orderIsMagic = orderIsSfx && order.magic;

  const expertId = orderIsSfx ? order.expertId : undefined;

  const initialValues = useMemo<OrderModifyingDto>(
    () => ({
      accountId,
      stoploss: `${stopLoss ?? 0}`,
      takeprofit: `${takeProfit ?? 0}`,
      price: openPrice.toString(),
      expirationTime,
      symbol,
      ticket,
      type,
      orderIsMagic,
    }),
    [accountId, stopLoss, takeProfit, openPrice, expirationTime, symbol, ticket, type, orderIsMagic],
  );

  const onSubmit = useCallback(
    async (values: OrderModifyingDto, helpers: FormikHelpers<OrderModifyingDto>) => {
      helpers.setStatus(undefined);

      const result = await (orderIsSfx
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we know that expertId is not undefined when order is sfx
          modifyOrderService(expertId!, values)
        : modifyExternalOrderService(accountId, values));

      if (result.status === 200) {
        helpers.setStatus({ type: 'success', message: null } as IFormStatus);

        if (orderIsSfx) {
          queryClient.invalidateQueries({ queryKey: ['fx', expertId] });
        } else {
          queryClient.invalidateQueries({ queryKey: ['fx', accountId, 'open-orders'] });
        }

        notify({
          type: 'success',
          title: t('updated'),
        });

        closeModal();
      } else {
        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
        notify({
          type: 'danger',
          title: result.payload,
        });
      }
    },
    [expertId, accountId, closeModal, t, orderIsSfx],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        stoploss: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .required(yupT('mixed.requiredNoPath'))
          .label(t('fields.sl.label')),
        takeprofit: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .required(yupT('mixed.requiredNoPath'))
          .label(t('fields.tp.label')),
        price: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .when('type', {
            is: OrderType.PENDING,
            then: (schema) => schema.required(yupT('mixed.requiredNoPath')),
          })
          .label(t('manualOrders.pending.fields.price.label', { ns: 'forex-experts' })),
        expirationTime: yup
          .string()
          .when('type', {
            is: OrderType.PENDING,
            then: (schema) => schema.required(yupT('mixed.required', { path: 'fields.expirationTime' })),
          })
          .label(t('fields.timeInForce.label', { ns: 'forex-experts' })),
      }),
    [t, yupT],
  );

  return { initialValues, onSubmit, validationSchema };
};
