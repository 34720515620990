import styled from 'styled-components';

import clsx from 'clsx';
import {
  ButtonHTMLAttributes,
  ComponentProps,
  ComponentType,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  SVGProps,
  forwardRef,
  lazy,
  useCallback,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { useXl } from '@root/infra/layout/hooks/media';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { Text } from '@root/shared/ui/typography';

const Popover = lazy(() => import('react-tiny-popover').then(({ Popover }) => ({ default: Popover })));

export interface SidebarItemProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'title'> {
  title: ReactNode | string;
  icon: ReactNode;
  nestedClassName?: string;
  isActive?: boolean;
  disabled?: boolean;
  menuOpened: boolean;
  newLabel?: string;
}

const StyledNav = styled.nav`
  box-shadow: rgba(12, 12, 12, 0.83) 0 1px 4px 0;
  overflow-x: visible;

  scrollbar-color: ${({ theme }) => theme.gray[800]} ${({ theme }) => theme.gray[800]};
  z-index: 20;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.gray[800]};
  }

  padding-right: 6px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    scrollbar-color: #1f2329 #2a2e34;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #1f2329;
    }

    &::-webkit-scrollbar-corner {
      background-color: #1f2329;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #1f2329;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2a2e34;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #2a2e34;
    }
  }
`;

const Item = forwardRef<HTMLAnchorElement, SidebarItemProps>(function Item({ newLabel, title, menuOpened, icon, isActive, disabled, className, nestedClassName, ...props }, ref) {
  const Wrapper = menuOpened ? ({ children }) => children : PopoveredItem;

  return (
    <Wrapper title={title as string}>
      <span
        className={twMerge(
          clsx(
            'flex flex-col items-start justify-center text-center w-full px-2 h-10 xl:h-12 relative transition text-gray-400',
            {
              'text-primary-300 bg-primary-500/5': isActive && !disabled,
            },
            {
              'hover:text-primary-300 hover:bg-primary-500/5': /*props.menuOpened &&*/ !disabled,
            },
            {
              'opacity-40! cursor-default! !hover:bg-transparent text-gray-600!': disabled,
            },
          ),
          className,
        )}
        {...props}
        ref={ref}
      >
        <div className={clsx('max-w-full flex items-center justify-start gap-x-3 text-left', nestedClassName)}>
          <div className='text-xl mb-1'>{icon}</div>
          {menuOpened ? <Text className='overflow-hidden text-ellipsis'>{title}</Text> : null}
          {menuOpened && newLabel && (
            <div className='bg-primary-800 text-gray-100 rounded-xl px-2 py-0.5'>
              <Text size='sm'>{newLabel}</Text>
            </div>
          )}
        </div>
        <div
          className={clsx('absolute top-0 right-0 bottom-0 w-0.5 bg-primary-300 rounded-l transition-opacity', {
            'opacity-0': !isActive,
            'opacity-100': !disabled && isActive,
          })}
        />
      </span>
    </Wrapper>
  );
});

const PopoveredItem = (props: { title: string; children: React.ReactNode }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  return (
    <Popover
      positions={['right', 'bottom']}
      align='center'
      padding={0}
      onClickOutside={toggleDropdown}
      isOpen={isDropdownOpen}
      content={() => <div className='px-1 text-xs leading-4 bg-grayscale-600 text-grayscale-100 rounded-sm z-1'>{props.title || ''}</div>}
      containerStyle={{ zIndex: '1000' }}
    >
      <span className={clsx('cursor-pointer')} onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
        {props.children}
      </span>
    </Popover>
  );
};

const SecondaryItem = forwardRef<HTMLAnchorElement, SidebarItemProps>(function Item({ title, icon, isActive, disabled, className, ...props }, ref) {
  return (
    <span
      className={clsx(
        'flex flex-col items-center justify-center text-center p-2 w-full h-15 relative transition rounded-sm',
        'hover:text-primary-300 hover:bg-primary-500/5',
        {
          'text-primary-300 bg-primary-500/5': isActive && !disabled,
        },
        {
          'opacity-40! cursor-default! !hover:bg-transparent text-gray-600!': disabled,
        },
        className,
      )}
      {...props}
      ref={ref}
    >
      <div className={clsx('w-full flex items-center gap-x-3', { 'justify-center': !title, 'justify-start': !!title })}>
        <div className='text-xl mb-1'>{icon}</div>
        {title ? <Text>{title}</Text> : null}
      </div>
    </span>
  );
});

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const xl = useXl();
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const { isGhostLogin } = useGhostLogin();

  if (!xl && !menuOpened) {
    return null;
  }

  const widthClassNames = xl ? (menuOpened ? 'w-[200px]' : 'w-[64px]') : 'w-[290px]! sm:w-[370px]!';
  const bgClassNames = 'bg-gray-800';
  const contentWrapperCommonClassNames = clsx('fixed xl:pt-[90px] pb-4 xl:pb-[72px] overflow-y-auto bg-gray-800 z-100! xl:z-15!', bgClassNames);

  const content = <div className='min-h-full flex flex-col justify-between '>{children}</div>;

  if (isGhostLogin) {
    return (
      <StyledNav className={clsx(widthClassNames, contentWrapperCommonClassNames, 'ltr:left-[3px] rtl:right-[3px] bottom-[4px] top-[60px] text-[14px]!')}>{content}</StyledNav>
    );
  }

  const positionClassNames = 'ltr:left-0 rtl:right-0 top-0 bottom-0';

  return (
    <StyledNav className={clsx(widthClassNames, positionClassNames, bgClassNames, 'absolute text-[14px]! z-1000000! xl:z-15!')}>
      <div className={clsx(widthClassNames, positionClassNames, contentWrapperCommonClassNames)}>{content}</div>
    </StyledNav>
  );
};

const Help = forwardRef<HTMLButtonElement, ComponentProps<'button'>>(function Help({ className, ...props }, ref) {
  return <button {...props} className={clsx('py-1 transition-colors hover:text-primary-300 inline-block bg-gray-900 rounded-sm', className)} ref={ref} />;
});

const HelpItem = forwardRef<HTMLAnchorElement, PropsWithChildren<{ className?: string }>>(function Item({ children, className }, ref) {
  return (
    <span className={clsx('block px-2 py-3 text-lg', className)} ref={ref}>
      {children}
    </span>
  );
});

const PopoverItem: FC<PropsWithChildren<{ icon: ComponentType<SVGProps<SVGSVGElement>> } & ButtonHTMLAttributes<HTMLButtonElement>>> = ({ icon: Icon, children, ...rest }) => (
  <button className='text-left text-[14px] px-4 py-2 flex items-center hover:text-primary-400 disabled:text-gray-600' {...rest}>
    <span className='mr-2'>
      <Icon />
    </span>
    <span>{children}</span>
  </button>
);

export const SidebarElements = { Wrapper, Item, SecondaryItem, Help, HelpItem, PopoverItem };
