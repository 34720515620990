import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IAccount } from '@root/modules/accounts';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { OpenOrdersDtoMapper } from '@root/modules/orders/mappers/open-orders-dto.mapper';
import { CurrentPrice, IOrder } from '@root/modules/orders/types/orders';
import { isBuyOrderType, isSellOrderType } from '@root/modules/orders/utils/orders';
import { quotesSelector } from '@root/modules/quotes/store/quotes.selector';

export const selectPriceByOrderType = (currentPrice: CurrentPrice, type: IOrder['type']) => {
  if (isSellOrderType(type)) {
    return currentPrice.ask;
  }

  if (isBuyOrderType(type)) {
    return currentPrice.bid;
  }

  throw new Error(`Invalid order type: ${type}`);
};

export const selectCurrentPrice = (order: Pick<IOrder, 'currentPrice' | 'type'>) => (order.currentPrice !== null ? selectPriceByOrderType(order.currentPrice, order.type) : null);

export const useOrderCurrentPrice = (order: IOrder) => {
  const { data: accounts } = useGetAccounts();
  const quotesData = useSelector(quotesSelector.quotesData);

  const brokerIdByAccountId = useMemo(() => {
    return (accounts as IAccount[])?.reduce((acc, item) => {
      acc[item.id] = item.companyId;
      return acc;
    }, {});
  }, [accounts]);

  const currentOrder = OpenOrdersDtoMapper.addCurrentPrice(order, quotesData?.[brokerIdByAccountId[order.accountId]]?.[order.symbol]);

  const currentPrice = selectCurrentPrice(currentOrder);

  return { currentPrice };
};
