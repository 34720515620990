import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { IAccount } from '@root/modules/accounts/types/account';
import { IOption } from '@root/shared/types/general';

export enum AllOptionAddingStrategy {
  'supportSeveralCurrencies',
  'singleCurrencyOnly',
}

type Props = {
  allOptionAddingStrategy?: AllOptionAddingStrategy;
  mtType?: string;
  signedOnly?: boolean;
};

const isSingleCurrency = (filteredAccounts: IAccount[]) => new Set(filteredAccounts.map((account) => account.currency).filter(Boolean)).size === 1;

export const useGetAccountsOptions = ({ allOptionAddingStrategy, mtType, signedOnly }: Props = {}) => {
  const { t } = useTranslation('dashboard');
  const { data: accounts, isFetched } = useGetAccounts();

  return useMemo(() => {
    // TODO: useGetAccounts return type can be improved to satisfy more strict type checking

    if (!isFetched) {
      return null;
    }

    if (accounts === undefined) {
      return [];
    }

    const conditions: ((item: IAccount) => boolean)[] = [];

    if (mtType) conditions.push((item) => item.mtType === mtType);
    if (signedOnly) conditions.push((item) => item.isSignedIn);

    const filteredAccounts = accounts.filter((item) => conditions.every((condition) => condition(item)));

    const options: IOption[] = filteredAccounts.map((account) => ({
      value: account.id,
      label: `${account.company} - ${account.mtType}`,
    }));

    if (
      filteredAccounts.length > 1 &&
      (allOptionAddingStrategy === AllOptionAddingStrategy.supportSeveralCurrencies ||
        (allOptionAddingStrategy === AllOptionAddingStrategy.singleCurrencyOnly && isSingleCurrency(filteredAccounts)))
    ) {
      options.unshift({ value: 'all', label: t('filters.type.all') });
    }

    return options;
  }, [accounts, isFetched, mtType, allOptionAddingStrategy]);
};
