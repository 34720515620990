import { createGlobalStyle } from 'styled-components';

import { Theme } from '@root/infra/theme/theme';

export const GlobalStyles = createGlobalStyle<{ theme?: Theme }>`
  .ReactModal__Body--open {
    overflow: hidden;
  }

  body {
    background-color: ${({ theme }) => theme.gray[900]};
    color: ${({ theme }) => theme.text.primary};
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    * {
      scrollbar-color: #1f2329 #2a2e34;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #1f2329;
      }

      &::-webkit-scrollbar-corner {
        background-color: #1f2329;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: #1f2329;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2a2e34;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #2a2e34;
      }
    }
  }

  :root {
  --featurebase-widget-width: 680px!important;
  }

  .fb-widget-fullscreen-x.fb-button-x-fade-in.fb-widget-fullscreen-x-right.featurebase-display-block {
    bottom: 90px !important;
    right: 8px !important;
    top: auto !important;
    width: 60px !important;
    height: 60px !important;
    border-radius: 50% !important;
    background-color: ${({ theme }) => theme.primary[400]} !important;
    color: ${({ theme }) => theme.gray[100]} !important;
    z-index: 10000000000010 !important;

    svg {
      transform: scale(0.8) !important;
    }
  }
    
  .fb-survey-widget-wrapper, .featurebase-widget-iframe.featurebase-widget-iframe-show {
    z-index: 10000000000000 !important;
  }

  #chat-widget-container {
    right: 0 !important;
    bottom: 0 !important;
  }

  [dir="rtl"] #chat-widget-container {
    left: 0 !important;
    right: auto !important;
  }

  .react-datepicker-popper {
    z-index: 1000 !important;
  }
`;
