import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router';

export type LinkProps = {
  appendLocales?: boolean;
  href: string;
  passHref?: boolean;
  className?: string;
};

const BaseLink: FC<PropsWithChildren<LinkProps>> = ({ children, href, passHref, ...props }) => {
  return (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
};

export default BaseLink;
