import { keepPreviousData, useQuery } from 'react-query';

import { GET_SHARED_EXPERT, GetExpertData, GetExpertQueryKey } from '@root/modules/experts/queries/get-shared-expert.query';

export const useGetSharedExpert = (id?: string, enabled = true) => {
  return useQuery<GetExpertData, Error, GetExpertData, GetExpertQueryKey>({
    queryKey: ['shared-experts', id || ''],
    queryFn: GET_SHARED_EXPERT,
    placeholderData: keepPreviousData,
    enabled: !!id && enabled,
  });
};
