import { datadogRum } from '@datadog/browser-rum';

const developmentEnvironment = import.meta.env.MODE === 'development';

if (!developmentEnvironment) {
  const SENTRY_ENVIRONMENT = import.meta.env.SENTRY_ENVIRONMENT || import.meta.env.VITE_SENTRY_ENVIRONMENT;

  datadogRum.init({
    applicationId: 'c4a354cf-af05-4892-b323-f437867e925f',
    clientToken: 'pub90eb2a0930c99afbce1c29dad919c74f',
    site: 'datadoghq.eu',
    service: 'forex-front',
    env: SENTRY_ENVIRONMENT,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    version: __APP_VERSION__,
    // allowedTracingUrls: [(url) => url.startsWith(import.meta.env.VITE_PROXY_API), (url) => url.startsWith(import.meta.env.VITE_API_HOST)],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}
