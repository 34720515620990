import clsx from 'clsx';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export interface TitleProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>, 'size'> {
  level?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  bold?: boolean;
  semibold?: boolean;
  light?: boolean;
}

export const Title: FC<TitleProps> = ({ className, level = 1, light, semibold, bold, children, ...props }) => {
  return (
    <h1
      {...props}
      className={clsx(className, {
        'text-3xl lg:text-6xl': level === 1,
        'text-2xl lg:text-5xl': level === 2,
        'text-2xl lg:text-4xl': level === 3,
        'text-2xl lg:text-3xl': level === 4,
        'text-xl lg:text-2xl': level === 5,
        'text-lg lg:text-xl': level === 6,
        'text-base lg:text-lg': level === 7,
        'text-base': level === 8,
        'text-sm': level === 9,
        'font-bold': bold,
        'font-semibold': semibold,
        'font-light': light,
      })}
    >
      {children}
    </h1>
  );
};
