import { useInterval } from '@chakra-ui/hooks';

import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { resetTowFaReasonOptions } from '@root/shared-files/modules/auth/components/two-fa/reset-two-fa-modal';
import { createResetTwoFaRequest } from '@root/shared-files/modules/auth/services/two-fa/create-reset-two-fa-request.service';
import { sendResetConfirmationCode } from '@root/shared-files/modules/auth/services/two-fa/send-reset-confirmation-code.service';
import { sendToSupport } from '@root/shared-files/modules/auth/services/two-fa/send-to-support.service';
import { updateResetTwoFaTradingActivities } from '@root/shared-files/modules/auth/services/two-fa/update-reset-two-fa-trading-activities.service';
import { verifyResetTwoFaRequest } from '@root/shared-files/modules/auth/services/two-fa/verify-reset-request-two-fa.service';
import { uploadImageService } from '@root/shared-files/modules/auth/services/upload-image.service';
import { ResetTwoFaForm, ResetTwoFaFormSteps } from '@root/shared-files/modules/auth/types/two-fa';
import { deleteCookie } from '@root/shared/utils/cookies';
import { notify } from '@root/shared/utils/notification';

const RESEND_PERIOD = 2 * 60;

const key = 'resetTwoFaForm.lastCodeResend';

export const useResetTwoFaSteps = () => {
  const { t } = useTranslation('two-fa');
  const { values, setSubmitting, setFieldValue, setTouched, setStatus, setFieldTouched, validateForm } = useFormikContext<ResetTwoFaForm>();
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);

  const restTime = useMemo(() => {
    const time = RESEND_PERIOD - timer;
    const mins = Math.floor(time / 60);
    const seconds = time % 60;
    return `0${mins}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }, [timer]);

  const sendEmailCode = useCallback(async () => {
    return await sendResetConfirmationCode();
  }, []);

  const resendCode = useCallback(async () => {
    setResendLoading(true);
    const response = await sendEmailCode();
    if (response.status === 200) {
      localStorage.setItem(key, new Date().toISOString());
    } else {
      notify({
        type: 'danger',
        title: response.payload,
      });
    }
    setResendLoading(false);
  }, [key, sendEmailCode]);

  const checkTimer = useCallback(() => {
    const lastRecent = localStorage.getItem(key);
    if (lastRecent) {
      const diff = Math.floor((new Date().getTime() - new Date(lastRecent).getTime()) / 1000);
      if (diff >= RESEND_PERIOD) {
        localStorage.removeItem(key);
        setTimer(0);
      } else {
        setTimer(diff);
      }
    } else {
      setTimer(0);
    }
  }, [key]);

  useEffect(() => {
    checkTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    checkTimer();
  }, 1000);

  const createResetRequest = useCallback(async () => {
    const errors = await validateForm();
    if (errors?.accountInfo) {
      setTouched(
        setNestedObjectValues<FormikTouched<ResetTwoFaForm>>(
          {
            accountInfo: errors.accountInfo,
          },
          true,
        ),
      );
    } else {
      setSubmitting(true);
      const response = await createResetTwoFaRequest(
        values.accountInfo.reason === resetTowFaReasonOptions[4] ? values.accountInfo : { ...values.accountInfo, reasonComment: undefined },
      );
      if (response.status === 200) {
        setFieldValue('step', ResetTwoFaFormSteps.ActivityQuestions);
        const requestInfo = response.payload;
        if (requestInfo.hasCryptoActivities) {
          const { initialCryptoOrders } = requestInfo;
          const orders =
            initialCryptoOrders?.map((order) => ({
              id: order.id,
              exchangeType: order.exchange,
              pair: order.symbol,
              datetime: order.datetime,
              average: order.average,
              amount: '',
              closeTime: order.closeTime,
            })) || [];
          setFieldValue('activityQuestions.orders', orders);
        } else {
          setFieldValue('activityQuestions.orders', []);
        }
        setFieldValue('activityQuestions.hasCryptoActivities', requestInfo.hasCryptoActivities);

        setFieldValue(
          'activityQuestions.tickets',
          Array.from({ length: 3 }, () => ''),
        );
        setFieldValue('activityQuestions.hasForexActivities', requestInfo.hasForexActivities);
        setFieldValue('id', requestInfo.id);
      } else if (response.status === 400) {
        notify({
          title: response.payload,
          type: 'danger',
        });
      } else {
        const accountInfoFields = ['country', 'fullName', 'registrationEmail'];
        const errors = response.payload.reduce(
          (acc, error) => ({
            ...acc,
            ...(accountInfoFields.includes(error.property) ? { [error.property]: error.errors?.[0] || '' } : {}),
          }),
          {},
        );

        setStatus({
          apiErrors: {
            accountInfo: errors,
          },
        });
      }
      setSubmitting(false);
    }
  }, [values]);

  const updateRequestReset = useCallback(async () => {
    setSubmitting(true);
    const errors = await validateForm();
    if (errors?.activityQuestions) {
      setTouched(
        setNestedObjectValues<FormikTouched<ResetTwoFaForm>>(
          {
            activityQuestions: errors.activityQuestions,
          },
          true,
        ),
      );
      setSubmitting(false);
      return;
    }
    const data = {
      cryptoOrders: values.activityQuestions.hasCryptoActivities
        ? values.activityQuestions.orders.map((order) => ({
            id: order.id,
            amount: Number(order.amount),
          }))
        : undefined,
      forexTickets: values.activityQuestions.hasForexActivities ? values.activityQuestions.tickets.map((ticket) => Number(ticket)) : undefined,
    };
    const response = await updateResetTwoFaTradingActivities(values.id, data);
    if (response.status === 200) {
      setFieldValue('step', ResetTwoFaFormSteps.SecurityVerification);
    } else {
      if (response.status === 418) {
        const hasCryptoOrdersError = response.payload.some((error) => error.property === 'cryptoOrders');
        const hasForexTicketsError = response.payload.some((error) => error.property === 'forexTickets');
        if (hasCryptoOrdersError) {
          notify({
            title: t('resetTwoFaModal.activityQuestions.crypto.invalidOrderAmount'),
            type: 'danger',
          });
        }
        if (hasForexTicketsError) {
          notify({
            title: t('resetTwoFaModal.activityQuestions.forex.invalidTicket'),
            type: 'danger',
          });
        }
      } else {
        notify({
          title: response.payload,
          type: 'danger',
        });
      }
    }
    setSubmitting(false);
  }, [values]);

  const verifyResetRequest = useCallback(async () => {
    setSubmitting(true);
    const errors = await validateForm();
    if (errors?.confirmationCode) {
      setTouched(
        setNestedObjectValues<FormikTouched<ResetTwoFaForm>>(
          {
            confirmationCode: errors.confirmationCode,
          },
          true,
        ),
      );
      setSubmitting(false);
      return;
    }

    const response = await verifyResetTwoFaRequest(values.id, { confirmationCode: values.confirmationCode });
    if (response.status === 200) {
      deleteCookie('twoFaAccessToken');
      setFieldValue('step', ResetTwoFaFormSteps.Success);
    } else {
      if (response.status === 418) {
        const confirmationCode = response.payload.find((e) => e.property === 'confirmationCode');
        if (confirmationCode) {
          setStatus({
            apiErrors: {
              confirmationCode: confirmationCode?.errors?.[0],
            },
          });
        }
      } else {
        notify({
          title: response.payload,
          type: 'danger',
        });
      }
    }

    setSubmitting(false);
  }, [values]);

  const handleSupportBackClick = useCallback(async () => {
    const errors = await validateForm();
    if (errors?.accountInfo) {
      setFieldValue('step', ResetTwoFaFormSteps.AccountInfo);
    } else if (errors?.activityQuestions) {
      setFieldValue('step', ResetTwoFaFormSteps.ActivityQuestions);
    } else if (errors?.confirmationCode) {
      setFieldValue('step', ResetTwoFaFormSteps.SecurityVerification);
    } else {
      setFieldValue('step', ResetTwoFaFormSteps.AccountInfo);
    }
  }, []);

  const onSupportNextClick = useCallback(
    async (step: 1 | 2) => {
      // if (step === 1) {
      //   const stepKeys = ['contactEmail', 'comment', 'registrationDate'];
      //   if (stepKeys.some((key) => !!errors?.supportRequest?.[key]) || errors?.accountInfo?.country) {
      //     setTouched(setNestedObjectValues<FormikTouched<ResetTwoFaForm>>(errors, true));
      //   } else {
      //     setFieldValue('supportRequest.step', 2);
      //   }
      // }

      const errors = await validateForm();
      if (!!errors?.supportRequest || !!errors?.accountInfo?.country) {
        setTouched(setNestedObjectValues<FormikTouched<ResetTwoFaForm>>(errors, true));
      } else {
        setSubmitting(true);
        const response = await sendToSupport(values.id, values.supportRequest);
        if (response.status === 200) {
          setFieldValue('step', ResetTwoFaFormSteps.SupportSuccess);
        } else {
          notify({
            title: response.payload,
            type: 'danger',
          });
        }
        setSubmitting(false);
      }
    },
    [values],
  );

  const onGovIdFileChange = useCallback(async (file: any) => {
    setFieldValue('supportRequest.identificationDocument', file);
    setFieldTouched('supportRequest.identificationDocument', true);
    setFieldTouched('supportRequest.identificationDocumentUrl', true);
    const response = await uploadImageService(file);
    if (response.status === 200) {
      setFieldValue('supportRequest.identificationDocumentUrl', response.payload.url);
    } else {
      notify({
        title: response.payload,
        type: 'danger',
      });
    }
  }, []);

  const onGovIdFileRemove = useCallback(() => {
    setFieldValue('supportRequest.identificationDocumentUrl', '');
  }, []);

  return {
    restTime,
    resendLoading,
    ableToResend: !timer,
    resendCode,
    createResetRequest,
    updateRequestReset,
    verifyResetRequest,
    handleSupportBackClick,
    onSupportNextClick,
    onGovIdFileChange,
    onGovIdFileRemove,
  };
};
