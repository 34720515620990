import isEqual from 'lodash/isEqual';

import { Formik, FormikProps } from 'formik';
import { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BackupCodeContent } from '@root/shared-files/modules/auth/components/two-fa/backup-code-content';
import { EnterCodeContent } from '@root/shared-files/modules/auth/components/two-fa/enter-code-content';
import { getDefaultMethod } from '@root/shared-files/modules/auth/helpers/get-default-method';
import { SignInVerifyForm, useSignInVerifyForm } from '@root/shared-files/modules/auth/hooks/use-sign-in-verify-form';
import { TwoFAAuthType, TwoFAResponse } from '@root/shared-files/modules/auth/types/two-fa';
import { Button } from '@root/shared/ui/button';
import { Modal, ModalProps } from '@root/shared/ui/modal';

type Props = ModalProps & {
  twoFaResponse: TwoFAResponse;
};

const SignInVerificationForm: FC<FormikProps<SignInVerifyForm> & { twoFaResponse: TwoFAResponse; onCancel?: () => void }> = memo(
  ({ twoFaResponse, values, isSubmitting, onCancel, handleSubmit }) => {
    const { t } = useTranslation('two-fa');
    const { email } = getDefaultMethod(twoFaResponse);

    return (
      <Modal isOpen title={values.useBackupCode ? t('enterBackupCode.title') : t('verifyModal.title')} footer={null} onCancel={onCancel} disabledOutSideClick>
        <form onSubmit={handleSubmit}>
          {values.useBackupCode ? (
            <BackupCodeContent type={values.type as TwoFAAuthType} onCancel={onCancel} canResetTwoFa />
          ) : (
            <Fragment>
              <EnterCodeContent
                type={values?.type as TwoFAAuthType}
                email={email || ''}
                hasAnotherMethod={twoFaResponse?.activeMethods?.length > 1}
                formName='SignInVerificationForm'
                isSignIn
                hideEmail
              />
              <Button loading={isSubmitting} type='submit' className='w-full mt-6'>
                {t('verifyModal.confirm')}
              </Button>
            </Fragment>
          )}
        </form>
      </Modal>
    );
  },
  (prev, next) => isEqual(prev, next),
);

SignInVerificationForm.displayName = 'SignInVerificationForm';

export const SignInVericationModal: FC<Props> = ({ twoFaResponse, onCancel }) => {
  const { initialValues, schema, onSubmit } = useSignInVerifyForm(twoFaResponse, onCancel);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
      component={(props) => <SignInVerificationForm {...props} twoFaResponse={twoFaResponse} onCancel={onCancel} />}
    />
  );
};
