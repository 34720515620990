import styled from 'styled-components';

import clsx from 'clsx';
import { FC } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { generateColor } from '@root/shared/utils/color-utils';

const Wrapper = styled.div<Pick<ImageProps, 'width' | 'height'>>`
  display: inline-flex;
  border-radius: 50%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: hidden;

  img {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;

const DefaultImage = styled.div<Pick<ImageProps, 'width' | 'height' | 'id'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  font-size: 16px;
  color: #fff;
  background: ${({ id }) => '#' + generateColor(id)};
  max-height: 100%;
  max-width: 100%;
`;

export interface ImageProps {
  wrapperClassName?: string;
  id: string;
  width?: number;
  height?: number;
  type?: 'currencies' | 'exchanges';
  isLazy?: boolean;
}

export const CryptoImage: FC<ImageProps> = ({ wrapperClassName, height = 26, width = 26, id }) => {
  return (
    <Wrapper width={width} height={height} className={clsx('shrink-0', wrapperClassName)}>
      <DefaultImage width={width} height={height} id={id}>
        {id?.substring(0, 1) || ''}
      </DefaultImage>
    </Wrapper>
  );
};
