import isFunction from 'lodash/isFunction';

import clsx from 'clsx';
import { CSSProperties, ComponentProps, FC, PropsWithChildren } from 'react';

import { DynamicSafeArea } from '@root/infra/layout/components/dynamic-safe-area';
import { HeaderElements as Header } from '@root/infra/layout/components/header.elements';
import { SidebarElements as Sidebar } from '@root/infra/layout/components/sidebar.elements';
import { useXl } from '@root/infra/layout/hooks/media';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

export const DynamicHeaderSafeArea: FC<
  { headerElement: HTMLElement | null; initialPaddingTop?: number; style?: CSSProperties | ((paddingTop: number | undefined) => CSSProperties | undefined) } & Omit<
    ComponentProps<'div'>,
    'style'
  >
> = ({ headerElement, initialPaddingTop, style: rawStyle, ...props }) => (
  <DynamicSafeArea element={headerElement}>
    {(size) => {
      const style = isFunction(rawStyle)
        ? rawStyle(size?.height ?? initialPaddingTop)
        : {
            ...rawStyle,
            paddingTop: size === null ? undefined : size.height + 'px',
          };

      return <div {...props} style={style} />;
    }}
  </DynamicSafeArea>
);

type Props = PropsWithChildren<{
  isGhostLogin: boolean;
  isDesktop: boolean;
  menuOpened: boolean;
  isGuestView?: boolean;
  isFullScreenMode?: boolean;
}>;

const SafeArea: FC<Props> = ({ children, isGhostLogin, isDesktop, menuOpened, isGuestView, isFullScreenMode }) => {
  const xl = useXl();

  return (
    <div
      className={clsx('w-full bg-gray-900 pt-[74px]', {
        'mt-[60px]': isGhostLogin,
        'pl-0': isGuestView,
        'pl-[200px] pr-0 rtl:pl-0 rtl:pr-[200px]': isDesktop && xl && menuOpened,
        'pl-[64px] pr-0 rtl:pl-0 rtl:pr-[64px]': isDesktop && xl && !menuOpened,
        'pl-0! pr-0!': isFullScreenMode,
      })}
    >
      {children}
    </div>
  );
};

const Content: FC<PropsWithChildren> = ({ children }) => {
  const { isGhostLogin } = useGhostLogin();

  return <main className={clsx('p-4 sm:p-5', { 'mt-[60px]': isGhostLogin })}>{children}</main>;
};

export const LayoutElements = { SafeArea, Content, Sidebar, Header };
