import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ShareAssistContent } from '@root/modules/magic-terminal/containers/share/share-magic-terminal-modal';
import { useCreateSharedTerminalLink } from '@root/modules/magic-terminal/hooks/use-create-shared-terminal-link';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { ShareDotsIcon } from '@root/shared/icons/share-dots-icon';
import { Button } from '@root/shared/ui/button';
import { Text } from '@root/shared/ui/typography';

export const ShareMagicTerminalButton = memo<{ as?: 'button' | 'text'; disabled?: boolean }>(({ as, disabled }) => {
  const { t } = useTranslation('forex-experts');
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const noRoles = !subscriptionInfo?.roles?.length;
  const { link, generateLink } = useCreateSharedTerminalLink();

  useEffect(() => {
    if (isOpen) {
      generateLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (as === 'text' && noRoles) {
    return null;
  }

  return (
    <div className='w-full'>
      {as === 'text' ? (
        <Text className='rounded-sm hover:cursor-pointer hover:bg-gray-700 transition px-5 py-1.5' onClick={() => setIsOpen(!isOpen)}>
          {t('sharedTerminal.shareSetup')}
        </Text>
      ) : (
        <Button
          variant='primary'
          className={clsx('flex items-center justify-center px-1! py-1.5! w-full gap-2 min-w-[117px]', {
            'bg-primary-400! text-gray-100! outline-hidden! hover:border-primary-400!': isOpen,
          })}
          onClick={() => setIsOpen(!isOpen)}
          outlined
          disabled={disabled || noRoles}
        >
          <ShareDotsIcon width={16} height={16} />
          <Text size='sm'>{t('sharedTerminal.shareSetup')}</Text>
        </Button>
      )}

      {isOpen && <ShareAssistContent link={link} onClose={() => setIsOpen(false)} />}
    </div>
  );
});

ShareMagicTerminalButton.displayName = 'ShareMagicTerminalButton';
