import { FC, PropsWithChildren, ReactElement } from 'react';

import { Title } from '@root/shared/ui/typography';

export const InfoContentModalLayout: FC<PropsWithChildren<{ leading: ReactElement; title: string }>> = ({ leading, title, children }) => (
  <>
    {leading}

    <Title level={7} className='text-center'>
      {title}
    </Title>

    {children}
  </>
);
