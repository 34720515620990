import { fetcher } from '@root/infra/fetcher';
import { SignInDtoMapper } from '@root/shared-files/modules/auth/mappers';
import { SignInResponse } from '@root/shared-files/modules/auth/types/sign-in';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type SignInWithGoogleServiceResponse = IHttpResponse<200, SignInResponse> | IHttpResponse<400, string>;

export const signInWithGoogleService = async (accessToken: string, accessCode: string): Promise<SignInWithGoogleServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/google-sign-in', { accessToken, registrationAccessCode: accessCode });

    return {
      status: 200,
      payload: SignInDtoMapper.toDomain(response.data),
    };
  } catch (e: any) {
    return {
      status: 400,
      payload: e?.response?.data?.message || 'Bad request',
    };
  }
};
