import { Selector, createSelector } from '@reduxjs/toolkit';

import { IAppState } from '@root/infra/store';
import { IAuthState } from '@root/shared-files/modules/auth/store/auth.slice';
import { IAuthUser } from '@root/shared-files/modules/auth/types';

const getAuth = (state: IAppState) => state.auth;
const getSelf = (auth: IAuthState) => auth;
const getUser = (auth: IAuthState) => auth.user;
const getUserId = (auth: IAuthState) => auth.user?.id;
const getFullName = (auth: IAuthState) => auth.user?.fullName || null;
const getIsAuthed = (auth: IAuthState) => !!auth.user;
const getSubscriptionInfo = (auth: IAuthState) => auth.subscriptionInfo;
const getIsEmailVerified = (auth: IAuthState) => !!auth.user?.emailVerifiedAt;
const getIsTokensRefreshing = (auth: IAuthState) => auth.isTokensRefreshing;
const getMaxEACount = (auth: IAuthState) => auth.subscriptionInfo?.permissions?.SUBSCRIPTION_EXPERT_ADVISORS?.value;
const getMaxExperts = (auth: IAuthState) => auth.subscriptionInfo?.permissions?.SUBSCRIPTION_FX_PERSONAL_WEBHOOK_ALERTS_MAX_EXPERTS?.value;
const getMaxMagicActiveSmartTrades = (auth: IAuthState) => auth.subscriptionInfo?.permissions?.SUBSCRIPTION_FX_MAGIC_ACTIVE_SMART_TRADES?.value;
const getActiveBrokerCount = (auth: IAuthState) => auth.subscriptionInfo?.permissions?.SUBSCRIPTION_BROKER?.value;
const getQuizState = (auth: IAuthState) => auth.quizState;
const getGhostToken = (auth: IAuthState) => auth.ghostToken;
const getResetTwoFaModalOpen = (auth: IAuthState) => auth.resetTwoFaModalOpen;
const getChangePasswordModalOpen = (auth: IAuthState) => auth.changePasswordModalOpen;
const getChangeEmailModalOpen = (auth: IAuthState) => auth.changeEmailModalOpen;
const hasFxPersonalWebhookRole = (auth: IAuthState) => getSubscriptionInfo(auth)?.roles.includes('fx personal webhook') ?? false;

export const authSelector = {
  main: createSelector<[Selector<IAppState, IAuthState>], IAuthState>([getAuth], getSelf),
  user: createSelector<[Selector<IAppState, IAuthState>], IAuthUser | null>([getAuth], getUser),
  userId: createSelector<[Selector<IAppState, IAuthState>], IAuthUser['id'] | undefined>([getAuth], getUserId),
  fullName: createSelector<[Selector<IAppState, IAuthState>], string | null>([getAuth], getFullName),
  isAuthed: createSelector<[Selector<IAppState, IAuthState>], boolean>([getAuth], getIsAuthed),
  isEmailVerified: createSelector<[Selector<IAppState, IAuthState>], boolean>([getAuth], getIsEmailVerified),
  isTokensRefreshing: createSelector<[Selector<IAppState, IAuthState>], boolean>([getAuth], getIsTokensRefreshing),
  isTokenRefreshed: createSelector<[Selector<IAppState, IAuthState>], boolean>([getAuth], (auth) => auth.isTokenRefreshed),
  getSubscriptionInfo: createSelector<[Selector<IAppState, IAuthState>], IAuthState['subscriptionInfo']>([getAuth], getSubscriptionInfo),
  subscriptionAllowFxMagicBreakeven: (state: IAppState) => getSubscriptionInfo(getAuth(state))?.permissions['SUBSCRIPTION_FX_MAGIC_BREAKEVEN']?.value,
  subscriptionAllowFxMagicTrailingStop: (state: IAppState) => getSubscriptionInfo(getAuth(state))?.permissions['SUBSCRIPTION_FX_MAGIC_TRAILING_STOP']?.value,
  subscriptionAllowFxMagicPartialTakeProfit: (state: IAppState) => getSubscriptionInfo(getAuth(state))?.permissions['SUBSCRIPTION_FX_MAGIC_PARTIAL_TAKE_PROFIT']?.value,
  hasFxPersonalWebhookRole: createSelector<[Selector<IAppState, IAuthState>], boolean>([getAuth], hasFxPersonalWebhookRole),
  maxEACount: createSelector<[Selector<IAppState, IAuthState>], number | undefined>([getAuth], getMaxEACount),
  getMaxExperts: createSelector<[Selector<IAppState, IAuthState>], number | undefined>([getAuth], getMaxExperts),
  getMaxMagicActiveSmartTrades: createSelector<[Selector<IAppState, IAuthState>], number | undefined>([getAuth], getMaxMagicActiveSmartTrades),
  activeBrokerCount: createSelector<[Selector<IAppState, IAuthState>], number | undefined>([getAuth], getActiveBrokerCount),
  ghostToken: createSelector<[Selector<IAppState, IAuthState>], string | null>([getAuth], getGhostToken),
  quizState: createSelector<[Selector<IAppState, IAuthState>], IAuthState['quizState']>([getAuth], getQuizState),
  resetTwoFaModalOpen: createSelector<[Selector<IAppState, IAuthState>], boolean>([getAuth], getResetTwoFaModalOpen),
  changePasswordModalOpen: createSelector<[Selector<IAppState, IAuthState>], boolean>([getAuth], getChangePasswordModalOpen),
  changeEmailModalOpen: createSelector<[Selector<IAppState, IAuthState>], boolean>([getAuth], getChangeEmailModalOpen),
};
