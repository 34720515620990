import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const ExitPriceIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M16.9497 7.05025C19.6834 9.78392 19.6834 14.2161 16.9497 16.9497C14.2161 19.6834 9.78392 19.6834 7.05025 16.9497C4.31658 14.2161 4.31658 9.78392 7.05025 7.05025C9.78392 4.31658 14.2161 4.31658 16.9497 7.05025'
        stroke='currentColor'
      />
      <path d='M12 21V17' stroke='currentColor' />
      <path d='M12 3V7' stroke='currentColor' />
      <path d='M7 12H3' stroke='currentColor' />
      <path d='M21 12H17' stroke='currentColor' />
    </svg>
  );
};
