import { datadogRum } from '@datadog/browser-rum';
import posthog from 'posthog-js';

import { SagaReturnType, call, fork, put, take, takeLatest } from 'redux-saga/effects';

import { queryClient } from '@root/infra/query';
import { clearLocalStorage, getTokens, removeTokens, updateTokens } from '@root/shared-files/modules/auth/helpers';
import { getProfileService, refreshTokenService } from '@root/shared-files/modules/auth/services';
import { getQuizState } from '@root/shared-files/modules/auth/services/get-quiz-state.service';
import { AuthActions, authSlice } from '@root/shared-files/modules/auth/store/auth.slice';
import { SHOULD_REFETCH_PROVIDERS, SHOULD_REFRESH_TOKEN } from '@root/shared-files/modules/shared/constants/local-storage-keys';
import { getCookie } from '@root/shared/utils/cookies';
import { notify } from '@root/shared/utils/notification';

const {
  signedIn,
  signedOut,
  signedInWithGoogle,
  tokensRefreshed,
  fetchProfilePending,
  fetchProfileFulfilled,
  fetchProfileRejected,
  updateProfile,
  tokensMissed,
  refreshSession,
  startGhostLogin,
  finalizeGhostLogin,
  refreshSessionAfterTradeIdeaSubscription,
} = authSlice.actions;

function handleTokensRefreshed(action: AuthActions.TokensRefreshed) {
  const accessToken = action.payload?.tokens?.accessToken;
  const refreshToken = action.payload?.tokens?.refreshToken;
  const isUpdatedAlready = action.payload?.tokensUpdatedAlready;

  if (!isUpdatedAlready) {
    updateTokens(accessToken, refreshToken);
  }
}

function* handleAuthFulfilled(action: AuthActions.SignedInWithGoogle | AuthActions.SignedIn) {
  const accessToken = action.payload.tokens.accessToken;
  const refreshToken = action.payload.tokens.refreshToken;

  updateTokens(accessToken, refreshToken);
  yield put(fetchProfileFulfilled(action.payload));
}

function* checkQuizState() {
  const result: SagaReturnType<typeof getQuizState> = yield call(getQuizState);

  if (result.status === 200) {
    yield put(authSlice.actions.setQuizState(result.payload));
  }
}

function* handleFetchProfile() {
  const { accessToken, refreshToken } = getTokens();

  if (!!accessToken || !!refreshToken) {
    if (localStorage.getItem(SHOULD_REFRESH_TOKEN)) {
      yield take([tokensRefreshed]);
    }

    const result: SagaReturnType<typeof getProfileService> = yield call(getProfileService);

    if (result.status === 200) {
      yield put(fetchProfileFulfilled(result.payload));
    } else {
      yield put(fetchProfileRejected(result.payload));
    }
  } else {
    console.log('tokensMissed');
    yield put(tokensMissed());
  }
}

function refetchTIProviders() {
  queryClient?.refetchQueries({ queryKey: ['marketplace-subscribed-providers'], exact: false });
}

function* handleRefreshSession() {
  const oldRefreshToken = getCookie('refreshToken');
  const response = yield call(refreshTokenService, oldRefreshToken as string);
  if (response.status === 200) {
    yield put(authSlice.actions.tokensRefreshed(response.payload));
    const result: SagaReturnType<typeof getProfileService> = yield call(getProfileService);
    if (result.status === 200) {
      yield put(updateProfile(result.payload));
    } else {
      notify({
        type: 'danger',
        title: result.payload,
      });
    }

    if (localStorage?.getItem(SHOULD_REFETCH_PROVIDERS)) {
      refetchTIProviders();
      localStorage?.removeItem(SHOULD_REFETCH_PROVIDERS);
    }
  }
}

function* handelRefreshSessionAfterTISubscription() {
  const oldRefreshToken = getCookie('refreshToken');
  const response = yield call(refreshTokenService, oldRefreshToken as string);
  if (response.status === 200) {
    yield put(authSlice.actions.tokensRefreshed(response.payload));
    yield put(authSlice.actions.refetchTIsAfterRefresh());
  }
}

function* handleGhostLogin(action: AuthActions.StartGhostLogin) {
  removeTokens();
  clearLocalStorage();
  queryClient.clear();

  const accessToken = action.payload;

  if (accessToken) {
    updateTokens(accessToken as string);
    const response = yield call(getProfileService);
    if (response.status === 200) {
      yield put(fetchProfileFulfilled(response.payload));
      yield put(finalizeGhostLogin(accessToken));
    } else {
      yield put(fetchProfileRejected(response.payload));
    }
  }
}

function handleLogout() {
  try {
    removeTokens();
    clearLocalStorage();
    datadogRum.clearUser();
    posthog.reset();
  } catch (e) {
    console.error('error on logout', e);
  }
}

function fillSessionInfo(action: AuthActions.FetchProfileFulfilled | AuthActions.SignedIn | AuthActions.SignedInWithGoogle) {
  try {
    const {
      userSubscriptionInfo,
      user: { email, id, fullName, country },
    } = action.payload;

    if (import.meta.env.VITE_PUBLIC_POSTHOG_KEY) {
      posthog.identify(action.payload.user.id as string, {
        name: fullName,
        language: getCookie('siteLang'),
        email,
        roles: userSubscriptionInfo.roles.toString(),
        permissions: userSubscriptionInfo.permissions,
      });
    }

    datadogRum.setUser({
      id: id as string,
      name: fullName,
      email,
    });
  } catch (error) {
    console.error('failed to retrieve session info', error);
  }
}

export function* authSaga() {
  yield fork(handleFetchProfile);
  yield takeLatest([signedIn, signedInWithGoogle], handleAuthFulfilled);
  yield takeLatest([signedOut], handleLogout);
  yield takeLatest([startGhostLogin], handleGhostLogin);
  yield takeLatest([tokensRefreshed], handleTokensRefreshed);
  yield takeLatest([fetchProfilePending], handleFetchProfile);
  yield takeLatest([refreshSession], handleRefreshSession);
  yield takeLatest([fetchProfileFulfilled], checkQuizState);
  yield takeLatest([fetchProfileFulfilled, signedInWithGoogle, signedIn], fillSessionInfo);
  yield takeLatest([refreshSessionAfterTradeIdeaSubscription], handelRefreshSessionAfterTISubscription);
}
