import get from 'lodash/get';
import omit from 'lodash/omit';
import styled from 'styled-components';

import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import React, { ReactNode, forwardRef, useCallback, useState } from 'react';

import { HidePasswordIcon } from '@root/shared/icons/hide-password-icon';
import { ShowPasswordIcon } from '@root/shared/icons/show-password-icon';
import { Field, FieldHint, Input, InputProps, Label } from '@root/shared/ui/form';

export interface PasswordFieldProps extends InputProps {
  label?: ReactNode;
  name: string;
  hideError?: boolean;
  hideIcon?: boolean;
}

const Button = styled.button`
  position: absolute;
  top: 32px;
  font-size: 18px;
  box-sizing: border-box;
  z-index: 1;

  svg {
    fill: ${({ theme }) => theme.gray['100']};
  }
`;

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(function PasswordField({ label, variant, hideError, hideIcon = false, ...props }, ref) {
  const [inputProps, meta] = useField(props.name);

  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const { status, setStatus } = useFormikContext();
  const apiError = get(status?.apiErrors, props.name);
  const errorText = meta.error || apiError;

  const handleShowPassword = useCallback(() => {
    setInputType((prev) => (prev === 'text' ? 'password' : 'text'));
  }, []);

  const changeFunction = props.onChange || inputProps.onChange;

  const clearError = () => {
    if (apiError) {
      const errors = omit(status?.apiErrors, [props.name]);
      setStatus({ apiErrors: errors });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    return changeFunction(e);
  };

  return (
    <Field className={clsx({ 'pointer-events-none': props.disabled, 'opacity-30': props.disabled })}>
      <div className='relative'>
        {label && <Label>{label}</Label>}
        {!hideIcon && (
          <Button className='right-5 rtl:right-[unset] rtl:left-5' type='button' onClick={handleShowPassword}>
            {inputType === 'password' ? <HidePasswordIcon width={24} height={24} /> : <ShowPasswordIcon width={24} height={24} />}
          </Button>
        )}
        <Input variant={meta.touched && errorText ? 'danger' : variant} {...inputProps} {...props} type={inputType} onChange={onChange} ref={ref} />
      </div>
      {!hideError && <FieldHint variant='danger'>{meta.touched && errorText}</FieldHint>}
    </Field>
  );
});
