import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { AuthUserMapper } from '@root/shared-files/modules/auth/mappers';
import { IAuthUser } from '@root/shared-files/modules/auth/types';
import { UserSubscriptionInfo } from '@root/shared-files/modules/subscriptions/types/user-subscription-info';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type UploadImageServiceResponse = IHttpResponse<200, { url: string }> | IHttpResponse<400, string>;

export const uploadImageService = async (data: File): Promise<UploadImageServiceResponse> => {
  try {
    const formData = new FormData();
    formData.append('file', data);

    const response = await fetcher.post('/misc/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
