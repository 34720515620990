import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Id } from 'react-toastify';

import { useGetSelectedBroker } from '@root/modules/orders/contexts/selected-broker.context';
import { useGetOrdersHistory } from '@root/modules/orders/hooks/use-order-history';
import { syncClosedOrders } from '@root/modules/orders/services/sync-closed-orders.service';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { notify } from '@root/shared/utils/notification';

export const useSyncClosedOrders = () => {
  const { selectedBroker } = useGetSelectedBroker();
  const userId = useSelector(authSelector.userId);
  const [loading, setLoading] = useState<boolean>(false);
  const [, { refetch }] = useGetOrdersHistory({ enabled: false, selectedBroker });

  const sync = useCallback(async () => {
    setLoading(true);
    const response = await syncClosedOrders({ userId: userId as Id, accounts: selectedBroker });

    if (response.status === 200) {
      await refetch();
    } else {
      notify(
        {
          title: response.payload,
          type: 'danger',
        },
        { data: response.errorData },
      );
    }

    setLoading(false);
  }, [selectedBroker, userId, refetch]);

  const state = {
    loading,
  };

  const handlers = {
    sync,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
