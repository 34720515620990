import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource, browserDetector } from '@opentelemetry/resources';
import { detectResourcesSync } from '@opentelemetry/resources/build/src/detect-resources';
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

import { SessionIdProcessor } from '@root/infra/integrations/telemetry/SessionIdProcessor';

const registerTracing = () => {
  let resource = new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'forex-browser',
  });

  const detectedResources = detectResourcesSync({ detectors: [browserDetector] });
  resource = resource.merge(detectedResources);
  const provider = new WebTracerProvider({
    resource,
  });

  provider.addSpanProcessor(new SessionIdProcessor());

  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: import.meta.env.VITE_TRACES_ENDPOINT,
      }),
      {
        scheduledDelayMillis: 500,
      },
    ),
  );

  const contextManager = new ZoneContextManager();

  provider.register({
    contextManager,
    propagator: new CompositePropagator({
      propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
    }),
  });

  const urls = [import.meta.env.VITE_API_HOST, import.meta.env.VITE_FX_API_HOST, import.meta.env.VITE_FX_CORE_HOST];

  // Escape dots and create a RegExp pattern for each URL
  const patterns = urls.map((url) => url.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'));
  const combinedPattern = `^(${patterns.join('|')})(?:$|/.*)$`;

  registerInstrumentations({
    tracerProvider: provider,
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-xml-http-request': {
          propagateTraceHeaderCorsUrls: [new RegExp(combinedPattern)],
          clearTimingResources: true,
          applyCustomAttributesOnSpan(span) {
            span.setAttribute('app.synthetic_request', 'web');
          },
        },
      }),
    ],
  });
};

if (import.meta.env.MODE !== 'development') {
  registerTracing();
}
