import styled, { css } from 'styled-components';

export const FormWrapper = styled.form`
  h1 {
    font-size: 15px;
  }

  .block span.suffix {
    font-size: 13px;
  }
`;

export const Footer = styled.div`
  box-shadow: 0px -4px 24px rgba(12, 12, 12, 0.6);
`;

export const HeightWrapper = styled.div<{ $isFullScreenMode?: boolean }>`
  ${({ $isFullScreenMode }) => css`
    iframe {
      height: ${$isFullScreenMode ? '100vh' : '543px'} !important;
      max-height: calc(100vh - ${180}px);
    }

    .order-section-container {
      height: ${$isFullScreenMode ? '100vh' : '492px'} !important;
      max-height: calc(100vh - ${228}px);
    }
  `}
`;
