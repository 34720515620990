import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import sageMasterNameLogo from '@root/assets/images/sage-master-name-logo.svg';
import { LayoutElements } from '@root/infra/layout/components';
import { HeaderDropdown } from '@root/infra/layout/components/header-dropdown';
import { HeaderLinksDropdown } from '@root/infra/layout/components/header-links-dropdown';
import { AssistWidget } from '@root/infra/layout/containers/assist-widget';
import { MasterGptLink } from '@root/infra/layout/containers/master-gpt-link';
import { useSm, useXl } from '@root/infra/layout/hooks/media';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { LogoIcon } from '@root/shared/icons/logo-icon';
import { PlusIcon } from '@root/shared/icons/plus-icon';
import { SidebarIcon } from '@root/shared/icons/sidebar-icon';
import { Button } from '@root/shared/ui/button';
import { IconButton } from '@root/shared/ui/button/icon-button';
import { BalanceWidget } from '@root/shared/ui/financial-widgets';
import { Image } from '@root/shared/ui/image';

const { Wrapper, Logo } = LayoutElements.Header;

export const Header = () => {
  const { t } = useTranslation('common');
  const isVerified = useSelector(authSelector.isEmailVerified);
  const navigate = useNavigate();
  const sm = useSm();
  const xl = useXl();

  const dispatch = useDispatch();

  const handleSideBarButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    dispatch(userSettingsSlice.actions.setMenuOpened(true));
  };

  return (
    <Wrapper>
      <div className='flex grow h-full shrink'>
        {isVerified && <HeaderLinksDropdown />}
        {!xl && (
          <IconButton className='ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2 p-2 self-center text-grayscale-300' onClick={handleSideBarButtonClick}>
            <SidebarIcon width={24} height={24} />
          </IconButton>
        )}
        <Logo className='mx-auto xl:mx-[unset]'>
          <div className='flex flex-row items-center'>
            <LogoIcon width={40} height={40} />
            {sm && <Image className='ml-1 mt-[3px]' src={sageMasterNameLogo} width={136} height={26} alt='SageMaster Logo' unoptimized={true} />}
          </div>
        </Logo>
      </div>
      <div className='flex items-center gap-x-2'>
        {xl ? (
          <AssistWidget />
        ) : (
          <Button className={'flex items-center gap-x-2 px-2! py-0! rounded-sm h-[38px]'} outlined={false} onClick={() => navigate(PageLinks.createExpert)}>
            <PlusIcon />
            {sm && <span className='whitespace-nowrap leading-5 py-px'>{t('header.setupSmartAssist')}</span>}
          </Button>
        )}

        {xl && isVerified && <BalanceWidget />}
        {xl && <MasterGptLink />}
        <HeaderDropdown />
      </div>
    </Wrapper>
  );
};
