import { Slot } from '@radix-ui/react-slot';

import { ComponentProps, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const BaseButton = forwardRef<HTMLButtonElement, ComponentProps<'button'> & { asChild?: boolean }>(function BaseButton(
  { className, type = 'button', asChild, ...props },
  ref,
) {
  const Comp = asChild ? Slot : 'button';

  return <Comp {...props} ref={ref} type={type} className={twMerge('flex', className)} />;
});
