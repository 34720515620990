import { QueryFunction } from 'react-query';

import {
  GetMarketplaceTradeIdeaListQuery,
  MarketplaceTradeIdeaListResponseData,
  getMarketplaceTradeIdeaListService,
} from '@root/modules/marketplace/services/get-marketplace-trade-idea-list.service';

export type GetMarketplaceTradeIdeaListQueryKey = ['marketplace-trade-idea-list', GetMarketplaceTradeIdeaListQuery];
export type GetMarketplaceTradeIdeaListData = MarketplaceTradeIdeaListResponseData;

export const GET_MARKETPLACE_TRADE_IDEA_LIST: QueryFunction<GetMarketplaceTradeIdeaListData, GetMarketplaceTradeIdeaListQueryKey> = async ({ queryKey }) => {
  const [, data] = queryKey;
  const result = await getMarketplaceTradeIdeaListService(data);

  if (result.status === 200) {
    return result.payload;
  } else {
    throw new Error(result.payload);
  }
};
