import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { LabelSafeArea } from '@root/infra/form/label-safe-area';
import { LabelWithInfoTooltip } from '@root/infra/form/label-with-info-tooltip';
import { TemplateUnitType } from '@root/modules/experts/types/common';
import { TextField } from '@root/shared/form';
import { resolveFieldPath } from '@root/shared/form/form-utils';
import { Text } from '@root/shared/ui/typography';

export const SmartPartialTakeProfitsFieldset: FC<
  PropsWithChildren<{
    nameSufix: string;
    index: number;
    densed: boolean;
    viewOnly: boolean;
    unitType: TemplateUnitType | '%';
  }>
> = ({ nameSufix, index, densed, viewOnly, unitType, children }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.partialClose' });

  return (
    <div className={clsx('flex', densed ? 'gap-x-2' : 'gap-x-3')}>
      <LabelSafeArea>
        <div className='h-10 w-8 flex items-center'>
          <Text size='sm'>TP {index + 1}</Text>
        </div>
      </LabelSafeArea>
      <TextField
        name={resolveFieldPath(nameSufix, 'percent')}
        disabled={viewOnly}
        label={<LabelWithInfoTooltip tooltipContent={t('smartLevels.percent.description')}>{t('smartLevels.percent.label')}</LabelWithInfoTooltip>}
        placeholder={t('levels.percent.placeholder')}
        suffix={unitType}
        wrapperClassName='grow'
      />
      {children}
    </div>
  );
};
