import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';

export const SidebarSafeArea = forwardRef<HTMLDivElement, ComponentProps<'div'>>(function XSafeArea({ className, ...props }, ref) {
  const menuOpened = useSelector(userSettingsSelector.menuOpened);

  return <div {...props} ref={ref} className={clsx(menuOpened ? 'xl:ltr:pl-[200px] xl:rtl:pr-[200px]' : 'xl:ltr:pl-[64px] xl:rtl:pr-[64px]', className)} />;
});
