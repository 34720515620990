import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ErrorImg } from '@root/infra/images/error-img';
import { ConfimationActionsModalLayout } from '@root/infra/layout/components/modal/confimation-actions-modal-layout';
import { InfoContentDetailsModalLayout } from '@root/infra/layout/components/modal/info-content-details-modal-layout';
import { InfoContentModalLayout } from '@root/infra/layout/components/modal/info-content-modal-layout';
import { LiveChatWidgetVisibility, liveChatWidgetVisibilityUrlSearchParameter } from '@root/infra/live-chat';
import Link from '@root/shared/ui/common/static-locales-link';
import { Modal } from '@root/shared/ui/modal';
import { Text } from '@root/shared/ui/typography';

export const TradeExecutionFailedModal: FC<{ open: boolean; onCancel: () => void; onTryAgain: () => void; error: string }> = ({ open, onCancel, onTryAgain, error }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'events.modals' });

  return (
    <Modal isOpen={open} onCancel={onCancel} footer={null} className='pt-0! max-w-[800px]!'>
      <ConfimationActionsModalLayout onCancel={onCancel} cancelButtonTitle={t('cancel')} confirmButtonTitle={t('tryAgain')} onConfirm={onTryAgain}>
        <InfoContentModalLayout leading={<ErrorImg />} title={t('tradeExecutionFailedModal.title')}>
          <InfoContentDetailsModalLayout
            description={`${t('tradeExecutionFailedModal.description')}\n${error}`}
            whatYouCanDo={[t('tradeExecutionFailedModal.whatYouCanDo.1'), t('tradeExecutionFailedModal.whatYouCanDo.2')]}
          />
          <Text size='sm' className='my-1 text-gray-400'>
            <Trans
              i18nKey='forex-experts:events.modals.tradeExecutionFailedModal.moreHelp'
              components={{ a: <Link className='text-primary-400  font-semibold' href={`?${liveChatWidgetVisibilityUrlSearchParameter}=${LiveChatWidgetVisibility.maximized}`} /> }}
            />
          </Text>
        </InfoContentModalLayout>
      </ConfimationActionsModalLayout>
    </Modal>
  );
};
