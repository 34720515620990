import { FxSessionManagementActionType } from '@3lgn/shared/dist/constants/fx';
import '@3lgn/shared/dist/constants/roles';

import { useFormikContext } from 'formik';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useCreateExpertFieldOptions } from '@root/modules/experts/hooks/use-create-expert-options';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { CheckboxField, SelectField, TextField } from '@root/shared/form';
import { SwitchField } from '@root/shared/form/fields/switch-field';
import { InfoIcon } from '@root/shared/icons/info-icon';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { Text, Title } from '@root/shared/ui/typography';

type Props = {
  isPartialEdit?: boolean;
};

export const SessionManagement: FC<Props> = ({ isPartialEdit }) => {
  const { t } = useTranslation('forex-experts', { keyPrefix: 'fields.sessionManagement' });
  const { t: tCommon } = useTranslation('common');
  const { values, setFieldValue } = useFormikContext<CreateExpertDto>();
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const { sessionManagementTypeOptions, sessionManagementSessionOptions } = useCreateExpertFieldOptions(values.unitType);

  const onUseChange = useCallback(
    (event) => {
      const checked = !!event.target.checked;
      setFieldValue('sessionManagement.use', checked);
    },
    [setFieldValue],
  );

  const onSessionsChange = useCallback(
    (options, item) => {
      const { option: selectedOption, removedValue } = item;

      if (selectedOption?.value !== 'all') {
        const newValue = options.filter((option) => option.value !== 'all').map((item) => item.value);
        setFieldValue('sessionManagement.sessions', newValue);
      } else if (removedValue) {
        const newValue = options.filter((option) => option.value !== 'all' && option.value !== removedValue.value).map((item) => item.value);

        setFieldValue('sessionManagement.sessions', newValue);
      } else {
        setFieldValue('sessionManagement.sessions', ['all']);
      }
    },
    [setFieldValue],
  );

  const allowSessionManagement = subscriptionInfo?.permissions?.['SUBSCRIPTION_FX_ORDER_SESSION_MANAGEMENT']?.value;
  const isExpertForm = !!(values as CreateExpertDto)?.unitType;

  return (
    <div>
      {!isPartialEdit && (
        <SwitchField
          name='sessionManagement.use'
          label={
            <InfoTooltip content={<Text size='sm'>{tCommon('comingSoonWithProSub')}</Text>} positions={['top', 'left', 'right', 'bottom']}>
              <div className='flex justify-start items-center gap-x-2 cursor-pointer'>
                <Title level={7}>{t('use.label')}</Title>
                <InfoIcon className='shrink-0' width={16} height={16} />
              </div>
            </InfoTooltip>
          }
          wrapperClassName='mb-2'
          onChange={onUseChange}
          disabled={!isExpertForm || !allowSessionManagement}
        />
      )}
      {values.sessionManagement?.use && (
        <>
          <div className='mb-4'>
            <SelectField
              options={sessionManagementSessionOptions}
              name='sessionManagement.sessions'
              label={
                <InfoTooltip content={<Text size='sm'>{t('sessions.hint')}</Text>} positions={['top', 'left', 'right', 'bottom']}>
                  <div className='flex justify-start items-center gap-x-2 cursor-pointer'>
                    <Text size='sm'>{t('sessions.label')}</Text>
                    <InfoIcon className='shrink-0' width={16} height={16} />
                  </div>
                </InfoTooltip>
              }
              placeholder={t('sessions.placeholder')}
              checkedDescriptionOption
              isMulti
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              onChange={onSessionsChange}
            />
          </div>
          <div className='mb-4'>
            <Text size='base' className='mb-2 font-semibold'>
              {t('type.label')}
            </Text>
            {sessionManagementTypeOptions.map((option, index) => (
              <CheckboxField
                id='sessionManagement.type'
                name='sessionManagement.type'
                className='mb-4'
                key={index}
                label={
                  option.description ? (
                    <InfoTooltip content={<Text size='sm'>{option.description}</Text>} positions={['top', 'left', 'right', 'bottom']}>
                      <div className='flex justify-start items-center gap-x-2 cursor-pointer'>
                        <Text size='sm'>{option.label}</Text>
                        <InfoIcon className='shrink-0' width={16} height={16} />
                      </div>
                    </InfoTooltip>
                  ) : (
                    option.label
                  )
                }
                value={option.value}
                onChange={() => setFieldValue('sessionManagement.type', option.value)}
                checked={values.sessionManagement?.type === option.value}
                type='radio'
                hideError
              />
            ))}
          </div>
          {[FxSessionManagementActionType.CLOSE_IF_IN_PROFIT as string].includes(values.sessionManagement.type) && (
            <div className='mb-4'>
              <TextField name='sessionManagement.threshold' label={t('threshold.label')} type='string' placeholder={t('threshold.placeholder')} suffix={values.unitType} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
