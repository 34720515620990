import { ComponentProps, FC, SVGProps } from 'react';

export const IconSvg: FC<
  Omit<SVGProps<SVGSVGElement>, 'xmlns' | 'viewBox' | 'width' | 'height'> &
    Required<Pick<SVGProps<SVGSVGElement>, 'viewBox'>> & {
      size?: number | string;
    }
> = ({ size = 16, ...props }) => <svg fill='currentColor' {...props} width={size} height={size} xmlns='http://www.w3.org/2000/svg' />;

export type IconProps = Omit<ComponentProps<typeof IconSvg>, 'viewBox'>;
