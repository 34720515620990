import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { OrderBaseModifyingDto, mapOrderBaseModifyingDtoToPersistance, mapPosibliPendingOrderModifyingDtoToPersistance } from '@root/modules/orders/dtos/modify-order.dto';
import { SfxOrder } from '@root/modules/orders/types/orders';
import { isLimitOrderType } from '@root/modules/orders/utils/orders';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type ModifyOrderServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export type SfxOrderModifyingDto = OrderBaseModifyingDto &
  Pick<SfxOrder, 'accountId' | 'type' | 'symbol' | 'expirationTime'> & {
    price: string;
    orderIsMagic: boolean;
  };

export const modifyOrderService = async (expertId: string, { accountId, orderIsMagic, type, symbol, ...rest }: SfxOrderModifyingDto): Promise<ModifyOrderServiceResponse> => {
  try {
    if (orderIsMagic) {
      await fetcher.put(
        `/accounts/${accountId}/magic`,
        mapPosibliPendingOrderModifyingDtoToPersistance(rest),

        { baseURL: import.meta.env.VITE_FX_CORE_HOST },
      );
    } else {
      if (isLimitOrderType(type)) {
        await fetcher.put(
          `/experts/${expertId}/orders/pending`,
          {
            ...mapPosibliPendingOrderModifyingDtoToPersistance(rest),
            symbol,
          },
          { baseURL: import.meta.env.VITE_FX_CORE_HOST },
        );
      } else {
        await fetcher.put(
          `/experts/${expertId}/orders/instant`,
          {
            ...mapOrderBaseModifyingDtoToPersistance(rest),
            symbol,
          },
          { baseURL: import.meta.env.VITE_FX_CORE_HOST },
        );
      }
    }

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
