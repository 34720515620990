import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';

import { useDrawings } from '@root/modules/charting-library/hooks/use-drawings';
import { DrawingType } from '@root/modules/charting-library/types/draw-types';
// import { useMarketPrice } from '@root/modules/magic-terminal/hooks/use-market-price';
import { MagicTerminalForm } from '@root/modules/magic-terminal/types/magic-terminal-form';

export const useMagicTerminalDrawings = () => {
  const { createDrawing, removeDrawing } = useDrawings();

  const { values, setFieldValue } = useFormikContext<MagicTerminalForm>();
  // const [{ debouncedMarketPrice }] = useMarketPrice();

  const { takeProfit, stopLoss } = useMemo(() => {
    return {
      takeProfit: Number(values.manualSlTp.fixedTp) || 1.1,
      stopLoss: Number(values.manualSlTp.fixedSl) || 1.08,
    };
  }, [values.manualSlTp.fixedTp, values.manualSlTp.fixedSl]);

  // const { partialTakeProfit } = useMemo(() => {
  //   if (values.partialClose.use) {
  //     return {
  //       partialTakeProfit: values.partialClose.takeprofits,
  //     };
  //   }
  //
  //   return {
  //     partialTakeProfit: null,
  //   };
  // }, [values.partialClose.use, values.partialClose.takeprofits]);

  const trailingStop = 1.07;
  // const breakEven = values.breakEven.use ? Number(values.breakEven.levels[0].setToY) : null;

  // here we manage takeProfit and stopLoss lines
  useEffect(() => {
    if (!takeProfit) {
      removeDrawing('takeProfit');
    }
    if (!stopLoss) {
      removeDrawing('stopLoss');
    }

    const createTakeProfitDrawing = () => {
      createDrawing(DrawingType.OrderLine, 'takeProfit', [
        {
          type: 'takeProfit',
          text: 'Take Profit',
          price: takeProfit,
          lowPrice: stopLoss || 0,
          onMove: (price) => setFieldValue('manualSlTp.fixedTp', price),
        },
      ]);
    };

    const createStopLossDrawing = () => {
      createDrawing(DrawingType.OrderLine, 'stopLoss', [
        {
          type: 'stopLoss',
          text: 'Stop Loss',
          price: stopLoss,
          highPrice: takeProfit,
          onMove: (price) => setFieldValue('manualSlTp.fixedSl', price),
        },
      ]);
    };

    if (takeProfit) createTakeProfitDrawing();
    if (stopLoss) createStopLossDrawing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopLoss, takeProfit, setFieldValue]);

  // here we manage trailingStop line
  useEffect(() => {
    if (!trailingStop) {
      removeDrawing('trailingStop');
    }
    const createTrailingStopDrawing = () => {
      createDrawing(DrawingType.OrderLine, 'trailingStop', [
        {
          type: 'trailingStop',
          text: 'Trailing Stop',
          price: trailingStop,
          onMove: (price) => setFieldValue('trailingStop', price),
        },
      ]);
    };

    if (trailingStop) createTrailingStopDrawing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailingStop]);

  // here we manage breakEven line

  // useEffect(() => {
  //   if (!breakEven) {
  //     removeDrawing('breakEven');
  //   }
  //   const createBreakEvenDrawing = () => {
  //     createDrawing(DrawingType.OrderLine, 'breakEven', [
  //       {
  //         type: 'breakEven',
  //         text: 'Break Even',
  //         price: breakEven,
  //         onMove: (price) => setFieldValue('breakEven', price),
  //       },
  //     ]);
  //   };
  //   if (breakEven) createBreakEvenDrawing();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [breakEven]);

  // here we manage partialTakeProfit line

  // useEffect(() => {
  //   if (!partialTakeProfit) {
  //     removeDrawing('partialTakeProfit');
  //   }
  //
  //   const createPartialTakeProfitDrawing = () => {
  //     createDrawing(
  //       DrawingType.OrderLine,
  //       'partialTakeProfit',
  //       partialTakeProfit?.map((item, index) => ({
  //         type: 'partialTakeProfit',
  //         text: 'Partial Take Profit',
  //         price: Number(item.price),
  //         onMove: (price) => setFieldValue(`partialClose.takeprofits.${index}.price`, price),
  //       })) || [],
  //     );
  //   };
  //
  //   if (partialTakeProfit) createPartialTakeProfitDrawing();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [partialTakeProfit]);
};
