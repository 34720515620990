import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { ResetTwoFaForm, ResetTwoRequestInfo } from '@root/shared-files/modules/auth/types/two-fa';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type SendToSupportResponse = IHttpResponse<200, ResetTwoRequestInfo> | IHttpResponse<400, string>;

export const sendToSupport = async (id: string, data: ResetTwoFaForm['supportRequest']): Promise<SendToSupportResponse> => {
  try {
    const payload = {
      ...data,
      step: undefined,
      contactEmail: data.contactEmail,
      identificationDocument: undefined,
      // identificationDocumentUrl: data.identificationDocumentUrl,
      identificationDocumentUrl: undefined,
      comment: data.comment?.length ? data.comment : undefined,
      registrationDate: data.registrationDate?.toISOString() || '',
    };

    const response = await fetcher.post(`/auth/2fa/reset-requests/${id}/send-to-support`, payload);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
