import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const ArrowGoUpAltIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' {...props}>
      <path d='M16.95 7.05078L7.05005 16.9508' stroke='currentColor' />
      <path d='M10 7L16.95 7.049L17 14' stroke='currentColor' />
    </svg>
  );
};
