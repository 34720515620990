import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IOrder } from '@root/modules/orders/types/orders';
import { isExternalOrder, isLimitOrderType } from '@root/modules/orders/utils/orders';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type DeleteOrderServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;
export type DeleteOrderServiceValues = IOrder;

export const deleteOrderService = async (userId: string, order: DeleteOrderServiceValues): Promise<DeleteOrderServiceResponse> => {
  try {
    if (isExternalOrder(order)) {
      if (isLimitOrderType(order.type)) {
        await fetcher.delete(`/accounts/${order.accountId}/orders/pending`, {
          baseURL: import.meta.env.VITE_FX_CORE_HOST,
          data: {
            ticket: order.ticket,
          },
        });
      } else {
        await fetcher.delete(`/accounts/${order.accountId}/orders/instant`, {
          baseURL: import.meta.env.VITE_FX_CORE_HOST,
          data: {
            ticket: order.ticket,
            lots: order.lots,
          },
        });
      }

      return {
        status: 200,
        payload: null,
      };
    }

    if (order.magic) {
      await fetcher.delete(`/accounts/${order.accountId}/magic`, {
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
        data: {
          ticket: order.ticket,
          lots: order.lots,
        },
      });

      return {
        status: 200,
        payload: null,
      };
    }

    if (isLimitOrderType(order.type)) {
      await fetcher.delete(`/experts/${order.expertId}/orders/pending`, {
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
        data: {
          ticket: order.ticket,
          symbol: order.symbol,
        },
      });
    } else {
      await fetcher.delete(`/experts/${order.expertId}/orders/instant`, {
        baseURL: import.meta.env.VITE_FX_CORE_HOST,
        data: {
          ticket: order.ticket,
          symbol: order.symbol,
          lots: order.lots,
        },
      });
    }

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};
