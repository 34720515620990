import ReactDOM from 'react-dom/client';

import App from '@root/App';
import '@root/i18n';
import '@root/infra/integrations/datadog';
import '@root/infra/integrations/sentry';
import '@root/infra/integrations/telemetry/tracing';
import '@root/infra/theme/styles/index.css';
import reportWebVitals from '@root/reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
