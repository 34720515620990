import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useChart } from '@root/modules/charting-library/contexts/chart-instance.context';
import { notify } from '@root/shared/utils/notification';

import { getSharedConfigService } from '../services/get-shared-config';
import { ShareConfigServiceRequest, shareConfigService } from '../services/share-config';

type SharedConfig = ShareConfigServiceRequest['data'];

interface ShareConfigContextType {
  sharedConfig: SharedConfig | null;
  isLoading: boolean;
  error: Error | null;
  downloadConfigById: (id: string) => Promise<SharedConfig | null>;
  saveConfig: (config: SharedConfig) => Promise<string | null>;
}

const ShareConfigContext = createContext<ShareConfigContextType | undefined>(undefined);

export const useShareConfig = () => {
  const context = useContext(ShareConfigContext);
  if (!context) {
    throw new Error('useShareConfig must be used within ShareConfigProvider');
  }
  return context;
};

interface ShareConfigProviderProps {
  children: React.ReactNode;
}

export const ShareConfigProvider: React.FC<ShareConfigProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [sharedConfig, setSharedConfig] = useState<SharedConfig | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const sharedConfigId = searchParams.get('sharedConfigId');

  const downloadConfigById = async (id: string): Promise<SharedConfig | null> => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await getSharedConfigService(id);
      if (response.status !== 200) {
        throw new Error('Failed to fetch shared config');
      }

      setSharedConfig(response.payload);

      return response.payload;
    } catch (err) {
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const saveConfig = async (config: SharedConfig): Promise<string | null> => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await shareConfigService({ data: config });
      if (response.status === 200) {
        notify({
          type: 'success',
          text: 'Config saved successfully',
        });

        return response.payload.id;
      } else {
        notify({
          type: 'danger',
          text: 'Failed to save config',
        });

        throw new Error('Failed to save config');
      }
    } catch {
      setError(new Error('Failed to save config'));
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (sharedConfigId) {
      (async () => {
        const config = await downloadConfigById(sharedConfigId);
        if (config?.chartData || config?.orderData) {
          setSharedConfig(config);
        }
      })();
    }
  }, [sharedConfigId]);

  const value = {
    sharedConfig,
    isLoading,
    error,
    downloadConfigById,
    saveConfig,
  };

  return <ShareConfigContext.Provider value={value}>{children}</ShareConfigContext.Provider>;
};
