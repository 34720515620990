import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { notify } from '@root/shared/utils/notification';

export const FeatureBaseWidget: FC = () => {
  const user = useSelector(authSelector.user);
  const { i18n } = useTranslation('common');

  useEffect(() => {
    const win = window;

    if (user?.id) {
      win.Featurebase(
        'identify',
        {
          organization: 'sgmfx',
          email: user?.email,
          name: user?.fullName,
          userId: user?.id,
          locale: i18n.language,
        },
        (err) => {
          if (!err) {
            if ((import.meta.env.VITE_MAIN_DOMAIN || '')?.includes('sagemaster') || window.location.origin.includes('https://sfx.3lgn.com')) {
              win.Featurebase(
                'initialize_survey_widget',
                {
                  organization: 'sgmfx',
                  placement: 'bottom-right',
                  theme: 'dark',
                  email: user?.email,
                  locale: 'en',
                },
                (err) => {
                  if (err) {
                    notify({
                      type: 'danger',
                      title: `${err}` || '',
                    });
                  }
                },
              );

              win.Featurebase('init_changelog_widget', {
                organization: 'sgmfx',
                dropdown: {
                  enabled: true,
                  placement: 'right',
                },
                popup: {
                  enabled: true,
                  usersName: user?.fullName,
                  autoOpenForNewUpdates: true,
                },
                theme: 'dark',
                locale: i18n.language,
              });
            }

            win.Featurebase('initialize_portal_widget', {
              organization: 'sgmfx',
              placement: 'right',
              fullScreen: true,
              userName: user?.fullName,
              initialPage: 'MainView',
              locale: i18n.language,
              metadata: null,
              theme: 'dark',
            });
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return null;
};
