import uniq from 'lodash/uniq';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetClosedOrdersProfit } from '@root/modules/orders/hooks/use-get-closed-orders-profit';
import { useGetOpenOrders } from '@root/modules/orders/hooks/use-open-orders';
import { Profit } from '@root/modules/orders/types/profit';
import { isMagicOrder, isNotUnknownCurrencyEntry, isSfxOrder } from '@root/modules/orders/utils/orders';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { getEndOfTheDay, getStartOfTheDay, toIsoStringWithoutTimezone } from '@root/shared/utils/date';
import { globalRound } from '@root/shared/utils/number/round';

export const useTodayClosedOrdersProfitByCurrencyMap = ({
  accountIds,
  mtType,
  expertId,
  isMagicTerminal,
  currencies,
  skipUnknownCurrency = false,
}: {
  accountIds?: string[];
  mtType?: string;
  expertId?: string;
  isMagicTerminal?: boolean;
  currencies?: string[];
  skipUnknownCurrency?: boolean;
}) => {
  const userId = useSelector(authSelector.userId);

  const todayClosedOrdersProfitByCurrencyMap = useGetClosedOrdersProfit({
    accountIds,
    isMagicTerminal,
    mtType,
    expertId: isMagicTerminal ? (userId as string) : expertId,
    fromDate: toIsoStringWithoutTimezone(getStartOfTheDay(new Date())),
    toDate: toIsoStringWithoutTimezone(getEndOfTheDay(new Date())),
    currencies,
  });

  return useMemo(() => {
    if (todayClosedOrdersProfitByCurrencyMap !== undefined && skipUnknownCurrency) {
      return Object.fromEntries(Object.entries(todayClosedOrdersProfitByCurrencyMap).filter(isNotUnknownCurrencyEntry));
    }

    return todayClosedOrdersProfitByCurrencyMap ?? {};
  }, [todayClosedOrdersProfitByCurrencyMap, skipUnknownCurrency]);
};

type Props = {
  selectedBroker?: string;
  accountIds?: string[];
  mtType?: string;
  expertId?: string;
  isMagicTerminal?: boolean;
  currencies?: string[];
  skipUnknownCurrency?: boolean;
};

export const useGetOrdersProfit = ({ selectedBroker, mtType, expertId, isMagicTerminal, currencies, skipUnknownCurrency = false }: Props) => {
  const userId = useSelector(authSelector.userId);

  const accountIds = selectedBroker === undefined || selectedBroker === 'all' ? undefined : [selectedBroker];

  const closedOrdersProfitByCurrencyMap = useGetClosedOrdersProfit({
    accountIds,
    isMagicTerminal,
    mtType,
    expertId: isMagicTerminal ? (userId as string) : expertId,
    currencies,
  });

  const { openOrders, loading: openOrdersLoading } = useGetOpenOrders({ enabled: true, selectedBroker, mtType });

  const openOrdersProfitByCurrencyMap: Record<string, Profit> = useMemo(() => {
    const orderByIsMagicType = isMagicTerminal ? (openOrders || []).filter(isSfxOrder).filter(isMagicOrder) : openOrders;
    const ordersByExpert = expertId ? (orderByIsMagicType || []).filter((item) => isSfxOrder(item) && item.expertId === expertId) : orderByIsMagicType || [];

    const data: Record<string, Profit> = {};

    ordersByExpert.forEach(({ currency, profit, calculatedUnits }) => {
      if (!currency) {
        return;
      }

      const profitData = data[currency];

      if (profitData === undefined) {
        data[currency] = {
          profit,
          pips: calculatedUnits,
        };
        return;
      }

      profitData.profit += profit;
      profitData.pips += calculatedUnits;
    });

    return data;
  }, [expertId, openOrders, isMagicTerminal]);

  const { filteredClosedOrdersProfitByCurrencyMap, filteredOpenOrdersProfitByCurrencyMap } = useMemo(
    () =>
      skipUnknownCurrency
        ? {
            filteredClosedOrdersProfitByCurrencyMap: Object.fromEntries(Object.entries(closedOrdersProfitByCurrencyMap ?? {}).filter(isNotUnknownCurrencyEntry)),
            filteredOpenOrdersProfitByCurrencyMap: Object.fromEntries(Object.entries(openOrdersProfitByCurrencyMap).filter(isNotUnknownCurrencyEntry)),
          }
        : {
            filteredClosedOrdersProfitByCurrencyMap: closedOrdersProfitByCurrencyMap ?? {},
            filteredOpenOrdersProfitByCurrencyMap: openOrdersProfitByCurrencyMap,
          },
    [closedOrdersProfitByCurrencyMap, openOrdersProfitByCurrencyMap, skipUnknownCurrency],
  );

  const ordersProfitByCurrencyMap: Record<string, Profit> = useMemo(
    () =>
      Object.fromEntries(
        uniq([...Object.keys(filteredOpenOrdersProfitByCurrencyMap), ...Object.keys(filteredClosedOrdersProfitByCurrencyMap ?? {})]).map((currency) => {
          const openOrdersProfit = filteredOpenOrdersProfitByCurrencyMap[currency];
          const closedOrderProfit = filteredClosedOrdersProfitByCurrencyMap?.[currency];

          if (openOrdersProfit !== undefined && closedOrderProfit !== undefined) {
            return [
              currency,
              {
                profit: globalRound(openOrdersProfit.profit + closedOrderProfit.profit, 2),
                pips: globalRound(openOrdersProfit.pips + closedOrderProfit.pips, 2),
              },
            ];
          }

          return [currency, openOrdersProfit ?? closedOrderProfit];
        }),
      ),
    [filteredOpenOrdersProfitByCurrencyMap, filteredClosedOrdersProfitByCurrencyMap],
  );

  return {
    openOrdersProfitByCurrencyMap: filteredOpenOrdersProfitByCurrencyMap,
    closedOrdersProfitByCurrencyMap: filteredClosedOrdersProfitByCurrencyMap,
    ordersProfitByCurrencyMap,
    loading: openOrdersLoading || closedOrdersProfitByCurrencyMap === undefined,
  };
};
