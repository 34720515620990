import { CreateExpertDtoMapper } from '@root/modules/experts/mappers/create-expert-dto.mapper';
import { GetMagicTerminalOrderType } from '@root/modules/magic-terminal/helpers/get-magic-terminal-order-type';
import { MagicTerminalForm, MagicTerminalOrderPendingType } from '@root/modules/magic-terminal/types/magic-terminal-form';
import { CreateMagicTerminalOrderData } from '@root/modules/magic-terminal/types/magic-terminal-order';

export class CreateMagicTerminalOrderMapper {
  public static toPersistance(data: MagicTerminalForm): CreateMagicTerminalOrderData {
    const expert = CreateExpertDtoMapper.toPersistence(data);

    return {
      expert: {
        ...expert,
        manualMoneyManagement: {
          ...expert.manualMoneyManagement,
          riskPercent: expert.manualMoneyManagement.riskPercent || 1,
          lotsize: expert.manualMoneyManagement.lotsize || 1,
        },
        trailStop: {
          ...expert.trailStop,
          afterX: expert.trailStop.afterX || 1,
          followY: expert.trailStop.followY || 1,
        },
        manualSlTp: {
          ...expert.manualSlTp,
          fixedTp: expert.manualSlTp.fixedTp || 30,
        },
      },
      symbol: data.symbol,
      price: data.pendingType === MagicTerminalOrderPendingType.LIMIT ? Number(data.price) : undefined,
      type: GetMagicTerminalOrderType(data.marketType, data.pendingType) as number,
    };
  }
}
