import { ExpertLogMessageCode } from '@3lgn/shared/dist/constants/fx';

import { fetcher } from '@root/infra/fetcher';
import { IExpert } from '@root/modules/experts/types/expert';
import { isSuccessfulResponseStatus } from '@root/shared/utils/http-utils';

export enum ResultLeave {
  error = 'ERROR',
  info = 'INFO',
  warning = 'WARNING',
  success = 'SUCCESS',
}

type SignalRetryResultCode =
  | ExpertLogMessageCode.NEW_SIGNAL_REPORT
  | ExpertLogMessageCode.NEW_SIGNAL_DYNAMIC_TAKE_PROFIT
  | ExpertLogMessageCode.NEW_SIGNAL_SUCCESS
  | ExpertLogMessageCode.NEW_SIGNAL_QUOTES_OUTDATED
  | ExpertLogMessageCode.NEW_SIGNAL_ACCOUNT_ORDER_LIMIT
  | ExpertLogMessageCode.NEW_SIGNAL_EXPERT_ORDER_LIMIT
  | ExpertLogMessageCode.NEW_SIGNAL_TIME_TOLERANCE_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_PRICE_TOLERANCE_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_MIN_LOT_SIZE_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_LOT_SIZE_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_BAD_REQUEST_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_SERVER_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_EXCEPTION_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_EXECUTION_EXCEPTION_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_EXECUTION_TP_PROXIMITY_VALIDATION_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_SYMBOL_NOT_EXIST_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_EXECUTION_CONNECTION_ERROR
  | ExpertLogMessageCode.NEW_SIGNAL_HIT_ACCOUNT_MAX_DRAW_DOWN_LIMIT
  | ExpertLogMessageCode.NEW_SIGNAL_HIT_EXPERT_MAX_DRAW_DOWN_LIMIT
  | ExpertLogMessageCode.NEW_SIGNAL_PLACED_TO_PENDING_ORDERS
  | ExpertLogMessageCode.NEW_SIGNAL_RETRY_FAILED_SIGNAL;

export type SignalRetryResult = {
  code: SignalRetryResultCode;
  level: ResultLeave;
  message: string;
};

export const sendSignalService = async (id: string, values: Omit<IExpert, 'id' | 'isEnabled' | 'createdAt'>): Promise<SignalRetryResult> => {
  const response = await fetcher.post<SignalRetryResult>(`/signals/${id}`, values, {
    baseURL: import.meta.env.VITE_FX_CORE_HOST,
  });

  if (isSuccessfulResponseStatus(response.status)) {
    return response.data;
  }

  throw response;
};
