import { ChartCandle } from '@root/modules/charting-library/types/candle';

export class CandlesMapper {
  public static toBars(candleList): ChartCandle[] {
    return candleList?.map(([timestamp, open, high, low, close, volume]) => ({
      time: isNaN(new Date(timestamp).getTime()) ? Number(timestamp) : new Date(timestamp).getTime(),
      low: low,
      high: high,
      open: open,
      close: close,
      volume: Number(volume),
    }));
  }

  // public static toBackTestFormat(candleList): BackTesting.ICandle[] {
  //   return candleList.map((bar) => ({
  //     timestamp: new Date(bar.timestamp).getTime().toString(),
  //     low: Number(bar.low),
  //     high: Number(bar.high),
  //     open: Number(bar.open),
  //     close: Number(bar.close),
  //     volume: Number(bar.volume),
  //   }));
  // }
  //
  // public static toNumbers(candleList): BackTesting.ICandle[] {
  //   return candleList.map((bar) => ({
  //     timestamp: bar.timestamp,
  //     low: Number(bar.low),
  //     high: Number(bar.high),
  //     open: Number(bar.open),
  //     close: Number(bar.close),
  //     volume: Number(bar.volume),
  //   }));
  // }
}
