export const createControllablePromise = <T>() => {
  let promiseResolve: (value: T | PromiseLike<T>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let promiseReject: (reason?: any) => void;

  const promise = new Promise<T>((resolve, reject) => {
    promiseResolve = resolve;
    promiseReject = reject;
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return [promise, { resolve: promiseResolve!, reject: promiseReject! }] as const;
};
