import * as yup from 'yup';

import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient } from '@root/infra/query';
import { ModifyModalProps } from '@root/modules/orders/components/update-order-modal';
import { ExternalPartialCloseModifyingDto, modifyExternalPartialCloseService } from '@root/modules/orders/services/modify-external-partial-close.service';
import { SfxPartialCloseModifyingDto, modifyPartialCloseService } from '@root/modules/orders/services/modify-partial-close.service';
import { isSfxOrder } from '@root/modules/orders/utils/orders';
import { IFormStatus } from '@root/shared/form';
import { notify } from '@root/shared/utils/notification';

export type ModifyPartialCloseDto = Omit<SfxPartialCloseModifyingDto & ExternalPartialCloseModifyingDto, 'lots'> & {
  lots: string;
  usePercentage: boolean;
  // In the form we use closeAbsoluteValue to store the original lots value
  // and use that while the validation
  closeAbsoluteValue: string;
  closePercentValue: string;
};

export const useModifyPartialClose = ({ order, closeModal }: ModifyModalProps) => {
  const { t } = useTranslation('forex-orders');
  const { t: yupT } = useTranslation('yup');

  const { accountId, symbol, ticket, lots } = order;

  const orderIsSfx = isSfxOrder(order);

  const orderIsMagic = orderIsSfx && order.magic;

  const expertId = orderIsSfx ? order.expertId : undefined;

  const initialValues = useMemo<ModifyPartialCloseDto>(
    () => ({
      lots: (lots ?? 0).toString(),
      symbol,
      ticket,
      usePercentage: false,
      closeAbsoluteValue: '0',
      closePercentValue: '0',
      orderIsMagic,
    }),
    [lots, symbol, ticket, orderIsMagic],
  );

  const onSubmit = useCallback(
    async (values: ModifyPartialCloseDto, helpers: FormikHelpers<ModifyPartialCloseDto>) => {
      helpers.setStatus(undefined);

      const data = {
        ...values,
        lots: parseFloat(values.closeAbsoluteValue),
      };

      const result = await (orderIsSfx
        ? modifyPartialCloseService(
            {
              accountId,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we know that expertId is not undefined when order is sfx
              expertId: expertId!,
            },
            data,
          )
        : modifyExternalPartialCloseService(accountId, data));

      if (result.status === 200) {
        helpers.setStatus({ type: 'success', message: null } as IFormStatus);

        queryClient.invalidateQueries({ queryKey: ['fx', expertId] });
        queryClient.invalidateQueries({ queryKey: ['fx', accountId, 'open-orders'] });
        queryClient.invalidateQueries({ queryKey: ['fx', { expertId }] });

        notify({
          type: 'success',
          title: t('updated'),
        });

        closeModal();
      } else {
        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
        notify({
          type: 'danger',
          title: result.payload,
        });
      }
    },
    [expertId, accountId, t, closeModal, orderIsSfx],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        lots: yup.number(),
        closeAbsoluteValue: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .required(yupT('mixed.requiredNoPath'))
          .positive()
          .test('is-not-more-than', t('fields.lotSize.validation-max-size'), function (value) {
            const { lots } = this.parent;
            return `${value}` <= lots;
          })
          .test('has-two-decimals', t('fields.lotSize.validation-decimals'), (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(`${value}`))
          .label(t('fields.lotSize.label')),
        closePercentValue: yup
          .number()
          .typeError(t('number.type', { ns: 'yup' }))
          .required(yupT('mixed.requiredNoPath'))
          .positive()
          .max(100, t('fields.lotSize.validation-max-percent'))
          .label(t('fields.lotSize.label')),
      }),
    [t, yupT],
  );

  return { initialValues, onSubmit, validationSchema };
};
