import React, { FC } from 'react';

import { IconProps, IconSvg } from '@root/shared/icons/icon-svg';

export const CloseIcon: FC<IconProps> = (props) => (
  <IconSvg {...props} viewBox='0 0 16 16'>
    <path d='M3.52902 3.5286C3.78937 3.26825 4.21148 3.26825 4.47183 3.5286L12.988 12.0447C13.2483 12.3051 13.2483 12.7272 12.988 12.9875C12.7276 13.2479 12.3055 13.2479 12.0452 12.9875L3.52902 4.4714C3.26867 4.21105 3.26867 3.78895 3.52902 3.5286Z' />
    <path d='M3.5286 12.9876C3.26825 12.7272 3.26825 12.3051 3.5286 12.0448L12.0447 3.52864C12.3051 3.26829 12.7272 3.26829 12.9875 3.52864C13.2479 3.78899 13.2479 4.2111 12.9875 4.47145L4.4714 12.9876C4.21105 13.2479 3.78895 13.2479 3.5286 12.9876Z' />
  </IconSvg>
);
