import { FC, SVGProps } from 'react';

import { baseIconProps } from '@root/shared/icons/base-icon-props';

export const CopyIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 16 17' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.6665 8.8488C6.6665 7.37604 7.86041 6.18213 9.33317 6.18213H13.3332C14.8059 6.18213 15.9998 7.37604 15.9998 8.8488V13.5155C15.9998 14.9882 14.8059 16.1821 13.3332 16.1821H9.33317C7.86041 16.1821 6.6665 14.9882 6.6665 13.5155V8.8488ZM9.33317 7.51546C8.59679 7.51546 7.99984 8.11242 7.99984 8.8488V13.5155C7.99984 14.2518 8.59679 14.8488 9.33317 14.8488H13.3332C14.0696 14.8488 14.6665 14.2518 14.6665 13.5155V8.8488C14.6665 8.11242 14.0696 7.51546 13.3332 7.51546H9.33317Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 2.8488C0 1.37604 1.19391 0.182129 2.66667 0.182129H6.66667C8.13943 0.182129 9.33333 1.37604 9.33333 2.8488V3.32499C9.33333 3.69318 9.03486 3.99165 8.66667 3.99165C8.29848 3.99165 8 3.69318 8 3.32499V2.8488C8 2.11242 7.40305 1.51546 6.66667 1.51546H2.66667C1.93029 1.51546 1.33333 2.11242 1.33333 2.8488V7.51546C1.33333 8.25184 1.93029 8.8488 2.66667 8.8488H4C4.36819 8.8488 4.66667 9.14727 4.66667 9.51546C4.66667 9.88365 4.36819 10.1821 4 10.1821H2.66667C1.19391 10.1821 0 8.98822 0 7.51546V2.8488Z'
        fill='currentColor'
      />
    </svg>
  );
};
