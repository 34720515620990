import { FC } from 'react';

import { TableWidgetItem } from '@root/modules/orders/components/total-widget-item';
import { Profit } from '@root/modules/orders/types/profit';

type Props = {
  ordersProfitByCurrencyMap: Record<string, Profit>;
  closedOrdersProfitByCurrencyMap: Record<string, Profit>;
  setStatVisible: (p: (prev: boolean) => boolean) => void;
  statVisible: boolean;
  isMagicTerminal?: boolean;
};

const Divider = () => <div className='hidden md:block w-px h-6 bg-color-divider mx-5' />;

export const OrderTableWidget: FC<Props> = ({ closedOrdersProfitByCurrencyMap, ordersProfitByCurrencyMap }) => (
  <div className='flex w-full md:w-auto flex-col md:flex-row py-1 px-2 md:px-3 items-center justify-center bg-grayscale-700 rounded-sm'>
    <TableWidgetItem profitByCurrencyMap={ordersProfitByCurrencyMap} />
    <Divider />
    <TableWidgetItem title='totalClosed' profitByCurrencyMap={closedOrdersProfitByCurrencyMap} />
  </div>
);
