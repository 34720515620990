import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrokerAccountSelect } from '@root/modules/accounts/components/broker-account-select';
import { useGetAccounts } from '@root/modules/accounts/hooks/use-get-accounts';
import { AllOptionAddingStrategy } from '@root/modules/accounts/hooks/use-get-accounts-options';
import { ModifyTradeModal } from '@root/modules/experts/components/details/trades-and-history/modify-trade-modal';
import { useGetExperts } from '@root/modules/experts/hooks/use-get-experts';
import { CloseOrderModal } from '@root/modules/orders/components/close-order-modal';
import { ExportToCsv } from '@root/modules/orders/components/export-to-csv';
import { ModifyPartialCloseModal } from '@root/modules/orders/components/partial-close-modal';
import { ShareOrderModal } from '@root/modules/orders/components/share-order-modal/share-order-modal';
import { SyncHistory } from '@root/modules/orders/components/sync-history';
import { OrderTableWidget } from '@root/modules/orders/components/total-widget';
import { ModifyOrderModal } from '@root/modules/orders/components/update-order-modal';
import { TradesTableContent } from '@root/modules/orders/containers/trades-table-content';
import { useGetSelectedBroker } from '@root/modules/orders/contexts/selected-broker.context';
import { TabType } from '@root/modules/orders/enums/orders';
import { useGetOrders } from '@root/modules/orders/hooks/use-get-orders';
import { useGetOrdersProfit } from '@root/modules/orders/hooks/use-get-orders-profit';
import { ModalType, useOrderActions } from '@root/modules/orders/hooks/use-order-actions';
import { useGetOrdersHistory } from '@root/modules/orders/hooks/use-order-history';
import { SimpleTabs } from '@root/shared/ui/tabs/simple-tabs';
import { Title } from '@root/shared/ui/typography';

export const TradesTable: FC<{ doNotDisplayUnknownCurrency?: boolean }> = ({ doNotDisplayUnknownCurrency = false }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'trades' });
  const { selectedBroker, mtType, isMagicTerminal } = useGetSelectedBroker();
  const [statVisible, setStatVisible] = useState<boolean>(false);

  const { data: accounts } = useGetAccounts();
  const currency = accounts?.find((item) => item.id === selectedBroker)?.currency;

  const [tab, setTab] = useState<TabType>(TabType.OPEN);
  const [{ list, pageSizeInput, page, paginatedData }, { setPage }] = useGetOrders({
    tab,
    enabled: true,
    selectedBroker,
    mtType,
    noPending: true,
    isMagicTerminal,
  });
  const [{ modalType, selectedOrder }, { deleteOrder, modifyOrder, modifyTrade, shareOrder, modifyPartialClose, closeModal }] = useOrderActions();

  const [{ ordersHistoryList, historyLoading, pagination }, { changeParams }] = useGetOrdersHistory({ enabled: tab === TabType.HISTORY, selectedBroker, mtType, isMagicTerminal });
  const { closedOrdersProfitByCurrencyMap, ordersProfitByCurrencyMap } = useGetOrdersProfit({
    selectedBroker,
    mtType,
    isMagicTerminal,
    skipUnknownCurrency: doNotDisplayUnknownCurrency,
  });

  const orders = tab === TabType.HISTORY ? ordersHistoryList : paginatedData;

  const { data: experts } = useGetExperts();

  const tableContentProps = {
    tab,
    page,
    historyLoading,
    orders,
    experts: experts || [],
    list,
    pagination,
    paginatedData,
    pageSizeInput,
    modifyOrder,
    modifyPartialClose,
    deleteOrder,
    modifyTrade,
    shareOrder,
    changeParams,
    setPage,
  };

  return (
    <div className='bg-gray-800 rounded-sm'>
      <div className='flex flex-col md:flex-row justify-between p-2 md:p-5 gap-2'>
        <Title level={5}>{t('title')}</Title>

        <div className='flex flex-col items-end xl:flex-row justify-end gap-4'>
          <BrokerAccountSelect tiny signedOnly={tab === TabType.OPEN} allOptionAddingStrategy={AllOptionAddingStrategy.supportSeveralCurrencies} />
          <OrderTableWidget
            ordersProfitByCurrencyMap={ordersProfitByCurrencyMap}
            closedOrdersProfitByCurrencyMap={closedOrdersProfitByCurrencyMap}
            statVisible={statVisible}
            setStatVisible={setStatVisible}
            isMagicTerminal={isMagicTerminal}
          />
        </div>
      </div>

      {/*{statVisible && isQaMember && <UserStatistics />}*/}

      <SimpleTabs value={tab} className='flex items-center justify-between mb-4 gap-4 bg-grayscale-700 pr-5 rtl:pr-[unset] rtl:pl-5' onChange={setTab}>
        <SimpleTabs.Nav>
          <SimpleTabs.Tab value={TabType.OPEN}>{t('filters.statuses.activeTrades')}</SimpleTabs.Tab>
          <SimpleTabs.Tab value={TabType.HISTORY}>{t('filters.statuses.ordersHistory')}</SimpleTabs.Tab>
        </SimpleTabs.Nav>
        {tab === TabType.HISTORY && (
          <div className='flex gap-1'>
            <SyncHistory />
            <ExportToCsv queryParams={{ accounts: selectedBroker && selectedBroker !== 'all' ? [selectedBroker] : undefined }} />
          </div>
        )}
      </SimpleTabs>

      <TradesTableContent {...tableContentProps} isExpertsDetails={isMagicTerminal} />
      {modalType === ModalType.Delete && selectedOrder && <CloseOrderModal isOpen closeModal={closeModal} order={selectedOrder} status={tab} />}
      {modalType === ModalType.Modify && selectedOrder && <ModifyOrderModal isOpen closeModal={closeModal} order={selectedOrder} />}
      {modalType === ModalType.EditTrade && selectedOrder && <ModifyTradeModal isOpen closeModal={closeModal} order={selectedOrder} />}
      {modalType === ModalType.PartialClose && selectedOrder && <ModifyPartialCloseModal isOpen closeModal={closeModal} order={selectedOrder} />}
      {modalType === ModalType.Share && selectedOrder && <ShareOrderModal isOpen closeModal={closeModal} order={selectedOrder} status={tab} currency={currency} />}
    </div>
  );
};
