import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { GET_SYMBOLS } from '@root/modules/accounts/queries/get-symbols.query';
import { authSelector } from '@root/shared-files/modules/auth/store';

export const useGetSymbols = () => {
  const userId = useSelector(authSelector.userId);

  return useQuery({ queryKey: ['fx', userId, 'symbols'], queryFn: GET_SYMBOLS, enabled: !!userId, staleTime: 1000 * 60 * 10 });
};
