export type Range = readonly [start: number, end: number];

export const hasIntersectRange = ([start, end]: Range, value: number) => value <= start || value >= end;

export const formatNumberWithCommas = (value: number): string => {
  // Convert number to string with 2 decimal places
  const parts = value.toString().split('.');

  // Add commas to the whole number part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Join whole and decimal parts with a dot
  return parts.join('.');
};
