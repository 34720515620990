import { useLayoutEffect, useState } from 'react';

import { useResizeObserver } from '@root/infra/layout/hooks/use-resize-observer';
import { useEvent } from '@root/shared/hooks/use-event';

export type Size = { width: number; height: number };

export const useElementSize = <T extends HTMLElement>() => {
  const [element, setElement] = useState<T | null>(null);
  const [size, setSize] = useState<Size | null>(null);

  useResizeObserver(element, ([entry]) => {
    const rect = entry.target.getBoundingClientRect();

    if (size !== null && rect.width === size.width && rect.height === size.height) {
      return;
    }

    setSize({ width: rect.width, height: rect.height });
  });

  useLayoutEffect(() => {
    if (element === null) {
      return;
    }

    return () => setSize(null);
  }, [element]);

  const setElementRef = useEvent((element: T | null) => {
    if (element === null) {
      if (size !== null) {
        setSize(null);
      }

      return;
    }

    const rect = element.getBoundingClientRect();

    setSize({ width: rect.width, height: rect.height });

    setElement(element);
  });

  return [setElementRef, size] as const;
};
