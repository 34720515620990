import { AxiosError } from 'axios';
import { ReactPaginateProps } from 'react-paginate';
import { Id } from 'react-toastify';

import { fetcher } from '@root/infra/fetcher';
import { MarketplaceTradeIdeaProviderTraderMapper } from '@root/modules/marketplace/mappers/marketplace-trade-idea-provider-trader.mapper';
import { MarketPlaceTradeIdeaProviderTrader } from '@root/modules/marketplace/types/marketplace-trade-idea-provider-trader';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';

export type MarketplaceTradeIdeaProviderTradersResponseData = {
  data: MarketPlaceTradeIdeaProviderTrader[];
  pagination: ReactPaginateProps;
};

export type GetMarketplaceTradeIdeaProviderTradersServiceResponse = IHttpResponse<200, MarketplaceTradeIdeaProviderTradersResponseData> | IHttpResponse<400, string>;

export type GetMarketplaceTradeIdeaProviderTradersQuery = {
  id: Id;
  page: number;
  itemsPerPage: number;
};

export const getMarketplaceTradeIdeaProviderTradersService = async ({
  id,
  page,
  itemsPerPage,
}: GetMarketplaceTradeIdeaProviderTradersQuery): Promise<GetMarketplaceTradeIdeaProviderTradersServiceResponse> => {
  try {
    const query = stringifyParams({
      page,
      size: itemsPerPage,
    });

    const response = await fetcher(`/trade-ideas/providers/${id}/traders?${query}`);

    return {
      status: 200,
      payload: {
        data: response.data.data.map((item) => MarketplaceTradeIdeaProviderTraderMapper.toDomain(item, id)),
        pagination: {
          pageCount: response.data.total / itemsPerPage > 1 ? Math.ceil(response.data.total / itemsPerPage) : 1,
        },
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
