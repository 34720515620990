import { ReactNode, createContext, useContext, useState } from 'react';

import { IChartingLibraryWidget, ResolutionString, SeriesType } from '../../../../public/charting_library/charting_library';

export type ChartFormatDrawings = {
  sources: Map<string, unknown>;
  lineToolsToValidate: string[];
  groups: Map<string, unknown>;
  groupsToValidate: Record<string, unknown>;
};

export type ChartSettings = {
  chartState: object;
  symbol: string;
  interval: ResolutionString;
  chartType: SeriesType;
};

interface ChartContextValue {
  chartInstance: IChartingLibraryWidget | null;
  setChartInstance: (instance: IChartingLibraryWidget | null) => void;
  exportChartSettings: () => Promise<ChartSettings | null>;
  importChartSettings: (settings: ChartSettings) => Promise<boolean>;
}

const ChartContext = createContext<ChartContextValue | undefined>(undefined);

interface ChartProviderProps {
  children: ReactNode;
}

export const ChartProvider = ({ children }: ChartProviderProps) => {
  const [chartInstance, setChartInstance] = useState<IChartingLibraryWidget | null>(null);

  const exportChartSettings = (): Promise<ChartSettings | null> => {
    return new Promise((resolve) => {
      if (!chartInstance) {
        console.warn('Chart instance not available');
        resolve(null);
        return;
      }

      try {
        const activeChart = chartInstance.activeChart();

        // Save chart state (includes indicators, etc.)
        chartInstance.save((chartState) => {
          try {
            // Get symbol and interval
            const symbol = activeChart.symbol();
            const interval = activeChart.resolution();
            const chartType = activeChart.chartType();

            // Convert Map objects to plain objects for storage
            const chartSettings: ChartSettings = {
              chartState,
              symbol,
              interval,
              chartType,
            };

            resolve(chartSettings);
          } catch (error) {
            console.error('Error exporting chart settings:', error);
            resolve(null);
          }
        });
      } catch (error) {
        console.error('Error exporting chart settings:', error);
        resolve(null);
      }
    });
  };

  const importChartSettings = async (settings: ChartSettings): Promise<boolean> => {
    if (!chartInstance) {
      console.warn('Chart instance not available');
      return false;
    }

    try {
      const activeChart = chartInstance.activeChart();

      // Update symbol and resolution if needed
      if (activeChart.symbol() !== settings.symbol || activeChart.resolution() !== settings.interval) {
        await new Promise<void>((resolve) => {
          chartInstance.setSymbol(settings.symbol, settings.interval, () => {
            resolve();
          });
        });
      }

      // Load chart state (includes indicators, etc.)
      chartInstance.load(settings.chartState);

      return true;
    } catch (error) {
      console.error('Error importing chart settings:', error);
      return false;
    }
  };

  const value = {
    chartInstance,
    setChartInstance,
    exportChartSettings,
    importChartSettings,
  };

  return <ChartContext.Provider value={value}>{children}</ChartContext.Provider>;
};

export const useChart = (): ChartContextValue => {
  const context = useContext(ChartContext);
  if (context === undefined) {
    throw new Error('useChart must be used within a ChartProvider');
  }
  return context;
};
