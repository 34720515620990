import axios, { AxiosError } from 'axios';

import { SentryFeedbackDto } from '@root/modules/user-settings/dtos/sentry-feedback.dto';
import type { IHttpMessage, IHttpResponse } from '@root/shared/utils/http-service';

export type LeaveFeedbackResponse = IHttpResponse<200, IHttpMessage> | IHttpResponse<400, string>;

export const leaveSentryFeedback = async (data: SentryFeedbackDto): Promise<LeaveFeedbackResponse> => {
  try {
    const response = await axios.post(`https://sentry.io/api/0/projects/bemeliorism/forex-fe/user-feedback/`, data, {
      headers: {
        Authorization: `DSN ${import.meta.env.VITE_SENTRY_DSN}`,
      },
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      console.log(error.response.data);
      return {
        status: 400,
        payload: error.response.data?.message || error.response.data?.detail || (typeof error.response.data === 'string' ? error.response.data : 'Bad request'),
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
