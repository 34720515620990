import isEqual from 'lodash/isEqual';

import { Formik, FormikProps } from 'formik';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BreakEven } from '@root/modules/experts/components/break-even';
import { PartialTakeProfitsFieldset } from '@root/modules/experts/components/partial-take-profits-fieldset/partial-take-profits-fieldset';
import { TrailingStop } from '@root/modules/experts/components/trailing-stop';
import { CreateExpertDto } from '@root/modules/experts/dtos/create-expert.dto';
import { useModifyTradeForm } from '@root/modules/experts/hooks/use-modify-trade-form';
import { IExpert } from '@root/modules/experts/types/expert';
import { IOrder, SfxOrder } from '@root/modules/orders/types/orders';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { Button } from '@root/shared/ui/button';
import { Modal } from '@root/shared/ui/modal';

export type ExpertOrder = SfxOrder & { expertConfiguration: IExpert };
export type ModifyTradeForm = Omit<CreateExpertDto, 'meta'> & { partialAllowed: boolean };

type Props = {
  order: IOrder;
  isOpen: boolean;
  closeModal: () => void;
};

type FormProps = FormikProps<ModifyTradeForm> & { closeModal: () => void; orderIsMagic: boolean };

const ModifyTradeFormComponent: FC<FormProps> = ({ initialValues, values, closeModal, handleSubmit, isSubmitting, orderIsMagic }) => {
  const { t } = useTranslation('dashboard');

  const hasChanged = useMemo(() => !isEqual(initialValues, values), [initialValues, values]);

  const subscriptionAllowFxMagicBreakeven = useSelector(authSelector.subscriptionAllowFxMagicBreakeven);
  const subscriptionAllowFxMagicTrailingStop = useSelector(authSelector.subscriptionAllowFxMagicTrailingStop);
  const subscriptionAllowFxMagicPartialTakeProfit = useSelector(authSelector.subscriptionAllowFxMagicPartialTakeProfit);

  return (
    <form className='w-full flex flex-col gap-2 -mt-4' onSubmit={handleSubmit}>
      <TrailingStop use={values.trailStop.use} unitType={values.unitType} isTradeUpdate disabled={orderIsMagic && subscriptionAllowFxMagicTrailingStop} />
      {(values.partialAllowed || values.partialClose.use) && (
        <PartialTakeProfitsFieldset viewOnly={!values.partialAllowed} isTradeUpdate disabled={orderIsMagic && subscriptionAllowFxMagicPartialTakeProfit} />
      )}
      <BreakEven use={values.breakEven.use} levels={values.breakEven.levels} disabled={orderIsMagic && subscriptionAllowFxMagicBreakeven} />
      <div className='w-full flex justify-between gap-x-4'>
        <Button onClick={closeModal} outlined>
          {t('editTradeModal.cancel')}
        </Button>
        <Button type='submit' loading={isSubmitting} disabled={!hasChanged}>
          {t('editTradeModal.update')}
        </Button>
      </div>
    </form>
  );
};

export const ModifyTradeModal: FC<Props> = memo(
  ({ isOpen, order, closeModal }) => {
    const { t } = useTranslation('dashboard');
    const { initialValues, validationSchema, onSubmit } = useModifyTradeForm({ order: order as ExpertOrder, closeModal });

    return (
      <Modal isOpen={isOpen} onCancel={closeModal} title={t('trades.editTrade')} footer={null} className='max-w-2xl!'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          component={(props) => <ModifyTradeFormComponent {...props} closeModal={closeModal} orderIsMagic={(order as ExpertOrder).magic} />}
          enableReinitialize
        />
      </Modal>
    );
  },
  (prev, next) => prev.isOpen === next.isOpen && prev.order.ticket === next.order.ticket,
);

ModifyTradeModal.displayName = 'ModifyTradeModal';
