import dayjs from 'dayjs';

import { nomicsResolutions, supportedResolutions } from '@root/modules/charting-library/constants/supported-resolutions';
import { getCandles } from '@root/modules/charting-library/services/get-candles.service';
import { DataFeedConfig, DataFeedInterface, SymbolInfo } from '@root/modules/charting-library/types/datafeed-types';
import { parseFullSymbol } from '@root/modules/charting-library/utils/parse-symbol';

import { ErrorCallback, HistoryCallback, LibrarySymbolInfo, OnReadyCallback, PeriodParams, ResolutionString, Timezone } from '../../../../public/charting_library/datafeed-api';

export class DataFeed implements DataFeedInterface {
  private maxHistoryDate: number;

  constructor(config?: DataFeedConfig) {
    this.maxHistoryDate = dayjs()
      .subtract(config?.maxHistoryDays || 89, 'd')
      .unix();
  }

  onReady(callback: OnReadyCallback): void {
    setTimeout(() => callback({}));
  }

  // search inside chart is disabled
  searchSymbols(): Promise<void> {
    return Promise.resolve();
  }

  async resolveSymbol(symbolName: string, onSymbolResolvedCallback: (symbolInfo: LibrarySymbolInfo) => void): Promise<void> {
    const [name, ticker] = symbolName.split(':');

    const symbolInfo: SymbolInfo = {
      name,
      ticker,
      type: 'forex',
      session: '24x7',
      timezone: 'Etc/UTC' as Timezone,
      minmov: 1,
      pricescale: 100000, // 1 = 0.00001 precision
      has_intraday: true,
      intraday_multipliers: ['1', '5', '15', '30', '60'],
      supported_resolutions: supportedResolutions as ResolutionString[],
      volume_precision: 2,
      data_status: 'streaming',
      description: name,
      exchange: 'forex',
      listed_exchange: 'forex',
      format: 'price',
    };

    setTimeout(() => onSymbolResolvedCallback(symbolInfo as LibrarySymbolInfo), 0);

    try {
      onSymbolResolvedCallback(symbolInfo as LibrarySymbolInfo);
    } catch {
      onSymbolResolvedCallback(symbolInfo as LibrarySymbolInfo);
    }
  }

  async getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback,
  ): Promise<void> {
    const { firstDataRequest } = periodParams;
    const parsedSymbol = parseFullSymbol(symbolInfo.name);

    if (!parsedSymbol) {
      return onErrorCallback('cannot resolve symbol');
    }

    const finalStartTime = Math.max(periodParams.from, this.maxHistoryDate);

    if (periodParams.from < this.maxHistoryDate && !firstDataRequest) {
      return onHistoryCallback([], { noData: true });
    }

    const response = await getCandles({
      exchange: parsedSymbol.exchange.toLowerCase(),
      market: parsedSymbol.symbol || 'EURUSD',
      end: dayjs(periodParams.to * 1000)
        .startOf('minute')
        .subtract(1, 'minute')
        .toISOString(),
      start: dayjs(finalStartTime * 1000)
        .startOf('minute')
        .toISOString(),
      interval: nomicsResolutions[resolution],
    });

    if (response.status === 200) {
      const bars = response.payload.map((candle) => ({
        time: candle.time || 0,
        open: candle.open,
        high: candle.high,
        low: candle.low,
        close: candle.close,
        volume: candle.volume,
      }));
      onHistoryCallback(bars, {
        noData: false,
      });
    } else {
      return onHistoryCallback([], {
        noData: true,
      });
    }
  }

  subscribeBars() // symbolInfo: LibrarySymbolInfo,
  // resolution: ResolutionString,
  // onTick: SubscribeBarsCallback,
  // listenerGuid: string,
  // onResetCacheNeededCallback: () => void,
  : void {
    // Subscription logic can be implemented here if needed
  }

  unsubscribeBars(/*listenerGuid: string*/): void {
    // Unsubscription logic can be implemented here if needed
  }
}
