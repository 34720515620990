import { fetcher } from '@root/infra/fetcher';
import { Profit } from '@root/modules/orders/types/profit';
import { stringifyParams } from '@root/shared/utils/string/query-stringify';
import { Id } from '@root/shared/utils/types';

export type GetClosedOrdersPnLServiceResponse = Record<string, Profit>;
export type GetClosedOrdersPnLServiceQuery = {
  accountIds?: Id[];
  expertId?: string;
  isMagicTerminal?: boolean;
  fromDate?: string;
  toDate?: string;
  currencies?: string[];
};

export const getClosedOrdersPnL = async ({
  fromDate,
  toDate,
  accountIds,
  expertId,
  isMagicTerminal,
  currencies,
}: GetClosedOrdersPnLServiceQuery): Promise<GetClosedOrdersPnLServiceResponse> => {
  const stringifiedParams = stringifyParams({
    accounts: accountIds,
    experts: expertId,
    isMagic: isMagicTerminal,
    fromDate: fromDate,
    toDate: toDate,
    currencies,
  });

  const response = await fetcher.get(`/users/orders/closed/profit-by-currency?${stringifiedParams}`, {
    baseURL: import.meta.env.VITE_FX_CORE_HOST,
  });

  return response.data.results;
};
