import { keepPreviousData, useQuery } from 'react-query';

import { GET_PROXY_SERVERS, GetProxyServersData, GetProxyServersQueryKey } from '@root/modules/accounts/queries/get-proxy-servers.query';

export const useGetProxyServers = () => {
  return useQuery<GetProxyServersData, Error, GetProxyServersData, GetProxyServersQueryKey>({
    queryKey: ['proxy-servers'],
    queryFn: GET_PROXY_SERVERS,
    placeholderData: keepPreviousData,
  });
};
