import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { SignUpDto } from '@root/shared-files/modules/auth/dtos';
import { AuthTokensMapper, AuthUserMapper, SignUpDtoMapper } from '@root/shared-files/modules/auth/mappers';
import { AuthModuleConfig } from '@root/shared-files/modules/auth/module.config';
import { IAuth } from '@root/shared-files/modules/auth/types';
import type { IHttpResponse } from '@root/shared/utils/http-service';

export type SignUpServiceResponse = IHttpResponse<200, Omit<IAuth, 'userSubscriptionInfo'>> | IHttpResponse<400, string>;

export const signUpService = async (payload: SignUpDto): Promise<SignUpServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/sign-up', {
      ...SignUpDtoMapper.toPersistence(payload),
      redirectPath: '/verify-email',
      sourceApp: AuthModuleConfig.sourceApp,
    });

    return {
      status: 200,
      payload: {
        user: AuthUserMapper.toDomain(response.data.user),
        tokens: AuthTokensMapper.toDomain(response.data),
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      const message = error?.response?.data?.message;
      if (typeof message === 'object' && message?.length) {
        return {
          status: 400,
          payload: error.response.data.message?.[0]?.errors?.[0],
        };
      } else {
        return {
          status: 400,
          payload: error.response.data.message,
        };
      }
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
