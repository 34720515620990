import { FC, ReactNode } from 'react';

import { CheckboxProps, CheckboxStyled } from '@root/shared/ui/form/checkbox/checkbox-field.styled';
import { FieldHint } from '@root/shared/ui/form/field/field-hint';
import { Toggler } from '@root/shared/ui/toggler/toggler';

export type CheckboxFieldProps = CheckboxProps & {
  label?: ReactNode;
  error?: string;
  hideError?: boolean;
  id?: string;
  toggleView?: boolean;
};

const { Container, Input, Icon, Label } = CheckboxStyled;

export const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  error,
  id,
  hideError,
  toggleView = false,
  checked,
  disabled,
  onChange,
  type = 'checkbox',
  className,
  ...inputProps
}) => {
  return (
    <div className={className}>
      <Container className='gap-2 items-center' htmlFor={id}>
        <Input disabled={disabled} checked={checked} onChange={onChange} {...inputProps} type={type} id={id} />
        {toggleView ? <Toggler checked={checked} disabled={disabled} /> : <Icon type={type as 'checkbox' | 'radio'} checked={!!checked} />}

        {label !== undefined && <Label>{label}</Label>}
      </Container>
      {!hideError && <FieldHint variant='danger'>{error}</FieldHint>}
    </div>
  );
};
