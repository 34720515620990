import styled from 'styled-components';

import clsx from 'clsx';
import { FC, ReactElement, ReactNode, cloneElement, lazy, useCallback } from 'react';
import { PopoverPosition } from 'react-tiny-popover';

import { useModalState } from '@root/shared/hooks/use-modal-state';

const Popover = lazy(() => import('react-tiny-popover').then(({ Popover }) => ({ default: Popover })));

const ArrowContainer = lazy(() => import('react-tiny-popover').then((module) => ({ default: module.ArrowContainer })));

const Container = styled.div`
  display: block;
  box-shadow: 0 10px 24px 0 rgba(12, 12, 12, 0.6);
  background-color: ${({ theme }) => theme.gray[800]};
  border-radius: 4px;
  padding: 4px 8px;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.gray[800]}`};
  min-width: 100px;
  max-width: 280px;
`;

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  positions?: PopoverPosition[];
  wrapperClassName?: string;
  keepOnMouseLeave?: boolean;
  arrowSize?: number;
  padding?: number;
  inline?: boolean;
};

export const PureInfoTooltip: FC<{
  content: ReactNode;
  positions?: PopoverPosition[];
  keepOnMouseLeave?: boolean;
  arrowSize?: number;
  padding?: number;
  children: ReactElement;
}> = ({ children, content, positions, keepOnMouseLeave = false, arrowSize = 10, padding = 15 }) => {
  const [isDropdownOpen, show, hide] = useModalState(false);

  const handleMouseLeave = useCallback(() => {
    if (keepOnMouseLeave) {
      return;
    }

    hide();
  }, [keepOnMouseLeave, hide]);

  const triggerProps = { onMouseEnter: show, onMouseLeave: handleMouseLeave };

  return (
    <Popover
      positions={positions || ['bottom']}
      align='center'
      padding={padding}
      onClickOutside={hide}
      isOpen={isDropdownOpen}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer position={position} childRect={childRect} popoverRect={popoverRect} arrowColor={'#202020'} arrowSize={arrowSize} arrowStyle={{ zIndex: 101 }}>
          <Container className='text-sm'>{content}</Container>
        </ArrowContainer>
      )}
      containerStyle={{ zIndex: '100000' }}
    >
      {cloneElement(children, triggerProps)}
    </Popover>
  );
};

export const InfoTooltip: FC<Props> = ({ children, content, positions, wrapperClassName = '', keepOnMouseLeave = false, arrowSize = 10, padding = 15, inline = false }) => {
  if (!content) {
    return <span>{children}</span>;
  }

  return (
    <PureInfoTooltip content={content} positions={positions} keepOnMouseLeave={keepOnMouseLeave} arrowSize={arrowSize} padding={padding}>
      {inline ? <span className={clsx('cursor-pointer', wrapperClassName)}>{children}</span> : <div className={clsx('cursor-pointer', wrapperClassName)}>{children}</div>}
    </PureInfoTooltip>
  );
};
